import React from "react";
import * as Icon from "react-feather";

import { Button, Tooltip, Typography } from "@material-ui/core";
import { ArrowSquareIn, LineSegments } from "phosphor-react";
import { useHistory } from "react-router";
import {
  FilterButton,
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component.jsx";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import TrackableStats from "../../Utilities/TrackableStats/TrackableStats.jsx";
import useStyle from "./ShotVersionsTools.styles";

function ShotVersionsTools(props) {
  const classes = useStyle();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <BreadCrumb
          shotCode={props?.parentShot ? props?.parentShot.code : ""}
        />
        <TrackableStats stats={props.shotVersionStats} type={"Shot Versions"} />
      </div>
      <div className={classes.divContainerRight}>
        <Tooltip title="See Timeline" placement="top">
          <Button
            className={classes.timelineButton}
            onClick={props.handleOpenTimelineModal}
          >
            <LineSegments size={20} />
            <Typography style={{ marginLeft: "4px" }}>Timeline</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="See Tasks" placement="top">
          <Button
            style={{ minWidth: "25px", padding: "2px" }}
            onClick={(event) => {
              event.stopPropagation();
              history.push(
                `/${props?.projectId}/sequence/${props?.parentShot?.parent_sequence?.id}/shots/${props?.parentShot?.id}/task`
              );
            }}
          >
            <ArrowSquareIn height={25} width={25} />
          </Button>
        </Tooltip>
        <div>
          <ToggleButton handleToggler={props?.handleShotVersionToggle} />
        </div>
        <div>
          <SortButton
            onSubmit={props?.onSubmit}
            defaultvalue={props?.defaultvalue}
          />
        </div>
        <div>
          <FilterButton
            btnActive={props?.filterButtonActive}
            openFilterModal={props?.handleFilterModalState}
          />
        </div>
        <Button
          size="small"
          className={classes.buttonEditShotandNewVersion}
          disableElevation
          onClick={props?.openNewShotVersionModal}
        >
          <Icon.Plus className={classes.buttonIcon} />
          New Version
        </Button>
      </div>
    </div>
  );
}

export default ShotVersionsTools;
