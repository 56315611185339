import { Box, Button, Typography } from "@material-ui/core";
import { Trash, X } from "phosphor-react";
import React from "react";
import { fdelete } from "../../../../API/callsAPI";
import useStyles from "./ProjectMediaDeleteModal.styles";

function ProjectMediaDeleteModal({
  handleClose,
  type,
  data,
  refreshData,
  handleBulkDelete,
  totalCount,
}) {
  const classes = useStyles();

  const handleDelete = async () => {
    const url =
      type === "Folder" ? `repository/${data.id}/` : `media/${data?.id}`;
    try {
      await fdelete({
        url: url,
      });
      refreshData();
      handleClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.flexModal}>
        <Trash size={100} color="#FF0000" />
        <Box style={{ display: "flex", flexDirection: "column", gap: "18px" }}>
          <Typography style={{ fontWeight: "600" }} variant="h4">
            Delete Confirmation
          </Typography>
          <Typography style={{ alignSelf: "flex-end" }}>
            Are you sure you want to delete{" "}
            {type === "Items" ? "these" : "this"} {type}?
          </Typography>
        </Box>
      </Box>
      <Box mt={4} mb={6} className={classes.flexModal}>
        <Typography variant="h5">
          {type === "Folder" && data.name}
          {type === "File" && data?.file_name}
        </Typography>
        {type === "Folder" && (
          <Typography>
            <span className={classes.text}>Total Items:</span>{" "}
            {data?.files?.length + data?.folders?.length}
          </Typography>
        )}
        {type === "Items" && (
          <Typography style={{ width: "100%", textAlign: "center" }}>
            <span className={classes.text}>Total Items:</span> {totalCount}
          </Typography>
        )}
      </Box>

      <Box className={classes.flexCenter}>
        <Button
          className={classes.btnRed}
          startIcon={<Trash />}
          onClick={() => {
            type === "Items" ? handleBulkDelete() : handleDelete();
          }}
        >
          Delete {type}
        </Button>
        <Button
          className={classes.btnOutline}
          startIcon={<X />}
          onClick={handleClose}
        >
          Cancel Delete
        </Button>
      </Box>
    </Box>
  );
}

export default ProjectMediaDeleteModal;
