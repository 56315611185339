import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    height: "250px",
    position: "absolute",
    right: "13%",

    top: "19vh",
    [theme.breakpoints.down("md")]: {
      top: "15vh",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20vh",
    },
  },
  popoverArrow: {
    zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-15px",
    right: "10px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "13px 27px",
    justifyContent: "space-between",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  sortButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "115px",
    textTransform: "none",
    marginBottom: "15px",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
  },
  //bulk task update Modal
  root: {
    width: "500px",
    borderRadius: "20px",
    padding: "16px 24px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    backgroundColor: theme.palette.primary.main,
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    borderRadius: "10px",
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 14px",
    height: "15px",
    width: "15px",
    border: "2px solid",
    borderColor: theme.palette.borderColor,
  },
  text1: {
    fontSize: "14px",
    color: theme.palette.secondary.main,
  },
  textTwo: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
    marginBottom: "5px",
  },
  textThree: {
    fontSize: "24px !important",
  },
  btn: {
    minWidth: "0px !important",
    width: "125px",
    borderRadius: "10px",
    padding: "8px !important",
    backgroundColor: theme.palette.secondary.main,
    alignSelf: "center",
  },
  deleteButton: {
    minWidth: "0px !important",
    width: "125px",
    borderRadius: "10px",
    padding: "8px !important",
    border: `2px solid red`,
    alignSelf: "center",
  },
  dateInput: {
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    borderRadius: "10px",
    marginTop: "0px",
  },
}));

export default useStyles;
