import { useState } from "react";

export const UpdatesPageDataHook = () => {
  const [updatePageTestData, setUpdatePageTestData] = useState(
    "updates page test data passed successfully"
  );
  return {
    updatePageTestData,
  };
};
