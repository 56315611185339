import { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { CaretRight, Link, DotsThree } from "phosphor-react";
import useStyles from "./ProjectMediaTools.style";
import { ProjectMediaIconBig } from "../../Utilities/Svg/ProjectMediaIcon";

const ProjectMediaBreadCrumbs = ({
  breadCrumbs,
  breadCrumbsId,
  projectId,
  handleCopy,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const bredCrumbsRef = useRef();
  const [overflowBreadCrumbs, setOverflowBreadCrumbs] = useState({
    isOverflowing: false,
    count: 0,
  });
  const [openBreadCrumbsModal, setBreadCrumbsModal] = useState(null);

  const handleOpenBreadcrumbsModal = (event) => {
    setBreadCrumbsModal(event.currentTarget);
  };

  const handleCloseBreadcrumbsModal = () => {
    setBreadCrumbsModal(null);
  };

  function handleClick(index) {
    if (index === 1) {
      history.push(`/${projectId}/project-media`, {
        shallow: true,
      });
    } else {
      history.push(`/${projectId}/project-media/${breadCrumbsId[index]}`, {
        shallow: true,
      });
    }
  }

  useEffect(() => {
    if (
      bredCrumbsRef?.current?.scrollWidth > bredCrumbsRef?.current?.offsetWidth
    ) {
      setOverflowBreadCrumbs({
        isOverflowing: true,
        count: breadCrumbs.length,
      });
    }
    if (breadCrumbs?.length < overflowBreadCrumbs.count) {
      setOverflowBreadCrumbs({ isOverflowing: false, count: 0 });
    }
  }, [breadCrumbs]);

  return (
    <Box className={classes.flexDiv} ref={bredCrumbsRef}>
      {overflowBreadCrumbs.isOverflowing ? (
        <div className={classes.gap}>
          <Typography
            className={classes.breadCrumb}
            onClick={() => handleClick(1)}
          >
            {/* <ProjectMediaIconBig /> */}
            {breadCrumbs.length > 0 && breadCrumbs[1]}
          </Typography>
          <Button onClick={handleOpenBreadcrumbsModal}>
            <DotsThree size={25} />
          </Button>
          <Typography
            className={classes.endBreadCrumb}
            onClick={() => handleClick(breadCrumbs.length - 1)}
          >
            {breadCrumbs.length > 0 && breadCrumbs[breadCrumbs.length - 1]}
            <Tooltip placement="top" title="Copy Folder Path">
              <Button onClick={handleCopy}>
                <Link size={21} color="#6C65D9" />
              </Button>
            </Tooltip>
          </Typography>
          <Menu
            anchorEl={openBreadCrumbsModal}
            open={Boolean(openBreadCrumbsModal)}
            onClose={handleCloseBreadcrumbsModal}
            style={{ marginTop: "30px" }}
            MenuListProps={{ className: `${classes.menuPop}` }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {breadCrumbs?.map((breadcrumb, index) => {
              return (
                <MenuItem
                  className={classes.menuItems}
                  onClick={() => {
                    handleClick(index);
                    handleCloseBreadcrumbsModal();
                  }}
                >
                  {breadcrumb}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      ) : (
        breadCrumbs?.map((eachLink, index) => {
          return (
            index !== 0 && (
              <Box className={classes.gap}>
                <Typography
                  className={
                    index === breadCrumbs.length - 1
                      ? classes.endBreadCrumb
                      : classes.breadCrumb
                  }
                  onClick={() => handleClick(index)}
                >
                  {/* {index === 1 && <ProjectMediaIconBig />} */}
                  {eachLink}
                </Typography>
                {index === breadCrumbs.length - 1 ? (
                  <Tooltip placement="top" title="Copy Folder Path">
                    <Button onClick={handleCopy}>
                      <Link size={21} color="#6C65D9" />
                    </Button>
                  </Tooltip>
                ) : (
                  <CaretRight
                    color="#606479"
                    size={21}
                    className={classes.separatorIcon}
                  />
                )}
              </Box>
            )
          );
        })
      )}
    </Box>
  );
};

export default ProjectMediaBreadCrumbs;
