import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "24px",
    position: "relative",
  },
  dragScreen: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    fontSize: "1.5rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.border.main}`,
    borderStyle: "dashed",
    color: "#FFF",
    zIndex: "999",
    backgroundColor: "rgb(37, 42, 56,0.4)",
  },
  flexDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.text.tertiary,
    fontSize: "14px",
  },
  containedButton: {
    minWidth: "0px",
    padding: "12px 24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    fontFamily: "Poppins",
  },
  outlinedButton: {
    minWidth: "0px",
    padding: "12px 24px",
    borderRadius: "10px",
    // backgroundColor: theme.palette.secondary.main,
    border: "1px solid gray",
    fontFamily: "Poppins",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    backgroundColor: theme.palette.secondary.light,
    border: "none",
    padding: "10px",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  text: {
    fontFamily: "Poppins",
    color: "white",
  },

  cardContentDiv2: {
    width: "100%",
    height: "75px",
    display: "grid",
    placeItems: "center",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    borderStyle: "dashed",
    border: `2px solid ${theme.palette.text.tertiary}`,
    marginTop: "2%",
  },

  fileIcon: {
    color: theme.palette.text.primary,
    position: "absolute",
    left: "0",
    marginLeft: "2rem",
  },

  label: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  labelText: {
    textAlign: "center",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    padding: "0px 17px",
    overflow: "hidden",
    wordBreak: "break-all",
  },

  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
  },
}));

export default useStyles;
