import React, { useState, useEffect } from "react";
import { Button, Switch, Popover } from "@material-ui/core";
import { List, Rows, SortAscending, Funnel, SquaresFour } from "phosphor-react";
import useStyles from "./CommonButtons.style";
import SortModal from "../../Utilities/SortModal/SortModal";

export const SortButton = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [btnActive, setBtnActive] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setOpen(true);
    }
  }, [anchorEl]);

  useEffect(() => {
    setBtnActive(
      props?.defaultvalue === "Z-A" ||
        props?.defaultvalue === "LastModified" ||
        props?.defaultvalue === "A-Z"
        ? true
        : false
    );
  }, [props?.defaultvalue]);

  return (
    <div>
      <div style={{ height: "8px", marginTop: "-8px" }}>
        {btnActive && <div className={classes.dotBlur}></div>}
        {btnActive && <div className={classes.dot}></div>}
      </div>
      <Button className={classes.filterSortBtn} onClick={handleClick}>
        <div className={classes.flexItem}>
          <SortAscending size={27} color={btnActive ? "#6C65D9" : "#F9F9F9"} />
          <span
            className={btnActive ? classes.sortActive : classes.sortInactive}
          >
            Sort
          </span>
        </div>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={classes.popoverArrowTriangle}
          style={{ marginLeft: props.left ? props.left : "30px" }}
        ></div>
        <div style={{ position: "relative" }}>
          <div
            className={classes.popoverArrow}
            style={{ left: props.left ? props.left : "30px" }}
          ></div>
          <SortModal
            onSubmit={props.onSubmit}
            defaultvalue={props.defaultvalue}
            closeModal={handleClose}
          />
        </div>
      </Popover>
    </div>
  );
};

export const ToggleButton = (props) => {
  return (
    <div
      style={{
        direction: "row",
        display: "flex",
        alignItems: "center",
        marginTop: "5px",
      }}
    >
      <div>
        <List size={27} weight="fill" />
      </div>
      <div style={{ marginTop: "-5px" }}>
        <Switch height={20} width={40} onClick={() => props.handleToggler()} />
      </div>
      <div>
        {props.showGridIcon ? (
          <SquaresFour size={27} weight="fill" />
        ) : (
          <Rows size={27} weight="fill" />
        )}
      </div>
    </div>
  );
};

export const FilterButton = ({ btnActive, openFilterModal }) => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ height: "8px", marginTop: "-8px" }}>
        {btnActive && <div className={classes.dotBlur}></div>}
        {btnActive && <div className={classes.dot}></div>}
      </div>
      <Button className={classes.filterSortBtn} onClick={openFilterModal}>
        <div className={classes.flexItem}>
          <Funnel size={27} color={btnActive ? "#6C65D9" : "#F9F9F9"} />
          <span
            className={btnActive ? classes.sortActive : classes.sortInactive}
          >
            Filter
          </span>
        </div>
      </Button>
    </div>
  );
};
