import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ChatText,
  CheckSquareOffset,
  CopySimple,
  Cube,
  FilmSlate,
  Users,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fget } from "../../../../API/callsAPI";
import { TrackablesAndIcons } from "../../../Settings/Utility/TrackablesDetails";
import CustomDropdown from "../../../Utilities/CustomDropdown/CustomDropdown.component";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import NoResultMessage from "../../../Utilities/NoResultFound/NoResultMessage";
import NoThumbnail from "../../../Utilities/NoThumbnail.component";
import StatusViewer from "../../../Utilities/StatusViewer/StatusViewer";
import ClockPlus from "../../../Utilities/Svg/ClockPlus";
import { ReviewerIcon } from "../../../Utilities/Svg/Reviewer";
import UsersViewer from "../../../Utilities/UsersViewer/UsersViewer";
import ExtendDeadlineModal from "../../UpdatePageModals/ExtendDeadlineModal";
import useStyles from "./TaskOverview.style";

const TaskOverview = ({
  loggedInUser,
  projectId,
  users,
  projectDetails,
  hideTitle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedTrackable, setSelectedTrackable] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [taskStatusFilter, setTaskStatusFilter] = useState("current");
  const [taskDepartmentFilter, setTaskDepartmentFilter] = useState("none");
  const [selectedUserFilter, setSelectedUserFilter] = useState("none");
  const [isAdmin, setIsAdmin] = useState(false);
  const [extendDeadlineModalData, setExtendDeadlineModalData] = useState({
    isOpen: false,
    data: {},
  });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const getTaskList = async () => {
    if (statusList?.length) {
      const filteredStatusIDs = statusList
        .filter((status) => {
          if (taskStatusFilter === "all") {
            return status.code === " ";
          } else if (taskStatusFilter === "current") {
            return (
              status.code === "IP" ||
              status.code === "WIP" ||
              status.code === "RVW" ||
              status.code === "BLCK"
            );
          } else if (taskStatusFilter === "upcoming") {
            return (
              status.code === "OPEN" ||
              status.code === "RTS" ||
              status.code === "11111"
            );
          } else {
            return (
              status.code === "IP" ||
              status.code === "WIP" ||
              status.code === "RVSN" ||
              status.code === "0099" ||
              status.code === "BLCK"
            );
          }
        })
        .map((status) => `&status=${status.id}`)
        .join("");
      const filterUrl = `trackables/task/?project=${projectId}&assigned=${
        isAdmin ? 0 : 1
      }${filteredStatusIDs}&page=${1}&department=${
        taskDepartmentFilter === "none" ? "" : taskDepartmentFilter
      }&linked_class=${
        selectedTrackable === "all" || "Review" ? "" : selectedTrackable
      }${
        selectedUserFilter !== "none" && selectedTrackable !== "Review"
          ? `&assigned_users=${selectedUserFilter}`
          : ""
      }${
        selectedUserFilter !== "none" && selectedTrackable === "Review"
          ? `&reviewer=${selectedUserFilter}`
          : ""
      }`;
      try {
        const taskListRes = await fget({
          url: filterUrl,
        });
        const currentDate = new Date();
        if (taskStatusFilter === "all") {
          setTaskList(taskListRes?.data?.results);
        } else {
          const list = taskListRes?.data?.results?.filter((task) => {
            if (taskStatusFilter === "current") {
              return (
                new Date(task?.start_time) <= currentDate &&
                new Date(task?.end_time) >= currentDate
              );
            } else if (taskStatusFilter === "upcoming") {
              return new Date(task?.start_time) > currentDate;
            } else {
              return new Date(task?.end_time) < currentDate;
            }
          });
          setTaskList(list);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const getStatusList = async () => {
    try {
      const res = await fget({
        url: `trackables/status/?project=${projectId}`,
      });
      const taskStat = res?.data?.results?.filter((st) => {
        return st.status_class.includes(10);
      });
      setStatusList(taskStat);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async () => {
    try {
      const res = await fget({ url: `department/?project=${projectId}` });
      const depts = res?.data?.results?.map((singDept) => {
        return { value: singDept?.id, title: singDept?.name };
      });
      setDepartments(depts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
    getStatusList();
  }, []);

  useEffect(() => {
    const isUserAdmin = projectDetails?.admin_users?.some(
      (eachUser) => eachUser === loggedInUser?.id
    );
    setIsAdmin(isUserAdmin);
  }, [projectDetails, loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      setSelectedUserFilter(loggedInUser?.id);
    }
  }, [loggedInUser]);

  useEffect(() => {
    setIsLoading(true);
    getTaskList();
  }, [
    taskDepartmentFilter,
    taskStatusFilter,
    statusList,
    selectedTrackable,
    selectedUserFilter,
    isAdmin,
    refreshCounter,
  ]);

  const closeExtendModal = () => {
    setExtendDeadlineModalData({
      isOpen: false,
      data: {},
    });
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      {!hideTitle ? <div className={classes.title}>Task Overview</div> : ""}
      <div className={classes.dropdowns}>
        <div style={{ width: "150px" }}>
          <CustomDropdown
            value={taskDepartmentFilter}
            updateValue={(val) => setTaskDepartmentFilter(val)}
            options={departments}
            showNone={true}
          />
        </div>
        {isAdmin ? (
          <div style={{ width: "150px" }}>
            <CustomDropdown
              showNone={true}
              value={selectedUserFilter}
              updateValue={(val) => setSelectedUserFilter(val)}
              options={users?.map((user) => {
                return { value: user?.id, title: user?.name };
              })}
            />
          </div>
        ) : (
          ""
        )}
        <div style={{ width: "150px" }}>
          <CustomDropdown
            showNone={false}
            value={taskStatusFilter}
            updateValue={(val) => setTaskStatusFilter(val)}
            options={[
              { value: "all", title: "All" },
              { value: "current", title: "Current Task" },
              { value: "upcoming", title: "Upcoming Task" },
              { value: "delayed", title: "Delayed Task" },
            ]}
          />
        </div>
      </div>
      <div className={classes.taskListViewer}>
        <div className={classes.taskListFilterButtonContainer}>
          <div className={classes.filterButtons}>
            <Button
              size="small"
              className={
                selectedTrackable === "all"
                  ? classes.selectedButton
                  : classes.button
              }
              onClick={() => setSelectedTrackable("all")}
            >
              <CopySimple size={20} className={classes.icon} />
              <span>All</span>
            </Button>
            <Button
              size="small"
              className={
                selectedTrackable === "Asset"
                  ? classes.selectedButton
                  : classes.button
              }
              onClick={() => setSelectedTrackable("Asset")}
            >
              <Cube size={20} className={classes.icon} />
              <span>Assets</span>
            </Button>
            <Button
              size="small"
              className={
                selectedTrackable === "Shot"
                  ? classes.selectedButton
                  : classes.button
              }
              onClick={() => setSelectedTrackable("Shot")}
            >
              <FilmSlate size={20} className={classes.icon} />
              <span>Shots</span>
            </Button>
            <Button
              size="small"
              className={
                selectedTrackable === "Review"
                  ? classes.selectedButton
                  : classes.button
              }
              onClick={() => setSelectedTrackable("Review")}
            >
              <ReviewerIcon
                size={18}
                color={selectedTrackable === "Review" ? "#F9F9F9" : "#606479"}
                className={classes.icon}
              />
              <span style={{ marginLeft: "5px" }}>Review</span>
            </Button>
          </div>
        </div>
        <div className={classes.taskCards}>
          {!isLoading ? (
            <>
              {taskList?.length > 0 ? (
                <>
                  {taskList?.map((task) => {
                    return (
                      <div className={classes.singleTaskCard}>
                        <Grid container>
                          <Grid item md={4}>
                            {task?.name ?? "No name"}
                          </Grid>
                          <Grid
                            item
                            md={4}
                            className={`${classes.flexCenter} ${classes.grayText}`}
                          >
                            <StatusViewer status={task?.status} />
                          </Grid>
                          <Grid item md={4} className={classes.flexEnd}>
                            <span
                              style={{
                                color:
                                  taskStatusFilter === "all"
                                    ? "#FFFFFF"
                                    : taskStatusFilter === "current"
                                    ? "#87F056"
                                    : taskStatusFilter === "upcoming"
                                    ? "#5C9EFF"
                                    : "#FF0000",
                              }}
                            >
                              {new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                              }).format(new Date(task?.start_time))}
                              -{" "}
                              {new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                              }).format(new Date(task?.end_time))}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid
                            item
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div>
                              {
                                TrackablesAndIcons?.find(
                                  (track) =>
                                    track?.trackable === task?.linked_class
                                )?.icon
                              }
                            </div>
                            {task.linked?.thumbnail ? (
                              <img
                                style={{
                                  borderRadius: "7px",
                                  objectFit: "cover",
                                  margin: "4px 6px 4px 10px",
                                }}
                                src={task.linked?.thumbnail}
                                height="28px"
                                width="28px"
                                alt="No Img"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            ) : (
                              <NoThumbnail
                                style={{
                                  margin: "4px 10px ",
                                  height: "28px",
                                  width: "28px",
                                }}
                              />
                            )}
                            <Tooltip
                              title={
                                task?.linked?.name
                                  ? task?.linked?.name
                                  : task?.linked?.code
                              }
                            >
                              <Typography
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {task?.linked?.name
                                  ? task?.linked?.name
                                  : task?.linked?.code}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            className={`${classes.flexCenter} ${classes.grayText}`}
                          >
                            <div>
                              <UsersViewer
                                assignedUser={task?.assigned_users}
                                selectedTask={{
                                  assigned_users: task?.assigned_users,
                                }}
                                editable="no"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4} className={classes.flexEnd}>
                            <div className={classes.commentCountButton}>
                              <div style={{ height: "8px" }}>
                                <div className={classes.dotBlur}></div>
                                <div className={classes.dot}></div>
                              </div>
                              <ChatText
                                size={22}
                                fill="#6C65D9"
                                weight="fill"
                              />
                              <span>{task?.related_comments_number}</span>
                            </div>
                            <Button
                              onClick={() =>
                                setExtendDeadlineModalData({
                                  isOpen: true,
                                  data: task,
                                })
                              }
                            >
                              <ClockPlus />
                            </Button>
                            <CheckSquareOffset
                              size={22}
                              className={classes.grayIcon}
                              onClick={() => {
                                if (task?.linked_class === "Asset") {
                                  history.push(
                                    `/${projectId}/assets/${task?.linked?.id}/task`
                                  );
                                } else {
                                  history.push(
                                    `/${projectId}/sequence/${task?.linked?.parent_sequence}/shots/${task?.linked?.id}/task`
                                  );
                                }
                              }}
                            />
                            {/* <Tooltip
                              title={task?.department?.name}
                              placement="top"
                            >
                              <span>
                                {task?.department?.name.slice(0, 10) ?? ""}
                                {task?.department?.name?.length > 10
                                  ? "..."
                                  : ""}
                              </span>
                            </Tooltip> */}
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className={classes.flexCenter} style={{ height: "100%" }}>
                  <NoResultMessage name="Task" iconSize={40} fontSize="24px" />
                </div>
              )}
            </>
          ) : (
            <div className={classes.flexCenter} style={{ height: "100%" }}>
              <CircularProgress
                type="Oval"
                color="secondary"
                height={50}
                width={50}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.taskInfoContainer}>
        <div className={classes.taskInfoLeft}>
          {1 == 2 ? (
            <div className={classes.flexColumn}>
              <div className={classes.flexCenter}>
                <Users size={24} />
                <span className={classes.departmentName}>Department Name</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>Admins : </span>
                <span className={classes.number}>24</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>Members : </span>
                <span className={classes.number}>24</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>
                  Number of Tasks :{" "}
                </span>
                <span className={classes.number}>24</span>
              </div>
            </div>
          ) : (
            <NoResultMessage name="Info" iconSize={30} fontSize="18px" />
          )}
        </div>
        <div className={classes.taskInfoRight}>
          {1 == 2 ? (
            <div className={classes.flexColumn}>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>
                  New Tasks Assigned :
                </span>
                <span className={classes.blueNumber}>24</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>
                  Tasks Completed :{" "}
                </span>
                <span className={classes.greenNumber}>24</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>Tasks Due : </span>
                <span className={classes.redNumber}>24</span>
              </div>
              <div className={classes.flexEnd} style={{ gap: "12px" }}>
                <span className={classes.taskInfoTitle}>
                  Tasks In Progress :
                </span>
                <span className={classes.yellowNumber}>24</span>
              </div>
            </div>
          ) : (
            <NoResultMessage name="Info" iconSize={30} fontSize="18px" />
          )}
        </div>
      </div>
      <Modal
        open={extendDeadlineModalData.isOpen}
        className={classes.flexCenter}
        onClose={() => closeExtendModal()}
      >
        <ExtendDeadlineModal
          taskDetails={extendDeadlineModalData.data}
          closeModal={closeExtendModal}
          updateRefreshCounter={updateRefreshCounter}
          setSnackValue={setSnackValue}
        />
      </Modal>
    </div>
  );
};

export default TaskOverview;
