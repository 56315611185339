import React, { useState } from "react";

//Validators
import { useFormik } from "formik";
import * as yup from "yup";

//@MUI
import {
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Asterisk } from "phosphor-react";
import useStyles from "./AssetsPageNewAssetModal.styles";
import HelperText from "../../Utilities/HelperText/HelperText";

//@API
import { fget, fpost, exportFileWithXhr } from "../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import { HelpCircle, Plus } from "react-feather";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";

export default function AssetsPageNewAssetModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const [thumbnail, uploadThumbnailSet] = useState();
  const [thumbnailTouched, setThumbnailTouch] = useState(false);
  const [department, setDepartment] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [defaultVersion, setDefaultVersion] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    console.log(file);
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
    setDeleteFileModal(false);
  };

  const selectedDepartment = [];
  const handleDeptChange = (e) => {
    e.map((el) =>
      selectedDepartment.includes(el.id) ? " " : selectedDepartment.push(el.id)
    );
    setDepartment(selectedDepartment);
  };

  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  //Validators
  const NewAssetValidationScheme = yup.object({
    assetName: yup
      .string("String required")
      .label("assetName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    type: yup.string().required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      assetName: "",
      status: "",
      type: "",
      department: department,
      assetVersionName: "",
      description: "",
    },
    validationSchema: NewAssetValidationScheme,

    onSubmit: async (values) => {
      setLoader(true);
      if (thumbnailTouched) {
        setFileUploading(true);
      }

      let formData = new FormData();
      formData.append("name", values.assetName);
      formData.append("description", values.description);
      if (thumbnail) {
        formData.append("file_name", thumbnail.name);
      }
      formData.append("file_path", "");
      formData.append("project", props.projectId);
      formData.append("status", values.status);
      formData.append("type", values.type);
      if (department) {
        for (var i = 0; i < department.length; i++) {
          formData.append(`allowed_dept`, department[i]);
        }
      }

      let newAsset = {
        url: "trackables/asset/",
        data: formData,
      };

      try {
        const newAssetRes = await fpost(newAsset);
        if (newAssetRes.status === 200 || 201) {
          if (defaultVersion) {
            createDefaultAssetVersion(newAssetRes.data);
          }
          if (thumbnail) {
            await getSignedUrl(newAssetRes.data.id, "asset");
          } else {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created ${newAssetRes.data.name}`,
              isError: false,
            });
            setFileUploading(false);
            props.updateRefreshCounter();
            props.handleNewAssetModal({ closeFlag: true });
          }
        }
      } catch (error) {
        setFileUploading(false);
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
      setThumbnailTouch(false);
    },
  });

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete(evt) {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled(evt) {
    setSnackValue({
      isOpen: true,
      message: `File Transfer Cancelled`,
      isError: true,
    });
  }

  const createDefaultAssetVersion = async (newData) => {
    let formData = new FormData();
    if (thumbnail) {
      formData.append("file_name", thumbnail.name);
    }
    formData.append(
      "name",
      formik.values.assetVersionName || `${newData.name}.v001`
    );
    formData.append("project", props.projectId);
    formData.append("asset", newData.id);

    let newVersion = {
      url: "trackables/asset-version/",
      data: formData,
    };
    try {
      const newVersionRes = await fpost(newVersion);
      if (newVersionRes.status === 200 || 201) {
        if (thumbnail) {
          getSignedUrl(newVersionRes?.data?.id, "assetversion");
        } else {
          setLoader(false);
          setSnackValue({
            isOpen: true,
            message: `SuccessFully Created ${newVersionRes.data.name}`,
            isError: false,
          });
          setFileUploading(false);
          props.updateRefreshCounter();
          props.handleNewAssetVersionModalState();
        }
      }
    } catch (error) {
      setLoader(false);
      setFileUploading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const getSignedUrl = async (newId, trackable) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/${trackable}/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await exportFileWithXhr({
          url: uploadUrl.data?.signed_url,
          data: thumbnail,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });
        fget({
          url: `trackables/${trackable}/${newId}/thumbnail`,
        });
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Created`,
          isError: false,
        });
        setFileUploading(false);
        props.updateRefreshCounter();
        props.handleNewAssetModal({ closeFlag: true });
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setThumbnailTouch(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Asset"
              type="New"
              closeModal={(e) => {
                props.handleNewAssetModal(e);
              }}
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Asset Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    fullWidth
                    placeholder="Enter asset name"
                    name="assetName"
                    id="TextField"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.assetName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.assetName &&
                      Boolean(formik.errors.assetName)
                    }
                  />
                </div>
                {formik.errors.assetName && formik.touched.assetName ? (
                  <HelperText text={formik.errors.assetName} />
                ) : null}
              </div>
              <div>
                <span>Description</span>
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    type="text"
                    id="desc"
                    placeholder="Enter a project description"
                    className={classes.cardContentInput2}
                    style={{ height: "70px", overflow: "auto" }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily: "Poppins",
                      },
                    }}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                  />
                </div>
              </div>
              <div className={classes.displayFlex}>
                <div style={{ width: "150px" }}>
                  <span>Status</span>
                  <div
                    className={classes.cardContentDiv3}
                    style={{ marginTop: "5px" }}
                  >
                    <Select
                      disableUnderline
                      name="status"
                      style={{ width: "100%" }}
                      className={classes.cardContentInput1}
                      labelId="demo-simple-select-outlined-label"
                      id="SelectStatus"
                      onChange={formik.handleChange}
                      value={formik.values.status}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                    >
                      <MenuItem
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Plus />
                        Add new Status
                      </MenuItem>
                      <MenuItem value="">None</MenuItem>
                      {props.status &&
                        props.status.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv3}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props.status.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <span>Type</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <Select
                    id="SelectType"
                    disableUnderline
                    className={classes.cardContentInput1}
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-outlined-label"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    name="type"
                  >
                    <MenuItem value="">None</MenuItem>
                    {props.assetType &&
                      props.assetType.map((eachAsset) => (
                        <MenuItem value={eachAsset}>{eachAsset}</MenuItem>
                      ))}
                  </Select>
                </div>
                {formik?.errors?.type && formik.touched.type ? (
                  <HelperText text={formik?.errors?.type} />
                ) : null}
              </div>

              <div>
                <span>
                  Departments{" "}
                  <Tooltip
                    placement="right"
                    title="CREATE TASK FROM TEMPLATE button will add phases from selected Dept. as task for this new asset!"
                  >
                    <HelpCircle size={15} style={{ padding: "0.2px" }} />
                  </Tooltip>
                </span>
                <div
                  style={{ marginTop: "5px" }}
                  className={classes.cardContentDiv3}
                >
                  <Autocomplete
                    id="SelectDept"
                    multiple
                    options={props.departments}
                    underlineStyle={false}
                    getOptionLabel={(option) =>
                      option.title ? option.title : "No Option"
                    }
                    className={classes.autoComplete}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, v) => handleDeptChange(v)}
                  />
                </div>
              </div>
              <div>
                <span>Attachments </span>
                <UploadFile
                  fileUploading={fileUploading}
                  uploadProgress={uploadProgress}
                  thumbnail={thumbnail}
                  handleClearUpload={handleClearUpload}
                  handleThumbnailUpload={handleThumbnailUpload}
                  setDeleteFileModal={setDeleteFileModal}
                />
              </div>
            </div>

            <FormControlLabel
              control={<Checkbox />}
              label={`Create a default asset version`}
              onChange={(e) => setDefaultVersion(e.target.checked)}
            />
            <br />
            {defaultVersion ? (
              <div>
                <span>Asset Version Name</span>
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    fullWidth
                    placeholder="Enter asset version name"
                    name="assetVersionName"
                    id="TextField"
                    defaultValue={
                      formik?.values?.assetName
                        ? `${formik?.values?.assetName}.v001`
                        : ""
                    }
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.assetName &&
                      Boolean(formik.errors.assetName)
                    }
                    helperText={
                      formik.errors.assetName ? formik.errors.assetName : null
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <CardActions className={classes.cardAction}>
              <Button
                className={classes.button}
                type="submit"
                value="Submit"
                size="small"
                variant="outlined"
                disableElevation
                color="secondary"
                disabled={fileUploading}
              >
                {loader ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "25px", height: "25px" }}
                  />
                ) : (
                  <span>Create New Asset</span>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof thumbnail === "object" ? thumbnail?.name : thumbnail}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleClearUpload();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
