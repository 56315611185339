import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "470px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "18px 18px 18px 18px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  cardTool: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px !important",
    fontWeight: "700",
  },
  name: {
    color: theme.palette.text.primary,
    fontSize: "16px !important",
  },
  textPurple: {
    color: theme.palette.secondary.main,
    fontSize: "16px !important",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  cardContentInput1: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },

  descriptionInput: {
    padding: "3.5px 18px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "60px",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    marginTop: "-10px",
    // paddingBottom: "7px ",
    color: theme.palette.primary.main,
    "& :first-child": {
      backgroundColor: theme.palette.secondary.main,
      textTransform: "none",
      padding: "3px",
      borderRadius: "7px",
      color: theme.palette.primary.main,
    },
  },

  datePickerDiv: {
    width: "43%",
    overflowY: "hidden",
    padding: "0px 8px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
  },

  doneButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  doneButton: {
    width: "200px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
  },
  trackableCount: {
    color: theme.palette.text.tertiary,
  },
  inputLabel: {
    color: theme.palette.text.tertiary,
    display: "inline-block",
    marginBottom: "10px",
  },
  cardContentDiv4: {
    // margin: "0% 5% 0% 5%",
    marginTop: "8px",
  },
}));

export default useStyles;
