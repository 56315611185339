import { Button, Chip, Grid, Menu, MenuItem } from "@material-ui/core";
import { Circle, DotsThree, PencilLine, Trash, Triangle } from "phosphor-react";
import React from "react";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer.jsx";
import useStyles from "./SinglePhaseCard.style.js";

const SinglePhaseCard = ({
  singlePhase,
  departments,
  handleEditTaskPhaseInfo,
  handleDeleteTaskPhaseInfo,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div className={classes.singleCardContainer}>
        {/* <div className={classes.flexBetween}>
          <div>{singlePhase?.name}</div>
          <div>Days - {singlePhase?.number_of_days}</div>
          <Button onClick={handleClick}>
            <DotsThree size={25} />
          </Button>
        </div> */}
        <Grid container style={{ alignItems: "start" }}>
          <Grid item xs={4} style={{ textAlign: "start" }}>
            {singlePhase?.name}
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            {singlePhase?.number_of_days ?? 0} Days{" "}
            {singlePhase?.number_of_hours ?? 0} Hours
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <Button onClick={handleClick}>
              <DotsThree size={25} />
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid md={4}>
            <span className={classes.description}>
              {singlePhase?.description}
            </span>
          </Grid>
          <Grid md={4} style={{ display: "flex", justifyContent: "center" }}>
            <UsersViewer
              assignedUser={singlePhase?.users_to_assign}
              selectedTask={{ assigned_users: singlePhase?.users_to_assign }}
              editable="no"
            />
          </Grid>
          <Grid md={4} style={{ display: "flex", justifyContent: "end" }}>
            {departments?.find((dep) => dep.id === singlePhase?.dept)?.name ?? (
              <Chip
                label="Un-configured"
                variant="outlined"
                size="small"
                style={{ color: "orange", borderColor: "orange" }}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.customArrow}>
        <Circle className={classes.circle} weight="fill" />
        <div className={classes.line}></div>
        <Triangle className={classes.triangle} weight="fill" />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditTaskPhaseInfo(singlePhase);
            handleClose();
          }}
        >
          <PencilLine size={25} />{" "}
          <span style={{ marginLeft: "10px" }}>Edit Task Phase</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteTaskPhaseInfo(singlePhase);
            handleClose();
          }}
        >
          <Trash size={25} />{" "}
          <span style={{ marginLeft: "10px" }}>Delete Phase</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SinglePhaseCard;
