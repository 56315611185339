import React, { useState } from "react";
import { fget, fpost, exportFileWithXhr } from "../../../API/callsAPI";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//@MUI
import useStyles from "./ReviewPageNewReviewModal.styles";
import { Button, TextField, Modal, CircularProgress } from "@material-ui/core";
//icons
import { X } from "react-feather";
import { ArrowsDownUp, Asterisk } from "phosphor-react";
//component
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import RearrangeReviewer from "./RearrangeReviewer";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import HelperText from "../../Utilities/HelperText/HelperText";

function ReviewPageNewReviewModal({
  handleNewReviewModal,
  reviewerUserList,
  projectId,
  setSnackValue,
  updateRefreshCounter,
}) {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const [isRearrangeReviewerOpen, setRearrangeReviewerOpen] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, uploadFileSet] = useState();
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [fileTouched, setFileTouched] = useState(false);

  //Validators
  const ReviewValidationScheme = yup.object({
    reviewFileName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is required field"),
  });

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadFileSet(null);
    setFileTouched(false);
    setDeleteFileModal(false);
  };

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadFileSet(file);
    setFileTouched(true);
  };

  const handleSelectUserModalState = () => {
    setSelectUserModalOpen(!isSelectUserModalOpen);
  };

  const handleRearrangeModal = () => {
    setRearrangeReviewerOpen(!isRearrangeReviewerOpen);
  };

  const handleSelectedUser = (users) => {
    setSelectedUsers(users);
  };

  const postFlowOrder = async () => {
    let newFlowOrder = {
      url: `project/${projectId}/flow-order/`,
      data: { project: projectId },
    };
    try {
      const newFlowOrderRes = await fpost(newFlowOrder);
      if (newFlowOrderRes.status === 200 || 201) {
        const res2 = await createFlowforReview(
          selectedUsers,
          newFlowOrderRes.data.id
        );
        if (res2 === 200 || 201) {
          return newFlowOrderRes.data.id;
        } else {
          return null;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createFlowforReview = async (users, id) => {
    const userArray = users.map((user) => user.value);
    let newFlow = {
      url: `project/${projectId}/flow/`,
      data: { users: userArray, order: id },
    };
    try {
      const newFlowRes = await fpost(newFlow);
      if (newFlowRes.status === 200 || 201) {
        return newFlowRes.status;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      reviewFileName: "",
    },
    validationSchema: ReviewValidationScheme,
    onSubmit: async (values) => {
      setLoader(true);
      if (fileTouched) {
        setFileUploading(true);
      }
      const res = await postFlowOrder();
      if (res !== null) {
        let formdata = new FormData();
        formdata.append("name", values.reviewFileName);
        if (file) {
          formdata.append("file_name", file.name);
        }
        formdata.append("flow", res);
        let newReviewFile = {
          url: `project/${projectId}/reviews/`,
          data: formdata,
        };
        try {
          const newFileRes = await fpost(newReviewFile);
          if (newFileRes.status === 200 || 201) {
            if (file) {
              getSignedUrl(newFileRes.data.id);
            } else {
              setLoader(false);
              setFileUploading(false);
              handleNewReviewModal();
              updateRefreshCounter();
            }
          }
        } catch (error) {
          setLoader(false);
          setFileUploading(false);
        }
      }
      setFileTouched(false);
    },
  });

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete() {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed() {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled() {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `review/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await exportFileWithXhr({
          url: uploadUrl.data?.signed_url,
          data: file,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });

        // after upload is complete make thumbnail api call
        await fget({ url: `review/${newId}/thumbnail` });
        setLoader(false);
        setFileUploading(false);
        handleNewReviewModal();
        updateRefreshCounter();
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
    }
  };

  const clearAll = () => {
    formik.setValues({
      ...formik.values,
      reviewFileName: "",
    });
    setSelectedUsers([]);
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      uploadFileSet(e.dataTransfer.files[0]);
      setFileTouched(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={classes.container}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.containerHeader}>
          <p style={{ margin: "0px" }}>New File</p>
          <Button onClick={handleNewReviewModal}>
            <X size={28} />
          </Button>
        </div>
        <div className={classes.innerContainer1}>
          <span>File Name</span>
          <Asterisk size={12} color="#FF0000" weight="fill" />
          <TextField
            fullWidth
            placeholder="Enter file name"
            name="reviewFileName"
            id="TextField"
            InputProps={{ disableUnderline: true }}
            className={classes.newReviewTextField}
            value={formik.values.reviewFileName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.reviewFileName)}
          />
          {formik.errors.reviewFileName && formik.touched.reviewFileName ? (
            <HelperText text={formik.errors.reviewFileName} />
          ) : null}
        </div>
        <div className={classes.innerContainer1}>
          <span>Select Reviewers</span>
          <Asterisk size={12} color="#FF0000" weight="fill" />
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className={classes.userDisplayDiv}>
              <UserViewerForManageUsers
                currentUsers={selectedUsers}
                handleSelectUserModalChange={handleSelectUserModalState}
                handleSelectUsers={handleSelectedUser}
              />
            </div>
            <Button
              style={{ minWidth: "0px", padding: "5px" }}
              onClick={handleRearrangeModal}
            >
              <ArrowsDownUp size={16} />
            </Button>
          </div>
        </div>

        <div className={classes.innerContainer1}>
          Attachment
          <UploadFile
            fileUploading={fileUploading}
            uploadProgress={uploadProgress}
            thumbnail={file}
            handleClearUpload={handleClearUpload}
            handleThumbnailUpload={displaySelectedFile}
            setDeleteFileModal={setDeleteFileModal}
          />
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.newFileBtn}
            style={{ backgroundColor: loader && "#eeeeee" }}
            type="submit"
            value="Submit"
            disabled={loader}
          >
            {loader ? (
              <CircularProgress
                color="secondary"
                style={{ width: "25px", height: "25px" }}
              />
            ) : (
              "Create New File"
            )}
          </Button>

          <Button className={classes.clearBtn} onClick={clearAll}>
            Clear All
          </Button>
        </div>
      </form>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={handleSelectUserModalState}
        >
          <SelectUserModal
            closeModal={handleSelectUserModalState}
            assignedUser={selectedUsers}
            usersList={reviewerUserList}
            handleSelectUsers={handleSelectedUser}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isRearrangeReviewerOpen}
          onClose={handleRearrangeModal}
        >
          <RearrangeReviewer
            closeModal={handleRearrangeModal}
            usersList={selectedUsers}
            handleSelectedUser={handleSelectedUser}
          />
        </Modal>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof file === "object" ? file?.name : file}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleClearUpload();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewPageNewReviewModal;
