import { Button } from "@material-ui/core";
import { Plus } from "phosphor-react";
import React from "react";
import { SortButton } from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import { EpisodesIconSmall } from "../../Utilities/Svg/EpisodesIcon";
import useStyles from "./EpisodesPageTools.styles";

function EpisodesPageTools(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <EpisodesIconSmall />
        <BreadCrumb />
      </div>
      <div className={classes.inlineDiv}>
        {/* <div>
          <ToggleButton handleToggler={props.handleToggler} />
        </div> */}
        {/* <div>
          <FilterButton />
        </div> */}
        <div>
          <SortButton
            onSubmit={props.onSubmit}
            defaultvalue={props.defaultvalue}
          />
        </div>
        <div>
          <Button
            size="small"
            className={classes.episodesPageNewEpisodeButton}
            disableElevation
            onClick={props.openNewEpisodeModal}
          >
            <Plus className={classes.plusIcon} />
            Add Episodes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EpisodesPageTools;
