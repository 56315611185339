import { CircularProgress } from "@material-ui/core";
import React from "react";
import { fget } from "../../../../API/callsAPI";
import DepartmentGraph from "./ProjectHealthWidgets/DepartmentGraph";
import DepartmentWisePerformance from "./ProjectHealthWidgets/DepartmentWisePerformance";
import useStyles from "./ProjectHealthWidgets/ProjectHealthWidgets.style";
import ProjectStatus from "./ProjectHealthWidgets/ProjectStatus";

const ProjectHealth = ({
  projectId,
  loggedInUser,
  projectDetails,
  hideTitle,
}) => {
  const classes = useStyles();
  const [healthData, setHealthData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getHealth = async () => {
      try {
        const res = await fget({ url: `project/${projectId}/task-widget/` });
        setHealthData(res?.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getHealth();
  }, [projectId]);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {!hideTitle ? (
        <div className={classes.projectHealthText}>Project Health Status</div>
      ) : (
        ""
      )}
      <div className={classes.hiUserName}>
        <span>Hi,</span>
        <span className={classes.userName}>{loggedInUser?.name || ""}</span>
      </div>
      {!isLoading ? (
        <>
          <div style={{ height: "28%" }}>
            <ProjectStatus
              healthData={healthData}
              projectDetails={projectDetails}
            />
          </div>
          <div
            style={{
              height: "29%",
              marginTop: "2%",
              marginBottom: "1%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DepartmentWisePerformance
              healthData={healthData}
              projectId={projectId}
            />
            {/* Floating div for creating shadow on departments list */}
            <div
              style={{
                position: "absolute",
                height: "38px",
                width: "calc(40% - 200px)",
                top: "calc(69% - 30px)",
                background:
                  "linear-gradient(360deg, #1B1D28 31%, rgba(0, 0, 0, 0) 100%)",
              }}
            ></div>
          </div>
          <div className={classes.allDepartmentGraph}>
            <DepartmentGraph projectId={projectId} />
          </div>
        </>
      ) : (
        <div className={classes.flexCenter} style={{ height: "90%" }}>
          <CircularProgress
            type="Oval"
            color="secondary"
            height={50}
            width={50}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectHealth;
