import React, { useEffect } from "react";
import { Route } from "react-router-dom";

//Component overview import from pages folder.
import ReportPageOverview from "./Components/ReportPage/ReportPageOverview/ReportPageOverview.component";
import AssetVersions from "./Pages/AssetVersions/AssetVersions.component";
import AssetsPage from "./Pages/AssetsPage/AssetsPage.component";
import Departments from "./Pages/DepartmentPage/Department.component";
import PlaylistPage from "./Pages/PlaylistPage/PlaylistPage.component";
import ReviewVersion from "./Pages/ReviewVersion/ReviewVersion";
import ReviewersPage from "./Pages/ReviewersPage/ReviewersPage";
import SequencePage from "./Pages/SequencePage/SequencePage.component";
import Sequencer from "./Pages/Sequencer/Sequencer.component";
import ShotVersion from "./Pages/ShotVersions/ShotVersion.Component";
import ShotsPage from "./Pages/ShotsPage/ShotsPage.component";
import EpisodesPage from "./Pages/EpisodesPage/EpisodesPage.component";

//inconsistent direct imports.
import { useState } from "react";
import TaskComponentOverview from "./Components/MyTasks/TaskPageOverview/TaskPageOverview.component";
import NotificationsPage from "./Components/Notifications/NotificationsPage.component";
import CustomLoader from "./Components/Utilities/Loader/CustomLoader";
import ComparePage from "./Pages/ComparePage/ComparePage.component";
import DepartmentDetail from "./Pages/DepartmentDetails/DepartmentDetail.component";
import ExternalReviewerFallback from "./Pages/InformativeComponents/ExternalReviewerFallback";
import UnAuthorized from "./Pages/InformativeComponents/UnAuthorized.component";
import ProjectMediaPage from "./Pages/ProjectMediaPage/ProjectMediaPage";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import Updates from "./Pages/UpdatesPage/Updates.component";
import PdfReportsPage from "./Pages/PdfReportsPage/PdfReportsPage";

//Add all new routes in this component instead of app.js
//this abstracts code out of app.js and prevents conflicts for crossdomain deployments.
export default function RegisterAppRoutes({
  showSeqExperimental,
  userRoleForProject,
  isUserInfoLoading,
}) {
  const [allowAllRoutes, setAllowAllRoutes] = useState(true);
  useEffect(() => {
    setAllowAllRoutes(
      Boolean(userRoleForProject === "admin" || userRoleForProject === "user")
    );
    return () => {};
  }, [userRoleForProject]);

  const CustomLoaderComponent = () => {
    return <CustomLoader type="appRegister" />;
  };

  const showLoader = (component) => {
    if (isUserInfoLoading) {
      return CustomLoaderComponent;
    }
    return component;
  };

  const withUserAccess = (component) => {
    if (isUserInfoLoading) {
      return CustomLoaderComponent;
    }
    return allowAllRoutes ? component : ExternalReviewerFallback;
  };

  const playlistPageComponent = (routerParams) => {
    if (allowAllRoutes) {
      return <PlaylistPage {...routerParams} />;
    } else {
      return <UnAuthorized />;
    }
  };

  const ReviewerPageComponent = (props) => {
    return (
      <ReviewersPage
        params={props.match.params}
        externalReviewer={!allowAllRoutes}
      />
    );
  };

  const ReviewVersionComponent = (props) => {
    return (
      <ReviewVersion
        params={props.match.params}
        externalReviewer={!allowAllRoutes}
      />
    );
  };

  const ReportPageComponent = (routerParams) => {
    return <ReportPageOverview {...routerParams} />;
  };

  const PdfReportPageComponent = (routerParams) => {
    return <PdfReportsPage {...routerParams} />;
  };

  const TaskComponent = (props) => {
    return <TaskComponentOverview {...props} trackableType="task" />;
  };

  return (
    <>
      <Route exact path="/reports" component={ReportPageComponent} />
      <Route exact path="/pdf-reports" component={PdfReportPageComponent} />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/tasks"
        component={showLoader(TaskComponent)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/updates"
        component={withUserAccess(Updates)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route exact path="/notifications" component={NotificationsPage} />
      {/* extreviewer routes     */}
      <Route
        exact
        path="/:projectId/review"
        component={showLoader(ReviewerPageComponent)}
      />
      <Route
        exact
        path="/:projectId/review/:id/version"
        component={showLoader(ReviewVersionComponent)}
      />

      <Route
        exact
        path="/:projectId/sequence"
        component={withUserAccess(SequencePage)}
      />
      <Route
        exact
        path="/:projectId/project-media/:pmid?"
        component={withUserAccess(ProjectMediaPage)}
      />
      <Route
        exact
        path="/:projectId/playlist"
        component={playlistPageComponent}
      />
      <Route
        exact
        path="/:projectId/episodes"
        component={withUserAccess(EpisodesPage)}
      />

      {showSeqExperimental ? (
        <Route
          exact
          path="/:projectId/playlist/:playlistId/sequencer"
          component={withUserAccess(Sequencer)}
        />
      ) : (
        ""
      )}

      <Route
        exact
        path="/:projectId/departments"
        component={withUserAccess(Departments)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/departments/:deptID/department"
        component={withUserAccess(DepartmentDetail)}
      />
      <Route
        exact
        path="/:projectId/sequence/:sid/shots"
        component={withUserAccess(ShotsPage)}
      />
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:shotID/shotversions"
        component={withUserAccess(ShotVersion)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:shotID/shotversions/:shotVID/compare"
        component={(routerParams) => (
          <ComparePage {...routerParams} trackableType="shot" />
        )}
      />
      <Route
        exact
        path="/:projectId/assets"
        component={withUserAccess(AssetsPage)}
      />
      <Route
        exact
        path="/:projectId/assets/:assetID/assetversions"
        component={withUserAccess(AssetVersions)}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/assets/:assetID/assetversions/:avID/assetversions/compare"
        component={(routerParams) => (
          <ComparePage {...routerParams} trackableType="asset" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/assets/:Id/task"
        component={(routerParams) => (
          <TaskComponentOverview {...routerParams} trackableType="asset" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/sequence/:sid/shots/:Id/task"
        component={(routerParams) => (
          <TaskComponentOverview {...routerParams} trackableType="shot" />
        )}
      />
      {/* FIX-ME: Component import not consistent */}
      <Route
        exact
        path="/:projectId/settings"
        component={showLoader(SettingsPage)}
      />
      {/* "if its a valid route disable the below render"     */}
    </>
  );
}
