import axios from "axios";
import {
  createCookie,
  deleteCookie,
  getPayload,
} from "../Components/authorization";
axios.interceptors.request.use(
  (config) => {
    let accessToken = readCookie("access");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const SSO_URL_FRONTEND = process.env.REACT_APP_BASE_URL_SSOF;
const APP_BASE_URL = process.env.REACT_APP_BASE_URL_MCF.split("/")[2];
const DOMAIN_TYPE = process.env.REACT_APP_DOMAIN_TYPE;
const APP_URL = window.location.href
  .replace("https://", "")
  .replace("http://", "");
const redirectParams = `?type=${DOMAIN_TYPE}&baseUrl=${APP_BASE_URL}&redirectUrl=${APP_URL}`;

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // console.log("err", error.response.data);

    const originalRequest = error.config;
    let refreshToken = readCookie("refresh");
    if (error.response.data.code === "token_not_valid") {
      deleteCookie("access");
      deleteCookie("refresh");
      localStorage.removeItem("notifications");
      window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
    } else {
      if (refreshToken) {
        const userId = JSON.parse(atob(refreshToken.split(".")[1]))?.user_id;
        if (userId) {
          if (
            refreshToken &&
            userId &&
            error.response.status === 401 &&
            // error.response.data.code === "token_not_valid" && //compare string from error body
            !originalRequest._retry
          ) {
            if (Date.now() >= getPayload(readCookie("refresh")).exp * 1000) {
              //check refresh token is valid or not by decode
              deleteCookie("access");
              deleteCookie("refresh");
              localStorage.removeItem("notifications");
              window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
            }
            originalRequest._retry = true;

            try {
              const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL_SSO}api/token/refresh/`,
                {
                  refresh: refreshToken,
                }
              );
              if (res.status === 200) {
                createCookie("access", res.data.access);
                console.log("Access token refreshed!");
                return axios(originalRequest);
              }
            } catch (error) {
              deleteCookie("access");
              deleteCookie("refresh");
              localStorage.removeItem("notifications");
            }
          }
        } else {
          localStorage.removeItem("notifications");
          deleteCookie("access");
          deleteCookie("refresh");
          window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
        }
      }
    }

    return Promise.reject(error);
  }
);

function readCookie(name) {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return false;
}

export const fget = async ({ url, signal }) => {
  const res = await axios.get(process.env.REACT_APP_BASE_URL + `${url}`, {
    signal,
  });
  return res;
};

export const fget2 = async ({ url }) => {
  // console.log(readCookie("access"))
  let headers = {
    Authorization: `Bearer ${readCookie("access")}`,
    // contentType: "multipart/form-data",
  };
  const res = await axios.get(process.env.REACT_APP_BASE_URL + `${url}`, {
    headers,
  });
  return res;
};

export const fgetNextPage = async ({ url }) => {
  let headers = { Authorization: `Bearer ${readCookie("access")}` };
  const res = await axios.get(url, {
    headers,
  });
  return res;
};

export const fpatch = async ({ url, data }) => {
  // let headers = { Authorization: `Bearer ${token_value}` };
  const res = await axios.patch(
    process.env.REACT_APP_BASE_URL + `${url}`,
    data
  );
  return res;
};

export const fpost = async ({ url, data }) => {
  // let headers = { Authorization: `Bearer ${token_value}` };
  const res = await axios.post(process.env.REACT_APP_BASE_URL + `${url}`, data);
  return res;
};

export const fpost2 = async ({ url, data }) => {
  // let headers = {
  //   Authorization: `Bearer ${token_value}`,
  // };
  const res = await axios.post(`${url}`, data);
  return res;
};

export const fpostBFF = async ({ url, data }) => {
  // let headers = {
  //   Authorization: `Bearer ${token_value}`,
  // };
  const res = await axios.post(
    process.env.REACT_APP_BASE_URL_MCBFF + `${url}`,
    data
  );
  return res;
};

export const fgetBFF = async ({ url, data }) => {
  const res = await axios.get(process.env.REACT_APP_BASE_URL_MCBFF + `${url}`);
  return res;
};

export const fdelete = async ({ url }) => {
  const res = await axios.delete(process.env.REACT_APP_BASE_URL + `${url}`);
  return res;
};

export const fdelete2 = async ({ url, data }) => {
  const res = await axios({
    method: "DELETE",
    url: process.env.REACT_APP_BASE_URL + `${url}`,
    data,
  });
  return res;
};

export const exportFileWithXhr = ({
  url,
  data,
  updateProgressBar,
  transferComplete,
  transferFailed,
  transferCanceled,
}) => {
  return new Promise(function (resolve, reject) {
    var oReq = new XMLHttpRequest();

    oReq.upload.onprogress = updateProgressBar;
    oReq.addEventListener("load", transferComplete);
    oReq.addEventListener("error", transferFailed);
    oReq.addEventListener("abort", transferCanceled);

    oReq.open("PUT", url);
    oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    oReq.send(data);
    oReq.onreadystatechange = function () {
      if (oReq.status === 200) {
        resolve();
      }
    };
  });
};
export const uploadFile = ({ url, data }) => {
  const fetchConfig = {
    method: "PUT",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: data,
  };
  return fetch(url, fetchConfig);
};
