import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "280px",
  },
  card: {
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    padding: "16px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  flexDiv1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  flexDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "30px",
  },
  sortName: {
    fontSize: "14px",
    fontFamily: "Poppins",
    color: theme.palette.text.tertiary,
    cursor: "pointer",
  },
  cross: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    minWidth: "0px",
    marginRight: "-10px",
    height: "100%",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  cardAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sortBtn: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: "3%",
    textTransform: "none",
    padding: "5px 25px",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
