import { useEffect, useState } from "react";

export const NotificationHook = () => {
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(true);
  const [isNotificationOn, setIsNotificationOn] = useState(true);
  const [isNewNotificationAvailable, setIsNewNotificationAvailable] =
    useState(false);

  const fetchNotification = async () => {
    setIsNotificationsLoaded(false);
    const noti = JSON.parse(localStorage.getItem("notifications") || "[]");
    setNotifications(noti);
    setIsNotificationsLoaded(true);
  };

  const handleUpdateNotificationOnSession = (newNotification) => {
    const existingNotifications = JSON.parse(
      localStorage.getItem("notifications") || "[]"
    );
    if (existingNotifications?.length) {
      const newNot = [...existingNotifications, newNotification];
      setNotifications(newNot);
      localStorage.setItem("notifications", JSON.stringify(newNot));
    } else {
      localStorage.setItem("notifications", JSON.stringify([newNotification]));
      setNotifications([newNotification]);
    }
  };

  const handleNotificationStatus = () => {
    const status = localStorage.getItem("notificationStatus");
    if (status) {
      setIsNotificationOn(status);
      localStorage.setItem("notificationStatus", status);
    } else {
      setIsNotificationOn(false);
      localStorage.setItem("notificationStatus", false);
    }
  };

  const handleNewNotificationStatus = (value) => {
    setIsNewNotificationAvailable(value);
    localStorage.setItem("isNewNotification", value);
  };

  useEffect(() => {
    fetchNotification();
    handleNotificationStatus();
    const notificationValue = localStorage.getItem("isNewNotification");
    setIsNewNotificationAvailable(notificationValue);
   
  }, []);
  return {
    notifications,
    isNotificationsLoaded,
    fetchNotification,
    handleUpdateNotificationOnSession,
    handleNotificationStatus,
    isNotificationOn,
    handleNewNotificationStatus,
    isNewNotificationAvailable,
  };
};
