import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    // width: "521px",
    height: "auto",
    background: theme.palette.primary.main,
    borderRadius: "20px",
    padding: "24px 36px",
    fontFamily: "Poppins",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    gap: "4px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    position: "absolute",
    right: "50px",
    bottom: "20px",
  },
  doneBtn: {
    height: "45px",
    width: "163px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  cancelBtn: {
    height: "45px",
    width: "183px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
