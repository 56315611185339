import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "./SortModal.style.js";
import { X, ArrowUp } from "react-feather";

export default function SortModal({ closeModal, onSubmit, defaultvalue }) {
  const classes = useStyles();
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultvalue);
  }, [defaultvalue]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.flexDiv1}>
            <span className={classes.title}>Sort</span>
            <Button className={classes.cross}>
              <X size="30px" onClick={() => closeModal()} />
            </Button>
          </div>
          <br />
          <div className={classes.flexDiv} style={{ marginBottom: "15px" }}>
            <span
              className={classes.sortName}
              style={{
                color:
                  value === "Z-A" || value === "A-Z" ? "#F9F9F9" : "#606479",
              }}
              onClick={() => setValue(value === "A-Z" ? "Z-A" : "A-Z")}
            >
              Alphabetical Order
            </span>

            {(value === "Z-A" || value === "A-Z") && (
              <Button
                className={classes.cross}
                onClick={() => setValue(value === "A-Z" ? "Z-A" : "A-Z")}
              >
                <ArrowUp
                  style={{
                    transform: `rotate(${value === "A-Z" ? "0deg" : "180deg"})`,
                    transition: "transform 0.6s ease-in-out",
                    color: "#6C65D9",
                  }}
                />
              </Button>
            )}
          </div>
          <div className={classes.flexDiv}>
            <span
              className={classes.sortName}
              style={{
                color:
                  value === "LastModified" || value === "LatestModified"
                    ? "#F9F9F9"
                    : "#606479",
              }}
              onClick={() =>
                setValue(
                  value === "LatestModified" ? "LastModified" : "LatestModified"
                )
              }
            >
              Date Modified
            </span>
            {(value === "LatestModified" || value === "LastModified") && (
              <Button
                className={classes.cross}
                onClick={() =>
                  setValue(
                    value === "LatestModified"
                      ? "LastModified"
                      : "LatestModified"
                  )
                }
              >
                <ArrowUp
                  style={{
                    transform: `rotate(${
                      value === "LatestModified" ? "0deg" : "180deg"
                    })`,
                    transition: "transform 0.6s ease-in-out",
                    color: "#6C65D9",
                  }}
                />
              </Button>
            )}
          </div>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button
            className={classes.sortBtn}
            onClick={() => {
              onSubmit(value);
              closeModal();
            }}
          >
            Apply
          </Button>
          <Button
            onClick={() => onSubmit("DateCreated")}
            style={{
              backgroundColor: "transparent",
              midWidth: "0px",
              padding: "5px",
              textDecoration: "underline",
            }}
          >
            Clear Sorts
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
