import React from "react";
import ProjectMediaOverview from "../../Components/ProjectMedia/ProjectMediaOverview/ProjectMediaOverview";

const ProjectMediaPage = (props) => {
  return (
    <div>
      <ProjectMediaOverview params={props.match.params} />
    </div>
  );
};

export default ProjectMediaPage;
