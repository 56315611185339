import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationToast: {
    color: theme.palette.text.primary,
  },
  toastTheme: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px 0px 10px 10px",
    border: `2px solid ${theme.palette.secondary.main}`,
    color: "white",
    marginTop: "60px",
  },
  notificationTitle: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
