import React, { useEffect, useState } from "react";
import { Button, Typography, Drawer, Modal } from "@material-ui/core";
import SettingsModal from "../SettingsModal/SettingsModal";
import useStyles from "./ExternalReviewerSidebar.styles";
import { fget } from "../../../API/callsAPI";
import clsx from "clsx";
//icons
// import { Bell } from "react-feather";

import { Gear, ArrowUpRight } from "phosphor-react";

export default function ExternalReviewerSidebar({
  projectId,
  setSubFilterNotification,
  handleNotificationModal,
}) {
  const classes = useStyles();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [reviewStatusCount, setReviewCount] = useState({});

  const getReviewFiles = async () => {
    try {
      const reviewFilesRes = await fget({
        url: `projects/${projectId}/review-count/`,
      });
      setReviewCount(reviewFilesRes.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviewFiles();
  }, [projectId]);

  return (
    <>
      <Drawer PaperProps={{ className: `${classes.root}` }} variant="permanent">
        <div id="external-sidebar">
          <Typography className={classes.heading}>External Review</Typography>
          <div className={classes.flexBox1}>
            {/* <div className={classes.flexBox}>
              <Typography className={classes.subHeading}>Notifications</Typography>
              <Button onClick={handleNotificationModal} >
               <Bell color="#6C65D9" size={20} />
               </Button>
            </div> */}

            <div className={classes.notificationType}>
              <div
                className={clsx(
                  classes.notificationTypeCount,
                  classes.approvedNotificationType
                )}
              >
                {reviewStatusCount.completed_reviews}
              </div>
              <div className={classes.notificationTypeText2}>
                Reviews Completed
              </div>
              <Button
                className={clsx(
                  classes.notificationTypeIcon,
                  classes.approvedNotificationTypeHover
                )}
                onClick={() => {
                  setSubFilterNotification({
                    projectId: projectId,
                    subFilterName: "trackable_approved",
                  });
                  handleNotificationModal();
                }}
              >
                <ArrowUpRight size="20px" />
              </Button>
            </div>
            <div className={classes.notificationType}>
              <div className={classes.notificationTypeCount}>
                {reviewStatusCount.comments}
              </div>
              <div className={classes.notificationTypeText2}>
                Total<br></br> Comments
              </div>
              <Button
                className={clsx(
                  classes.notificationTypeIcon,
                  classes.simpleHover
                )}
                onClick={() => {
                  setSubFilterNotification({
                    projectId: projectId,
                    subFilterName: "review_comment_added",
                  });
                  handleNotificationModal();
                }}
              >
                <ArrowUpRight size="20px" />
              </Button>
            </div>
            <div className={classes.notificationType}>
              <div className={classes.notificationTypeCount}>
                {reviewStatusCount.total_assigned_reviews}
              </div>
              <div className={classes.notificationTypeText}>
                Total <br></br> Reviews
              </div>
              {/* <div className={classes.notificationTypeIcon}>
                <ArrowUpRight size="20px" />
              </div> */}
            </div>
            <div className={classes.notificationType}>
              <div
                className={clsx(
                  classes.notificationTypeCount,
                  classes.pendingNotificationType
                )}
              >
                {reviewStatusCount.pending_reviews}
              </div>
              <div className={classes.notificationTypeText}>
                In <br></br> Progress
              </div>
              {/* <div
                className={clsx(
                  classes.notificationTypeIcon,
                  classes.pendingNotificationType
                )}
              >
                <ArrowUpRight size="20px" />
              </div> */}
            </div>
            {/* <div className={classes.notificationType}>
              <div className={classes.notificationTypeCount}>12</div>
              <div className={classes.notificationTypeText}>New Comments</div>
              <div className={classes.notificationTypeIcon}>
                <ArrowUpRight size="20px" />
              </div>
            </div> */}
            <div className={classes.settingsContainer}>
              <Typography className={classes.subHeading}>Settings</Typography>
              <Button onClick={() => setOpenSettingsModal(true)}>
                <Gear size="20px" weight="fill" color="#6C65D9" />
              </Button>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox />
              <Typography>Show tips and recommendation</Typography>
            </div> */}
          </div>
        </div>
      </Drawer>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={openSettingsModal}
        onClose={() => setOpenSettingsModal(false)}
      >
        <SettingsModal
          projectId={projectId}
          onClose={() => setOpenSettingsModal(false)}
        />
      </Modal>
    </>
  );
}
