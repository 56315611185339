import React from "react";

export default function ReviewVersionIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2407 4.17276C19.371 4.05069 19.4441 3.88512 19.4441 3.71248C19.4441 3.53984 19.371 3.37427 19.2407 3.25219C19.1105 3.13012 18.9339 3.06154 18.7497 3.06154C18.5655 3.06154 18.3889 3.13012 18.2586 3.25219C18.1284 3.37427 18.0552 3.53984 18.0552 3.71248C18.0552 3.88512 18.1284 4.05069 18.2586 4.17276C18.3889 4.29484 18.5655 4.36342 18.7497 4.36342C18.9339 4.36342 19.1105 4.29484 19.2407 4.17276ZM19.8484 2.45083C20.1585 2.68839 20.3714 3.01913 20.4511 3.38701H22.2219C22.4061 3.38701 22.5827 3.45559 22.7129 3.57766C22.8432 3.69974 22.9163 3.86531 22.9163 4.03795V11.8492C22.9163 12.0219 22.8432 12.1874 22.7129 12.3095C22.5827 12.4316 22.4061 12.5002 22.2219 12.5002H16.4268C16.4692 12.3967 16.5083 12.2917 16.544 12.1854C16.8246 11.3515 16.8912 10.4616 16.7379 9.59594C16.5846 8.73024 16.2161 7.91582 15.6657 7.22592C15.3538 6.83498 14.989 6.49097 14.583 6.2028V4.03795C14.583 3.86531 14.6562 3.69974 14.7864 3.57766C14.9166 3.45559 15.0933 3.38701 15.2775 3.38701H17.0483C17.128 3.01913 17.3409 2.68839 17.651 2.45083C17.961 2.21327 18.3492 2.0835 18.7497 2.0835C19.1501 2.0835 19.5383 2.21327 19.8484 2.45083ZM16.8189 6.7847C16.7212 6.87625 16.6663 7.00043 16.6663 7.12991C16.6663 7.25939 16.7212 7.38357 16.8189 7.47512C16.9166 7.56668 17.049 7.61811 17.1872 7.61811H20.3122C20.4503 7.61811 20.5828 7.56668 20.6805 7.47512C20.7781 7.38357 20.833 7.25939 20.833 7.12991C20.833 7.00043 20.7781 6.87625 20.6805 6.7847C20.5828 6.69314 20.4503 6.64171 20.3122 6.64171H17.1872C17.049 6.64171 16.9166 6.69314 16.8189 6.7847ZM16.8189 8.41205C16.9166 8.32049 17.049 8.26905 17.1872 8.26905H20.3122C20.4503 8.26905 20.5828 8.32049 20.6805 8.41205C20.7781 8.5036 20.833 8.62778 20.833 8.75726C20.833 8.88674 20.7781 9.01092 20.6805 9.10247C20.5828 9.19403 20.4503 9.24546 20.3122 9.24546H17.1872C17.049 9.24546 16.9166 9.19403 16.8189 9.10247C16.7212 9.01092 16.6663 8.88674 16.6663 8.75726C16.6663 8.62778 16.7212 8.5036 16.8189 8.41205Z"
        fill="#F9F9F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0461 15.3537C16.0442 15.3583 16.0423 15.3629 16.0405 15.3675L15.9487 15.597C15.7847 16.0069 15.4699 16.3333 15.0755 16.5138C14.009 15.8095 12.7511 15.4292 11.4584 15.4292C9.91067 15.4292 8.41293 15.9744 7.23363 16.9679C6.05423 17.9616 5.27029 19.339 5.02344 20.8537C5.00795 20.9499 4.9733 21.0431 4.92154 21.1265C4.86977 21.21 4.80172 21.2828 4.7211 21.3406C4.64046 21.3984 4.54887 21.4402 4.45152 21.4632C4.35416 21.4862 4.25311 21.4901 4.15421 21.4745C4.0553 21.4589 3.96066 21.4243 3.87567 21.3726C3.79068 21.321 3.71709 21.2535 3.65893 21.1742C3.60077 21.0949 3.55914 21.0052 3.53621 20.9104C3.5133 20.8157 3.50947 20.7174 3.52493 20.6212C3.72972 19.3597 4.239 18.1652 5.00994 17.1391C5.78097 16.113 6.79106 15.2854 7.95485 14.727L8.47211 14.4788L8.0516 14.0885C7.55709 13.6295 7.16339 13.0752 6.89466 12.4601C6.62593 11.845 6.48783 11.182 6.48878 10.5122C6.48868 9.69879 6.69295 8.89734 7.08348 8.18101C7.47402 7.46465 8.0387 6.85508 8.72711 6.40747C9.41553 5.95984 10.206 5.68832 11.0273 5.61764C11.8487 5.54695 12.6748 5.67936 13.4313 6.00279C14.1878 6.32621 14.8507 6.83037 15.3607 7.46954C15.8706 8.10868 16.2117 8.86282 16.3536 9.66406C16.4955 10.4653 16.4338 11.2889 16.1741 12.0608C15.9144 12.8328 15.4646 13.5292 14.8648 14.0873L14.4454 14.4776L14.9619 14.7255C15.3406 14.9072 15.7029 15.1174 16.0461 15.3537ZM9.01782 8.09138C9.66581 7.45037 10.5438 7.09101 11.4584 7.09101C12.373 7.09101 13.251 7.45037 13.899 8.09138C14.5471 8.73254 14.912 9.60303 14.912 10.5116C14.912 11.4202 14.5471 12.2907 13.899 12.9318C13.251 13.5728 12.373 13.9322 11.4584 13.9322C10.5438 13.9322 9.66581 13.5728 9.01782 12.9318C8.36968 12.2907 8.00477 11.4202 8.00477 10.5116C8.00477 9.60303 8.36968 8.73254 9.01782 8.09138Z"
        fill="#F9F9F9"
      />
      <path
        d="M19.0723 20.528L20.43 16.4062H21.8751L19.8869 21.7791H18.2382L16.2598 16.4062H17.7145L19.0723 20.528Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}
