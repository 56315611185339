import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    height: "165px",
  },
  viewProjectLink: {
    color: theme.palette.secondary.main,
  },
  card: {
    width: "100%",
    height: "100%",
    background: theme.palette.secondary.light,
    // boxShadow: " 6px 11px 43px rgba(0, 0, 0, 0.25);",
    boxShadow: "none",
    borderRadius: "15px",
    transition: "0.2s",
    "&:hover": {
      background: theme.palette.secondary.light,
      boxShadow: " 6px 11px 43px rgba(0, 0, 0, 0.45);",
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      width: "29rem",
      // height: "11rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "11rem",
    },
  },
  cardContentDiv: {
    display: "flex",
    height: "125px",
    gap: "4px",
  },
  cardContentDiv1: {
    width: "25%",
    marginRight: "2.5%",
    height: "125px",
    overflow: "hidden",
  },
  cardContentDiv2: {
    fontFamily: "Poppins",
    width: "55%",
    marginRight: "2.5%",
    height: "125px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "0px",
  },
  imageStyle: {
    objectFit: "cover",
    borderRadius: "15px",
    height: "100%",
    width: "100%",
  },
  userRoleIcon: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px 0 0 0",
    padding: "5px 0px 0px 5px",
  },
  userRoleText: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 6px",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.secondary.main}`,
    zIndex: 9999,
    position: "absolute",
    width: "400px",
    marginLeft: "50px",
  },
  title: {
    height: "100%",
    width: "100%",
    fontSize: "24px",
    fontWeight: "500",
    fontFamily: "Poppins",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  abbreviationText: {
    fontSize: "17px",
    fontFamily: "Poppins",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  cardContentDiv2Title: {
    height: "36px",
    width: "100%",
  },
  cardContentDiv2Desc: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    height: "42px",
  },
  descText: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitBoxDirection: "reverse",
    position: "relative",
    top: "80%",
    transform: "translateY(-80%)",
  },
  cardContentDiv2Footer: {
    display: "flex",
    fontSize: "14px",

    "& :nth-child(1)": {
      color: "grey",
    },
    "& :nth-child(2)": {
      color: theme.palette.text.primary,
      marginLeft: "12px",
    },
  },
  clickableDiv: {
    height: "30px",
    width: "54px",
    cursor: "pointer",
    borderRadius: "5.5px",
    border: "none",
    background: "#171b2100",
  },

  projectDot: {
    color: theme.palette.text.primary,
    flex: "0.1",
    marginTop: "3px",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },

  trashIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },
  trashIcon1: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },

  menuPop: {
    backgroundColor: theme.palette.secondary.light,
    "& li.MuiMenuItem-root": {
      backgroundColor: theme.palette.secondary.light,
    },
    "& ul.MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "5px",
  },

  modalButton: {
    width: "180px",
    height: "45px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.secondary.main,
      boxShadow: " 6px 11px 43px rgba(0, 0, 0, 0.45);",
      cursor: "pointer",
    },
  },

  projectPageModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  linkText: {
    color: theme.palette.secondary.main,
    margin: "0px",
    padding: "0px",
    fontWeight: "600",
  },

  circleDefaultColor: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
