import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "470px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "18px 18px 18px 18px",
  },
  cardTool: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  cardContentDiv1: {
    justifyContent: "space-around",

    marginTop: "5%",
  },

  textFieldContainer: {
    marginTop: "10px",
  },

  cardContentDiv2: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
  },

  cardContentInput1: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },
  textField: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    color: "white",
    cursor: "pointer",
  },
  popperDiv: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "450px",
    height: "505px",
    top: "-60px !important",
  },

  datePickers: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    marginTop: "-10px",
    // paddingBottom: "7px ",
    color: theme.palette.primary.main,
    "& :first-child": {
      backgroundColor: theme.palette.secondary.main,
      textTransform: "none",
      padding: "3px",
      borderRadius: "7px",
      color: theme.palette.primary.main,
    },
  },
  addNewStatusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },

  searchInput: {
    padding: "4px 16px",
    width: "60%",
    // background: theme.palette.secondary.light,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "14px",
    marginTop: "1px",
  },
  searchDiv: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    width: "80%",
    height: "46px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    marginTop: "0.8rem",
    marginLeft: "0.5rem",
  },
  findTrackableSelect: {
    border: "1px solid white",
    borderRadius: "30px",
    margin: "10px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  findTrackableDeselect: {
    border: "1px solid grey",
    color: "grey",
    borderRadius: "30px",
    margin: "10px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  sequenceSelectorHidden: {
    visibility: "hidden",
  },
  sequenceSelectorVisible: {
    backgroundColor: theme.palette.secondary.light,
    position: "absolute",
    // left: "3rem",
    display: "flex",
    visibility: "visible",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
  },
  horizontalLine: {
    border: "none",
    borderTop: "1px solid grey",
    width: "93%",
  },
  datePickerDiv: {
    width: "43%",
    overflowY: "hidden",
    padding: "0px 8px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    marginTop: "10px",
  },
  selectedTrackablesContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "5px",
    maxHeight: "200px",
    overflowY: "auto",
  },
  singleTrack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid ${theme.palette.text.tertiary}`,
    padding: "3px 8px",
    borderRadius: "20px",
    width: "130px",
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectedTrackable: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: "3px",
    borderRadius: "10px",
  },
  doneButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  doneButton: {
    width: "100px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
  },
  trackableCount: {
    color: theme.palette.text.tertiary,
  },
  /* datePickerComponent: {
    // marginLeft: "4px",
    // marginBottom: "2.2px",
    padding: "9px",
    borderRadius: "5px",
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    minHeight: "28px",
  }, */
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 4px",
  },
  singleButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "5px",
    padding: "2px 4px",
  },
}));

export default useStyles;
