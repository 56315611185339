import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    background: theme.palette.primary.main,
    width: "100%",
    display: "table",
    tableLayout: "fixed",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(823)]: {
      height: `calc(100vh - 305px)`,
    },
  },
  tooglerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  versionsTab: {
    textTransform: "none",
    minWidth: "40px",
    margin: "0px 10px",
  },
  taskTab: {
    textTransform: "none",
    minWidth: "40px",
  },
  assetsPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    display: "flex",
    borderRadius: "6px",
    height: "30px",
    width: "250px",
    background: "#393E46",
    margin: "8px 8px",
  },
  dropdownDiv: {
    marginTop: "5px",
  },
  selectText: {
    marginLeft: "12px",
    marginTop: "5px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    width: "60%",
    fontStyle: "normal",
  },
  iconStyle: {
    float: "right",
    width: "27px",
    height: "18px",
    color: theme.palette.secondary.main,
  },
  assetVersionTablecell: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    // borderCollapse: "collapse",
    background: theme.palette.primary.main,
    width: "16%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  assetVersionTablecellTwo: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    // borderCollapse: "collapse",
    background: theme.palette.primary.main,
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  assetVersionTablecellThree: {
    padding: "1.3% 1% 1.3% 0% !important",
    color: theme.palette.text.tertiary,
    // borderCollapse: "collapse",
    background: theme.palette.primary.main,
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  closeFullScreen: {
    position: "absolute",
    top: "10px",
    right: "20px",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    height: "30px",
    width: "30px",
  },

  floatingDiv: {
    position: "fixed",
    right: "0px",
    width: "550px",
    borderLeft: "2px solid #606479",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: "100",
  },
}));

export default useStyles;
