import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    border: `1px solid ${theme.palette.text.tertiary}`,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  divider: {
    width: "90%",
  },
  listText: {
    fontFamily: "Poppins",
    color: theme.palette.secondary.main,
  },
  redText: {
    fontFamily: "Poppins",
    color: "#FF0000",
  },
}));

export default useStyles;
