import MomentUtils from "@date-io/moment";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { Calendar, PencilLine, ShuffleSimple, Trash, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { fpatch } from "../../../../API/callsAPI";
import DateAndTimeViewer from "../../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import DateAndTimePicker from "./DateAndTimePicker";
import useStyles from "./TaskUpdateModal.style";

const TaskUpdateModal = ({
  selectedTasks,
  statusList,
  handleClose,
  updateRefreshCounter,
}) => {
  const classes = useStyles();
  const [referenceDate, setReferenceDate] = useState(() => {
    const today = new Date();
    today.setHours(10);
    today.setMinutes(0);
    return today;
  });
  const [selectedTasksData, setSelectedTasksData] = useState([]);
  const [isUpdatingTasks, setIsUpdatingTasks] = useState(false);

  const handleBulkTasksUpdate = async () => {
    setIsUpdatingTasks(true);
    const taskArray = selectedTasksData.map((task) => {
      return {
        id: task.id,
        status: task?.isChanged ? task.status : task.status?.id ?? null,
        end_time: task.end_time,
        start_time: task.start_time,
        color: task.color,
        linked: task?.linked?.id,
      };
    });

    let updatedTasks = {
      url: `trackables/task/`,
      data: taskArray,
    };
    try {
      const updateTaskRes = await fpatch(updatedTasks);
      if (updateTaskRes?.status) {
        updateRefreshCounter();
        setIsUpdatingTasks(false);
        handleClose();
      }
    } catch (error) {
      setIsUpdatingTasks(false);
      console.log(error);
    }
  };

  const handleRemoveFromList = (id) => {
    const filtered = selectedTasksData?.filter((single) => single.id !== id);
    setSelectedTasksData(filtered);
  };

  const handleUpdateAllTaskDate = () => {
    const updatedTasks = selectedTasksData.map((task) => {
      if (task?.phase) {
        const endDate = new Date(referenceDate);
        endDate.setDate(endDate.getDate() + task?.phase?.number_of_days ?? 0);
        endDate.setHours(
          endDate.getHours() + task?.phase?.number_of_hours ?? 0
        );
        return {
          ...task,
          start_time: referenceDate,
          end_time: endDate,
        };
      }
      return task;
    });
    setSelectedTasksData(updatedTasks);
  };

  const handleUpdateSingleTaskDuration = (id) => {
    const updatedTasks = selectedTasksData.map((task) => {
      if (task?.id === id && task?.phase) {
        const endDate = new Date(referenceDate);
        endDate.setDate(endDate.getDate() + task?.phase?.number_of_days ?? 0);
        endDate.setHours(
          endDate.getHours() + task?.phase?.number_of_hours ?? 0
        );
        return {
          ...task,
          start_time: referenceDate,
          end_time: endDate,
        };
      }
      return task;
    });
    setSelectedTasksData(updatedTasks);
  };

  const handleUpdateSingleTaskDate = (id, startDate, endDate) => {
    const updatedTasks = selectedTasksData.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          start_time: startDate,
          end_time: endDate ? endDate : startDate,
        };
      }
      return task;
    });
    setSelectedTasksData(updatedTasks);
  };

  const handleUpdateSingleTaskStatus = (id, status) => {
    const updatedTasks = selectedTasksData.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          status: status,
          isChanged: true,
        };
      }
      return task;
    });
    setSelectedTasksData(updatedTasks);
  };

  const handleRemoveSingleTaskDates = (id) => {
    const updatedTasks = selectedTasksData.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          start_time: null,
          end_time: null,
        };
      }
      return task;
    });
    setSelectedTasksData(updatedTasks);
  };

  useEffect(() => {
    setSelectedTasksData(selectedTasks);
  }, [selectedTasks]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.flexBetween}>
            <div></div>
            <h2>Task update modal</h2>
            <Button onClick={handleClose}>
              <X />
            </Button>
          </div>
          <div className={classes.referenceDate}>
            <span>Reference Date :</span>
            <span>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker
                  disableToolbar
                  autoOk
                  invalidDateMessage={"Invalid date format"}
                  variant="inline"
                  format="YYYY/MM/DD hh:mm A"
                  margin="normal"
                  value={referenceDate}
                  onChange={(date) => setReferenceDate(date)}
                  id="date-time-picker"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    style: {
                      padding: "0px",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className={classes.dateInput}
                  size="small"
                  fullWidth
                  ampm={true}
                  minDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </span>
            <Button onClick={() => handleUpdateAllTaskDate()}>
              <ShuffleSimple size={22} color="#6C65D9" />
            </Button>
          </div>
          <div>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="simple table" className={classes.table}>
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      className={classes.headTableTwo}
                      align="left"
                      width="15%"
                    >
                      Task Name
                    </TableCell>
                    <TableCell
                      className={classes.headTable}
                      align="center"
                      width="10%"
                    >
                      Trackable
                    </TableCell>
                    <TableCell
                      className={classes.headTable}
                      align="center"
                      width="15%"
                    >
                      Template Duration
                    </TableCell>
                    <TableCell
                      className={classes.headTable}
                      align="center"
                      width="20%"
                    >
                      Start Date | Time
                    </TableCell>
                    <TableCell
                      className={classes.headTable}
                      align="center"
                      width="20%"
                    >
                      End Date | Time
                    </TableCell>
                    <TableCell
                      className={classes.headTableTwo}
                      align="center"
                      width="15%"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className={classes.headTableTwo}
                      align="right"
                      width="5%"
                    />
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {selectedTasksData?.map((task) => (
                    <SingleTask
                      task={task}
                      statusList={statusList}
                      handleRemoveFromList={handleRemoveFromList}
                      handleUpdateSingleTaskDate={handleUpdateSingleTaskDate}
                      handleUpdateSingleTaskStatus={
                        handleUpdateSingleTaskStatus
                      }
                      handleRemoveSingleTaskDates={handleRemoveSingleTaskDates}
                      handleUpdateSingleTaskDuration={
                        handleUpdateSingleTaskDuration
                      }
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span>Total Selected : {selectedTasks?.length}</span>
          </div>
          <div className={classes.buttons}>
            <Button
              className={classes.resetButton}
              onClick={() => setSelectedTasksData(selectedTasks)}
              disabled={isUpdatingTasks}
            >
              {isUpdatingTasks ? (
                <CircularProgress color="secondary" size={18} />
              ) : (
                "Reset Changes"
              )}
            </Button>
            <Button
              className={classes.newTaskButton}
              onClick={handleBulkTasksUpdate}
              disabled={isUpdatingTasks}
            >
              {isUpdatingTasks ? (
                <CircularProgress size={18} />
              ) : (
                "Update Tasks"
              )}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TaskUpdateModal;

const SingleTask = ({
  task,
  statusList,
  handleRemoveFromList,
  handleUpdateSingleTaskDate,
  handleUpdateSingleTaskStatus,
  handleRemoveSingleTaskDates,
  handleUpdateSingleTaskDuration,
}) => {
  const classes = useStyles();
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [status, setStatus] = useState("none");
  useEffect(() => {
    setStatus(task?.isChanged ? task.status : task?.status?.id ?? "none");
  }, [task]);

  return (
    <>
      <TableRow hover className={classes.tableBodyRow}>
        <TableCell className={classes.listText} align="start" width="15%">
          <span style={{ paddingLeft: "6px" }}>{task?.name}</span>
        </TableCell>
        <TableCell className={classes.listText} align="center" width="10%">
          <span>{task?.linked?.name || task?.linked?.code || ""}</span>
        </TableCell>
        <TableCell className={classes.listText} align="center" width="15%">
          <div className={classes.flexAndGap}>
            {task?.phase ? (
              <>
                <span>
                  {task?.phase?.number_of_days ?? 0} Days{" "}
                  {task?.phase?.number_of_hours ?? 0} hrs
                </span>
                <Button
                  onClick={() => handleUpdateSingleTaskDuration(task?.id)}
                >
                  <ShuffleSimple size={20} color="#6C65D9" />
                </Button>
              </>
            ) : (
              "--"
            )}
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center" width="20%">
          <div className={classes.flexAndGap}>
            {task?.start_time ? (
              <DateAndTimeViewer dateAndTime={task?.start_time} />
            ) : (
              "--"
            )}
            <PencilLine
              size={20}
              onClick={() => setDatePickerModal(true)}
              className={classes.iconColor}
            />
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center" width="20%">
          <div className={classes.flexAndGap}>
            {task?.end_time ? (
              <DateAndTimeViewer dateAndTime={task?.end_time} />
            ) : (
              "--"
            )}
            <PencilLine
              size={20}
              onClick={() => setDatePickerModal(true)}
              className={classes.iconColor}
            />
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center" width="15%">
          <div>
            <Select
              disableUnderline
              className={classes.input}
              value={status}
              onClick={(e) => e.stopPropagation()}
              onChange={(event) =>
                handleUpdateSingleTaskStatus(task?.id, event.target.value)
              }
              fullWidth
            >
              <MenuItem className={classes.menuItem} value={"none"}>
                <Tooltip title={"No status selected"} placement="top">
                  <div>
                    <span style={{ overflow: "hidden" }}>None</span>
                  </div>
                </Tooltip>
              </MenuItem>
              {statusList &&
                statusList.map((status) => (
                  <MenuItem
                    className={classes.menuItem}
                    value={status.id}
                    key={status.id}
                  >
                    <Tooltip title={status?.name} placement="top">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className={classes.statusColor}
                          style={{
                            backgroundColor: `rgb(${status?.color})`,
                          }}
                        />
                        <span>{status.name}</span>
                      </div>
                    </Tooltip>
                  </MenuItem>
                ))}
            </Select>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="right" width="5%">
          <div className={classes.calenderAndDelete}>
            <Button
              onClick={() => handleRemoveSingleTaskDates(task.id)}
              style={{ minWidth: "0px", padding: "0px" }}
              disabled={task?.start_time === null && task.end_time === null}
            >
              <Calendar size={20} className={classes.iconColor} />
            </Button>
            <Button
              onClick={() => handleRemoveFromList(task?.id)}
              style={{ minWidth: "0px", padding: "0px" }}
            >
              <Trash size={20} className={classes.iconColor} />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <Modal
        open={datePickerModal}
        onClose={() => setDatePickerModal(false)}
        className={classes.flexCenter}
      >
        <DateAndTimePicker
          task={task}
          handleUpdateSingleTaskDate={handleUpdateSingleTaskDate}
          handleClose={() => setDatePickerModal(false)}
        />
      </Modal>
    </>
  );
};
