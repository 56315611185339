import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },

  table: {
    width: "100%",
  },

  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },

  tableRow: {
    borderCollapse: "collapse",
  },

  headTable: {
    padding: "4px 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "17.5%",
    background: theme.palette.primary.main,
  },

  headTableTwo: {
    padding: "4px 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "15%",
    background: theme.palette.primary.main,
  },

  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(823)]: {
      height: `calc(100vh - 305px)`,
    },
  },

  listText: {
    color: theme.palette.text.primary,
    padding: "1px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "17.5%",
  },

  fileNameText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: "10px",
  },

  listTextTwo: {
    color: theme.palette.text.primary,
    padding: "1px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "15%",
    overflow: "hidden",
    borderCollapse: "collapse",
  },

  flexCenterModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  //media view modal styles
  modalRoot: {
    width: "800px",
    height: "600px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    borderRadius: "10px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "10px",
    height: "100%",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  videoPlayer: {
    height: "85%",
    width: "100%",
  },
  imageView: {
    height: "85%",
    width: "100%",
  },
  closeIcon: {
    // backgroundColor: "red",
    fontSize: "20px",
  },
  downloadButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    minWidth: "150px",
  },
  canNotPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
  },
  canNotPreviewText: {
    fontSize: "25px",
    fontWeight: 600,
  },
  downloadText: {
    fontSize: "20px",
    fontWeight: 600,
  },
}));

export default useStyles;
