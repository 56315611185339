import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.4%",
    marginBottom: "1.7%",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "30px",
    fontWeight: 600,
  },
  tool: {
    fontSize: "16px !important",
    fontWeight: "400",
  },
  sortActive: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    fontWeight: "400",
  },
  sortInactive: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    fontWeight: "400",
  },
  dotBlur: {
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    filter: "blur(4px)",
  },
  dot: {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    marginTop: "-5px",
  },
  projectPageToolsGrid: {
    display: "flex",
    // margin: "0px 10px",
    width: "100%",
  },
  projectPageButton: {
    textTransform: "none",
    height: "2.5rem",
    width: "100%",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "8px",

      fontSize: "1.2rem",
    },
  },

  plusIcon: {
    width: "30px",
    height: "20px",
  },

  projectPageNewProjButton: {
    backgroundColor: theme.palette.secondary.main,
    // height: "43px",
    width: "160px",
    marginLeft: "9px",
    justifyContent: "flex-start",
    textTransform: "none",
    borderRadius: "7px",
    padding: "10px",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  popoverArrow: {
    position: "absolute",
    borderRadius: "5px",
    left: "40px",
    top: "-23px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    marginLeft: "40px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.secondary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popover: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  tab: {
    minWidth: "65px",
    fontSize: "14px",
    // minHeight: "30px",
    padding: "2px",
    marginLeft: "20px",
  },
  tabItems: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "16px",
    fontWeight: 500,
  },
  active: {
    color: theme.palette.text.primary,
  },
  inActive: {
    color: theme.palette.text.tertiary,
  },
  centerItem: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
