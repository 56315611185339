import { TableCell, TableRow, Tooltip, Modal, Button } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./SequenceList.styles";
import * as Icon from "react-feather";

import ImageViewer from "./../../Utilities/ImageViewer/ImageViewer.component";

import NoThumbnail from "../../Utilities/NoThumbnail.component";
import StringTruncate from "../../Utilities/StringTruncate/StringTruncate";
import { useHistory } from "react-router";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import SequencePageEditSequenceModal from "./../SequencePageModals/SequencePageEditSequenceModal";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";

export default function SequenceList(props) {
  const classes = useStyles();
  const history = useHistory();

  const [deleteSequenceModalOpen, setDeleteSequenceModalOpen] = useState(false);
  const [isEditSequenceModalOpen, setEditSequenceModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  function handleDeleteSequenceModalState(e) {
    e?.stopPropagation();
    setDeleteSequenceModalOpen(!deleteSequenceModalOpen);
  }

  function handleEditSequenceModalState(e) {
    e?.stopPropagation();
    setEditSequenceModalOpen(!isEditSequenceModalOpen);
  }

  const handleDeleteSequence = async () => {
    try {
      await fdelete({
        url: `trackables/shot-sequence/${deleteData?.id}/`,
      });
      props.updateRefreshCounter();
      setDeleteSequenceModalOpen(false);
      setEditSequenceModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          if (event.ctrlKey) {
            window.open(`/${props.projectId}/sequence/${props.data.id}/shots`);
            sessionStorage.setItem("scrollpos", window.scrollY);
          } else {
            history.push(`/${props.projectId}/sequence/${props.data.id}/shots`);
            sessionStorage.setItem("scrollpos", window.scrollY);
          }
        }}
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.SequenceListItemTwo}
          align="left"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                sliderState={props.toggler ? 25 : 0}
                url={props.data.thumbnail}
              />
            ) : (
              <NoThumbnail sliderState={props.toggler ? 25 : 0} />
            )}
            <p className={classes.textColor}>
              {props.data.code ? props.data.code : "No Name"}
            </p>
          </div>
        </TableCell>
        <TableCell className={classes.SequenceListItem} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={props?.data?.updated_at} />
          </div>
        </TableCell>
        <TableCell className={classes.SequenceListItem} align="center">
          {props.data.description ? (
            <>
              <Tooltip title={props.data.description}>
                <p>{StringTruncate(props.data.description, 50)}</p>
              </Tooltip>
            </>
          ) : (
            <p style={{ color: "white" }}>No Description</p>
          )}
        </TableCell>
        <TableCell align="center" className={classes.SequenceListItem}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px",
            }}
          >
            <StatusDropDown
              url={`trackables/shot-sequence/${props.data.id}/`}
              updateRefreshCounter={props.updateRefreshCounter}
              statusList={props.status}
              currStatus={props.data.status}
            />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.SequenceListItem}>
          {props.data.parent_episode?.id ? (
            <p className={classes.textColor}>
              {" "}
              {props.data.parent_episode?.name}
            </p>
          ) : (
            <p className={classes.textColor}> -- </p>
          )}
        </TableCell>
        <TableCell align="center" className={classes.SequenceListItem}>
          {props.data.status?.code ? (
            <p className={classes.textColor}> {props.data.status.code}</p>
          ) : (
            <p className={classes.textColor}> None </p>
          )}
        </TableCell>
        <TableCell className={classes.SequenceListItemThree} align="right">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  props
                    .fetchFile(props.data.file ? props.data.id : null)
                    // opening the modal after the function has fetched file, else previous file loads first
                    .then(() => props.setIsViewModalOpen(true));
                }}
              >
                View
              </Button>
              <Button
                onClick={(e) => {
                  setDeleteData(props?.data);
                  handleEditSequenceModalState(e);
                }}
              >
                <Icon.Edit3 style={{ height: "20px", width: "20px" }} />
              </Button>
            </div>
          </div>
        </TableCell>
      </TableRow>

      <div>
        <Modal
          className={classes.sequencePageModals}
          open={deleteSequenceModalOpen}
          onClose={() => setDeleteSequenceModalOpen(false)}
        >
          <DeleteModal
            type="Sequence"
            name={deleteData?.name || deleteData?.code}
            handleCloseDeleteModal={() => {
              setDeleteSequenceModalOpen(false);
            }}
            handleDelete={handleDeleteSequence}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.sequencePageModals}
          open={isEditSequenceModalOpen}
          onClose={() => setEditSequenceModalOpen(false)}
        >
          <SequencePageEditSequenceModal
            dragScreen={props.dragScreen}
            handleDragScreen={props.handleDragScreen}
            setDragFileEnterModal={props.setDragFileEnterModal}
            sequenceId={props.data.id}
            projectId={props.projectId}
            sequence={props.data}
            status={props.status}
            handleEditSequenceModal={handleEditSequenceModalState}
            handleDeleteSequenceModalState={handleDeleteSequenceModalState}
            updateRefreshCounter={props.updateRefreshCounter}
          />
        </Modal>
      </div>
    </>
  );
}
