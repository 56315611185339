import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fdelete, fpatch, fpost } from "../../API/callsAPI";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APPID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
  vapId: process.env.REACT_APP_FCM_FIREBASE_VAPID,
};
const publicKey = process.env.REACT_APP_FCM_FIREBASE_VAPID;

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//generate firebase token
export const getFirebaseToken = async () => {
  let token = "";
  try {
    if (messaging) {
      const currentToken = await getToken(messaging, { vapidKey: publicKey });
      if (currentToken) {
        console.log("Firebase Token : ", currentToken);
        token = currentToken;
      } else {
        console.log("No registration token available.");
      }
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
  return token;
};

//message listener
export function onMessageListener() {
  if (!messaging) {
    return new Promise((resolve, reject) => {
      reject("");
    });
  } else {
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  }
}

export const unregisterDevice = async () => {
  const deviceId = localStorage.getItem("deviceId");
  try {
    if (deviceId) {
      await fdelete({
        url: `device/unregister/${deviceId}`,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
