import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//UI
import {
  Button,
  CircularProgress,
  Divider,
  Input,
  Switch,
} from "@material-ui/core";
import { EnvelopeSimpleOpen, Trash } from "phosphor-react";
import { Search, X } from "react-feather";
import useStyles from "./NotificationModal.styles";

//APIs
import { fdelete, fdelete2, fget, fpatch } from "../../../API/callsAPI";
//components
import NotificationsTools from "../NotificationsTools/NotificationTools";
import NotificationInformation from "./NotificationInformation";

function NotificationModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]); //stores all the notification
  const [selectedTrackable, setSelectedTrackable] = useState(""); //stores selected trackable to filter according to it
  const [projects, setProjects] = useState([]); // stores all the project for dropdown
  const [selectedProject, setSelectedProject] = useState(""); //stores the selected project to filter
  const [allUsers, setAllUsers] = useState([]); //stores all the users
  const [searchQueryNotif, setSearchQueryNotif] = useState(""); //stores the search query
  const [period, setPeriod] = useState(""); //stores selected period from ->(today, yesterday weekly, monthly)
  const [subFilters, setSubFilters] = useState([]); //stores all the subfilter from backend
  const [selectedSubFilter, setSelectedSubFilter] = useState(""); // stores selected subfilter
  const [notificationIds, setNotificationIds] = useState([]); // stores notification ids for bulk update
  const [checkedNotifications, setCheckedNotifications] = useState([]); // stores all the checked notification
  const [subFilterData, setSubFilterData] = useState(); //stores selected subfilter according to trackable
  const [checked, setChecked] = useState(false);

  const [hasMore, setHasMore] = useState(false); //to check if next is present in each page result
  const [pageNumber, setPageNumber] = useState(1); // stores the page number
  const [pageSize] = useState(15);
  const [nextPageLoading, setNextPageLoading] = useState(false); // to show the loader if next page is loading
  const [advanceFilter, setAdvanceFilter] = useState({
    selectedStartDate: "",
    selectedEndDate: "",
    selectedStartTime: "",
    selectedEndTime: "",
  }); // will store all the advance filter

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);
  const controllerRef = useRef(new AbortController());

  //to get exact url according to different filters attached
  const createUrl = (url) => {
    if (selectedTrackable !== "") {
      url += `&content_type=${selectedTrackable}`;
    }
    if (selectedProject !== "") {
      url += `&project=${selectedProject}`;
    }
    if (period !== "") {
      url += `&period=${period}`;
    }
    if (
      advanceFilter.selectedStartDate !== "" &&
      advanceFilter.selectedEndDate !== ""
    ) {
      url += `&start_date=${advanceFilter.selectedStartDate}&end_date=${advanceFilter.selectedEndDate}`;
    }
    if (
      advanceFilter.selectedStartTime !== "" &&
      advanceFilter.selectedEndTime !== ""
    ) {
      url += `&start_time=${advanceFilter.selectedStartTime}&end_time=${advanceFilter.selectedEndTime}`;
    }
    if (selectedSubFilter !== "") {
      console.log(selectedSubFilter);
      url += `&type=${selectedSubFilter}`;
    }
    if (checked) {
      url += `&is_read=${!checked}`;
    }
    return url;
  };

  //to get notifications
  const fetchNotification = async () => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
    setNextPageLoading(true);
    setHasMore(false);
    let baseUrl = `notification/v2/?page=${pageNumber}&page_size=${pageSize}`;
    const url = createUrl(baseUrl);
    try {
      const notificationRes = await fget({
        url: url,
        signal: controllerRef.current.signal,
      });
      if (notificationRes.status === 200 || 201) {
        setNextPageLoading(false);
        //would add new page notification along with previous ones
        if (notificationRes.data.results) {
          if (pageNumber === 1) {
            setNotifications(notificationRes.data.results);
          } else {
            setNotifications((prevNotification) => [
              ...prevNotification,
              ...notificationRes.data.results,
            ]);
          }
        }
        if (notificationRes.data.next) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      setNextPageLoading(false);
      console.log(error);
    }
  };

  //fetch all projects
  const fetchProjects = async () => {
    try {
      const projectsRes = await fget({
        url: `project/`,
      });
      if (projectsRes.status === 200 || 201) {
        setProjects(projectsRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //to fetch all users
  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: "organization/users/",
      });
      if (usersRes.status === 200 || 201) {
        setAllUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //gets the subfilter from backend
  const getSubFilter = async () => {
    try {
      //TODO: Change to "notification-types/"
      const subfilterRes = await fget({
        url: `notification-types/`,
      });
      if (subfilterRes.status === 200 || 201) {
        const finalSubFilter = {};
        //to arrange all the sub filters accoriding to trackable etc.
        //TODO model->content_type,
        subfilterRes.data.results.forEach((subfilter) => {
          if (!finalSubFilter[subfilter.content_type])
            finalSubFilter[subfilter.content_type] = [];
          finalSubFilter[subfilter.content_type].push(subfilter);
        });
        setSubFilters(finalSubFilter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, [
    selectedTrackable,
    selectedProject,
    pageNumber,
    period,
    advanceFilter,
    selectedSubFilter,
    checked,
  ]);

  //to initially store taskcomment and trackable comment in subfilter and whenever a trackable is selected the
  // subfilters are selected and stored in subFilterData accordingly
  useEffect(() => {
    if (selectedTrackable === "" && Object.entries(subFilters).length > 0) {
      // console.log(subFilterData);
      // Commented this since we don't have subfilters for default "all" notification
      // setSubFilterData([
      //   ...subFilters["assetcomment"],
      // ]);
    } else {
      setSubFilterData(subFilters[selectedTrackable]);
    }
  }, [selectedTrackable, subFilters]);

  //to filter notifications according to trackable
  const filterNotification = (trackable) => {
    //to load from page 1 when button is clicked
    setNotifications([]);
    setPageNumber(1);
    setSelectedTrackable(trackable);
    setSelectedSubFilter("");
  };

  //to filter according to project
  const handleProject = (project) => {
    setNotifications([]);
    setPageNumber(1);
    setSelectedProject(project);
  };

  //to filter according to period
  const handlePeriod = (time) => {
    setNotifications([]);
    setPageNumber(1);
    setPeriod(time);
  };

  const clearAdvanceFilter = () => {
    setPageNumber(1);
    setAdvanceFilter({
      ...advanceFilter,
      selectedStartDate: "",
      selectedEndDate: "",
      selectedStartTime: "",
      selectedEndTime: "",
    });
  };

  //to handle advance filters
  const handleAdvanceFilter = (dateObj) => {
    setNotifications([]);
    setPageNumber(1);
    setAdvanceFilter({
      ...advanceFilter,
      selectedStartDate: dateObj.startDate,
      selectedEndDate: dateObj.endDate,
      selectedStartTime: dateObj.startTime,
      selectedEndTime: dateObj.endTime,
    });
  };

  //to handle subfilter
  const handleSubFilter = (id) => {
    setNotifications([]);
    setPageNumber(1);
    setSelectedSubFilter(id);
  };

  //to filter unread notification
  const handleReadSwitch = (event) => {
    setChecked(event.target.checked);
    setNotifications([]);
    setPageNumber(1);
  };

  const getTrackableId = async (id) => {
    try {
      const result = await fget({
        url: `trackables/task/${id}`,
      });
      if (result.status === 200 || 201) {
        if (result.data.linked_class == "Shot") {
          return {
            id: result.data.linked.id,
            parentId: result.data.linked.parent_sequence,
          };
        } else {
          return { id: result.data.linked.id };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function that return a url according to notification type
  const sendToUrl = async (notificationDetail) => {
    let url = `/${notificationDetail.projectId}/`;
    switch (notificationDetail.notification_object.content_type) {
      case "project":
        return (url += "updates");
      case "department":
        return (url += `departments/${notificationDetail.notification_object.content_object.id}/department`);
      case "task":
        return (url += "tasks");
      case "asset":
        return (url += `assets/${notificationDetail.notification_object.content_object.id}/assetversions`);
      case "assetversion":
        if (
          notificationDetail.notification_object.event_type ===
          "asset_comment_added"
        ) {
          searchParams.set(
            "commentId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.version_object.id}`
          );
          return (url += `assets/${notificationDetail.notification_object.content_object.version_object.asset}/assetversions`);
        } else {
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          return (url += `assets/${notificationDetail.notification_object.content_object.asset.id}/assetversions`);
        }
      case "sequence":
        return (url += `sequence/${notificationDetail.notification_object.content_object.id}/shots`);
      case "shot":
        return (url += `sequence/${notificationDetail.notification_object.content_object.parent_sequence}/shots/${notificationDetail.content_object.id}/shotversions`);
      case "shotversion":
        if (
          notificationDetail.notification_object.event_type ===
          "shot-comment_added"
        ) {
          searchParams.set(
            "commentId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.version_object.id}`
          );
          return (url += `sequence/${notificationDetail.notification_object.content_object.version_object.sequence_id}/shots/${notificationDetail.notification_object.content_object.version_object.shot}/shotversions`);
        } else {
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          return (url += `sequence/${notificationDetail.notification_object.content_object.shot.parent_sequence}/shots/${notificationDetail.notification_object.content_object.shot.id}/shotversions`);
        }
      case "review":
        return (url += `review`);
      case "reviewversion":
        if (
          notificationDetail.notification_object.event_type ===
            "review_comment_added" ||
          notificationDetail.notification_object.event_type ===
            "review_comment_mentioned"
        ) {
          searchParams.set(
            "commentId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.version.id}`
          );
          return (url += `review/${notificationDetail.notification_object.content_object.version.review}/version`);
        } else {
          searchParams.set(
            "versionId",
            `${notificationDetail.notification_object.content_object.id}`
          );
          return (url += `review/${notificationDetail.notification_object.content_object?.review}/version`);
        }
      case "taskcomment":
        const object = await getTrackableId(
          notificationDetail.notification_object.content_object.task
        );
        if (object.id && object.parentId) {
          url += `sequence/${object.parentId}/shots/${object.id}/task`;
        } else {
          url += `assets/${object.id}/task`;
        }
        return url;
      // case "trackablecomment":
      //   searchParams.set(
      //     "commentId",
      //     `${notificationDetail.notification_object.content_object.id}`
      //   );
      //   if (
      //     notificationDetail.notification_object.content_object.version_type ===
      //     "shotversion"
      //   ) {
      //     searchParams.set(
      //       "versionId",
      //       `${notificationDetail.notification_object.content_object.version_object.id}`
      //     );
      //     return (url += `sequence/${notificationDetail.notification_object.content_object.version_object.sequence_id}/shots/${notificationDetail.notification_object.content_object.version_object.shot}/shotversions`);
      //   } else if (
      //     notificationDetail.notification_object.content_object.version_type ===
      //     "assetversion"
      //   ) {
      //     searchParams.set(
      //       "versionId",
      //       `${notificationDetail.notification_object.content_object.version_object.id}`
      //     );

      //     return (url += `assets/${notificationDetail.notification_object.content_object.version_object.asset}/assetversions`);
      //   }
      default:
        return (url += "updates");
    }
  };

  //mark read notification and takes to the respective url
  const readNotifications = async (id, notificationDetail) => {
    let readNotification = "";
    if (!notificationDetail.is_read) {
      let newNotification = {
        url: `notification/v2/${id}/`,
        data: {
          is_read: true,
        },
      };

      try {
        const res = await fpatch(newNotification);
        if (res.status === 200) {
          readNotification = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
    const openUrl = await sendToUrl(notificationDetail);
    window.open(
      `${
        openUrl +
        "?" +
        btoa(unescape(encodeURIComponent(searchParams.toString())))
      }`
    );
    return readNotification;
  };

  //to mark one notification as read
  const readOneNotification = async (id, markRead) => {
    let newNotification = {
      url: `notification/v2/${id}/`,
      data: {
        is_read: markRead,
      },
    };
    try {
      const readNotification = await fpatch(newNotification);
      if (readNotification.status === 200) {
        return readNotification.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //mark bulk read
  const handleMarkRead = async () => {
    let notifications = {
      url: "notification-bulk-update/",
      data: {
        isRead: true,
        "notification-ids": notificationIds,
      },
    };
    try {
      await fpatch(notifications);
    } catch (error) {
      console.log(error);
    }
    setNotificationIds([]);
    setNotifications([]);
    setCheckedNotifications([]);
    setPageNumber(1);
    fetchNotification();
  };

  //to delete one notification
  const handleDeleteNotification = async (event, id) => {
    event.stopPropagation();
    try {
      const res = await fdelete({
        url: `notification/${id}/`,
      });
      if (res.status === 204) {
        const newNotifications = notifications.filter(
          (notification) => notification.id !== id
        );
        setNotifications(newNotifications);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //mark bulk delete
  const handleBulkDelete = async () => {
    let notificationdata = {
      url: "notification-bulk-update/",
      data: {
        "notification-ids": notificationIds,
      },
    };
    try {
      const res = await fdelete2(notificationdata);
      if (res.status === 200) {
        const newNotifications = notifications.filter(
          (notification) => !notificationIds.includes(notification.id)
        );
        setNotifications(newNotifications);
        setNotificationIds([]);
        setCheckedNotifications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //marks all notification as read
  const handleMarkAllRead = async () => {
    let notifications = {
      url: "notification-all-update/",
    };
    try {
      await fpatch(notifications);
    } catch (error) {
      console.log(error);
    }
    setNotifications([]);
    setPageNumber(1);
    fetchNotification();
  };

  useEffect(() => {
    setPageNumber(1);
    fetchUsers();
    getSubFilter();
    fetchProjects();
    if (props.sidebarType === "external" && !props.isPath) {
      filterNotification("review");
    }
  }, []);

  //pagination function
  const observer = useRef();
  const lastNotificationReference = useCallback(
    (node) => {
      if (nextPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((pagenumber) => pagenumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPageLoading, hasMore]
  );

  //handle search query
  const onChangeSearchQuery = (e) => {
    setSearchQueryNotif(e.target.value);
  };

  const toSettingsPage = () => {
    location.pathname = `/${selectedProject}/settings`;
    window.open(location.pathname);
    props.setNotificationModalOpen(false);
  };

  //runs upon clicking calcel button
  const handleCloseModal = () => {
    setNotificationIds([]);
    setCheckedNotifications([]);
  };

  const handleNotificationfromSidebar = (trackable, subfilter, projectId) => {
    setNotifications([]);
    setPageNumber(1);
    setSelectedProject(projectId);
    setSelectedTrackable(trackable);
    setSelectedSubFilter(subfilter);
  };

  useEffect(() => {
    if (props.subFilterNotification.subFilterName !== "" && subFilters.review) {
      if (props.subFilterNotification.subFilterName === "trackable_approved") {
        const data = subFilters.review.filter(
          (data) => data.event_type === "trackable_approved"
        );
        data &&
          handleNotificationfromSidebar(
            "review",
            data[0].entity_type_id,
            props.subFilterNotification.projectId
          );
      } else if (
        props.subFilterNotification.subFilterName === "review_comment_added"
      ) {
        const data = subFilters.reviewversion.filter(
          (data) => data.event_type === "review_comment_added"
        );
        data &&
          handleNotificationfromSidebar(
            "reviewversion",
            data[0].entity_type_id,
            props.subFilterNotification.projectId
          );
      }
    }
  }, [props.subFilterNotification, subFilters]);

  return (
    <div className={classes.notificationsDropdown}>
      <div className={classes.notificationHeader}>
        <h1 style={{ fontFamily: "Nunito Sans", fontSize: "36px" }}>
          Notifications
        </h1>
        <div className={classes.searchDiv}>
          <Search size="20px" style={{ marginLeft: "10px" }} />
          <Input
            className={classes.searchInput}
            disableUnderline="false"
            placeholder="Search anything"
            onChange={(e) => onChangeSearchQuery(e)}
          />
        </div>
        <div className={classes.headerCorner}>
          <div className={classes.headerItem}>
            <p>All</p> <Switch checked={checked} onChange={handleReadSwitch} />{" "}
            <p>Unread</p>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.headerDivider}
            />
          </div>
          <div className={classes.headerItem}>
            <Button className={classes.crossBtn} onClick={handleMarkAllRead}>
              Mark all as read
            </Button>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.headerDivider}
          />
          <Button
            onClick={props.handleNotificationModal}
            className={classes.crossBtn}
          >
            <X size="30px" style={{ cursor: "pointer" }} />
          </Button>
        </div>
      </div>
      <NotificationsTools
        filterNotification={filterNotification}
        selectedTrackable={selectedTrackable}
        projects={projects}
        handleProject={handleProject}
        selectedProject={selectedProject}
        toSettingsPage={toSettingsPage}
        period={period}
        handlePeriod={handlePeriod}
        handleAdvanceFilter={handleAdvanceFilter}
        clearAdvanceFilter={clearAdvanceFilter}
        handleSubFilter={handleSubFilter}
        selectedSubFilter={selectedSubFilter}
        subFilterData={subFilterData}
      />
      <Divider className={classes.divider} />
      <div className={classes.notificationsDropdownMenu}>
        {notifications && notifications.length !== 0
          ? notifications
              ?.filter((search) => {
                if (searchQueryNotif === undefined) {
                  return search;
                } else if (searchQueryNotif === "") {
                  return search;
                } else if (
                  search?.notification_object?.content_object?.name
                    ?.toLowerCase()
                    .includes(searchQueryNotif.toLowerCase())
                ) {
                  return search;
                }
                return null;
              })
              .map((notificationDetails, index) => {
                if (notifications.length === index + 1) {
                  return (
                    <div
                      key={notificationDetails.id}
                      ref={lastNotificationReference}
                    >
                      <NotificationInformation
                        notificationDetails={notificationDetails}
                        allUsers={allUsers}
                        setNotificationIds={setNotificationIds}
                        handleCheckNofications={setCheckedNotifications}
                        checkedNotifications={checkedNotifications}
                        readOneNotification={readOneNotification}
                        handleDeleteNotification={handleDeleteNotification}
                        notificationIds={notificationIds}
                        readNotifications={readNotifications}
                      />
                    </div>
                  );
                }
                return (
                  <div key={notificationDetails.id}>
                    <NotificationInformation
                      notificationDetails={notificationDetails}
                      allUsers={allUsers}
                      setNotificationIds={setNotificationIds}
                      handleCheckNofications={setCheckedNotifications}
                      checkedNotifications={checkedNotifications}
                      readOneNotification={readOneNotification}
                      handleDeleteNotification={handleDeleteNotification}
                      notificationIds={notificationIds}
                      readNotifications={readNotifications}
                    />
                  </div>
                );
              })
          : ""}
        {nextPageLoading ? (
          <CircularProgress
            type="Oval"
            color="secondary"
            height={50}
            width={50}
            style={{ margin: "auto" }}
          />
        ) : null}
      </div>
      <Divider className={classes.divider} />
      {notificationIds.length > 0 ? (
        <div className={classes.bulkEditContainer}>
          <Button onClick={handleBulkDelete}>
            <Trash size={18} color="#FF0000" style={{ marginRight: "5px" }} />
            Delete
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "80%", backgroundColor: "#606479" }}
          />
          <Button onClick={handleMarkRead}>
            <EnvelopeSimpleOpen
              size={18}
              color="#6C65D9"
              style={{ marginRight: "5px" }}
            />
            Mark as Read
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "80%", color: "#606479" }}
          />
          <Button onClick={handleCloseModal}>
            <X style={{ marginRight: "5px" }} />
            Cancel
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default NotificationModal;
