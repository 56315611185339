import React from "react";

import AssetVersionsOverview from "../../Components/AssetsVersions/AssetVersionsOverview/AssetversionsOverview.component.jsx";

export default function AssetVersions(props) {
  return (
    <div>
      <AssetVersionsOverview params={props.match.params} />
    </div>
  );
}
