import { Card, CardMedia, CardContent } from "@material-ui/core";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import useStyles from "./PlayerCard.style";

export default function PlayerCard({
  annotationDetails,
  scrollToComment,
  annotationValue,
  forExternalReviewer,
}) {
  const classes = useStyles();
  const getDisplayTime = (time) => {
    if (time.includes(":")) {
      return time;
    }
    let seconds = 0;
    let minutes = 0;
    if (time % 60) {
      minutes = parseInt(time / 60);
      seconds = parseInt(time % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  return (
    <div
      style={{
        position: "relative",
        width: "233px",
        height: "180px",
        left: `${annotationValue - 5.9}%`,
        cursor: "pointer",
      }}
      onClick={() => scrollToComment(annotationDetails.id)}
    >
      <Card className={classes.cardContainer}>
        {annotationDetails.attachment ? (
          <CardMedia
            component="img"
            alt="annotation screenshot"
            src={annotationDetails.attachment}
            className={classes.cardMedia}
            height="123"
          />
        ) : (
          <div className={classes.cardText}>
            {(annotationDetails?.description || annotationDetails?.text)?.split(
              "<p>"
            )[0] ? (
              <span className={classes.annotationText}>
                {getDisplayTime(
                  (
                    annotationDetails?.description || annotationDetails?.text
                  )?.split("<p>")[0]
                )}
              </span>
            ) : null}
            <span
              dangerouslySetInnerHTML={{
                __html: (
                  annotationDetails?.description || annotationDetails?.text
                )
                  ?.split("<p>")[1]
                  ?.split("</p>")[0],
              }}
            />
          </div>
        )}
        <CardContent className={classes.cardContent}>
          <div className={classes.cardContentUser}>
            <UserAvatar
              avatar={
                forExternalReviewer
                  ? annotationDetails?.user?.avatar
                  : annotationDetails?.author?.avatar
              }
              firstName={
                forExternalReviewer
                  ? annotationDetails?.user?.first_name
                  : annotationDetails?.author?.first_name[0]
              }
              lastName={
                forExternalReviewer
                  ? annotationDetails?.user?.last_name
                  : annotationDetails?.author?.last_name[0]
              }
            />
            <p className={classes.userDetail}>
              {forExternalReviewer
                ? annotationDetails?.user?.first_name
                : annotationDetails.author.first_name}{" "}
              {forExternalReviewer
                ? annotationDetails?.user?.last_name
                : annotationDetails.author.last_name}
            </p>
          </div>
          <div className={classes.annotationDate}>
            {new Intl.DateTimeFormat("en-US", {
              day: "2-digit",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(
              new Date(
                forExternalReviewer
                  ? annotationDetails.date_added
                  : annotationDetails.created_at
              )
            )}
          </div>
        </CardContent>
      </Card>
      <div className={classes.pointer}></div>
    </div>
  );
}
