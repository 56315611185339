import React, { useState } from "react";
import { fdelete2, fpost } from "../../../API/callsAPI";
//@MUI
import { Button, Divider, Modal, TextField } from "@material-ui/core";
import useStyles from "./ReviewPageManageReviewModal.styles";
//components
import HelperText from "../../Utilities/HelperText/HelperText";
import SelectUserModal from "../../Utilities/ManageUsers/SelectUsersModal/SelectUserModal.component";
import UserViewerForManageUsers from "../../Utilities/ManageUsers/UsersViewerForManageUsers/UserViewerForManageUsers.component";

function ReviewPageManageReviewModal({
  handleManageReviewModal,
  orgUsers,
  projectId,
  setSnackValue,
  projectReviewer,
  getReviewer,
}) {
  const classes = useStyles();
  const [isSelectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const [reviewerUserList, setReviewerUserList] = useState(projectReviewer);
  const [userMailId, setUserMailId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const handleUpdateReviewer = async () => {
    if (isSelectUserModalOpen) {
      const addedUsers = reviewerUserList.filter(
        (obj2) => !projectReviewer.some((obj1) => obj1.value === obj2.value)
      );
      const removedUsers = projectReviewer.filter(
        (obj1) => !reviewerUserList.some((obj2) => obj2.value === obj1.value)
      );
      if (addedUsers.length !== 0) {
        const users = addedUsers.map((user) => user.email);
        const formData = {
          project: projectId,
          email: users,
        };
        let reviwerUsers = {
          url: "add-reviewer/",
          data: formData,
        };
        try {
          const res = await fpost(reviwerUsers);
          if (res.status === 200 || 201) {
            setSnackValue({
              isOpen: true,
              message: res.data.detail,
              isError: false,
            });
            getReviewer();
            setSelectUserModalOpen(false);
          }
        } catch (error) {
          setSnackValue({
            isOpen: true,
            message: `${error}`,
            isError: true,
          });
          setSelectUserModalOpen(false);
        }
      }
      if (removedUsers.length !== 0) {
        const users = removedUsers.map((user) => user.value);
        removeUser(users);
      }
      if (addedUsers?.length === 0 && removedUsers?.length === 0) {
        setSelectUserModalOpen(false);
      }
    } else {
      setSelectUserModalOpen(true);
    }
  };

  const handleSelectUser = (users) => {
    setReviewerUserList(users);
  };

  const removeUser = async (usersToRemove) => {
    const formData = {
      users: usersToRemove,
    };
    let reviwerUsers = {
      url: `project/${projectId}/delete-reviewers/`,
      data: formData,
    };

    try {
      const res = await fdelete2(reviwerUsers);
      if (res.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: res.data.detail,
          isError: false,
        });
        getReviewer();
        setSelectUserModalOpen(false);
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setSelectUserModalOpen(false);
    }
  };

  const handleInviteNewReviewer = async () => {
    if (userMailId && firstName) {
      const formData = {
        project: projectId,
        email: userMailId,
        first_name: firstName,
        last_name: lastName,
      };
      let reviwerUsers = {
        url: "add-new-reviewer/",
        data: formData,
      };
      try {
        const res = await fpost(reviwerUsers);
        if (res.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: res.data.detail,
            isError: false,
          });
        }
      } catch (error) {
        var message = "";
        if (error.response) {
          message = error?.response?.data?.[0];
        } else {
          message = error.message;
        }
        setSnackValue({
          isOpen: true,
          message: `${message}`,
          isError: true,
        });
      }
      handleManageReviewModal();
    } else {
      if (!firstName) {
        setFirstNameError("First Name Is Required");
      }
      if (!userMailId) {
        setLastNameError("Email Is Required");
      }
    }
  };

  return (
    <div className={classes.container}>
      <p className={classes.containerHeader}>Manage Reviewers</p>
      <div>
        <span className={classes.containerText}>Reviewers</span>
        <Divider variant="inset" className={classes.divider} />
      </div>
      <div
        style={{
          marginTop: "17px",
          marginBottom: "43px",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <UserViewerForManageUsers
          currentUsers={reviewerUserList}
          handleSelectUserModalChange={handleUpdateReviewer}
          handleSelectUsers={handleSelectUser}
        />
        <div>
          <span className={classes.containerText}>Invite User</span>
          <Divider variant="inset" className={classes.divider} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div>
            <div className={classes.innerContainer1}>
              <span>First Name</span>
              <TextField
                required
                fullWidth
                placeholder="Enter first name"
                name="firstName"
                id="TextField"
                InputProps={{ disableUnderline: true }}
                className={classes.newReviewTextField}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {firstNameError ? <HelperText text={firstNameError} /> : null}
          </div>
          <div className={classes.innerContainer1}>
            <span>Last Name</span>
            <TextField
              fullWidth
              placeholder="Enter last name"
              name="lastName"
              id="TextField"
              InputProps={{ disableUnderline: true }}
              className={classes.newReviewTextField}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.innerContainer1}>
          <span>Email</span>
          <TextField
            required
            placeholder="User Mail Id"
            fullWidth
            type="email"
            name="userEmail"
            id="userEmail"
            InputProps={{ disableUnderline: true }}
            value={userMailId}
            onChange={(e) => setUserMailId(e.target.value)}
            className={classes.newReviewTextField}
          />
          {lastNameError ? <HelperText text={lastNameError} /> : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          className={classes.doneBtn}
          onClick={handleInviteNewReviewer}
          disabled={firstName?.length && userMailId?.length ? false : true}
        >
          Invite
        </Button>
        <Button className={classes.cancelBtn} onClick={handleManageReviewModal}>
          Cancel
        </Button>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isSelectUserModalOpen}
          onClose={() => setSelectUserModalOpen(false)}
        >
          <SelectUserModal
            closeModal={handleUpdateReviewer}
            assignedUser={reviewerUserList}
            usersList={orgUsers}
            handleSelectUsers={handleSelectUser}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewPageManageReviewModal;
