import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { CopySimple, Cube, FilmSlate, FilmStrip } from "phosphor-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fgetBFF, fpatch } from "../../../../API/callsAPI";
import NoResultMessage from "../../../Utilities/NoResultFound/NoResultMessage";
import UpdatesPageUpdateCards from "../../UpdatesPageUpdateCards/UpdatesPageUpdateCards.component";
import useStyles from "./ProjectUpdates.style";

const ProjectUpdates = ({ users, projectId }) => {
  const classes = useStyles();
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(5);
  const [selectedTrackable, setSelectedTrackable] = useState("all");
  useEffect(() => {
    if (pageNumber !== 0) fetchUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    fetchUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrackable]);

  const handleFilterByTrackable = (trackableId) => {
    setUpdates([]);
    setPageNumber(0);
    setHasMore(false);
    setSelectedTrackable(trackableId);
  };

  //Function to fetch pagination on scroll
  const observer = useRef();
  const lastBookReference = useCallback(
    (node) => {
      if (nextPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(pageNumber + 5);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPageLoading, hasMore]
  );

  //Function to mark a update as read
  const markAsRead = async (updateId) => {
    const updateFilter = updates.filter((item) => item.id !== updateId);
    try {
      await fpatch({
        url: `updates/${updateId}/`,
        data: { isRead: true },
      });
      setUpdates([...updateFilter]);
    } catch (error) {
      console.log(error);
    }
  };

  //Bff Function to fetchUpdates
  const fetchUpdates = async () => {
    setNextPageLoading(true);
    setHasMore(false);
    try {
      const res = await fgetBFF({
        url: `web/updates/?project=${projectId}&offset=${pageNumber}&page_size=${pageSize}&trackable=${
          selectedTrackable === "all" ? "" : selectedTrackable
        }`,
      });
      if (res.status === 200) {
        setNextPageLoading(false);
        if (res.data.updates.results) {
          setUpdates((prevUpdates) => [
            ...prevUpdates,
            ...res.data.updates.results,
          ]);
        }
        if (res.data.updates.next) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.log(error);
      setNextPageLoading(false);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.title}>Project Updates</div>
      <div className={classes.filterButtons}>
        <Button
          size="small"
          className={
            selectedTrackable === "all"
              ? classes.selectedButton
              : classes.button
          }
          onClick={() => handleFilterByTrackable("all")}
        >
          <CopySimple size={20} className={classes.icon} />
          <span>All</span>
        </Button>
        <Button
          size="small"
          className={
            selectedTrackable === "12" ? classes.selectedButton : classes.button
          }
          onClick={() => handleFilterByTrackable("12")}
        >
          <Cube size={20} className={classes.icon} />
          <span>Assets</span>
        </Button>
        <Button
          size="small"
          className={
            selectedTrackable === "18" ? classes.selectedButton : classes.button
          }
          onClick={() => handleFilterByTrackable("18")}
        >
          <Cube size={20} className={classes.icon} />
          <Tooltip title="Asset Versions" placement="top">
            <span>Asset V</span>
          </Tooltip>
        </Button>
        <Button
          size="small"
          className={
            selectedTrackable === "13" ? classes.selectedButton : classes.button
          }
          onClick={() => handleFilterByTrackable("13")}
        >
          <FilmStrip size={20} className={classes.icon} />
          <span>Sequence</span>
        </Button>
        <Button
          size="small"
          className={
            selectedTrackable === "14" ? classes.selectedButton : classes.button
          }
          onClick={() => handleFilterByTrackable("14")}
        >
          <FilmSlate size={20} className={classes.icon} />
          <span>Shots</span>
        </Button>
        <Button
          size="small"
          className={
            selectedTrackable === "17" ? classes.selectedButton : classes.button
          }
          onClick={() => handleFilterByTrackable("17")}
        >
          <Cube size={20} className={classes.icon} />
          <Tooltip title="Shot Versions" placement="top">
            <span>Shot V</span>
          </Tooltip>
        </Button>
      </div>
      <div style={{ height: "92%", overflowY: "auto", paddingRight: "10px" }}>
        {updates?.length ? (
          <>
            {updates.map((update, index) => {
              if (updates.length === index + 1) {
                return (
                  <div ref={lastBookReference} key={update.id}>
                    <UpdatesPageUpdateCards update={update} users={users} />
                  </div>
                );
              }
              return (
                <div key={update.id}>
                  <UpdatesPageUpdateCards
                    markAsRead={markAsRead}
                    users={users}
                    update={update}
                    projectId={projectId}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <div
            className={classes.flexCenter}
            style={{
              height: "80%",
              display: nextPageLoading ? "none" : "flex",
            }}
          >
            <NoResultMessage name="Updates" iconSize={50} fontSize="28px" />
          </div>
        )}
        {nextPageLoading ? (
          <div className={classes.flexCenter}>
            <CircularProgress
              type="Oval"
              color="secondary"
              height={50}
              width={50}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectUpdates;
