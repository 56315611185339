import { Button } from "@material-ui/core";
import { X } from "phosphor-react";
import React from "react";
import { Document, Page } from "react-pdf";
import useStyles from "./ProjectMediaListView.style";

const ProjectMediaViewModal = ({ url, handleClose, fileType }) => {
  const classes = useStyles();

  return (
    <div className={classes.modalRoot}>
      <div className={classes.modalContainer}>
        <div className={classes.buttonContainer}>
          <Button onClick={handleClose} className={classes.closeIcon}>
            <X />
          </Button>
        </div>
        {fileType === "image" ? (
          <img src={url} alt="" className={classes.imageView} />
        ) : fileType === "video" ? (
          <video src={url} controls className={classes.videoPlayer} />
        ) : fileType === "pdf" ? (
          <div className={classes.flexCenterModal}>
            {/* <PDFViewer style={{ height: "90%" }}> */}
            <Document file={url}>
              <Page pageNumber={1} />
            </Document>
            {/* </PDFViewer> */}
          </div>
        ) : (
          <div className={classes.canNotPreviewContainer}>
            <span className={classes.canNotPreviewText}>
              Can't Preview this file
            </span>
            <span className={classes.downloadText}>
              Download this file to preview
            </span>
          </div>
        )}
        <div className={classes.flexCenterModal}>
          <Button
            onClick={() => window.open(url)}
            className={classes.downloadButton}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectMediaViewModal;
