import React from "react";
import { CheckSquareOffset, Cube, FilmStrip, FilmSlate } from "phosphor-react";
import ShotVersion from "../Svg/ShotVersion.svg";
import AssestVersion from "../Svg/AssestVersion.svg";
import ReviewIcon from "../Svg/ReviewIcon";
import ReviewVersionIcon from "../Svg/ReviewVersionIcon";
export function getTrackableIconFromAssetName(name) {
  const icon = TrackableData.find((el) => el.trackable === name);
  return icon.icon;
}

// Trackable id can be different for other instances
export const TrackableData = [
  {
    id: 10,
    icon: <CheckSquareOffset size={18} />,
    trackable: "Tasks",
    type: "task",
  },
  {
    id: 12,
    icon: <Cube size={18} />,
    trackable: "Asset",
    type: "asset",
  },
  {
    id: 13,
    icon: <FilmStrip size={18} />,
    trackable: "Sequence",
    type: "sequence",
  },
  {
    id: 14,
    icon: <FilmSlate size={18} />,
    trackable: "Shot",
    type: "shot",
  },
  {
    id: 18,
    icon: <img src={AssestVersion} />,
    trackable: "Asset Version",
    type: "assetversion",
  },
  {
    id: 17,
    icon: <img src={ShotVersion} />,
    trackable: "Shot Version",
    type: "shotversion",
  },
  // {
  //   id: 14,
  //   icon: <ReviewIcon />,
  //   trackable: "Review",
  //   type: "review",
  // },
  // {
  //   id: 17,
  //   icon: <ReviewVersionIcon />,
  //   trackable: "Review Verison",
  //   type: "reviewversion",
  // },
];
