import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//@components
import { X } from "react-feather";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import Assetversionslist from "../AssetVersionsList/Assetversionslist.component";
import AssetVersionsTools from "../AssetVersionsTools/AssetVersionsTools.component.jsx";
import AssetVersionNewVersionModal from "../AssetsVersionsPageModal/AssetVersionNewVersionModal";
//@MUI
import {
  Box,
  Divider,
  Modal,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import useStyles from "./AssetversionsOverview.styles";

import { Alert } from "@material-ui/lab";
import {
  fget,
  fpatch,
  fpost,
  fpostBFF,
  uploadFile,
} from "../../../API/callsAPI";
import CanvasFrame from "../../CanvasFrame/CanvasFrame.component";
import AssetAndShotVersionTimeline from "../../Utilities/AssetAndShotVersionTimeline/AssetAndShotVersionTimeline.jsx";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import Loader from "../../Utilities/Loader/Loader";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";
import { StatusRearrange } from "../../Utilities/StatusRearrange/StatusRearrange";
import UpdateParentAssetOrShootThumbnailModal from "../../Utilities/UpdateParentAssetOrShootThumbnail/UpdateParentAssetOrShootThumbnailModal";
import VersionFilterModal from "../../Utilities/VersionFilterModal/VersionFilterModal";
import VersionViewerOverview from "../../Utilities/VersionViewer/VersionViewerOverview/VersionViewerOverview";

function AssetVersionsOverview(props) {
  const history = useHistory();
  const classes = useStyles();
  const tableRef = useRef();

  const [assetVersionList, setAssetVersionList] = useState();
  const [parentAsset, setParentAsset] = useState();
  const [assetVersionFilteredStatus, setAssetVersionFilteredStatus] =
    useState("");
  const [versionFromURL, setVersionFromURL] = useState(); //open versionDetailModal if id present in url
  const [trackableClass, setTrackableClass] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [allUsersName, setAllUsersName] = useState();
  const [statusList, setStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortByDefault, setSortByDefault] = useState("");
  const [isAssetTogglerOn, setIsAssetTogglerOn] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [commentsRefreshCounter, setCommentsRefreshCounter] = useState(0);
  const [isNewAssetVerisonModalOpen, setNewAssetVersionModalOpen] =
    useState(false);
  const [isAssetDetailModalOpen, setAssetDetailModalOpen] = useState(false);
  const [selectedAssetVersion, setSelectedAssetVersion] = useState();
  const [option, setoption] = React.useState(0);
  const [isUpdateParentAssetModalOpen, setUpdateParentAssetModalOpen] =
    useState(false);
  const [updatedFileForParent, setUpdatedFileForParent] = useState();
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [taskNameFromComment, setTaskNameFromComment] = useState("");
  const [canvasModal, setCanvasModal] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [playerProgress, setPlayerProgress] = useState("00:00");

  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  // to store tasks of the current trackable
  const [taskList, setTaskList] = useState([]);
  // state for opening fullscreen viewer of version viewer file
  const [isOpenFullscreenViewer, setIsOpenFullscreenViewer] = useState(false);
  const [fileForFullscreenViewer, setFileForFullscreenViewer] = useState(null);
  const [scrollValue, setScrollValue] = useState("");
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [commentId, setCommentId] = useState("");
  //statuslist of status linked with task
  const [taskStatusList, setTaskStatusList] = useState([]);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [assetVersionStats, setAssetVersionStats] = useState({
    total: 0,
    visibleCount: 0,
  });
  const [filterValues, setFilterValues] = useState({
    status: "",
    task: "",
  });
  const [filterButtonActive, setFilterButtonActive] = useState(false);
  const [commentsPageNo, setCommentsPageNo] = useState(1);
  const [isTimeLineModalOpen, setIsTimeLineModalOpen] = useState(false);

  function handleFilterModalState() {
    setIsFilterModalOpen(!isFilterModalOpen);
  }

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);
  let scrollTimer = null;
  useEffect(() => {
    fetchAssetVersion();
    fetchTrackableClass();
    setVersionFromURL(searchParams.get("versionId") || "");
    setCommentId(searchParams.get("commentId" || ""));
    setAssetVersionFilteredStatus(searchParams.get("status") || "");
    setSortByDefault(searchParams.get("sortBy") || "A-Z");

    var scrollStored = JSON.parse(localStorage.getItem("scroll"));
    if (scrollStored) {
      setScrollValue(scrollStored.assetVersion);
    } else {
      const scrollData = {
        asset: "0",
        assetVersion: "0",
        sequence: "0",
        shots: "0",
        shotVersion: "0",
      };
      localStorage.setItem("scroll", JSON.stringify(scrollData));
    }
  }, []);

  useEffect(() => {
    if (
      filterValues?.status ||
      filterValues?.task ||
      refreshCounter ||
      updateOnPageChange
    )
      fetchFilteredAssetVersion();
  }, [
    assetVersionFilteredStatus,
    currentPage,
    updateOnPageChange,
    refreshCounter,
  ]);

  useEffect(() => {
    assetVersionList &&
      versionFromURL &&
      openVersionModalFromURL(versionFromURL);
  }, [versionFromURL, assetVersionList]);

  useEffect(() => {
    assetVersionList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
  }, [assetVersionList]);

  const fetchAssetVersion = async () => {
    const assetversion = {
      url: "web/assetversion/list/",
      data: {
        project_id: props.params.projectId,
        asset_id: props.params.assetID,
      },
    };
    try {
      const assetVersionRes = await fpostBFF(assetversion);
      if (
        searchParams.get("status") === null ||
        searchParams.get("status") === ""
      ) {
        setAssetVersionList(assetVersionRes.data.assetversion_list);
        setStatusList(assetVersionRes.data.status_list.results);
        setIsLoaded(true);
      }
      setAssetVersionStats((prevData) => ({
        ...prevData,
        visibleCount: assetVersionRes.data.assetversion_list.count,
      }));
      setTaskList(assetVersionRes?.data?.tasks_list?.results);
      setParentAsset(assetVersionRes.data.asset_details);
      setAllUsers(assetVersionRes.data.project_users.results);
      setAllUsersName(
        assetVersionRes.data.project_users.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setIsLoaded(true);
    }
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateCommentsRefreshCounter = () => {
    setCommentsRefreshCounter(commentsRefreshCounter + 1);
  };

  const fetchFilteredAssetVersion = async () => {
    const url = `trackables/asset-version/?page=${currentPage}&asset=${props.params.assetID}&status=${filterValues.status}&sortBy=${sortByDefault}&task=${filterValues.task}`;
    try {
      const filteredAssetVersion = await fget({
        url: `trackables/asset-version/?page=${currentPage}&asset=${props.params.assetID}&status=${filterValues.status}&task=${filterValues.task}`,
      });
      setAssetVersionList(filteredAssetVersion.data);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log(error);
      setIsNewPageLoading(false);
    }
    history.push("?" + btoa(unescape(encodeURIComponent(url.split("?")[1]))));
  };

  const fetchTrackableClass = async () => {
    try {
      const trackableClasses = await fget({
        url: "trackables/trackable-classes",
      });
      setTrackableClass(trackableClasses.data);
      if (trackableClasses.data) {
        const taskClassId = trackableClasses.data.find(
          (eachvalue) => eachvalue.model === "task"
        );
        if (taskClassId.id) {
          await fetchTaskStatusList(taskClassId.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchTaskStatusList(taskClassId) {
    try {
      const taskStatusList = await fget({
        url: `trackables/status-list/${taskClassId}/?project=${props.params.projectId}`,
      });
      if (taskStatusList.data) {
        setTaskStatusList(StatusRearrange(taskStatusList.data.results));
      }
    } catch (error) {}
  }

  function handleBackButton() {
    const newRoute = history.location.pathname
      .split("/")
      .slice(0, -2)
      .join("/");
    history.replace(newRoute);
  }

  function handleNewAssetVersionModalState() {
    setNewAssetVersionModalOpen(!isNewAssetVerisonModalOpen);
  }

  const handleAssetToggler = () => {
    setIsAssetTogglerOn(!isAssetTogglerOn);
  };

  //to open version modal by fetching id from the url
  const openVersionModalFromURL = (verId) => {
    handleAssetDetailModalState(
      assetVersionList?.results?.filter((x) => x.id === parseInt(verId))[0]
    );
  };

  function handleAssetDetailModalState(shotVer) {
    searchParams.set("versionId", `${shotVer.id}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );

    setSelectedAssetVersion(shotVer);
    setAssetDetailModalOpen(true);
  }

  //Function to sort AssetVersion
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );
    switch (sortBy) {
      case "A-Z":
        assetVersionList?.results?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        assetVersionList?.results?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        assetVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        assetVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        assetVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return assetVersionList?.results;
    }
  }

  const handleUpdatedFileForParent = (file) => {
    setUpdatedFileForParent(file);
    setUpdateParentAssetModalOpen(true);
  };

  const updateParentAssetThumbnail = async () => {
    let formdata = new FormData();
    formdata.append("name", parentAsset ? parentAsset.name : "");
    updatedFileForParent &&
      formdata.append("file_name", updatedFileForParent.name);
    formdata.append("project", props.params.projectId);
    formdata.append(
      "status",
      parentAsset && parentAsset.status ? parentAsset.status.id : ""
    );
    formdata.append(
      "type",
      parentAsset && parentAsset.type ? parentAsset.type : ""
    );

    let updatedAsset = {
      url: `trackables/asset/${props.params.assetID}/`,
      data: formdata,
    };

    try {
      const updateParentRes = await fpatch(updatedAsset);
      if (updateParentRes.status === 200 || 201) {
        getSignedUrl(updateParentRes.data.id);
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setUpdateParentAssetModalOpen(false);
    }
  };

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/asset/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await uploadFile({
          url: uploadUrl.data?.signed_url,
          data: updatedFileForParent,
        }).then(() => {
          fget({
            url: `trackables/asset/${newId}/thumbnail`,
          });
          setSnackValue({
            isOpen: true,
            message: `SuccessFully updated thumbnail for parent asset ${parentAsset.name}`,
            isError: false,
          });
          setUpdateParentAssetModalOpen(false);
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setUpdateParentAssetModalOpen(false);
    }
  };

  const fetchFile = async () => {
    const fetchFileRes = await fget({
      url: `trackables/assetversion/${selectedAssetVersion?.id}/file`,
    });
    return fetchFileRes.data.signed_url;
  };

  const fetchComments = async () => {
    const commentsRes = await fget({
      url: `trackables/trackable-comment/?version_id=${
        selectedAssetVersion?.id
      }&version_type=${"assetversion"}&page=${commentsPageNo}`,
    });

    return {
      comments: commentsRes.data.results.reverse(),
      isNextAvailable: commentsRes?.data?.next ? true : false,
    };
  };

  const handleStatusChange = async (e) => {
    let formdata = new FormData();

    formdata.append("status", e.target.value);
    let editedVersion = {
      url: `trackables/asset-version/${selectedAssetVersion?.id}/`,
      data: formdata,
    };

    try {
      await fpatch(editedVersion);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully changed status`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  let assetVersionClass = trackableClass.find(
    (el) => el.model == "assetversion"
  );

  // fetch tasks of the current trackable
  const fetchTasks = async () => {
    let url = `trackables/task/?project=${props.params.projectId}&linked=${props.params.assetID}`;

    try {
      const taskListRes = await fget({
        url: url,
      });
      setTaskList(taskListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async ({
    description,
    mentions,
    repliedTo,
    attachmentImage,
  }) => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("trackable", props.params.assetID);
    formData.append("version_id", selectedAssetVersion?.id);
    formData.append("version_type", assetVersionClass.id || 0);
    formData.append("mentions[]", mentions);

    if (repliedTo) formData.append("replied_to", repliedTo.id);

    if (attachmentImage) {
      const blob = await fetch(attachmentImage).then((res) => res.blob());
      const file = new File([blob], "commentImage.jpg", { type: "image/jpg" });
      formData.append("attachment", file);
    }

    let newComment = {
      url: "trackables/trackable-comment/",
      data: formData,
    };
    // mentionsRef.current.reset();
    try {
      await fpost(newComment);
      return newComment;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type == "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type == "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  const handleTableScroll = (e) => {
    if (scrollTimer !== null) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      searchParams.set("scroll", `${e.target.scrollTop}`);
      history.push(
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      );
      let scrollStored = JSON.parse(localStorage.getItem("scroll"));
      scrollStored.assetVersion = e.target.scrollTop;
      localStorage.setItem("scroll", JSON.stringify(scrollStored));
    }, 700);
  };

  const getAssetVersionStats = async () => {
    try {
      const assetVersionRes = await fget({
        url: `trackables/trackable-count/assetversion/${props.params.assetID}/`,
      });
      setAssetVersionStats((prevData) => ({
        ...prevData,
        total: assetVersionRes.data.instances,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssetVersionStats();
  }, [refreshCounter]);

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <AssetVersionsTools
        handleFilterModalState={handleFilterModalState}
        status={statusList}
        handleBackButton={handleBackButton}
        openNewAssetVerisonModal={handleNewAssetVersionModalState}
        handleStatusChange={(value) => {
          setAssetVersionFilteredStatus(value);
          if (value === "") {
            history.replace(history.location.pathname);
            window.location.reload();
          }
        }}
        filterValues={filterValues}
        parentAsset={parentAsset}
        tabOption={option}
        handleAssetToggler={handleAssetToggler}
        projectId={props.params.projectId}
        assetID={props.params.assetID}
        onSubmit={handleSubmitSort}
        defaultValue={sortByDefault}
        filterButtonActive={filterButtonActive}
        assetVersionStats={assetVersionStats}
        handleOpenTimelineModal={() => setIsTimeLineModalOpen(true)}
      />
      <Divider style={{ height: "4px", marginTop: "4px" }} />

      <>
        {isLoaded ? (
          <>
            {assetVersionList && assetVersionList.count !== 0 ? (
              <>
                <TableContainer className={classes.tableContainer}>
                  <Table aria-label="simple table" className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          align="left"
                          className={classes.assetVersionTablecellTwo}
                        >
                          Asset Version Name
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.assetVersionTablecell}
                        >
                          Last Modified
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.assetVersionTablecell}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.assetVersionTablecell}
                        >
                          Created By
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.assetVersionTablecell}
                        >
                          Task Related
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.assetVersionTablecell}
                        >
                          Comments
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.assetVersionTablecellThree}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      className={classes.tableBody}
                      onScroll={handleTableScroll}
                      ref={tableRef}
                      onLoad={() => {
                        if (tableRef?.current && tableRef?.current?.scrollTop) {
                          tableRef.current.scrollTop = scrollValue;
                        }
                      }}
                      style={{
                        height:
                          assetVersionList?.count > 50
                            ? `calc(100vh - 292px)`
                            : `calc(100vh - 252px)`,
                      }}
                    >
                      {assetVersionList && assetVersionList.count !== 0
                        ? assetVersionList.results?.map((assetV) => {
                            return (
                              <Assetversionslist
                                key={assetV.id}
                                taskList={taskList}
                                fetchTasks={fetchTasks}
                                openAssetDetailModal={
                                  handleAssetDetailModalState
                                }
                                projectId={props.params.projectId}
                                data={assetV}
                                status={statusList}
                                assetID={props.params.assetID}
                                isAssetTogglerOn={isAssetTogglerOn}
                                updateRefreshCounter={updateRefreshCounter}
                                dragScreen={dragScreen}
                                handleDragScreen={handleDragScreen}
                                setDragFileEnterModal={setDragFileEnterModal}
                              />
                            );
                          })
                        : "No AssetVersion"}
                    </TableBody>
                  </Table>
                </TableContainer>
                {assetVersionList && assetVersionList.count > 50 ? (
                  <footer style={{ bottom: "0", height: "100%" }}>
                    <div>
                      <CustomPagination
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                        totalItem={assetVersionList?.count}
                        totalPage={Math.ceil(assetVersionList?.count / 50)}
                        setIsNewPageLoading={setIsNewPageLoading}
                        trackable="Asset versions"
                      />
                    </div>
                  </footer>
                ) : (
                  ""
                )}
              </>
            ) : (
              <NoData />
            )}
          </>
        ) : (
          <Loader />
        )}
      </>
      <div>
        <Modal className={classes.assetsPageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Asset versions" />
        </Modal>
      </div>
      <div>
        <Modal
          open={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          className={classes.assetsPageModals}
        >
          <VersionFilterModal
            updateList={() => fetchFilteredAssetVersion()}
            closeModal={handleFilterModalState}
            taskList={taskList}
            status={statusList}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setFilterButtonActive={setFilterButtonActive}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetsPageModals}
          open={isNewAssetVerisonModalOpen}
          onClose={() => setNewAssetVersionModalOpen(false)}
        >
          <AssetVersionNewVersionModal
            handleUpdatedFileForParent={handleUpdatedFileForParent}
            projectId={props.params.projectId}
            assetID={props.params.assetID}
            status={statusList}
            trackableClassList={trackableClass}
            handleNewAssetVersionModalState={handleNewAssetVersionModalState}
            updateRefreshCounter={updateRefreshCounter}
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            taskList={taskList}
            fetchTasks={fetchTasks}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetsPageModals}
          open={isUpdateParentAssetModalOpen}
          onClose={() => setUpdateParentAssetModalOpen(false)}
        >
          <UpdateParentAssetOrShootThumbnailModal
            versionType="Asset"
            parent={parentAsset}
            handleSubmit={() => {
              updateParentAssetThumbnail();
            }}
            closeUpdateParentModal={() => setUpdateParentAssetModalOpen(false)}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.assetVersionPageModals}
          open={isAssetDetailModalOpen}
          onClose={() => {
            setAssetDetailModalOpen(false);
            searchParams.set("versionId", "");
            setVersionFromURL("");
            history.push(
              "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
            );
          }}
        >
          <VersionViewerOverview
            commentsPageNo={commentsPageNo}
            setCommentsPageNo={setCommentsPageNo}
            versionList={assetVersionList}
            selectedVersion={selectedAssetVersion}
            setSelectedVersion={setSelectedAssetVersion}
            projectId={props.params.projectId}
            fetchFile={fetchFile}
            fetchComments={fetchComments}
            parentTrackable={parentAsset}
            closeModal={() => {
              setAssetDetailModalOpen(false);
              setVersionFromURL("");
              searchParams.set("versionId", "");
              history.push(
                "?" +
                  btoa(unescape(encodeURIComponent(searchParams.toString())))
              );
            }}
            handleStatusChange={handleStatusChange}
            status={statusList}
            updateRefreshCounter={updateRefreshCounter}
            setTaskNameFromComment={setTaskNameFromComment}
            canvasModal={canvasModal}
            setCanvasModal={setCanvasModal}
            setImageRef={setImageRef}
            setScreenshot={setScreenshot}
            screenshot={screenshot}
            allUsersName={allUsersName}
            handleSubmit={handleSubmit}
            version_type={assetVersionClass ? assetVersionClass.id : 0}
            assetId={props.params.assetID}
            setPlayerProgress={setPlayerProgress}
            setIsOpenFullscreenViewer={setIsOpenFullscreenViewer}
            setFileForFullscreenViewer={setFileForFullscreenViewer}
            commentsRefreshCounter={commentsRefreshCounter}
            taskStatusList={taskStatusList}
            commentId={commentId}
          />
        </Modal>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={canvasModal}
        >
          <CanvasFrame
            closeModal={() => setCanvasModal(false)}
            imageRef={imageRef}
            image={screenshot}
            assetID={props.params.assetID}
            toggle={() => setCanvasModal(!canvasModal)}
            fetchComments={fetchComments}
            version_id={selectedAssetVersion?.id}
            version_type={assetVersionClass ? assetVersionClass.id : 0}
            users={allUsersName}
            progress={playerProgress}
            updateCommentsRefreshCounter={updateCommentsRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          BackdropProps={{
            style: { backgroundColor: "black", opacity: "0.7" },
          }}
          open={isOpenFullscreenViewer}
          onClose={() => setIsOpenFullscreenViewer(false)}
        >
          <div
            style={{
              borderRadius: "30px",
              height: "90%",
              width: "90%",
              position: "relative",
              outline: "none",
            }}
          >
            {fileForFullscreenViewer ? (
              <img
                src={fileForFullscreenViewer}
                style={{
                  borderRadius: "30px",
                  height: "100%",
                  width: "100%",
                }}
              />
            ) : (
              "No File Found"
            )}
            <X
              className={classes.closeFullScreen}
              onClick={() => setIsOpenFullscreenViewer(false)}
            />
          </div>
        </Modal>
      </div>
      {isTimeLineModalOpen ? (
        <Slide direction="left" in={isTimeLineModalOpen}>
          <Box
            style={{
              display: isTimeLineModalOpen ? "block" : "none",
              top: "157px",
              height: `calc(100vh - 157px)`,
            }}
            className={classes.floatingDiv}
          >
            <AssetAndShotVersionTimeline
              type="asset"
              id={props.params.assetID}
              parent={parentAsset}
              handleClose={() => setIsTimeLineModalOpen(false)}
            />
          </Box>
        </Slide>
      ) : (
        ""
      )}
    </div>
  );
}

export default AssetVersionsOverview;
