import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "538px",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    padding: "42px 42px 24px 42px",
  },

  flexModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",
  },
  btnRed: {
    minWidth: "0px",
    padding: "10px",
    backgroundColor: "#FF0000",
    borderRadius: "10px",
  },
  btnOutline: {
    minWidth: "0px",
    padding: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  text: {
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
