import {
  Button,
  Modal,
  Radio,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DownloadSimple,
  File,
  FilePdf,
  Image,
  Link,
  PencilLine,
  Trash,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import FolderIcon from "../../Utilities/Svg/FolderIcon";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import useStyles from "./ProjectMediaListView.style";
import ProjectMediaViewModal from "./ProjectMediaViewModal";
import { Video } from "react-feather";

const imageExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "webp",
  "svg",
  "ico",
  "psd",
  "heif",
  "heic",
];

const videoExtensions = [
  "mp4",
  "avi",
  "mov",
  "wmv",
  "flv",
  "mkv",
  "webm",
  "m4v",
  "3gp",
  "mpeg",
  "mpg",
];

const ProjectMediaList = ({
  singleItem,
  type,
  handleSelectUnselectMedia,
  selectedItems,
  projectId,
  openEditModal,
  openDeleteModal,
  handleCopyFolderLink,
  handleOpenEditMediaModal,
  handleOpenDeleteMediaModal,
  getFileUrl,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isSelected, setIsSelected] = useState(false);
  const [showFileModalData, setShowFileModalData] = useState({
    isOpen: false,
    url: "",
  });
  const [fileType, setFileType] = useState("others");
  useEffect(() => {
    setIsSelected(selectedItems?.some((single) => single === singleItem.id));
  }, [singleItem, selectedItems]);

  const handleOpenMediaInNewTab = async () => {
    try {
      const res = await getFileUrl(singleItem.id, "get link");
      setShowFileModalData({
        isOpen: true,
        url: res,
      });
      // window.open(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fileExtension =
      singleItem?.file?.split(".")[singleItem?.file?.split(".")?.length - 1];
    if (imageExtensions.includes(fileExtension)) {
      setFileType("image");
    } else if (videoExtensions?.includes(fileExtension)) {
      setFileType("video");
    } else if (fileExtension === "pdf") {
      setFileType("pdf");
    } else {
      setFileType("others");
    }
  }, [singleItem]);

  return (
    <>
      <TableRow
        hover
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
        onClick={() => {
          type === "folder"
            ? history.push(`/${projectId}/project-media/${singleItem.id}`, {
                shallow: true,
              })
            : handleOpenMediaInNewTab();
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.listTextTwo}
          align="left"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              size="small"
              color={isSelected ? `secondary` : "default"}
              style={{ color: !isSelected && "gray" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectUnselectMedia(singleItem.id);
              }}
              checked={isSelected}
            />
            <div>
              {type === "folder" ? (
                <FolderIcon size="small" />
              ) : fileType === "image" ? (
                <Image size={22} />
              ) : fileType === "video" ? (
                <Video size={22} />
              ) : fileType === "pdf" ? (
                <FilePdf size={22} />
              ) : (
                <File size={22} />
              )}
            </div>
            <Tooltip
              title={type === "folder" ? singleItem.name : singleItem.file_name}
            >
              <Typography className={classes.fileNameText}>
                {type === "folder" ? singleItem.name : singleItem.file_name}
              </Typography>
            </Tooltip>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {new Intl.DateTimeFormat("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(singleItem.updated_at))}
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <p style={{ marginLeft: "10px" }}>
            {type === "folder"
              ? `${singleItem.files?.length + singleItem.folders?.length} Items`
              : fileType}
          </p>
        </TableCell>
        {type === "file" ? (
          <TableCell className={classes.listText} align="center">
            <p style={{ marginLeft: "10px" }}>2 KB</p>
          </TableCell>
        ) : (
          ""
        )}
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {singleItem.created_by && (
              <UsersViewer
                assignedUser={[singleItem.created_by]}
                selectedTask={""}
                editable="no"
              />
            )}
          </div>
        </TableCell>

        <TableCell align="right" className={classes.listTextTwo}>
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip placement="top" title="Copy Link">
              <Button
                style={{ minWidth: "25px", padding: "2px" }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  type === "folder"
                    ? handleCopyFolderLink(singleItem.id)
                    : getFileUrl(singleItem.id, "copy");
                }}
              >
                <Link size={20} color="#6C65D9" />
              </Button>
            </Tooltip>
            {type === "file" ? (
              <Tooltip placement="top" title="Download File">
                <Button
                  style={{ minWidth: "25px", padding: "2px" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    getFileUrl(singleItem.id, "download");
                  }}
                >
                  <DownloadSimple size={20} />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            <Tooltip
              placement="top"
              title={type === "folder" ? "Edit Folder" : "Edit File"}
            >
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  type === "folder"
                    ? openEditModal({
                        isOpen: true,
                        data: singleItem,
                        type: "edit",
                      })
                    : handleOpenEditMediaModal(singleItem);
                }}
                style={{ minWidth: "25px", padding: "2px" }}
              >
                <PencilLine size={20} />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={type === "folder" ? "Delete Folder" : "Delete File"}
            >
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  type === "folder"
                    ? openDeleteModal({
                        isOpen: true,
                        data: singleItem,
                        type: "Folder",
                      })
                    : handleOpenDeleteMediaModal(singleItem);
                }}
                style={{ minWidth: "25px", padding: "2px" }}
              >
                <Trash size={20} color="#FF0000" />
              </Button>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      <Modal
        open={showFileModalData?.isOpen}
        className={classes.flexCenterModal}
        onClose={() =>
          setShowFileModalData({
            isOpen: false,
            url: "",
          })
        }
      >
        <ProjectMediaViewModal
          url={showFileModalData?.url}
          fileType={fileType}
          handleClose={() =>
            setShowFileModalData({
              isOpen: false,
              url: "",
            })
          }
        />
      </Modal>
    </>
  );
};

export default ProjectMediaList;
