import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    borderRadius: "20px",
    position: "absolute",
    right: "10%",
    top: "19vh",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      top: "15%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "20%",
    },
  },
  popoverArrow: {
    zIndex: -1,
    borderRadius: "5px",
    position: "absolute",
    top: "-15px",
    right: "10px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "600",
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },
  typography: {
    margin: "10px 0px",
    color: theme.palette.text.primary,
  },
  cardContentDiv: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    fontWeight: "normal",

    margin: "5px 0px",
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "13px 27px",
    justifyContent: "space-between",
    "& :hover": {
      color: theme.palette.text.tertairy,
    },
  },
  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "115px",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    marginBottom: "15px",
    color: theme.palette.text.secondary,
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
  },
  datePickerDiv: {
    width: "93%",
    overflowY: "hidden",
    height: "4.3rem",
    marginTop: "-9px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "normal",
  },
  searchQuery: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.secondary.light,
    minHeight: "25px",
  },
  datePickerComponent: {
    // marginLeft: "4px",
    // marginBottom: "2.2px",
    padding: "9px",
    borderRadius: "5px",
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    minHeight: "28px",
  },
  trackableContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginTop: "8px",
  },
  inactiveFilter: {
    border: `1px solid ${theme.palette.text.tertiary}`,
    borderRadius: "10px",
    padding: "4px 6px",
    color: theme.palette.text.tertiary,
  },
  activeFilter: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "10px",
    padding: "4px 6px",
  },
  cardContentInput1: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },
  selectedTrackablesModal: {
    padding: "4px 10px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "460px",
    height: "510px",
  },
  selectedTrackablesContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "5px",
    maxHeight: "160px",
    overflowY: "auto",
  },
  singleTrack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid ${theme.palette.text.tertiary}`,
    padding: "3px 8px",
    borderRadius: "20px",
    width: "140px",
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textFieldContainer: {
    marginTop: "10px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
