import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "370px",
    maxWidth: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "30px",
    position: "relative",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  iconStyle: {
    width: "100%",
    marginTop: "22%",
    color: theme.palette.text.primary,
  },

  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  cardContentDiv1: {
    justifyContent: "space-around",
    marginTop: "5%",
  },
  cardContentDiv2: {
    width: "100%",
    height: "45px",
    backgroundColor: theme.palette.secondary.light,
    padding: "0px 8px",
    borderRadius: "10px",
  },
  cardContentDiv3: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px",
    borderRadius: "10px",
  },

  cardContentDiv4: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    height: "65px",
    width: "100%",
  },

  cardContentInput2: {
    width: "100% ",
    padding: "4px 5px",
  },

  cardContentInput1: {
    maxHeight: "100%",
    overflow: "hidden",
    padding: "0.2px 6px",
  },

  done: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "100%",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    },
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    padding: "0",
  },
  addNewStatusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
  },
  labelText: {
    display: "block",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },
  //filter modal
  filterRoot: {
    width: "500px",
    borderRadius: "20px",
    padding: "45px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  input: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "2%",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  text: {
    color: theme.palette.text.tertiary,
    marginBottom: "8px",
  },
  title2: {
    fontSize: "24px !important",
  },
  done2: {
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "40%",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

export default useStyles;
