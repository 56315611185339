import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { fpatch } from "../../../../API/callsAPI";
import useStyles from "./ProjectMediaEditMediaModal.style";

const ProjectMediaEditMediaModal = ({
  handleClose,
  selectedMedia,
  refreshData,
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (fileName === selectedMedia?.file_name) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [selectedMedia, fileName]);

  useEffect(() => {
    setFileName(selectedMedia?.file_name);
  }, [selectedMedia]);

  const handleEditMedia = async () => {
    const editMediaData = {
      url: `media/${selectedMedia?.id}/`,
      data: {
        file_name: fileName,
      },
    };
    try {
      const res = await fpatch(editMediaData);
      if (res.status === 200 || 201) {
        refreshData();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.flexDiv}>
            <Typography variant="h5" className={classes.text}>
              Edit Media
            </Typography>
            <Button onClick={handleClose}>
              <X size={22} />
            </Button>
          </div>
          <Box mt={2} mb={2} className={classes.flexColumn}>
            <Typography className={classes.label}>Media Name</Typography>
            <TextField
              className={classes.textField}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              fullWidth
              placeholder="enter media name"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box className={classes.fileTypeAndSize}>
            <Typography style={{ fontSize: "18px", color: "white" }}>
              File Type
            </Typography>
            <Typography>File Size</Typography>
          </Box>
          <Box className={classes.flexCenter} mt={2}>
            <Button
              className={classes.containedButton}
              onClick={() => handleEditMedia()}
              disabled={!isChanged}
            >
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProjectMediaEditMediaModal;
