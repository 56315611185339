import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DragSource, DropTarget } from "react-dnd";
import _ from "lodash";
import useStyles from "./CustomizedTaskCard.style";
import { PencilSimpleLine } from "phosphor-react";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer";
import NoThumbnail from "../../../../Utilities/NoThumbnail.component";
import { TrackablesAndIcons } from "../../../../Settings/Utility/TrackablesDetails";
import { Tooltip, Popover } from "@material-ui/core";
import TaskVersionHover from "../../../../Utilities/TaskVersionHover/TaskVersionHover";
import PriorityViewer from "../../../../Utilities/PriorityViewer/PriorityViewer.component";

export function Card(props) {
  const {
    singleTask,
    connectDragSource,
    connectDropTarget,
    isDragging,
    isSpacer,
    handleViewTaskModal,
    redirectPath,
  } = props;
  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setOpen(true);
    }
  }, [anchorEl]);

  const openVersionInNewTab = () => {
    const version =
      singleTask.shotversions[0]?.id || singleTask.assetversions[0]?.id;
    if (singleTask.linked_class === "Asset") {
      location.pathname = `/${singleTask.linked.project}/assets/${singleTask.linked.id}/assetversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));

      window.open(`${location.pathname}?${encode}`);
    } else {
      location.pathname = `/${singleTask.linked.project}/sequence/${singleTask.linked.parent_sequence}/shots/${singleTask.linked.id}/shotversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));
      window.open(`${location.pathname}?${encode}`);
    }
  };

  return _.flowRight(
    connectDragSource,
    connectDropTarget
  )(
    <div
      className={isDragging ? classes.dragging : isSpacer ? classes.spacer : ""}
    >
      <div
        className={classes.card}
        style={{ opacity: isDragging ? "0" : "100%" }}
      >
        <div className="card-body">
          <div
            className={classes.displayFlex}
            style={{ margin: "5px 0px 10px 0px" }}
          >
            <Tooltip title={props?.singleTask?.name} placement="top">
              <span className={classes.taskTitle}>
                {singleTask?.name?.length > 20
                  ? `${singleTask?.name.slice(0, 18) + "..."}`
                  : singleTask?.name}
              </span>
            </Tooltip>
            <button
              className={classes.button}
              onClick={() => handleViewTaskModal(singleTask)}
            >
              <PencilSimpleLine size={20} />
            </button>
          </div>
          <div className={classes.displayFlex}>
            <span>
              {singleTask?.start_time
                ? new Date(singleTask?.start_time).toString().substr(4, 6)
                : ""}{" "}
              -{" "}
              {singleTask?.end_time
                ? new Date(singleTask?.end_time).toString().substr(4, 6)
                : ""}
            </span>
            <PriorityViewer priority={singleTask?.priority} />
          </div>
          <div className={classes.displayFlex} style={{ margin: "10px 0px" }}>
            <UsersViewer selectedTask={singleTask} editable="no" />
            <div className={classes.inLineDiv} style={{ marginRight: "10px" }}>
              <div>
                {
                  TrackablesAndIcons?.find(
                    (track) => track?.trackable === singleTask?.linked_class
                  )?.icon
                }
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {singleTask.linked?.thumbnail ? (
                    <img
                      style={{
                        borderRadius: "7px",
                        objectFit: "cover",
                        margin: "4px 6px 4px 10px",
                      }}
                      src={singleTask.linked?.thumbnail}
                      height="28px"
                      width="28px"
                      alt="No Img"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  ) : (
                    <NoThumbnail
                      style={{
                        margin: "4px 10px ",
                        height: "28px",
                        width: "28px",
                      }}
                    />
                  )}

                  <span>
                    <span
                      onClick={() => {
                        window.open(redirectPath);
                      }}
                      className={classes.onHover}
                    >
                      {singleTask.linked.name || singleTask.linked.code}
                    </span>
                    {singleTask.shotversions[0]?.name ||
                    singleTask.assetversions[0]?.name ? (
                      <>
                        <span>{" - "}</span>
                        <span
                          className={classes.onHover}
                          onClick={openVersionInNewTab}
                        >
                          {singleTask.shotversions[0]?.name ||
                            singleTask.assetversions[0]?.name}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {singleTask.shotversions.length > 1 ? (
                      <span
                        onMouseEnter={handleClick}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        +{singleTask.shotversions.length - 1}
                      </span>
                    ) : singleTask.assetversions.length > 1 ? (
                      <span
                        onMouseEnter={handleClick}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        +{singleTask.assetversions.length - 1}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <TaskVersionHover
                      versionList={
                        singleTask.shotversions.length
                          ? singleTask.shotversions
                          : singleTask.assetversions
                      }
                      parent={singleTask.linked}
                      kanban={true}
                      handleClose={handleClose}
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const DraggableCard = _.flowRight([
  DropTarget(
    "Card",
    {
      drop(props, monitor) {
        const { singleGroupId, singleGroupIndex, id, moveCard } = props;
        const draggingItem = monitor.getItem();
        if (draggingItem.id !== id) {
          moveCard(draggingItem.id, singleGroupId, singleGroupIndex);
        }
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  ),
  DragSource(
    "Card",
    {
      beginDrag({ id }) {
        return { id: id };
      },

      isDragging({ id }, monitor) {
        return id === monitor.getItem().id;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  ),
])(Card);
