import { Button, Input, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Search, X } from "react-feather";
import { fget } from "../../../API/callsAPI";
import NoThumbnail from "../../Utilities/no-thumbnail.png";
import useStyles from "./NewTask.styles.js";

const ThumbNail = (props) => {
  return (
    <img
      src={props.data ? props.data : NoThumbnail}
      width="32px"
      height="32px"
      alt="thumbnail"
      style={
        props.data
          ? { borderRadius: "10px", marginRight: "5px" }
          : { marginRight: "5px" }
      }
    />
  );
};

// trackable list component
const TrackableList = (props) => {
  const classes = useStyles();

  // function to get the assets-trackables
  const assetList = () => {
    const filteredAssetList = props.assetList?.filter((search) => {
      if (props.searchQuery === undefined) {
        return search;
      } else if (props.searchQuery === "") {
        return search;
      } else if (
        search.name?.toLowerCase().includes(props.searchQuery.toLowerCase())
      ) {
        return search;
      } else return null;
    });
    return (
      <>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.singleButton}
            onClick={() => props.handleSelectAllTrackable(filteredAssetList)}
          >
            Select all asset
          </Button>
          <Button
            className={classes.singleButton}
            onClick={() => props.handleUnSelectAllTrackable()}
          >
            Unselect all
          </Button>
        </div>
        {filteredAssetList?.map((asset) => (
          <>
            <SingleTrackable
              type="asset"
              selectedTrackable={props.selectedTrackable}
              trackable={asset}
              handleSelectTrackable={props.handleSelectTrackable}
            />

            {props.assetList[props.assetList.length - 1] === asset ? (
              props.sequenceList.length ? (
                props.findAsset ? (
                  props.findSequence ? (
                    <hr className={classes.horizontalLine} />
                  ) : (
                    ""
                  )
                ) : (
                  <hr className={classes.horizontalLine} />
                )
              ) : (
                ""
              )
            ) : (
              <hr className={classes.horizontalLine} />
            )}
          </>
        ))}
      </>
    );
  };

  // function to get sequences-trackables
  const sequenceList = () => {
    return (
      <>
        {props.sequenceList
          ?.filter((search) => {
            if (props.searchQuery === undefined) {
              return search;
            } else if (props.searchQuery === "") {
              return search;
            } else if (
              search.code
                ?.toLowerCase()
                .includes(props.searchQuery.toLowerCase())
            ) {
              return search;
            } else return null;
          })
          .map((sequence) => (
            <>
              <SingleTrackable
                type="sequence"
                setSelectedSequence={props?.setSelectedSequence}
                selectedTrackable={props.selectedTrackable}
                trackable={sequence}
                handleSelectTrackable={props.handleSelectTrackable}
              />
              {props.sequenceList[props.sequenceList.length - 1] ===
              sequence ? (
                ""
              ) : (
                <hr className={classes.horizontalLine} />
              )}
            </>
          ))}
      </>
    );
  };

  // function to list both assets and sequences
  const allTrackableList = () => {
    return (
      <>
        {assetList()}
        {sequenceList()}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            className={
              props.findAsset
                ? classes.findTrackableSelect
                : classes.findTrackableDeselect
            }
            onClick={() => {
              props.setFindAsset(!props.findAsset);
            }}
          >
            find assets:
          </Button>
          <Button
            className={
              props.findSequence
                ? classes.findTrackableSelect
                : classes.findTrackableDeselect
            }
            onClick={() => props.setFindSequence(!props.findSequence)}
          >
            find sequences:
          </Button>
        </div>
        <div className={classes.trackableCount}>
          Trackables : {props.selectedTrackable?.length}
        </div>
      </div>

      <div style={{ height: "330px", overflow: "auto" }}>
        {!props.findAsset && !props.findSequence && allTrackableList()}
        {props.findAsset && assetList()}
        {props.findSequence && sequenceList()}
      </div>
    </>
  );
};

// sequence shots list  component
const ShotList = (props) => {
  const [shots, setShots] = useState();
  const classes = useStyles();

  useEffect(() => {
    getShot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShot = () => {
    fget({
      url: `trackables/shot/?project=${props.selectedSequence.project}&parent_sequence=${props.selectedSequence.id}`,
    })
      .then((res) => {
        setShots(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.singleButton}
          onClick={() => props.handleSelectAllTrackable(shots)}
        >
          Select all shot
        </Button>
        <Button
          className={classes.singleButton}
          onClick={() => props.handleUnSelectAllTrackable()}
        >
          Unselect all
        </Button>
      </div>
      <div style={{ height: "340px", overflow: "auto", marginTop: "1rem" }}>
        {shots?.map((shot) => (
          <>
            <SingleTrackable
              type="shot"
              selectedTrackable={props.selectedTrackable}
              trackable={shot}
              handleSelectTrackable={props.handleSelectTrackable}
            />
            {shots[shots.length - 1] === shot ? (
              ""
            ) : (
              <hr className={classes.horizontalLine} />
            )}
          </>
        ))}
      </div>
    </>
  );
};

// main component
const SelectTrackablesModal = (props) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={classes.searchDiv}>
          <Search size="18px" style={{ marginLeft: "10px" }} />
          <Input
            className={classes.searchInput}
            disableUnderline="false"
            placeholder={props.selectedSequence ? "" : "Search Trackables"}
            disabled={!!props.selectedSequence}
            onChange={(e) => props.onChangeSearchQuery(e)}
          />
          <div
            className={
              props.selectedSequence
                ? classes.sequenceSelectorVisible
                : classes.sequenceSelectorHidden
            }
          >
            <Button onClick={() => props.setSelectedSequence()}>
              <X size="16px" />
              <span style={{ marginBottom: "1px", marginLeft: "3px" }}>
                sequence:
              </span>
            </Button>
            <span style={{ paddingRight: "5px" }}>
              {props.selectedSequence?.code}
            </span>
          </div>
        </div>
        <Button
          style={{
            display: "grid",
            alignContent: "center",
            marginLeft: "1.5rem",
            marginTop: "0.8rem",
            minWidth: "10px",
          }}
          onClick={() => props.setAnchorEl(false)}
        >
          <X size="22px" />
        </Button>
      </div>
      {props.selectedSequence ? (
        <ShotList
          handleSelectTrackable={props.handleSelectTrackable}
          selectedSequence={props.selectedSequence}
          setSelectedSequence={props.setSelectedSequence}
          selectedTrackable={props.selectedTrackable}
          handleSelectAllTrackable={props.handleSelectAllTrackable}
          handleUnSelectAllTrackable={props.handleUnSelectAllTrackable}
        />
      ) : (
        <TrackableList
          handleSelectTrackable={props.handleSelectTrackable}
          assetList={props.assetList}
          sequenceList={props.sequenceList}
          findAsset={props.findAsset}
          setFindAsset={props.setFindAsset}
          findSequence={props.findSequence}
          setFindSequence={props.setFindSequence}
          setSelectedSequence={props.setSelectedSequence}
          searchQuery={props.searchQuery}
          selectedTrackable={props.selectedTrackable}
          handleSelectAllTrackable={props.handleSelectAllTrackable}
          handleUnSelectAllTrackable={props.handleUnSelectAllTrackable}
        />
      )}
      <div className={classes.doneButtonContainer}>
        <Button
          className={classes.doneButton}
          onClick={() => props.setAnchorEl(false)}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default SelectTrackablesModal;

const SingleTrackable = ({
  type,
  trackable,
  handleSelectTrackable,
  selectedTrackable,
  setSelectedSequence,
}) => {
  const classes = useStyles();
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    if (selectedTrackable) {
      const exist = selectedTrackable.some(
        (eachUser) => eachUser.id === trackable.id
      );
      setIsExist(exist);
    }
  }, [trackable, selectedTrackable]);

  return (
    <MenuItem
      className={isExist ? classes.selectedTrackable : ""}
      value={trackable.id}
      onClick={() => {
        if (type === "sequence") {
          setSelectedSequence(trackable);
        } else {
          handleSelectTrackable({
            id: trackable.id,
            name: trackable.name || trackable.code,
          });
        }
      }}
    >
      <ThumbNail data={trackable.thumbnail} />
      {trackable.code || trackable.name}
    </MenuItem>
  );
};
