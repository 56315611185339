import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { ChevronDown } from "react-feather";
import { X } from "phosphor-react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import useStyles from "./ProjectMediaFilterModal.styles";

function ProjectMediaFilterModal({
  handleClose,
  projectUsers,
  selectedUser,
  startDate,
  endDate,
  handleEndDate,
  handleStartDate,
  handleSlectedUser,
  handleFilter,
  clearFilter,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Filter</Typography>
        <Button onClick={handleClose}>
          <X size={30} />
        </Button>
      </div>
      <div
        style={{
          dispaly: "flex",
          flexDirection: "column",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <div className={classes.datePicker}>
          <div className={classes.datePickerDiv}>
            <Typography className={classes.datePickerText}>from</Typography>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                invalidDateMessage={"inValid"}
                variant="inline"
                format="yyyy/MM/DD"
                margin="normal"
                name="startDate"
                value={startDate}
                onChange={(date) => handleStartDate(new Date(date))}
                maxDate={new Date()}
                InputProps={{
                  disableUnderline: true,
                }}
                id="Startdate-picker"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: {
                    padding: "0px",
                  },
                }}
                className={classes.dateInput}
                size="small"
                fullWidth={true}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.datePickerDiv}>
            <div className={classes.flexBox}>
              <Typography className={classes.datePickerText}>to</Typography>
              <Typography className={classes.datePickerText}>
                (Last Modified)
              </Typography>
            </div>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                invalidDateMessage={"inValid"}
                variant="inline"
                format="yyyy/MM/DD"
                margin="normal"
                value={endDate}
                onChange={(date) => handleEndDate(new Date(date))}
                id="Enddate-picker"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: {
                    padding: "0px",
                  },
                }}
                maxDate={new Date()}
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.dateInput}
                size="small"
                fullWidth={true}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div>
          <Typography className={classes.datePickerText}>Created By</Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.dropdownDiv}>
              <text className={classes.selectText}>
                {selectedUser ? (
                  <>
                    {selectedUser.first_name} {selectedUser.last_name}
                  </>
                ) : (
                  "Choose User"
                )}
              </text>
              <ChevronDown className={classes.iconStyle} />
            </div>
            <Select
              style={{ height: "0%", opacity: "0" }}
              className={classes.formLabelText}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Choose user"
              defaultValue="none"
              MenuProps={{
                classes: {
                  paper: classes.durationDropDown,
                },
              }}
              value={selectedUser}
              onChange={(event) => handleSlectedUser(event.target.value)}
            >
              {projectUsers.map((user) => {
                return (
                  <MenuItem
                    key={user.id}
                    value={user}
                    className={classes.menuItem}
                  >
                    {user.first_name} {user.last_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.flexBox}>
        <Button className={classes.filterBtn} onClick={handleFilter}>
          Add Filter
        </Button>
        <Button startIcon={<X />} onClick={clearFilter}>
          Clear filters
        </Button>
      </div>
    </Box>
  );
}

export default ProjectMediaFilterModal;
