import React, { useEffect, useState } from "react";

//MUI components
import { Button, Menu, MenuItem, TableCell } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

//css
import { ArchiveBox, DotsThree } from "phosphor-react";
import { Edit3, Trash2, Users } from "react-feather";
import { useHistory } from "react-router-dom";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import {
  AdminIcon,
  ReviewerIcon,
  UserIcon,
} from "../../Utilities/Svg/UserRoleIcons";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import useStyles from "./ProjectListView.styles";

export default function ListView({
  project,
  isTasksTogglerOn,
  handleManageUserModal,
  handleEditProjectModal,
  handleDeleteProjectModal,
  allUserList,
  handleArchiveProject,
  handleUnArchiveProject,
  loggedInUser,
  handleProjectSelect,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [assignedUsers, setAssignedUsers] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isArchived, setIsArchived] = React.useState(false);
  const [showUserRoleInfo, setShowUserRoleInfo] = React.useState({
    isOpen: false,
    role: "",
  });

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (project?.archived_by?.length > 0) {
      const exist = project?.archived_by?.some(
        (eachId) => eachId === loggedInUser?.id
      );
      if (exist) {
        setIsArchived(true);
      }
    }
  }, [project]);

  useEffect(() => {
    //this loop is to get whole data of project users
    const tempUserList = [];
    for (let i = 0; i < allUserList.length; i++) {
      for (let j = 0; j < project.users.length; j++) {
        if (project.users[j] === allUserList[i].id) {
          tempUserList.push(allUserList[i]);
        }
      }
    }
    setAssignedUsers(tempUserList);
    // eslint-disable-next-line no-unused-vars
  }, []);

  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleProjectSelect(project);

          // if (event.ctrlKey) {
          //   window.open(`/${project.id}/updates`);
          //   sessionStorage.setItem("scrollpos", window.scrollY);
          // } else {
          //   history.push(`/${project.id}/updates`);
          //   sessionStorage.setItem("scrollpos", window.scrollY);
          // }
        }}
      >
        <TableCell className={classes.text} component="th" scope="row">
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {project?.thumbnail ? (
              <ImageViewer
                sliderState={isTasksTogglerOn ? 25 : 0}
                url={project?.thumbnail}
              />
            ) : (
              <NoThumbnail sliderState={isTasksTogglerOn ? 25 : 0} />
            )}
            <span>
              {project.name.slice(0, 40)}{" "}
              {project?.name?.length > 40 ? "..." : ""}
            </span>
          </div>
        </TableCell>
        <TableCell className={classes.text} align="left">
          <div
            className={classes.userRole}
            onMouseEnter={() =>
              setShowUserRoleInfo({
                isOpen: true,
                role: project?.user_role,
              })
            }
            onMouseLeave={() =>
              setShowUserRoleInfo({
                isOpen: false,
                role: "",
              })
            }
          >
            <span className={classes.userIcon}>
              {project?.user_role === "admin" ? (
                <AdminIcon />
              ) : project?.user_role === "user" ? (
                <UserIcon />
              ) : (
                <ReviewerIcon />
              )}
            </span>
            <span>{project?.user_role}</span>
          </div>
          {showUserRoleInfo?.isOpen ? (
            <div className={classes.userRoleText}>
              {showUserRoleInfo?.role === "admin"
                ? `Admin : as you are an admin in this project, you have
                permissions to all of the content in this project.`
                : showUserRoleInfo?.role === "user"
                ? `User : as you are a user in this project, some of the content might be hidden, please contact project admin for content permissions.`
                : `Reviewer : as you are a reviewer in this project, some of the content might be hidden, please contact project admin for content permissions.`}
            </div>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell className={classes.text} align="center">
          <DateAndTimeViewer dateAndTime={project.created_at} />
        </TableCell>
        <TableCell className={classes.text} align="left">
          {project.description
            ? project.description.slice(0, 50)
            : "Project has no description !"}{" "}
          {project?.description?.length > 50 ? "..." : ""}
        </TableCell>
        <TableCell className={classes.text} align="center">
          <DateAndTimeViewer dateAndTime={project.updated_at} />
        </TableCell>
        <TableCell align="left" className={classes.viewProject}>
          <UsersViewer
            assignedUser={assignedUsers}
            selectedTask={{ assigned_users: assignedUsers }}
            editable="no"
          />
        </TableCell>
        <TableCell align="right" className={classes.viewProject}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleOpen(e);
            }}
          >
            <DotsThree size={25} />
          </Button>
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ className: `${classes.menuPop}` }}
      >
        <MenuItem
          className={classes.modalButton}
          onClick={() => {
            handleClose();
            handleManageUserModal({
              modalOpen: "true",
              projectId: project.id,
            });
          }}
        >
          <Users className={classes.trashIcon} />
          Manage Users
        </MenuItem>
        <MenuItem
          className={classes.modalButton}
          onClick={() => {
            handleClose();
            handleEditProjectModal(project);
          }}
        >
          <Edit3 className={classes.trashIcon} />
          Edit Project
        </MenuItem>
        <MenuItem
          className={classes.modalButton}
          onClick={() => {
            handleClose();
            if (isArchived) {
              handleUnArchiveProject(project);
            } else {
              handleArchiveProject(project);
            }
          }}
        >
          {isArchived ? (
            <>
              <ArchiveBox className={classes.archiveIcon} />
              <>Unarchive Project</>
            </>
          ) : (
            <>
              <ArchiveBox className={classes.trashIcon} />
              <>Archive Project</>
            </>
          )}
        </MenuItem>
        <MenuItem
          id={`del${project.id}`}
          className={classes.modalButton}
          onClick={() => {
            handleClose();
            handleDeleteProjectModal(project);
          }}
        >
          <Trash2 className={classes.trashIcon1} />
          Delete Project
        </MenuItem>
      </Menu>
    </>
  );
}
