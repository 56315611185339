import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { LoaderSvg } from "../Utilities/Loader/CustomLoader";
import axios from "axios";
import { createCookie } from "../authorization";

const AuthPageOverview = () => {
  const SSO_URL_FRONTEND = process.env.REACT_APP_BASE_URL_SSOF;
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL_MCF.split("/")[2];
  const DOMAIN_TYPE = process.env.REACT_APP_DOMAIN_TYPE;
  const APP_URL = window.location.href
    .replace("https://", "")
    .replace("http://", ""); //Follow the same URL Pattern
  const redirectParams = `?type=${DOMAIN_TYPE}&baseUrl=${APP_BASE_URL}&redirectUrl=${APP_URL}`;
  const [isError, setIsError] = useState(false);
  const [counter, setCounter] = useState(5);
  const sso_base_url = process.env.REACT_APP_BASE_URL_SSO;
  const params = new URLSearchParams(window.location.search);
  const token = params.get("idToken");
  const redirect = params.get("redirectUrl");

  const handleGenerate = async () => {
    try {
      const res = await axios.post(`${sso_base_url}api/token/user-token/ `, {
        auth_token: token,
      });
      if (res.status === 200) {
        createCookie("refresh", res.data.refresh);
        createCookie("access", res.data.access);
        window.location.href = redirect.includes("localhost:")
          ? `http://${redirect.replace("http://", "")}`
          : redirect;
      }
    } catch (error) {
      setIsError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    if (token && redirect) {
      handleGenerate();
    }
  }, [token, redirect]);

  useEffect(() => {
    if (isError) {
      if (counter > 0) {
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      } else {
        window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
      }
    }
  }, [isError, counter]);

  return (
    <div>
      <Box
        style={{
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "absolute",
          zIndex: "1300",
          left: "0",
          top: "0",
          backgroundColor: "#1B1D28",
        }}
      >
        <div
          style={{
            width: "550px",
            height: "300px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "320px",
              height: "60px",
              left: "180px",
              top: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div style={{ color: "black" }}>
              <span style={{ color: "#6C65D9" }}>Just a sec! </span>{" "}
              <span>
                {isError
                  ? `Something went wrong. You will be redirected to SSO with in ${counter} seconds.`
                  : `We’re running some authorization checks...`}
              </span>
            </div>
          </div>
          <div>
            <LoaderSvg />
          </div>

          <div
            style={{
              height: "400px",
              display: "flex",
              width: "100%",
              position: "absolute",
              left: "310px",
              top: "160px",
            }}
          >
            <ScaleLoader
              color="#6C65D9"
              height={65}
              style={{
                display: "flex",
                verticalAlign: "middle",
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AuthPageOverview;
