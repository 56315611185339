import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: theme.palette.text.primary,
  },
  menuPop: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default useStyles;
