const SelectAllIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33398 15.5002C5.82982 15.5002 5.39822 15.3206 5.03919 14.9616C4.68017 14.6026 4.50065 14.171 4.50065 13.6668V2.66683C4.50065 2.16266 4.68017 1.73107 5.03919 1.37204C5.39822 1.01301 5.82982 0.833496 6.33398 0.833496H17.334C17.8382 0.833496 18.2697 1.01301 18.6288 1.37204C18.9878 1.73107 19.1673 2.16266 19.1673 2.66683V13.6668C19.1673 14.171 18.9878 14.6026 18.6288 14.9616C18.2697 15.3206 17.8382 15.5002 17.334 15.5002H6.33398ZM6.33398 13.6668H17.334V2.66683H6.33398V13.6668ZM2.66732 19.1668C2.16315 19.1668 1.73155 18.9873 1.37253 18.6283C1.0135 18.2693 0.833984 17.8377 0.833984 17.3335V4.50016H2.66732V17.3335H15.5007V19.1668H2.66732Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export default SelectAllIcon;
