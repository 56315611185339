import {
  Button,
  Grid,
  Input,
  Menu,
  MenuItem,
  Popover,
  TextField,
} from "@material-ui/core";
import { Plus, UploadSimple, MagnifyingGlass } from "phosphor-react";
import React, { useState } from "react";
import {
  FilterButton,
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component";
import useStyles from "./ProjectMediaTools.style";
import ProjectMediaBreadCrumbs from "./ProjectMediaBreadCrumbs";
import ProjectMediaFilterModal from "../ProjectMediaModals/ProjectMediaFilterModal/ProjectMediaFilterModal";

const ProjectMediaTools = ({
  handleToggleView,
  handleOpenUploadFolderModal,
  handleOpenAddMediaModal,
  breadCrumbs,
  breadCrumbsId,
  projectId,
  handleSubmitSort,
  sortByDefault,
  projectUsers,
  selectedUser,
  startDate,
  endDate,
  handleEndDate,
  handleStartDate,
  handleSlectedUser,
  handleFilter,
  clearFilter,
  setSearchQuery,
  searchQuery,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModal, setFilterModal] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterModal = (event) => {
    setFilterModal(event.currentTarget);
  };

  const handleCloseFilterModal = () => {
    setFilterModal(null);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        console.log("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Grid container alignContent="center" className={classes.root}>
      <Grid item md={6}>
        <ProjectMediaBreadCrumbs
          breadCrumbs={breadCrumbs}
          breadCrumbsId={breadCrumbsId}
          projectId={projectId}
          handleCopy={handleCopy}
        />
      </Grid>
      <Grid item md={6} alignContent="center">
        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="flex-end"
          className={classes.projectMediaPageToolsGrid}
        >
          <Grid item md={4}>
            <div className={classes.searchIconContainer}>
              <MagnifyingGlass size={20} />
              <TextField
                placeholder="Search Folders or Files"
                InputProps={{ disableUnderline: true }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </Grid>
          <Grid item>
            <ToggleButton
              handleToggler={() => handleToggleView()}
              showGridIcon={true}
            />
          </Grid>
          <Grid item>
            <div>
              <SortButton
                onSubmit={handleSubmitSort}
                defaultvalue={sortByDefault}
              />
            </div>
          </Grid>
          <Grid item>
            <FilterButton
              btnActive={false}
              openFilterModal={handleOpenFilterModal}
            />
            <Popover
              open={filterModal !== null}
              anchorEl={filterModal}
              onClose={handleCloseFilterModal}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{ className: `${classes.paperProp}` }}
            >
              <ProjectMediaFilterModal
                handleClose={handleCloseFilterModal}
                projectUsers={projectUsers}
                selectedUser={selectedUser}
                startDate={startDate}
                endDate={endDate}
                handleEndDate={handleEndDate}
                handleStartDate={handleStartDate}
                handleSlectedUser={handleSlectedUser}
                handleFilter={handleFilter}
                clearFilter={clearFilter}
              />
            </Popover>
          </Grid>
          <Grid item>
            <Button
              size="medium"
              startIcon={<Plus className={classes.plusIcon} />}
              className={classes.addNewFolderButton}
              disableElevation
              onClick={handleClick}
            >
              New
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              style={{ marginTop: "50px" }}
              MenuListProps={{ className: `${classes.menuPop}` }}
            >
              <MenuItem
                className={classes.menuItems}
                onClick={() => {
                  handleOpenUploadFolderModal();
                  handleClose();
                }}
              >
                <Plus className={classes.plusIcon} />
                Add New Folder
              </MenuItem>
              <MenuItem
                className={classes.menuItems}
                onClick={() => {
                  handleOpenAddMediaModal();
                  handleClose();
                }}
              >
                <UploadSimple className={classes.plusIcon} />
                Upload Media
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectMediaTools;
