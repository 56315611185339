import useStyles from "./iconSize.styles";

export const EpisodesIconSmall = () => {
  const classes = useStyles();
  return (
    <svg
      className={classes.iconsize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 10L28 14.1686C28 14.8866 27.418 15.4686 26.7 15.4686C25.982 15.4686 25.4 14.8866 25.4 14.1686L25.4 10H4.6V22.5H13.75C14.4404 22.5 15 23.0596 15 23.75C15 24.4404 14.4404 25 13.75 25H4.6C3.91044 25 3.24912 24.7366 2.76152 24.2678C2.27393 23.7989 2 23.163 2 22.5V7.5C2 6.1125 3.157 5 4.6 5H12.4L15 7.5H25.4C26.0896 7.5 26.7509 7.76339 27.2385 8.23223C27.7261 8.70107 28 9.33696 28 10Z"
        fill="#F9F9F9"
      />
      <path
        d="M30.0043 20.739C30.0043 21.2195 29.8884 21.67 29.6568 22.0904C29.4337 22.5109 29.0776 22.8498 28.5885 23.1072C28.108 23.3646 27.4988 23.4933 26.7609 23.4933H25.255V26.455C25.255 26.7243 25.0367 26.9427 24.7673 26.9427H23.9408C23.6715 26.9427 23.4531 26.7243 23.4531 26.455V18.4467C23.4531 18.1773 23.6715 17.959 23.9408 17.959H26.7609C27.4559 17.959 28.0479 18.0791 28.537 18.3194C29.0261 18.5596 29.3908 18.89 29.631 19.3104C29.8798 19.7308 30.0043 20.207 30.0043 20.739ZM26.6836 22.039C27.1813 22.039 27.5503 21.9274 27.7905 21.7043C28.0308 21.4727 28.1509 21.1509 28.1509 20.739C28.1509 19.8638 27.6618 19.4262 26.6836 19.4262H25.255V22.039H26.6836Z"
        fill="#F9F9F9"
      />
      <path
        d="M18.4034 19.4126V21.6649H20.9404C21.2097 21.6649 21.428 21.8833 21.428 22.1526V22.6059C21.428 22.8752 21.2097 23.0936 20.9404 23.0936H18.4034V25.4746H21.3265C21.5958 25.4746 21.8142 25.693 21.8142 25.9623V26.4542C21.8142 26.7235 21.5958 26.9419 21.3265 26.9419H17.0892C16.8199 26.9419 16.6016 26.7235 16.6016 26.4542V18.433C16.6016 18.1637 16.8199 17.9453 17.0892 17.9453H21.3265C21.5958 17.9453 21.8142 18.1637 21.8142 18.433V18.9249C21.8142 19.1942 21.5958 19.4126 21.3265 19.4126H18.4034Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export const EpisodesIconBig = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 40C6.9 40 5.95867 39.6087 5.176 38.826C4.392 38.042 4 37.1 4 36V12C4 10.9 4.392 9.95867 5.176 9.176C5.95867 8.392 6.9 8 8 8H18.35C18.8833 8 19.392 8.1 19.876 8.3C20.3587 8.5 20.7833 8.78333 21.15 9.15L24 12H40C41.1 12 42.042 12.392 42.826 13.176C43.6087 13.9587 44 14.9 44 16H12C10.9 16 9.95867 16.3913 9.176 17.174C8.392 17.958 8 18.9 8 20V36L11.95 22.85C12.2167 21.9833 12.7087 21.2913 13.426 20.774C14.142 20.258 14.9333 20 15.8 20H41.6C42.9667 20 44.042 20.5413 44.826 21.624C45.6087 22.708 45.8167 23.8833 45.45 25.15L41.85 37.15C41.5833 38.0167 41.092 38.7087 40.376 39.226C39.6587 39.742 38.8667 40 38 40H8Z"
        fill="#606479"
      />
      <path
        d="M38.7489 28.866C38.7489 29.359 38.63 29.8212 38.3923 30.2526C38.1634 30.684 37.7981 31.0317 37.2963 31.2958C36.8033 31.5599 36.1782 31.692 35.4211 31.692H33.8761V34.7306C33.8761 35.007 33.6521 35.231 33.3757 35.231H32.5277C32.2514 35.231 32.0273 35.007 32.0273 34.7306V26.514C32.0273 26.2377 32.2514 26.0137 32.5277 26.0137H35.4211C36.1342 26.0137 36.7416 26.1369 37.2434 26.3834C37.7452 26.6299 38.1194 26.9689 38.3659 27.4002C38.6212 27.8316 38.7489 28.3202 38.7489 28.866ZM35.3419 30.1998C35.8525 30.1998 36.231 30.0853 36.4775 29.8564C36.724 29.6187 36.8473 29.2886 36.8473 28.866C36.8473 27.9681 36.3455 27.5191 35.3419 27.5191H33.8761V30.1998H35.3419Z"
        fill="#1B1D28"
      />
      <path
        d="M26.8487 27.5054V29.8163H29.4516C29.728 29.8163 29.952 30.0404 29.952 30.3167V30.7818C29.952 31.0581 29.728 31.2821 29.4516 31.2821H26.8487V33.7251H29.8478C30.1241 33.7251 30.3482 33.9491 30.3482 34.2255V34.7302C30.3482 35.0065 30.1241 35.2305 29.8478 35.2305H25.5004C25.224 35.2305 25 35.0065 25 34.7302V26.5004C25 26.224 25.224 26 25.5004 26H29.8478C30.1241 26 30.3482 26.224 30.3482 26.5004V27.005C30.3482 27.2814 30.1241 27.5054 29.8478 27.5054H26.8487Z"
        fill="#1B1D28"
      />
    </svg>
  );
};
