import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  commentDiv: {
    marginTop: "20px",
    position: "absolute",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    border: "thin white",
  },
  commentInput: {
    width: "90%",
    // minHeight: "50px",
    height: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    padding: "10px",
  },
  sendButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    margin: "4px",
    minWidth: "44px",
    height: "44px",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },
  sendIcon: {
    margin: "2px",
    color: theme.palette.text.primary,
    height: "85%",
    borderRadius: "2px",
    background: theme.palette.secondary.main,
  },
  slider: {
    width: "100%",
    color: theme.palette.secondary.main,
  },

  backgroundC: {
    background: theme.palette.primary.main,
  },
  modalHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "poppins",
  },
  canvasDiv: {
    backgroundColor: theme.palette.primary.main,
    height: "75%",
    width: "90%",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "2%",
    padding: "1%",
    marginBottom: "140px",
  },
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
  },
  closeIcon: {
    cursor: "pointer",
    color: theme.palette.text.primary,
  },
  image: {
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "65%",
    height: "100%",
  },
  anotationTools: {
    height: "100%",
    width: "35%",
    display: "flex",
    flexDirection: "column",
    gap: "3.5%",
  },
  annotationContainer: {
    padding: "12px 25px 25px 25px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.text.tertiary}`,
    height: "87%",
    width: "100%",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "24px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "600",
  },
  buttonContainer: {
    display: "flex",
    gap: "8%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
  },
  btncontainer1: {
    display: "flex",
    gap: "5%",
    width: "35%",
    alignItems: "center",
    justifyContent: "end",
  },
  btncontainer2: {
    width: "35%",
    display: "flex",
    gap: "5%",
    alignItems: "center",
    justifyContent: "start",
  },
  commentBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "3%",
    width: "100%",
  },
  btnInactive: {
    padding: "10px",
    borderRadius: "10px",
  },
  btnActive: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
  },
  iconColor: {
    color: theme.palette.text.tertiary,
  },
  selectText: {
    color: theme.palette.text.tertiary,
    fontSize: "14px",
    marginLeft: "10px",
  },
  activeIconColor: {
    color: theme.palette.secondary.main,
  },
  colorStyle: {
    cursor: "pointer",
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    transition: "transform 0.3s, border 0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
    [theme.breakpoints.down("lg")]: {
      height: "18px",
      width: "18px",
    },
  },
}));

export default useStyles;
