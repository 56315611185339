import React from "react";

function ReferenceFolderIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.12088 14.0769C3.73853 14.0769 3.42857 14.3869 3.42857 14.7692C3.42857 15.1516 3.73853 15.4615 4.12088 15.4615H6.70429L2.52078 19.5163C2.23583 19.7925 2.23583 20.2497 2.52078 20.5258C2.79341 20.7901 3.22659 20.7901 3.49922 20.5258L7.71429 16.4405V18.9011C7.71429 19.2956 8.03408 19.6154 8.42857 19.6154C8.82306 19.6154 9.14286 19.2956 9.14286 18.9011V14.8408C9.14286 14.4189 8.80084 14.0769 8.37893 14.0769H4.12088ZM20.5714 5.76923H12.8102C12.2906 5.76923 11.7914 5.567 11.4182 5.20537L9.56143 3.40569C9.29358 3.14601 8.93028 3.00008 8.55143 3H3.42857C3.04969 3 2.68633 3.14588 2.41842 3.40554C2.15051 3.66521 2 4.01739 2 4.38462V11.978C2 12.3725 2.3198 12.6923 2.71429 12.6923C3.10877 12.6923 3.42857 12.3725 3.42857 11.978V4.38462H7.74125C8.26086 4.38462 8.76008 4.58684 9.13319 4.94848L10.99 6.74815C11.2585 7.00835 11.6176 7.15385 11.9915 7.15385H20.5714V18.8654C20.5714 19.2796 20.2356 19.6154 19.8214 19.6154H12.6923C12.31 19.6154 12 19.9253 12 20.3077C12 20.69 12.31 21 12.6923 21H21C21.5523 21 22 20.5523 22 20V7.15385C22 6.78662 21.8495 6.43444 21.5816 6.17478C21.3137 5.91511 20.9503 5.76923 20.5714 5.76923Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default ReferenceFolderIcon;
