import { createContext } from "react";
import { ProjectsPageDataHook } from "../Hooks/ProjectsPageDataHook";
import { UpdatesPageDataHook } from "../Hooks/UpdatesPageDataHook";
import { NotificationHook } from "../Hooks/NotificationHook";

export const MovieColabContext = createContext(null);

const MovieColabDataProvider = ({ children }) => {
  const projectsPageData = ProjectsPageDataHook();
  const updatesPageData = UpdatesPageDataHook();
  const notificationData = NotificationHook();
  const allData = {
    ...projectsPageData,
    ...updatesPageData,
    ...notificationData,
  };
  return (
    <MovieColabContext.Provider value={allData}>
      {children}
    </MovieColabContext.Provider>
  );
};

export default MovieColabDataProvider;
