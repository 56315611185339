import React from "react";
import SequenceOverview from "../../Components/Sequences/SequenceOverview/SequenceOverview.component.jsx";

export default function SequencePage(props) {
  return (
    <div>
      <SequenceOverview params={props.match.params} />
    </div>
  );
}
