import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  projectMediaPageToolsGrid: {
    display: "flex",
    alignItems: "center",
  },
  searchIconContainer: {
    borderRadius: "10px",
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#252A38",
  },

  addNewFolderButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "15px",
    color: theme.palette.text.secondary,
  },

  plusIcon: {
    width: "28px",
    height: "22px",
  },

  menuPop: {
    margin: "0px",
    backgroundColor: theme.palette.primary.main,
    border: `2px solid rgba(96, 100, 121, 1)`,
    borderRadius: "10px",
    padding: "4px",
  },

  menuItems: {
    fontSize: "16px",
    borderRadius: "10px",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    gap: "12px",
  },
  paperProp: {
    borderRadius: "20px",
  },

  //breadcrumbs
  separatorIcon: {
    color: theme.palette.text.primary,
  },
  breadCrumb: {
    color: theme.palette.text.tertiary,
    fontSize: "20px !important",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  endBreadCrumb: {
    color: theme.palette.text.primary,
    fontSize: "20px !important",
    fontFamily: "poppins",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  flexDiv: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    width: "100%",
    whiteSpace: "nowrap",
  },
  gap: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
}));

export default useStyles;
