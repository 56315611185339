import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "370px",
    maxWidth: "450px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },
  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "30px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "700",
    textAlign: "center",
  },
  goBackButton: {
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "40%",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

export default useStyles;
