import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    //flexWrap: "wrap",
  },

  select: {
    padding: "0.2px 6px",
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,

    fontStyle: "normal",
  },
  divContainerLeft: {
    display: "flex",
    flexWrap: "wrap",
    width: "40%",
    alignItems: "baseline",
  },

  divContainerRight: {
    width: "60%",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
  },
  assetName: {
    padding: "4px",
    fontSize: "30px",
    margin: "0px",
  },
  assetType: {
    padding: "4px",
    fontSize: "15px",
    margin: "0px",
  },

  timelineButton: {
    minWidth: "25px",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
  },

  dropDown: {
    backgroundColor: theme.palette.secondary.light,
    "& ul.MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  assetVersionPageButton: {
    textTransform: "none",
    height: "45px",
    width: "100%",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "8px",

      fontSize: "1.2rem",
    },
  },

  buttonDeleteAsset: {
    padding: "0px 7px 0px 0px",
    height: "45px",
    borderRadius: "6px",
    color: "#D75858",
    border: "1.5px solid #D75858",
    margin: "0px 1%",
  },
  buttonEditAssetandNewVersion: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },
  buttonIcon: {
    width: "28px",
    height: "19px",
  },
  buttonArrowBack: {
    minWidth: "20px",
  },
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
  },
  dropdownDiv: {
    display: "flex",
    alignItems: "center",
    width: "250px",
    justifyContent: "space-between",
  },
  selectText: {
    marginLeft: "10px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    fontSize: "12px",
    color: theme.palette.text.primary,
  },
  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  filterButton: {
    textTransform: "none",
    height: "40px",
    width: "126px",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    borderRadius: "10px",
  },
  dotBlur: {
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    filter: "blur(4px)",
  },
  dot: {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    backgroundColor: "#6C65D9",
    marginLeft: "50%",
    marginTop: "-5px",
  },
  sortActive: {
    color: theme.palette.secondary.main,
  },
  sortInactive: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
