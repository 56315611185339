import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { X, Cookie } from "phosphor-react";
import React, { useState } from "react";
import useStyles from "./MyTaskPageSortByModal.styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const TaskBulkUpdateModal = ({
  statusList,
  handleClose,
  taskCount,
  handleBulkTasksUpdate,
  isLoading,
  handleBulkTasksDelete,
}) => {
  const classes = useStyles();
  const [priority, setPriority] = useState(0);
  const [priorityHover, setPriorityHover] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  return (
    <Box className={classes.root}>
      <div className={classes.flexItem}>
        <Typography className={classes.textThree}>Bulk Update Tasks</Typography>
        <Button onClick={handleClose}>
          <X size={24} />
        </Button>
      </div>
      <Typography className={classes.text1}>
        Selected Tasks: {taskCount}
      </Typography>
      <div>
        <Typography className={classes.textTwo}>Status</Typography>
        <Select
          disableUnderline
          className={classes.input}
          value={status}
          onClick={(e) => e.stopPropagation()}
          onChange={(event) => setStatus(event.target.value)}
          fullWidth
        >
          <MenuItem className={classes.menuItem} value={"none"}>
            <Tooltip title={"No status selected"} placement="top">
              <div>
                <span style={{ overflow: "hidden" }}>None</span>
              </div>
            </Tooltip>
          </MenuItem>
          {statusList &&
            statusList.map((status) => (
              <MenuItem
                className={classes.menuItem}
                value={status.id}
                key={status.id}
              >
                <Tooltip title={status?.name} placement="top">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className={classes.statusColor}
                      style={{
                        backgroundColor: `rgb(${status?.color})`,
                      }}
                    ></span>
                    <span>{status.name}</span>
                  </div>
                </Tooltip>
              </MenuItem>
            ))}
        </Select>
      </div>
      <div>
        <Typography className={classes.textTwo}>Start Date</Typography>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            invalidDateMessage={"inValid"}
            variant="inline"
            format="yyyy/MM/DD"
            margin="normal"
            value={selectedStartDate}
            onChange={(date) => setSelectedStartDate(new Date(date))}
            id="Enddate-picker"
            KeyboardButtonProps={{
              "aria-label": "change date",
              style: {
                padding: "0px",
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.dateInput}
            size="small"
            fullWidth={true}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <Typography className={classes.textTwo}>End Date</Typography>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            invalidDateMessage={"inValid"}
            variant="inline"
            format="yyyy/MM/DD"
            margin="normal"
            value={selectedEndDate}
            onChange={(date) => setSelectedEndDate(new Date(date))}
            id="Enddate-picker"
            KeyboardButtonProps={{
              "aria-label": "change date",
              style: {
                padding: "0px",
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.dateInput}
            size="small"
            fullWidth={true}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <Typography className={classes.textTwo}>Priority</Typography>
        <div>
          {[...Array(3)].map((star, index) => {
            const selectedPriority = index + 1;
            return (
              <label>
                <input
                  type="radio"
                  style={{ display: "none" }}
                  name="priority"
                  value={selectedPriority}
                  onClick={() => setPriority(selectedPriority)}
                />
                <Cookie
                  size={20}
                  style={{ marginRight: "3px", cursor: "pointer" }}
                  color={
                    selectedPriority <= (priorityHover || priority || 0)
                      ? "#FC5959"
                      : "#e4e5e9"
                  }
                  onMouseEnter={() => setPriorityHover(selectedPriority)}
                  onMouseLeave={() => setPriorityHover(0)}
                />
              </label>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          className={classes.btn}
          onClick={() =>
            handleBulkTasksUpdate(
              status,
              priority,
              selectedEndDate,
              selectedStartDate
            )
          }
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : "Update Status"}
        </Button>
        <Button
          className={classes.deleteButton}
          onClick={() => handleBulkTasksDelete()}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : "Delete Tasks"}
        </Button>
      </div>
    </Box>
  );
};

export default TaskBulkUpdateModal;
