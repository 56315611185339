// Library
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

//Components
import {
  AppBar,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import NotificationsDropdown from "../Notifications/NotificationDropDown/NotificationsDropdown.component";
import NotificationModal from "../Notifications/NotificationModal/NotificationModal";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown.component";
import MovieColabLogo from "../Utilities/MovieColabLogo";
import { EpisodesIconSmall } from "../Utilities/Svg/EpisodesIcon";
import ExternalReviewerSidebar from "./ExternalReviewerSideBar/ExternalReviewerSidebar";

// Styles
import { matchPath, withRouter } from "react-router-dom";
import useStyles from "./SidebarAndNavbar.styles";

//icons
import { Bell, ChevronDown, User } from "react-feather";

import {
  CheckSquareOffset,
  Clock,
  Cube,
  FilmStrip,
  GearSix,
  House,
  Queue,
  Users,
  Warning,
} from "phosphor-react";

import { MovieColabContext } from "../../Context/MovieColabContext";
import StringTruncate from "../Utilities/StringTruncate/StringTruncate";
import { ProjectMediaIconSmall } from "../Utilities/Svg/ProjectMediaIcon";
import { ReviewerIconSidebar } from "../Utilities/Svg/Reviewer";

function NavbarAndSidebar(props) {
  const { userRoleForProject } = props;
  //States
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [openNotificationsDropDownModal, setopenNotificationsDropDownModal] =
    useState(false);
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [projectId, setProjectId] = useState(null);
  const [currentProject, setCurrentProject] = useState({});
  //state that updates sidebar
  const [sidebarType, setSidebarType] = useState("internal"); //internal || external
  const [subFilterNotification, setSubFilterNotification] = useState({
    projectId: "",
    subFilterName: "",
  });
  const {
    notifications,
    isNewNotificationAvailable,
    handleNewNotificationStatus,
  } = useContext(MovieColabContext);

  useEffect(() => {
    setCurrentProject(props.selectedProject);
    setProjectId(props.selectedProject.id);
    if (userRoleForProject === "reviewer") {
      setSidebarType("external");
    } else {
      setSidebarType("internal");
    }
    return () => {};
  }, [userRoleForProject, props.selectedProject]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleNewNotificationStatus(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelToggle = () => {
    setModalOpen(!openModal);
  };
  const handleNotificationsModalToggle = () => {
    setopenNotificationsDropDownModal(!openNotificationsDropDownModal);
  };

  const handleNotificationModal = () => {
    setNotificationModalOpen(!isNotificationModalOpen);
  };

  const { pathname } = props.location;

  const isPath = pathname === "/";
  const isNotification = pathname === "/notifications";
  const isReports = pathname === "/reports";
  const isPdf = pathname === "/pdf-reports";
  const TIMEOUT = 3000;
  let timer;
  function mouseEnter() {
    timer = setTimeout(() => {
      setOpen(true);
    }, TIMEOUT);
  }

  function mouseLeave() {
    clearTimeout(timer);
    setOpen(false);
  }

  const openpopover = Boolean(anchorEl);
  const id = openpopover ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.topToolbar}>
          {isPath || isNotification || isReports || isPdf ? (
            <a href="/" style={{ textDecoration: "none" }}>
              <div style={{ display: "flex", cursor: "pointer" }}>
                <div style={{ margin: "5px 5px" }}>
                  <MovieColabLogo />
                </div>
                <Typography className={classes.title}>Movie Colab</Typography>
              </div>
            </a>
          ) : null}

          {!isPath && !isNotification && !isReports && !isPdf ? (
            <div
              className={
                sidebarType === "external"
                  ? classes.extrProjectName
                  : classes.simpleProjectName
              }
            >
              {sidebarType === "external" && (
                <a href="/" style={{ textDecoration: "none" }}>
                  <div>
                    <MovieColabLogo />
                  </div>
                </a>
              )}
              <div className={classes.projectNameAndImage}>
                <img
                  className={classes.projectThumbnail}
                  alt="project image"
                  src={
                    currentProject?.thumbnail
                      ? currentProject?.thumbnail
                      : "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  }
                />
                <span style={{ fontSize: "16px" }}>
                  {StringTruncate(currentProject?.name, 25)}
                </span>
                {/* <SelectProjectDropDown
                items={projectItems}
                // projectId={projectId}
                history={props.location}
              /> */}
              </div>
            </div>
          ) : null}

          <div className={classes.topBarIconDiv}>
            <Button
              className={classes.topBarIcon}
              aria-describedby={id}
              onClick={handleClick}
            >
              {isNewNotificationAvailable && notifications?.length ? (
                <BellIcon />
              ) : (
                <Bell />
              )}
            </Button>
            <Popover
              id={id}
              open={openpopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                },
              }}
            >
              <div className={classes.popoverArrowTriangle}></div>
              <div style={{ position: "relative" }}>
                <div className={classes.popoverArrow}></div>
                <NotificationsDropdown
                  handleNotificationsModalToggle={
                    handleNotificationsModalToggle
                  }
                  handleNotificationModal={handleNotificationModal}
                />
              </div>
            </Popover>
            <Button
              className={classes.topBarIcon}
              onClick={() => window.open("/reports", "_blank")}
            >
              <Warning size={25} />
            </Button>
            <Button className={classes.topBarIcon} onClick={handleModelToggle}>
              <User />
              <ChevronDown />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {/* sidebar drawer isn't shown when its notification and reports page or projects page */}
      {/* render below sidebar if it is admin or normal user             */}
      {!isPath &&
      !isNotification &&
      !isReports &&
      !isPdf &&
      sidebarType === "internal" ? (
        <Drawer
          // //  style={{backgroundColor:"red"}}
          onMouseLeave={mouseLeave}
          onMouseEnter={mouseEnter}
          onClick={mouseLeave}
          PaperProps={{
            style: {
              borderRight: "0px",
              borderRadius: "10px",
              height: "96.5vh",
              margin: "15px",
              position: "fixed",
            },
          }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              margin: "1rem 0 2rem 11px",
            }}
          >
            <a href="/" style={{ textDecoration: "none", cursor: "pointer" }}>
              <div style={{ zIndex: 2 }}>
                <MovieColabLogo />
              </div>
            </a>
          </div>
          <List
            className={open ? classes.sideBarListOpen : classes.sideBarList}
          >
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem>
                {open ? (
                  <div className={`${classes.iconDiv}`}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <House className={classes.iconsize} weight="fill" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Home
                    </ListItemText>
                  </div>
                ) : (
                  <Tooltip arrow title="Home" placement="right">
                    <ListItemIcon className={classes.iconDiv}>
                      <House className={classes.iconsize} />
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/tasks`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="tasks">
                {matchPath(pathname, `/${projectId}/tasks`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckSquareOffset
                          className={classes.iconsize}
                          weight="fill"
                        />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        My Tasks
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="My Tasks" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <CheckSquareOffset className={classes.iconsize} />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckSquareOffset className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      My Tasks
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <CheckSquareOffset className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/updates`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Updates">
                {matchPath(pathname, `/${projectId}/updates`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Clock className={classes.iconsize} weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Updates
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Updates" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Clock className={classes.iconsize} weight="bold" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Clock className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Updates
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Clock className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/assets`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Assets">
                {matchPath(pathname, `/${projectId}/assets`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Cube className={classes.iconsize} weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Assets
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Assets" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Cube className={classes.iconsize} weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Cube className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Assets
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Cube className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/sequence`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Sequences">
                {matchPath(pathname, `/${projectId}/sequence`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FilmStrip className={classes.iconsize} weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Sequences
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Sequence" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <FilmStrip className={classes.iconsize} weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FilmStrip className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Sequences
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <FilmStrip className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
            <NavLink
              to={`/${projectId}/episodes`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Episodes">
                {matchPath(pathname, `/${projectId}/episodes`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EpisodesIconSmall />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Episodes
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Episodes" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <EpisodesIconSmall />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EpisodesIconSmall />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Episodes
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <EpisodesIconSmall />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
            <NavLink
              to={`/${projectId}/project-media`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="ProjectMedia">
                {matchPath(pathname, `/${projectId}/project-media`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ProjectMediaIconSmall size="small" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Project Media
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Project Media" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <ProjectMediaIconSmall size="small" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ProjectMediaIconSmall size="small" />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Project Media
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <ProjectMediaIconSmall size="small" />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            {/* Showing options that are experimental       */}

            {props.showSeqExperimental ? (
              <NavLink
                to={`/${projectId}/playlist`}
                style={{ textDecoration: "none" }}
              >
                <ListItem id="Playlist">
                  {matchPath(pathname, `/${projectId}/playlist`) ? (
                    // when selected and drawer is open
                    open ? (
                      <div className={`${classes.iconDiv} ${classes.selected}`}>
                        <ListItemIcon
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Queue className={classes.iconsize} weight="fill" />
                        </ListItemIcon>

                        <ListItemText
                          className={classes.text}
                          disableTypography
                        >
                          Playlist
                        </ListItemText>
                      </div>
                    ) : (
                      // when selected and drawer is closed
                      <Tooltip arrow title="Playlist" placement="right">
                        <ListItemIcon
                          className={`${classes.iconDiv} ${classes.selected}`}
                        >
                          <Queue className={classes.iconsize} weight="fill" />
                        </ListItemIcon>
                      </Tooltip>
                    )
                  ) : // not selected and drawer is open
                  open ? (
                    <div className={classes.iconDiv}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Queue className={classes.iconsize} />
                      </ListItemIcon>
                      <ListItemText className={classes.text} disableTypography>
                        Playlist
                      </ListItemText>
                    </div>
                  ) : (
                    // not selected and drawer is closed
                    <ListItemIcon className={classes.iconDiv}>
                      <Queue className={classes.iconsize} />
                    </ListItemIcon>
                  )}
                </ListItem>
              </NavLink>
            ) : (
              ""
            )}

            <NavLink
              to={`/${projectId}/review`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Reviewer">
                {matchPath(pathname, `/${projectId}/review`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ReviewerIconSidebar />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Review
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Review" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <ReviewerIconSidebar />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ReviewerIconSidebar />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Review
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <ReviewerIconSidebar />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
            <NavLink
              to={`/${projectId}/departments`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Departments">
                {matchPath(pathname, `/${projectId}/departments`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Users className={classes.iconsize} weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Departments
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Departments" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <Users className={classes.iconsize} weight="bold" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Users className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Departments
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is closed
                  <ListItemIcon className={classes.iconDiv}>
                    <Users className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>

            <NavLink
              to={`/${projectId}/settings`}
              style={{ textDecoration: "none" }}
            >
              <ListItem id="Settings">
                {matchPath(pathname, `/${projectId}/settings`) ? (
                  // when selected and drawer is open
                  open ? (
                    <div className={`${classes.iconDiv} ${classes.selected}`}>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GearSix className={classes.iconsize} weight="fill" />
                      </ListItemIcon>

                      <ListItemText className={classes.text} disableTypography>
                        Settings
                      </ListItemText>
                    </div>
                  ) : (
                    // when selected and drawer is closed
                    <Tooltip arrow title="Settings" placement="right">
                      <ListItemIcon
                        className={`${classes.iconDiv} ${classes.selected}`}
                      >
                        <GearSix className={classes.iconsize} weight="fill" />
                      </ListItemIcon>
                    </Tooltip>
                  )
                ) : // not selected and drawer is open
                open ? (
                  <div className={classes.iconDiv}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <GearSix className={classes.iconsize} />
                    </ListItemIcon>
                    <ListItemText className={classes.text} disableTypography>
                      Settings
                    </ListItemText>
                  </div>
                ) : (
                  // not selected and drawer is open
                  <ListItemIcon className={classes.iconDiv}>
                    <GearSix className={classes.iconsize} />
                  </ListItemIcon>
                )}
              </ListItem>
            </NavLink>
          </List>
        </Drawer>
      ) : null}

      {/* add external reviewer component here */}
      {sidebarType === "external" && !isPath && !isReports ? (
        // <Drawer
        //   //style={{backgroundColor:"red"}}
        //   onMouseLeave={mouseLeave}
        //   onMouseEnter={mouseEnter}
        //   onClick={mouseLeave}
        //   PaperProps={{
        //     style: {
        //       borderRight: "0px",
        //       borderRadius: "10px",
        //       height: "96.5vh",
        //       margin: "15px",
        //       position: "fixed",
        //     },
        //   }}
        //   variant="permanent"
        //   className={clsx(classes.drawer, {
        //     [classes.drawerOpen]: open,
        //     [classes.drawerClose]: !open,
        //   })}
        //   classes={{
        //     paper: clsx({
        //       [classes.drawerOpen]: open,
        //       [classes.drawerClose]: !open,
        //     }),
        //   }}
        // >
        //   <div
        //     style={{
        //       display: "flex",
        //       position: "relative",
        //       margin: "1rem 0 3rem 11px",
        //     }}
        //   >
        //     <a href="/" style={{ textDecoration: "none", cursor: "pointer" }}>
        //       <div style={{ zIndex: 2 }}>
        //         <MovieColabLogo />
        //       </div>
        //     </a>
        //   </div>
        //   <div
        //     style={{
        //       width: "100%",
        //       height: "100%",
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //     }}
        //   >
        //     <div
        //       style={{
        //         height: "80%",
        //         width: "80%",
        //         backgroundColor: "#1B1D28",
        //         border: "1px solid #6C65D9",
        //         borderRadius: "10px",
        //         textOrientation: "mixed",
        //         writingMode: "vertical-rl",
        //         padding: "25%",
        //         paddingTop: "50%",
        //         transform: "rotate(180deg)",
        //       }}
        //     >
        //       Update status of the version by selecting Revision OR Complete
        //       icons in the action section.
        //     </div>
        //   </div>
        // </Drawer>
        <ExternalReviewerSidebar
          projectId={projectId}
          handleNotificationModal={handleNotificationModal}
          setSubFilterNotification={setSubFilterNotification}
        />
      ) : (
        ""
      )}

      <Modal
        open={openModal}
        onClose={handleModelToggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ProfileDropdown
          themeToggler={props.themeToggler}
          userInfo={props.userInfo}
        />
      </Modal>
      {/* <Modal
        open={openNotificationsDropDownModal}
        onClose={handleNotificationsModalToggle}
      >
        <NotificationsDropdown
          handleNotificationsModalToggle={handleNotificationsModalToggle}
          handleNotificationModal={handleNotificationModal}
        />
      </Modal> */}
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={isNotificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
      >
        {/* setNotificationModalOpen is passed to close the modal after redirecting to url */}
        <NotificationModal
          handleNotificationModal={handleNotificationModal}
          setNotificationModalOpen={setNotificationModalOpen}
          sidebarType={sidebarType}
          isPath={isPath}
          subFilterNotification={subFilterNotification}
        />
      </Modal>
      <main
        className={
          sidebarType === "external" && !isPath && !isReports
            ? classes.reviewerContent
            : open
            ? classes.contentOpen
            : classes.contentClose
        }
      >
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default withRouter(NavbarAndSidebar);

const BellIcon = () => {
  return (
    <svg
      width="34"
      height="37"
      viewBox="0 0 34 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2686 15.7501C10.2673 14.861 10.442 13.9804 10.7825 13.159C11.123 12.3377 11.6227 11.5918 12.2527 10.9644C12.8828 10.337 13.6307 9.84041 14.4535 9.50329C15.2762 9.16617 16.1575 8.99516 17.0467 9.0001C20.7592 9.02823 23.731 12.1126 23.731 15.8345V16.5001C23.731 19.8564 24.4342 21.8064 25.0529 22.8751C25.1186 22.9889 25.1533 23.118 25.1534 23.2494C25.1535 23.3809 25.1191 23.51 25.0536 23.624C24.9881 23.7379 24.8938 23.8326 24.7801 23.8986C24.6665 23.9647 24.5375 23.9996 24.406 24.0001H9.59355C9.46212 23.9996 9.33312 23.9647 9.21948 23.8986C9.10583 23.8326 9.01153 23.7379 8.94601 23.624C8.8805 23.51 8.84608 23.3809 8.84619 23.2494C8.84631 23.118 8.88096 22.9889 8.94667 22.8751C9.56542 21.8064 10.2686 19.8564 10.2686 16.5001V15.7501Z"
        stroke="#F9F9F9"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 24V24.75C14 25.5456 14.3161 26.3087 14.8787 26.8713C15.4413 27.4339 16.2044 27.75 17 27.75C17.7956 27.75 18.5587 27.4339 19.1213 26.8713C19.6839 26.3087 20 25.5456 20 24.75V24"
        stroke="#F9F9F9"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="21.3637" cy="11.4545" r="5.45455" fill="#1B1D28" />
      <g filter="url(#filter0_f_12091_4506)">
        <path
          d="M10.2683 15.7501C10.2671 14.861 10.4417 13.9804 10.7823 13.159C11.1228 12.3377 11.6225 11.5918 12.2525 10.9644C12.8825 10.337 13.6305 9.84041 14.4532 9.50329C15.276 9.16617 16.1573 8.99516 17.0464 9.0001C20.7589 9.02823 23.7308 12.1126 23.7308 15.8345V16.5001C23.7308 19.8564 24.4339 21.8064 25.0527 22.8751C25.1184 22.9889 25.153 23.118 25.1532 23.2494C25.1533 23.3809 25.1189 23.51 25.0533 23.624C24.9878 23.7379 24.8935 23.8326 24.7799 23.8986C24.6662 23.9647 24.5372 23.9996 24.4058 24.0001H9.59331C9.46188 23.9996 9.33288 23.9647 9.21923 23.8986C9.10558 23.8326 9.01128 23.7379 8.94577 23.624C8.88025 23.51 8.84583 23.3809 8.84595 23.2494C8.84606 23.118 8.88072 22.9889 8.94643 22.8751C9.56518 21.8064 10.2683 19.8564 10.2683 16.5001V15.7501Z"
          stroke="#F9F9F9"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter1_f_12091_4506)">
        <path
          d="M14 24V24.75C14 25.5456 14.3161 26.3087 14.8787 26.8713C15.4413 27.4339 16.2044 27.75 17 27.75C17.7956 27.75 18.5587 27.4339 19.1213 26.8713C19.6839 26.3087 20 25.5456 20 24.75V24"
          stroke="#F9F9F9"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <circle cx="21.3636" cy="11.4545" r="4.36364" fill="#FF0000" />
      <defs>
        <filter
          id="filter0_f_12091_4506"
          x="0.158447"
          y="0.3125"
          width="33.6821"
          height="32.375"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_12091_4506"
          />
        </filter>
        <filter
          id="filter1_f_12091_4506"
          x="5.3125"
          y="15.3125"
          width="23.375"
          height="21.125"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_12091_4506"
          />
        </filter>
      </defs>
    </svg>
  );
};
