import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  tableContainer: {
    height: `calc(100vh - 220px)`,
  },
  headCellsSmall: {
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    background: theme.palette.primary.main,
    width: "10%",
  },
  headCellsBig: {
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    background: theme.palette.primary.main,
    width: "16%",
  },
  episodesPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
