import { CaretRight, CaretLeft } from "phosphor-react";
import React, { useState, useEffect, useRef } from "react";
import NoThumbnailVersionViewer from "../../NoThumbnailVersionViewer.png";
import useStyles from "./VersionSelector.styles";

export default function VersionSelector({
  onVersionChange,
  versionList,
  setCommentList,
  selectedVersion,
  setSelectedVersion,
  versionModalRef,
  setCommentsPageNo,
}) {
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState({});
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState({});
  const contRef = useRef(null);
  const selectedVersionRef = useRef(null);
  const classes = useStyles();

  //function to truncate string
  const stringBreak = (s) => {
    return s.length > 20 ? (
      <span>
        {s.substr(0, 20)}
        <br />
        {s.substr(20)}
      </span>
    ) : (
      <span>{s}</span>
    );
    // return s.length > 10 ? s.substr(0, 10) + "..." : s;
  };

  useEffect(() => {
    if (contRef.current.scrollWidth - contRef.current.clientWidth === 0) {
      setIsLeftArrowDisabled({ noScroll: true, scrollStart: true });
      setIsRightArrowDisabled({ noScroll: true, scrollEnd: true });
    } else {
      setIsLeftArrowDisabled({ noScroll: false, scrollStart: true });
      setIsRightArrowDisabled({ noScroll: false, scrollEnd: false });
    }

    // Scroll to the selected version
    if (selectedVersionRef.current) {
      const container = contRef?.current;
      const selectedElement = selectedVersionRef?.current;

      const containerLeft = container?.scrollLeft;
      const containerRight = containerLeft + container?.clientWidth;
      const elementLeft = selectedElement?.offsetLeft;
      const elementRight = elementLeft + selectedElement?.clientWidth;

      if (elementLeft < containerLeft) {
        container?.scrollTo({
          left: elementLeft,
          behavior: "smooth",
        });
      } else if (elementRight > containerRight) {
        container?.scrollTo({
          left: elementRight - container?.clientWidth,
          behavior: "smooth",
        });
      }
    }
  }, [selectedVersion]);

  const sideScroll = (element, speed, distance, step, direction) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === "right") {
        element.scrollLeft += step;
        setIsLeftArrowDisabled({ noScroll: false, scrollStart: false });
        element.scrollWidth - element.clientWidth - element.scrollLeft < 1 &&
          setIsRightArrowDisabled({ noScroll: false, scrollEnd: true });
      } else {
        element.scrollLeft -= step;
        element.scrollLeft === 0 &&
          setIsLeftArrowDisabled({ noScroll: false, scrollStart: true });
        element.scrollWidth - element.clientWidth - element.scrollLeft > 1 &&
          setIsRightArrowDisabled({ noScroll: false, scrollEnd: false });
      }
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleKeyDown = (event) => {
    if (
      event.shiftKey &&
      (event.keyCode === 39 || event.key === "ArrowRight")
    ) {
      sideScroll(contRef.current, 5, 1200, 10, "right");
    } else if (
      event.shiftKey &&
      (event.keyCode === 37 || event.key === "ArrowLeft")
    ) {
      sideScroll(contRef.current, 5, 1200, 10, "left");
    }
  };

  useEffect(() => {
    versionModalRef.current?.addEventListener("keydown", handleKeyDown);
    return () => {
      versionModalRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={classes.root}>
      {isRightArrowDisabled.noScroll ? (
        ""
      ) : (
        <div
          className={
            isLeftArrowDisabled.scrollStart
              ? classes.leftArrowDisabled
              : classes.leftArrowEnabled
          }
        >
          <CaretLeft
            weight="bold"
            size={40}
            onClick={() => {
              sideScroll(contRef.current, 5, 1200, 10, "left");
            }}
          />
        </div>
      )}
      <div className={classes.mainDiv} ref={contRef}>
        {versionList.results.map((version) => (
          <div
            className={classes.versionList}
            ref={version === selectedVersion ? selectedVersionRef : null}
          >
            <div
              className={
                version === selectedVersion
                  ? classes.versionSelected
                  : classes.version
              }
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (selectedVersion !== version) {
                  setCommentList({ data: [], loading: true });
                  setSelectedVersion(version);
                  setCommentsPageNo && setCommentsPageNo(1);
                }
              }}
            >
              <img
                className={
                  version.thumbnail
                    ? classes.versionThumbnail
                    : classes.noThumbnail
                }
                src={
                  version.thumbnail
                    ? version.thumbnail
                    : NoThumbnailVersionViewer
                }
                alt="version thumbnail"
                draggable="false"
              />
            </div>
            {version.name ? (
              <span className={classes.versionName}>
                {stringBreak(version.name)}
              </span>
            ) : (
              <span>Version Name</span>
            )}
          </div>
        ))}
      </div>
      {isRightArrowDisabled.noScroll ? (
        ""
      ) : (
        <div
          className={
            isRightArrowDisabled.scrollEnd
              ? classes.rightArrowDisabled
              : classes.rightArrowEnabled
          }
        >
          <CaretRight
            weight="bold"
            size={40}
            onClick={() => {
              sideScroll(contRef.current, 5, 1200, 10, "right");
            }}
          />
        </div>
      )}
    </div>
  );
}
