import React, { useState } from "react";
import { fpatch, fdelete } from "../../../API/callsAPI";
import { TableRow, TableCell, Button, Modal } from "@material-ui/core";
import useStyles from "./EpisodesList.styles";
import { EpisodesIconBig } from "../../Utilities/Svg/EpisodesIcon";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import { PencilSimpleLine } from "phosphor-react";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import EditEpisodeModal from "../EpisodesPageModals/EditEpisodeModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";

function EpisodesList({
  eachEpisode,
  updateRefreshCounter,
  sequenceList,
  statusList,
  projectId,
  setSnackValue,
}) {
  const classes = useStyles();
  const [editEpisodeModal, setEditEpisodeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const searchParams = new URLSearchParams();

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleEditEpisodeModal = () => {
    setEditEpisodeModal(!editEpisodeModal);
  };

  const handleEditEpisode = async (obj) => {
    try {
      const res = await fpatch({
        url: `trackables/episode/${eachEpisode.id}`,
        data: obj,
      });
      updateRefreshCounter();
    } catch (error) {
      console.log("error", error);
      setSnackValue({
        isOpen: true,
        message: `Error Editing Episode`,
        isError: true,
      });
    }
  };

  const handleDeleteEpisode = async () => {
    try {
      const res = await fdelete({
        url: `trackables/episode/${eachEpisode.id}`,
      });
      updateRefreshCounter();
    } catch (error) {
      console.log("error", error);
      setSnackValue({
        isOpen: true,
        message: `Error Deleting Episode`,
        isError: true,
      });
    }
  };

  const toSequencePage = () => {
    if (eachEpisode.sequence_list.length > 0) {
      searchParams.set("episodeId", eachEpisode.id);

      const url = `/${projectId}/sequence?${btoa(
        unescape(encodeURIComponent(searchParams.toString()))
      )}`;

      window.open(url);
    }
  };

  return (
    <>
      <TableRow
        hover
        style={{
          cursor: "pointer",
          width: "100%",
        }}
        onClick={(e) => {
          e.preventDefault();
          toSequencePage();
        }}
      >
        <TableCell align="left" className={classes.rowCell}>
          <div className={classes.flexGap}>
            <EpisodesIconBig />
            {eachEpisode.name}
          </div>
        </TableCell>
        <TableCell align="center" className={classes.rowCell}>
          <div className={classes.flexCenter}>
            <DateAndTimeViewer dateAndTime={eachEpisode.updated_at} />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.rowCell}>
          <div className={classes.flexCenter}>{eachEpisode.description}</div>
        </TableCell>
        <TableCell align="center" className={classes.rowCell}>
          {eachEpisode.sequence_list.length}
        </TableCell>
        <TableCell align="center" className={classes.rowCell}>
          <div className={classes.flexCenter}>
            <div className={classes.avatarWithName}>
              <UserAvatar
                firstName={eachEpisode.created_by.first_name}
                lastName={eachEpisode.created_by.last_name}
                avatar={eachEpisode.created_by.avatar}
              />
              <span>
                {eachEpisode.created_by.first_name}{" "}
                {eachEpisode.created_by.last_name}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell align="center" className={classes.rowCell}>
          <div className={classes.flexCenter}>
            <StatusDropDown
              url={`trackables/episode/${eachEpisode.id}`}
              statusList={statusList}
              currStatus={{ id: eachEpisode.status }}
              updateRefreshCounter={updateRefreshCounter}
            />
          </div>
        </TableCell>
        <TableCell align="right" className={classes.rowCell}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditEpisodeModal();
            }}
          >
            <PencilSimpleLine size={22} />
          </Button>
        </TableCell>
      </TableRow>
      <Modal
        className={classes.episodesPageModals}
        open={editEpisodeModal}
        onClose={handleEditEpisodeModal}
      >
        <EditEpisodeModal
          handleClose={handleEditEpisodeModal}
          sequenceList={sequenceList}
          handleEditEpisode={handleEditEpisode}
          selectedEpisode={eachEpisode}
          handleDeleteModal={handleDeleteModal}
        />
      </Modal>
      <Modal
        className={classes.episodesPageModals}
        open={deleteModal}
        onClose={handleDeleteModal}
      >
        <DeleteModal
          type="Episode"
          name={eachEpisode.name}
          handleCloseDeleteModal={handleDeleteModal}
          handleDelete={handleDeleteEpisode}
        />
      </Modal>
    </>
  );
}

export default EpisodesList;
