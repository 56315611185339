import { Box, Button, Divider, Modal, Typography } from "@material-ui/core";
import { DownloadSimple, Files, SelectionAll, Trash, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fdelete2, fget } from "../../../API/callsAPI";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import Loader from "../../Utilities/Loader/Loader";
import ProjectMediaCardView from "../ProjectMediaCardView/ProjectMediaCardView";
import ProjectMediaListView from "../ProjectMediaListView/ProjectMediaListView";
import ProjectMediaAddMediaModal from "../ProjectMediaModals/ProjectMediaAddMediaModal/ProjectMediaAddMediaModal";
import ProjectMediaDeleteModal from "../ProjectMediaModals/ProjectMediaDeleteModal/ProjectMediaDeleteModal";
import ProjectMediaEditMediaModal from "../ProjectMediaModals/ProjectMediaEditMediaModal/ProjectMediaEditMediaModal";
import ProjectMediaNewAndEditFolder from "../ProjectMediaModals/ProjectMediaNewAndEditFolder/ProjectMediaNewAndEditFolder";
import ProjectMediaTools from "../ProjectMediaTools/ProjectMediaTools";
import useStyles from "./ProjectMediaOverview.style";

const ProjectMediaOverview = ({ params }) => {
  const classes = useStyles();
  const history = useHistory();
  const [viewMode, setViewMode] = useState("list");
  const [uploadFolderModalState, setUploadFolderModalState] = useState({
    isOpen: false,
    data: {},
    type: "",
  });
  const [addMediaModalState, setAddMediaModalState] = useState(false);
  const [deleteFolderModal, setDeleteFolderModal] = useState({
    isOpen: false,
    data: {},
    type: "",
  });
  const [selectedMediasFolder, setSelectedMediaFolders] = useState([]);
  const [selectedMediaFiles, setSelectedMediaFiles] = useState([]);
  const [dragScreen, setDragScreen] = useState(false);
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  const [allFolders, setAllFolders] = useState({ count: 0, folders: [] }); //all folders in current folder
  const [allFiles, setAllFiles] = useState({ count: 0, files: [] }); //all files in current folder
  // const [refreshCounter, setRefreshCounter] = useState(0);
  const [breadCrumbs, setBreadCrumbs] = useState([]); //to store folder/file name for breadcrumbs
  const [breadCrumbsId, setBreadCrumbsId] = useState([]); //to store folder/file ids for breadcrumbs
  const [isProjectMediaLoaded, setProjectMediaLoaded] = useState(false); //for loaders
  const [sortByDefault, setSortByDefault] = useState("LatestModified");
  const [projectUsers, setProjectUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editMediaModalData, setEditMediaModalData] = useState({
    isOpen: false,
    data: null,
  });
  const [deleteMediaModalData, setDeleteMediaModalData] = useState({
    isOpen: false,
    data: null,
  });
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState();

  const getFullDate = (date) => {
    let fullDate = [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
    ].join("-");
    return fullDate;
  };
  //
  const [endDate, setEndDate] = useState(getFullDate(new Date()));

  const handleSlectedUser = (value) => {
    setSelectedUser(value);
  };

  const handleStartDate = (date) => {
    setStartDate(getFullDate(date));
  };

  const handleEndDate = (date) => {
    setEndDate(getFullDate(date));
  };

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type === "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type === "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  const handleSelectUnselectMedia = (media) => {
    const isExist = selectedMediasFolder.some((single) => single === media);
    if (isExist) {
      setSelectedMediaFolders(
        selectedMediasFolder.filter((single) => single !== media)
      );
    } else {
      setSelectedMediaFolders((prev) => [...prev, media]);
    }
  };

  const handleSelectUnselectFiles = (media) => {
    const isExist = selectedMediaFiles.some((single) => single === media);
    if (isExist) {
      setSelectedMediaFiles(
        selectedMediaFiles.filter((single) => single !== media)
      );
    } else {
      setSelectedMediaFiles((prev) => [...prev, media]);
    }
  };

  const handleSelectAll = () => {
    setSelectedMediaFolders(allFolders.folders.map((folder) => folder.id));
    setSelectedMediaFiles(allFiles.files.map((file) => file.id));
  };

  const clearAllSelect = () => {
    setSelectedMediaFolders([]);
    setSelectedMediaFiles([]);
  };

  const handleToggleView = () => {
    if (viewMode === "list") {
      setViewMode("grid");
    } else {
      setViewMode("list");
    }
  };

  function handleSubmitSort(sortBy) {
    switch (sortBy) {
      case "LatestModified":
        allFolders.folders.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        allFiles.files.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "A-Z":
        allFolders.folders.sort((a, b) => a.name.localeCompare(b.name));
        allFiles.files.sort((a, b) => a.file_name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        allFolders.folders.sort((a, b) => b.name.localeCompare(a.name));
        allFiles.files.sort((a, b) => b.file_name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LastModified":
        allFolders.folders.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        allFiles.files.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        allFolders.folders.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        allFiles.files.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return;
    }
  }

  const handleBulkDelete = async () => {
    try {
      await fdelete2({
        url: `repo-media-bulk-delete/`,
        data: {
          repo_ids: selectedMediasFolder,
          media_ids: selectedMediaFiles,
        },
      });
      if (selectedMediasFolder.length > 0) {
        getProjectFolderData();
      }
      if (selectedMediaFiles.length > 0) {
        getProjectFilesData();
      }
      clearAllSelect();
      setDeleteFolderModal({
        isOpen: false,
        data: {},
        type: "",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProjectFolderData = async () => {
    let url = params.pmid
      ? `repository/?project=${params.projectId}&root=${params.pmid}`
      : `repository/?project=${params.projectId}`;
    if (selectedUser && startDate) {
      url =
        url + `&created_by=${selectedUser.id}&from=${startDate}&to=${endDate}`;
    } else if (selectedUser && !startDate) {
      url = url + `&created_by=${selectedUser.id}`;
    } else if (startDate && !selectedUser) {
      url = url + `&from=${startDate}&to=${endDate}`;
    }
    try {
      const res = await fget({
        url: url,
      });
      setAllFolders({ folders: res.data.results, count: res.data.count });
      setBreadCrumbs(res.data.folder_path?.split("/"));
      setBreadCrumbsId(res.data.id_path?.split("/"));
      setProjectMediaLoaded(true);
    } catch (error) {
      console.log("error", error);
      setProjectMediaLoaded(true);
    }
  };

  const handleFilter = () => {
    if (selectedUser || startDate) {
      getProjectFolderData();
      getProjectFilesData();
    }
  };

  const getProjectFilesData = async () => {
    let url = params.pmid
      ? `media/?project=${params.projectId}&root=${params.pmid}`
      : `media/?project=${params.projectId}`;
    if (selectedUser && startDate) {
      url =
        url + `&created_by=${selectedUser.id}&from=${startDate}&to=${endDate}`;
    } else if (selectedUser && !startDate) {
      url = url + `&created_by=${selectedUser.id}`;
    } else if (startDate && !selectedUser) {
      url = url + `&from=${startDate}&to=${endDate}`;
    }
    try {
      const res = await fget({
        url: url,
      });
      setAllFiles({ files: res.data.results, count: res.data.count });
      setProjectMediaLoaded(true);
    } catch (error) {
      console.log("error", error);
      setProjectMediaLoaded(true);
    }
  };

  const handleCopyFolderLink = (id) => {
    const paths = window.location.href.split("/");
    let newPath = "";
    if (paths[paths.length - 1] === "project-media") {
      newPath = window.location.href + `/${id}`;
    } else {
      paths[paths.length - 1] = id;
      newPath = paths.join("/");
    }
    CopyFileLink(newPath);
  };

  const handleOpenEditMediaModal = (mediaData) => {
    setEditMediaModalData({
      isOpen: true,
      data: mediaData,
    });
  };

  const handleOpenDeleteMediaModal = (mediaData) => {
    setDeleteMediaModalData({
      isOpen: true,
      data: mediaData,
    });
  };

  const downloadFile = async (url) => {
    try {
      window.location.href = url;
      return Promise.resolve("data");
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const CopyFileLink = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setSnackValue({
          isOpen: true,
          message: "Link Copied",
          isError: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: false,
        });
      });
  };

  const getFileUrl = async (id, type) => {
    if (type === "download" || type === "copy") {
      setSnackValue({
        isOpen: true,
        message: type === "copy" ? "Copying..." : "Downloading...",
        isError: false,
      });
    }

    const url = `project/${params.projectId}/media/files/?media_file=${id}`;
    try {
      const res = await fget({
        url: url,
      });
      if (type === "download") {
        await downloadFile(res.data.signed_url).then((res) => {
          res === "data" &&
            setSnackValue({
              isOpen: true,
              message: "Downloaded",
              isError: false,
            });
        });
      } else if (type === "copy") {
        CopyFileLink(res.data.signed_url);
      } else if (type === "download all") {
        return res.data.signed_url;
      } else {
        return res.data.signed_url;
      }
    } catch (error) {
      console.log("error", error);
      if (type === "download" || type === "copy") {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: false,
        });
      }
    }
  };

  const downloadAll = async () => {
    for (let i = 0; i < selectedMediaFiles.length; i++) {
      try {
        const res = await getFileUrl(selectedMediaFiles[i], "download all");
        await downloadFile(res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (allFiles.count > 0 || allFolders.count > 0) {
      handleSubmitSort(sortByDefault);
    }
  }, [allFiles, allFolders]);

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${params.projectId}/users/`,
      });
      if (usersRes.status === 200) {
        setProjectUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearFilter = () => {
    setSelectedUser("");
    setStartDate();
    setEndDate(getFullDate(new Date()));
    getProjectFolderData();
    getProjectFilesData();
  };

  useEffect(() => {
    setSelectedUser("");
    setStartDate();
    setEndDate(getFullDate(new Date()));
    setProjectMediaLoaded(false);
    setAllFolders({ ...allFolders, folders: [] });
    setAllFiles({ ...allFiles, files: [] });
    getProjectFolderData();
    getProjectFilesData();
    fetchUsers();
  }, [history.location.pathname]);

  return (
    <Box
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <ProjectMediaTools
        handleToggleView={handleToggleView}
        handleOpenUploadFolderModal={() =>
          setUploadFolderModalState({ isOpen: true, data: {}, type: "new" })
        }
        handleOpenAddMediaModal={() => setAddMediaModalState(true)}
        breadCrumbs={breadCrumbs}
        breadCrumbsId={breadCrumbsId}
        projectId={params.projectId}
        handleSubmitSort={handleSubmitSort}
        sortByDefault={sortByDefault}
        projectUsers={projectUsers}
        selectedUser={selectedUser}
        startDate={startDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        handleStartDate={handleStartDate}
        handleSlectedUser={handleSlectedUser}
        handleFilter={handleFilter}
        clearFilter={clearFilter}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />
      <Divider style={{ height: "2px", marginTop: "8px" }} />
      <Box
        sx={{
          display:
            selectedMediasFolder.length > 0 || selectedMediaFiles.length > 0
              ? "flex"
              : "none",
          gap: "18px",
        }}
      >
        <Button onClick={handleSelectAll} startIcon={<SelectionAll />}>
          Select All
        </Button>
        <Button onClick={clearAllSelect} startIcon={<X />}>
          Clear All
        </Button>
      </Box>
      <Box
        sx={{
          height:
            selectedMediasFolder.length > 0 || selectedMediaFiles > 0
              ? `calc(100vh - 270px)`
              : `calc(100vh - 200px)`,
          overflow: "auto",
          pr: 1,
        }}
      >
        {isProjectMediaLoaded ? (
          <>
            {allFolders.count === 0 && allFiles.count === 0 ? (
              <NoData />
            ) : (
              <>
                {viewMode === "list" ? (
                  <>
                    <>
                      {allFolders?.count ? (
                        <ProjectMediaListView
                          type="folder"
                          allProjectMediaData={allFolders.folders}
                          handleSelectUnselectMedia={handleSelectUnselectMedia}
                          selectedItems={selectedMediasFolder}
                          projectId={params.projectId}
                          openEditModal={setUploadFolderModalState}
                          openDeleteModal={setDeleteFolderModal}
                          handleCopyFolderLink={handleCopyFolderLink}
                          searchQuery={searchQuery}
                        />
                      ) : (
                        ""
                      )}
                    </>
                    {allFiles?.count ? (
                      <>
                        <Typography variant="h5" style={{ marginTop: "16px" }}>
                          Files
                        </Typography>
                        <ProjectMediaListView
                          type="file"
                          allProjectMediaData={allFiles.files}
                          handleSelectUnselectMedia={handleSelectUnselectFiles}
                          selectedItems={selectedMediaFiles}
                          handleOpenEditMediaModal={handleOpenEditMediaModal}
                          handleOpenDeleteMediaModal={
                            handleOpenDeleteMediaModal
                          }
                          getFileUrl={getFileUrl}
                          searchQuery={searchQuery}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <Box mt={1}>
                    <ProjectMediaCardView
                      allFolders={allFolders.folders}
                      allFiles={allFiles.files}
                      handleSelectUnselectMedia={handleSelectUnselectMedia}
                      handleSelectUnselectFiles={handleSelectUnselectFiles}
                      selectedMediasFolder={selectedMediasFolder}
                      selectedMediaFiles={selectedMediaFiles}
                      projectId={params.projectId}
                      openEditFolderModal={setUploadFolderModalState}
                      setDeleteFolderModal={setDeleteFolderModal}
                      handleCopyFolderLink={handleCopyFolderLink}
                      handleOpenEditMediaModal={handleOpenEditMediaModal}
                      handleOpenDeleteMediaModal={handleOpenDeleteMediaModal}
                      getFileUrl={getFileUrl}
                      searchQuery={searchQuery}
                    />
                  </Box>
                )}
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
      <Modal
        className={classes.flexCenterModal}
        open={addMediaModalState}
        onClose={() => setAddMediaModalState(false)}
      >
        <ProjectMediaAddMediaModal
          handleClose={() => setAddMediaModalState(false)}
          dragScreen={dragScreen}
          handleDragScreen={handleDragScreen}
          setDragFileEnterModal={setDragFileEnterModal}
          projectId={params.projectId}
          repositoryId={params.pmid}
          getProjectFilesData={getProjectFilesData}
        />
      </Modal>
      <Modal
        className={classes.flexCenterModal}
        open={editMediaModalData.isOpen}
        onClose={() =>
          setEditMediaModalData({
            isOpen: false,
            data: null,
          })
        }
      >
        <ProjectMediaEditMediaModal
          handleClose={() =>
            setEditMediaModalData({
              isOpen: false,
              data: null,
            })
          }
          selectedMedia={editMediaModalData.data}
          refreshData={getProjectFilesData}
        />
      </Modal>
      <Modal
        className={classes.flexCenterModal}
        open={uploadFolderModalState.isOpen}
        onClose={() => setUploadFolderModalState(false)}
      >
        <ProjectMediaNewAndEditFolder
          type={uploadFolderModalState.type}
          handleClose={() =>
            setUploadFolderModalState({ isOpen: false, data: {}, type: "" })
          }
          currentFolder={uploadFolderModalState.data}
          rootFolderId={params.pmid}
          projectId={params.projectId}
          getProjectFolderData={getProjectFolderData}
        />
      </Modal>
      <Modal
        className={classes.flexCenterModal}
        open={deleteFolderModal.isOpen}
        onClose={() =>
          setDeleteFolderModal({ isOpen: false, data: {}, type: "" })
        }
      >
        <ProjectMediaDeleteModal
          handleClose={() =>
            setDeleteFolderModal({ isOpen: false, data: {}, type: "" })
          }
          type={deleteFolderModal.type}
          data={deleteFolderModal.data}
          refreshData={getProjectFolderData}
          handleBulkDelete={handleBulkDelete}
          totalCount={selectedMediaFiles.length + selectedMediasFolder.length}
        />
      </Modal>
      <Modal
        className={classes.flexCenterModal}
        open={deleteMediaModalData.isOpen}
        onClose={() => setDeleteMediaModalData({ isOpen: false, data: null })}
      >
        <ProjectMediaDeleteModal
          handleClose={() =>
            setDeleteMediaModalData({ isOpen: false, data: null })
          }
          type="File"
          data={deleteMediaModalData.data}
          refreshData={getProjectFilesData}
        />
      </Modal>
      <Box
        sx={{
          display:
            selectedMediasFolder.length > 0 || selectedMediaFiles.length > 0
              ? "flex"
              : "none",
          gap: "48px",
          marginTop: "5px",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            minWidth: "0px",
            padding: "8px 20px",
            backgroundColor: "#6C65D9",
            borderRadius: "10px",
          }}
          startIcon={<DownloadSimple />}
          disabled={selectedMediaFiles.length < 1}
          onClick={downloadAll}
        >
          Download
        </Button>

        <Button
          style={{
            minWidth: "0px",
            padding: "8px 20px",
            backgroundColor: "#FF0000",
            borderRadius: "10px",
          }}
          startIcon={<Trash />}
          onClick={() =>
            setDeleteFolderModal({
              isOpen: true,
              data: {},
              type: "Items",
            })
          }
        >
          Delete Items
        </Button>
        <Button
          style={{
            minWidth: "0px",
            padding: "8px 20px",
            border: "1px solid #6C65D9",
            borderRadius: "10px",
          }}
          startIcon={<X />}
          onClick={clearAllSelect}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectMediaOverview;
