import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

//UI
import { Button, CircularProgress, Divider, Snackbar } from "@material-ui/core";
import useStyles from "./NotificationsDropdown.styles";

//APIs
import { fdelete, fget, fpatch } from "../../../API/callsAPI";
//
import { MovieColabContext } from "../../../Context/MovieColabContext";
import NotificationDropdownDetail from "./NotificationDropdownDetail";

import { BellZ } from "phosphor-react";
import { Alert } from "@material-ui/lab";

function NotificationsDropdown(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const { notifications, isNotificationsLoaded, fetchNotification } =
    useContext(MovieColabContext);

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);

  /* const fetchNotification = async () => {
    try {
      const notificationRes = await fget({
        url: "notification/",
      });
      setNotifications(notificationRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  }; */

  const getTrackableId = async (id) => {
    try {
      const result = await fget({
        url: `trackables/task/${id}`,
      });
      if (result.status === 200 || 201) {
        if (result.data.linked_class == "Shot") {
          return {
            id: result.data.linked.id,
            parentId: result.data.linked.parent_sequence,
          };
        } else {
          return { id: result.data.linked.id };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function that return a url according to notification type
  const sendToUrl = async (trackable, notificationDetail) => {
    let url = `/${notificationDetail.project}/`;
    switch (trackable) {
      case "project":
        return (url += "updates");
      case "department":
        return (url += `departments/${notificationDetail.content_object.id}/department`);
      case "task":
        return (url += "tasks");
      case "asset":
        return (url += `assets/${notificationDetail.content_object.id}/assetversions`);
      case "assetversion":
        searchParams.set(
          "versionId",
          `${notificationDetail.content_object.id}`
        );
        return (url += `assets/${notificationDetail.content_object.asset}/assetversions`);
      case "sequence":
        return (url += `sequence/${notificationDetail.content_object.id}/shots`);
      case "shot":
        return (url += `sequence/${notificationDetail.content_object.parent_sequence}/shots/${notificationDetail.content_object.id}/shotversions`);
      case "shotversion":
        searchParams.set(
          "versionId",
          `${notificationDetail.content_object.id}`
        );
        return (url += `sequence/${notificationDetail.content_object.sequence_id}/shots/${notificationDetail.content_object.shot}/shotversions`);
      case "taskcomment":
        const object = await getTrackableId(
          notificationDetail.content_object.task
        );
        if (object.id && object.parentId) {
          url += `sequence/${object.parentId}/shots/${object.id}/task`;
        } else {
          url += `assets/${object.id}/task`;
        }
        return url;
      case "trackablecomment":
        searchParams.set(
          "commentId",
          `${notificationDetail.content_object.id}`
        );
        if (notificationDetail.content_object.version_type === "shotversion") {
          searchParams.set(
            "versionId",
            `${notificationDetail.content_object.version_object.id}`
          );
          return (url += `sequence/${notificationDetail.content_object.version_object.sequence_id}/shots/${notificationDetail.content_object.version_object.shot}/shotversions`);
        } else if (
          notificationDetail.content_object.version_type === "assetversion"
        ) {
          searchParams.set(
            "versionId",
            `${notificationDetail.content_object.version_object.id}`
          );
          return (url += `assets/${notificationDetail.content_object.version_object.asset}/assetversions`);
        }
      default:
        return (url += "updates");
    }
  };

  //mark notification as read and takes to the respective url
  const readNotifications = async (id, notificationDetail) => {
    if (!notificationDetail.isRead) {
      let newNotification = {
        url: `notification/${id}/`,
        data: {
          isRead: true,
        },
      };
      try {
        await fpatch(newNotification);
      } catch (error) {
        console.log(error);
      }
    }
    if (notificationDetail.notification_object) {
      location.pathname = await sendToUrl(
        notificationDetail.notification_object.content_type,
        notificationDetail.notification_object
      );
      window.open(
        `${
          location.pathname +
          "?" +
          btoa(unescape(encodeURIComponent(searchParams.toString())))
        }`
      );
    }
  };

  const handleUnregister = async () => {
    try {
      const deviceId = window.localStorage.getItem("deviceId");
      if (deviceId) {
        const response = await fdelete({
          url: `device/unregister/${deviceId}`,
        });
        setSnackValue({
          isOpen: true,
          message: `Successfully snoozed notification for this device!`,
          isError: false,
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <div className={classes.notificationsDropdown}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>All ({notifications?.length ?? 0})</span>
          <div>
            <Button onClick={() => handleUnregister()}>
              <BellZ size={20} />
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem("notifications");
                fetchNotification();
              }}
              style={{
                paddingRight: "0px",
              }}
            >
              Discard All
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.notificationsDropdownMenu}>
          {!isNotificationsLoaded ? (
            <CircularProgress color="secondary" style={{ margin: "auto" }} />
          ) : (
            <>
              {notifications?.length ? (
                notifications?.map((notificationDetails) => {
                  return (
                    <div
                      onClick={() =>
                        readNotifications(
                          notificationDetails.id,
                          notificationDetails
                        )
                      }
                      key={notificationDetails.id}
                    >
                      <NotificationDropdownDetail
                        notificationDetails={notificationDetails}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={classes.noNotification}>
                  <h2>No recent notifications</h2>
                  <NoNotificationIcon />
                  <span>{`We’ll notify you when something arrives`}</span>
                </div>
              )}
            </>
          )}
        </div>
        <Divider className={classes.divider} />
        <div
          style={{ textDecoration: "none" }}
          onClick={() => {
            props.handleNotificationsModalToggle();
            props.handleNotificationModal();
          }}
        >
          <p className={classes.seeAll}>Open All Notifications</p>
        </div>
      </div>
    </>
  );
}

export default NotificationsDropdown;

const NoNotificationIcon = () => {
  return (
    <svg
      width="142"
      height="122"
      viewBox="0 0 142 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7025_12175)">
        <path
          opacity="0.25"
          d="M141.349 84.1157C137.471 64.4064 114.748 48.654 110.316 28.1154C102.262 1.9848 75.7128 -2.14649 58.4607 9.20201C31.1248 27.1839 60.2865 31.6462 6.68965 74.1902C-6.54463 84.6952 -2.32759 131.067 39.3067 120.425C61.7124 114.697 68.5237 113.894 78.6019 112.951C104.004 110.574 147.859 117.2 141.349 84.1157Z"
          fill="#606479"
        />
        <path
          d="M111.002 89.0551L71.7403 109.952L32.5374 89.0551V57.4985H111.002V89.0551Z"
          fill="#6C65D9"
        />
        <path
          d="M71.7403 110.273C71.6884 110.273 71.6372 110.26 71.5914 110.236L32.3898 89.3384C32.3389 89.3112 32.2962 89.2707 32.2663 89.221C32.2365 89.1713 32.2206 89.1144 32.2204 89.0563V57.4987C32.2204 57.4137 32.2539 57.3322 32.3135 57.2721C32.3732 57.212 32.4541 57.1782 32.5384 57.1782H111.002C111.087 57.1782 111.168 57.212 111.227 57.2721C111.287 57.3322 111.321 57.4137 111.321 57.4987V89.0553C111.32 89.1134 111.304 89.1703 111.275 89.22C111.245 89.2697 111.202 89.3102 111.151 89.3373L71.8889 110.235C71.8432 110.26 71.7922 110.273 71.7403 110.273ZM32.8554 88.8627L71.7403 109.59L110.684 88.8622V57.8193H32.8554V88.8627Z"
          fill="#262626"
        />
        <path
          d="M111.002 50.7271L71.7403 29.8298L89.3832 21.2598L128.645 42.1573L111.002 50.7271Z"
          fill="#6C65D9"
        />
        <path
          d="M111.002 51.0477C110.951 51.0477 110.9 51.0351 110.854 51.0108L71.5917 30.1133C71.5397 30.0855 71.4963 30.0438 71.4664 29.9927C71.4365 29.9416 71.4213 29.8832 71.4224 29.8239C71.4234 29.7646 71.4408 29.7068 71.4726 29.6568C71.5043 29.6069 71.5491 29.5668 71.6021 29.541L89.245 20.9709C89.2899 20.949 89.3392 20.9381 89.3891 20.939C89.4389 20.94 89.4878 20.9527 89.5318 20.9763L128.794 41.8738C128.846 41.9016 128.889 41.9433 128.919 41.9943C128.949 42.0454 128.964 42.1038 128.963 42.1631C128.962 42.2224 128.945 42.2802 128.913 42.3301C128.881 42.3801 128.837 42.4201 128.784 42.4459L111.141 51.0159C111.098 51.0369 111.05 51.0478 111.002 51.0477ZM72.4458 29.8425L111.009 50.3677L127.94 42.1438L89.3766 21.6191L72.4458 29.8425Z"
          fill="#262626"
        />
        <path
          d="M32.5374 50.7271L71.7996 29.8298L54.1567 21.2598L14.8945 42.1573L32.5374 50.7271Z"
          fill="#6C65D9"
        />
        <path
          d="M32.5374 51.0477C32.4896 51.0477 32.4423 51.0369 32.3992 51.0159L14.7573 42.4459C14.7043 42.4201 14.6595 42.3801 14.6277 42.3301C14.596 42.2802 14.5786 42.2224 14.5775 42.1631C14.5764 42.1038 14.5917 42.0454 14.6216 41.9943C14.6515 41.9433 14.6949 41.9016 14.7469 41.8738L54.0091 20.9763C54.0531 20.9527 54.102 20.94 54.1518 20.939C54.2016 20.9381 54.251 20.949 54.2958 20.9709L71.9387 29.541C71.9917 29.5668 72.0366 29.6069 72.0683 29.6568C72.1001 29.7068 72.1174 29.7646 72.1185 29.8239C72.1196 29.8832 72.1043 29.9416 72.0744 29.9927C72.0445 30.0438 72.0012 30.0855 71.9492 30.1133L32.687 51.0108C32.6409 51.0353 32.5895 51.0479 32.5374 51.0477ZM15.6 42.1444L32.5308 50.3682L71.094 29.843L54.1633 21.6191L15.6 42.1444Z"
          fill="#262626"
        />
        <path
          d="M32.5374 50.7271L71.7996 71.6246L51.6123 82.7585L12.3501 61.8612L32.5374 50.7271Z"
          fill="#6C65D9"
        />
        <path
          d="M51.6123 83.0791C51.5605 83.0791 51.5095 83.0664 51.4637 83.0419L12.2015 62.1444C12.1508 62.1173 12.1084 62.0769 12.0786 62.0275C12.0488 61.978 12.0329 61.9214 12.0325 61.8636C12.0321 61.8057 12.0472 61.7489 12.0762 61.699C12.1053 61.6491 12.1472 61.6081 12.1975 61.5803L32.3847 50.4461C32.4308 50.4206 32.4825 50.4071 32.5351 50.4067C32.5877 50.4064 32.6395 50.4192 32.686 50.4441L71.9482 71.3416C71.9989 71.3687 72.0413 71.4091 72.0711 71.4585C72.1008 71.508 72.1168 71.5646 72.1172 71.6224C72.1176 71.6803 72.1025 71.7371 72.0735 71.787C72.0444 71.8369 72.0025 71.8779 71.9522 71.9057L51.7649 83.0399C51.7181 83.0656 51.6656 83.0791 51.6123 83.0791ZM13.0218 61.8562L51.6095 82.3947L71.1281 71.6296L32.5402 51.091L13.0218 61.8562Z"
          fill="#262626"
        />
        <path
          d="M111.002 50.7271L71.7403 71.6246L91.9276 82.7585L131.19 61.8612L111.002 50.7271Z"
          fill="#6C65D9"
        />
        <path
          d="M91.9281 83.0791C91.8747 83.0791 91.8223 83.0656 91.7754 83.0399L71.5881 71.9057C71.5378 71.8779 71.4959 71.8369 71.4669 71.787C71.4379 71.7371 71.4228 71.6803 71.4232 71.6224C71.4236 71.5646 71.4395 71.508 71.4693 71.4585C71.499 71.4091 71.5415 71.3687 71.5922 71.3416L110.854 50.4441C110.901 50.4192 110.953 50.4064 111.005 50.4067C111.058 50.4071 111.11 50.4206 111.156 50.4461L131.343 61.5803C131.393 61.6081 131.435 61.6491 131.464 61.699C131.493 61.7489 131.508 61.8057 131.508 61.8636C131.507 61.9214 131.492 61.978 131.462 62.0275C131.432 62.0769 131.39 62.1173 131.339 62.1444L92.0767 83.0419C92.0309 83.0664 91.9799 83.0791 91.9281 83.0791ZM72.4128 71.6296L91.9311 82.3947L130.519 61.8562L111 51.091L72.4128 71.6296Z"
          fill="#262626"
        />
        <path
          d="M71.7996 29.8301L32.5374 50.7273L71.7403 71.6249L111.002 50.7273L71.7996 29.8301Z"
          fill="#262626"
        />
        <path
          d="M72.0583 71.6245H71.4222V109.953H72.0583V71.6245Z"
          fill="#262626"
        />
        <path
          d="M49.7424 48.8302C49.6898 48.8302 49.6384 48.8136 49.5956 48.7827C49.5406 48.7434 49.5032 48.6837 49.4919 48.6167C49.4805 48.5496 49.4961 48.4808 49.5351 48.4253C49.5821 48.3584 54.1806 41.6589 49.0471 35.5512C48.1385 34.4645 47.0051 33.5913 45.7263 32.9926C44.4475 32.3939 43.0542 32.0843 41.6442 32.0855H41.575C42.0625 33.7922 41.9755 35.3096 41.2852 36.4737C40.9911 36.9998 40.557 37.4326 40.032 37.7234C39.5069 38.0143 38.9117 38.1516 38.3134 38.1199C37.8181 38.079 37.3454 37.8934 36.9532 37.5859C36.561 37.2784 36.2662 36.8623 36.1051 36.3886C35.5405 34.9045 36.1306 33.4655 37.6857 32.5381C38.6708 31.9906 39.7655 31.6733 40.8888 31.6096C40.5234 30.6333 40.0482 29.7023 39.4726 28.835C37.619 25.9821 34.2409 24.0073 30.204 23.4167C26.7828 22.9165 23.5041 23.5057 21.85 24.9175C21.7985 24.9614 21.7318 24.983 21.6645 24.9773C21.5973 24.9717 21.535 24.9393 21.4914 24.8874C21.4478 24.8355 21.4265 24.7682 21.4321 24.7004C21.4377 24.6327 21.4698 24.5699 21.5213 24.526C23.2815 23.0237 26.7182 22.3885 30.277 22.9106C34.4581 23.5224 37.9648 25.5796 39.8985 28.5552C40.5254 29.4989 41.0354 30.5164 41.417 31.585C42.9381 31.5482 44.448 31.8557 45.8355 32.485C47.2229 33.1142 48.4527 34.0492 49.434 35.2209C54.8263 41.6361 49.9981 48.653 49.9488 48.7233C49.9253 48.7563 49.8943 48.7832 49.8584 48.8018C49.8225 48.8204 49.7828 48.8301 49.7424 48.8302ZM41.0539 32.1042C39.9649 32.151 38.9013 32.4506 37.946 32.9796C36.6036 33.7801 36.1064 34.956 36.5814 36.2053C36.7101 36.588 36.9468 36.9247 37.2624 37.1741C37.578 37.4235 37.9588 37.5747 38.3584 37.6094C38.8615 37.6321 39.3609 37.5133 39.8009 37.2663C40.2408 37.0194 40.6039 36.6539 40.8496 36.2109C41.4831 35.1417 41.5412 33.7204 41.0532 32.1042H41.0539Z"
          fill="#1B1D28"
        />
        <path
          d="M17.4587 29.0513C17.0263 29.1822 16.5603 29.1378 16.1599 28.9276C15.7595 28.7175 15.4563 28.3581 15.3149 27.926C15.1735 27.4939 15.2051 27.0233 15.4029 26.6143C15.6007 26.2053 15.9491 25.8903 16.3739 25.7363C16.7988 25.5823 17.2664 25.6016 17.6774 25.7899C18.0884 25.9783 18.4102 26.3208 18.5742 26.7447C18.7383 27.1686 18.7317 27.6402 18.5559 28.0593C18.38 28.4783 18.0488 28.8116 17.6327 28.9882C17.5759 29.0124 17.5178 29.0335 17.4587 29.0513ZM16.6127 26.202C16.5715 26.2145 16.531 26.2292 16.4913 26.2461C16.1986 26.3705 15.9656 26.6052 15.842 26.9002C15.7184 27.1951 15.7139 27.527 15.8295 27.8253C15.9452 28.1235 16.1718 28.3644 16.4611 28.4967C16.7504 28.6291 17.0795 28.6423 17.3784 28.5337C17.6773 28.4251 17.9223 28.2032 18.0612 27.9153C18.2001 27.6273 18.222 27.2961 18.1222 26.9921C18.0224 26.6882 17.8087 26.4355 17.5268 26.2879C17.2448 26.1403 16.9169 26.1095 16.6127 26.202Z"
          fill="#606479"
        />
        <path
          d="M17.4556 25.0335C17.0466 25.1566 16.6069 25.1229 16.221 24.9391C15.8351 24.7552 15.5303 24.434 15.3652 24.0374C15.2328 23.7193 15.1969 23.3689 15.2622 23.0304C15.3274 22.6918 15.4908 22.3804 15.7317 22.1354C15.9726 21.8905 16.2801 21.723 16.6154 21.6542C16.9508 21.5854 17.2988 21.6184 17.6156 21.7489C17.9323 21.8795 18.2035 22.1017 18.3949 22.3876C18.5863 22.6735 18.6893 23.0102 18.6908 23.355C18.6923 23.6999 18.5923 24.0375 18.4034 24.3251C18.2145 24.6126 17.9453 24.8373 17.6297 24.9707C17.5728 24.9947 17.5147 25.0156 17.4556 25.0335ZM16.6107 22.184C16.569 22.1966 16.5279 22.2114 16.4878 22.2284C16.1893 22.3547 15.9528 22.5953 15.8303 22.8973C15.7077 23.1993 15.7092 23.538 15.8344 23.8389C15.9256 24.0582 16.078 24.2462 16.2731 24.3801C16.4682 24.514 16.6975 24.5881 16.9335 24.5934C17.1695 24.5987 17.4019 24.5349 17.6027 24.4099C17.8034 24.2849 17.964 24.1039 18.0648 23.8889C18.1657 23.6738 18.2025 23.4339 18.171 23.1982C18.1394 22.9625 18.0407 22.7411 17.8869 22.5607C17.733 22.3803 17.5306 22.2487 17.3041 22.1818C17.0776 22.1149 16.8368 22.1159 16.6107 22.184Z"
          fill="#606479"
        />
        <path
          d="M16.9618 26.8469C18.2113 26.8469 19.2242 26.1651 19.2242 25.3241C19.2242 24.4831 18.2113 23.8013 16.9618 23.8013C15.7123 23.8013 14.6993 24.4831 14.6993 25.3241C14.6993 26.1651 15.7123 26.8469 16.9618 26.8469Z"
          fill="#6C65D9"
        />
        <path
          d="M17.9632 26.9591C17.6389 27.0558 17.3023 27.1045 16.9641 27.1038C15.5525 27.1038 14.4451 26.3243 14.4451 25.3266C14.4451 24.3289 15.5491 23.5466 16.9603 23.545C17.5755 23.5331 18.1808 23.7024 18.7019 24.0322C18.9359 24.1551 19.1321 24.3402 19.2691 24.5676C19.406 24.7949 19.4786 25.0557 19.4788 25.3216C19.479 25.5876 19.407 25.8485 19.2704 26.0761C19.1338 26.3037 18.938 26.4892 18.7042 26.6125C18.4756 26.7643 18.2259 26.8812 17.9632 26.9591ZM16.1155 24.1799C15.4357 24.385 14.9538 24.8278 14.954 25.3253C14.954 26.0117 15.8743 26.5909 16.9641 26.5899C17.4788 26.6008 17.9856 26.4604 18.4225 26.1858C18.5864 26.1097 18.7251 25.9879 18.8223 25.8348C18.9195 25.6817 18.9711 25.5036 18.971 25.3218C18.9708 25.14 18.9189 24.9621 18.8214 24.8092C18.7239 24.6562 18.585 24.5346 18.421 24.4589C17.9838 24.1848 17.4768 24.0453 16.962 24.0573C16.6755 24.0566 16.3903 24.0979 16.1155 24.1799Z"
          fill="#606479"
        />
        <path
          d="M17.5167 26.8004L17.4633 23.8388L16.7325 23.8091L16.6814 26.835L17.5167 26.8004Z"
          fill="#606479"
        />
        <path
          d="M13.9602 25.9683C14.0892 25.9683 14.1938 25.8629 14.1938 25.7329C14.1938 25.6029 14.0892 25.4976 13.9602 25.4976C13.8312 25.4976 13.7266 25.6029 13.7266 25.7329C13.7266 25.8629 13.8312 25.9683 13.9602 25.9683Z"
          fill="#606479"
        />
        <path
          d="M14.1426 24.7091C14.2716 24.7091 14.3762 24.6037 14.3762 24.4737C14.3762 24.3437 14.2716 24.2383 14.1426 24.2383C14.0136 24.2383 13.909 24.3437 13.909 24.4737C13.909 24.6037 14.0136 24.7091 14.1426 24.7091Z"
          fill="#606479"
        />
        <path
          d="M89.7208 34.0429C89.6664 34.0429 89.6134 34.0253 89.5696 33.9927C89.5258 33.96 89.4935 33.9141 89.4776 33.8616C88.6336 30.6857 88.4085 27.375 88.8145 24.1126C89.4722 19.3434 92.0823 13.0841 100.781 10.3433C102.005 9.94678 103.26 9.65675 104.533 9.47642C104.943 6.89667 106.502 4.09819 109.989 2.16741C118.274 -2.4203 123.871 1.69587 123.926 1.73793C123.98 1.77873 124.016 1.83947 124.025 1.90679C124.035 1.97411 124.017 2.0425 123.977 2.0969C123.936 2.1513 123.876 2.18727 123.809 2.19689C123.743 2.20651 123.675 2.18899 123.621 2.14818C123.568 2.10818 118.22 -1.80491 110.235 2.61716C106.976 4.42153 105.485 7.01128 105.061 9.41539C108.897 9.03462 110.735 10.3244 111.559 11.2985C112.069 11.8843 112.397 12.6084 112.502 13.3806C112.607 14.1529 112.484 14.9391 112.148 15.6413C111.883 16.2089 111.453 16.6821 110.915 16.9984C110.378 17.3147 109.757 17.4592 109.137 17.4129C106.994 17.2862 105.221 15.4698 104.62 12.7852C104.422 11.8764 104.369 10.9415 104.464 10.0159C103.266 10.1893 102.085 10.463 100.933 10.8341C94.1534 12.9703 90.2465 17.4623 89.3183 24.1847C88.9207 27.373 89.14 30.6087 89.9641 33.7129C89.9757 33.7513 89.9783 33.7918 89.9716 33.8314C89.9649 33.8709 89.9491 33.9083 89.9254 33.9405C89.9017 33.9727 89.8709 33.9989 89.8354 34.017C89.7999 34.0351 89.7606 34.0445 89.7208 34.0445V34.0429ZM104.982 9.95181C104.876 10.8597 104.922 11.7792 105.116 12.6721C105.666 15.128 107.256 16.7877 109.166 16.9008C109.687 16.9384 110.207 16.8165 110.658 16.5511C111.109 16.2857 111.469 15.8892 111.692 15.4136C111.983 14.8013 112.089 14.1162 111.996 13.4437C111.904 12.7713 111.617 12.1411 111.171 11.6318C109.982 10.2249 107.811 9.65668 104.982 9.95181Z"
          fill="#1B1D28"
        />
        <path
          d="M129.853 4.32957C129.51 4.32884 129.175 4.23048 128.885 4.04582C128.596 3.86117 128.364 3.59775 128.216 3.28597C128.069 2.9742 128.012 2.62677 128.052 2.28379C128.092 1.94081 128.228 1.61626 128.443 1.3476C128.658 1.07893 128.945 0.877105 129.269 0.765412C129.593 0.653718 129.942 0.636709 130.276 0.716348C130.609 0.795988 130.914 0.969029 131.154 1.2155C131.394 1.46197 131.56 1.77183 131.632 2.1093C131.689 2.37682 131.686 2.65381 131.623 2.91998C131.561 3.18615 131.439 3.43477 131.269 3.64763C131.098 3.86049 130.882 4.0322 130.637 4.1502C130.393 4.26819 130.124 4.32948 129.853 4.32957ZM129.857 1.17981C129.586 1.1798 129.322 1.26439 129.101 1.4219C128.88 1.57941 128.713 1.8021 128.623 2.05928C128.533 2.31645 128.524 2.59545 128.598 2.85782C128.672 3.12019 128.825 3.35301 129.036 3.5242C129.246 3.69538 129.504 3.79651 129.775 3.81363C130.045 3.83076 130.313 3.76304 130.544 3.61981C130.774 3.47658 130.955 3.26489 131.06 3.01392C131.166 2.76295 131.192 2.48506 131.135 2.21853C131.073 1.92365 130.912 1.65934 130.679 1.47013C130.446 1.28092 130.156 1.17839 129.857 1.17981Z"
          fill="#606479"
        />
        <path
          d="M129.087 8.51464C128.62 8.5146 128.172 8.3344 127.833 8.01112C127.495 7.68784 127.292 7.24609 127.267 6.77681C127.242 6.30753 127.397 5.84644 127.699 5.48845C128.001 5.13046 128.428 4.90282 128.891 4.8524C129.355 4.80198 129.82 4.93261 130.191 5.2174C130.562 5.50219 130.811 5.91946 130.886 6.38332C130.96 6.84718 130.856 7.32232 130.594 7.71093C130.332 8.09953 129.931 8.37203 129.476 8.47233C129.348 8.50045 129.218 8.51463 129.087 8.51464ZM129.093 5.36462C128.999 5.36467 128.905 5.3749 128.813 5.39514C128.488 5.46707 128.203 5.66181 128.016 5.93927C127.829 6.21674 127.755 6.55584 127.809 6.88679C127.862 7.21774 128.04 7.51538 128.305 7.71847C128.569 7.92155 128.901 8.01464 129.232 7.97857C129.563 7.9425 129.868 7.78003 130.083 7.52457C130.299 7.26911 130.409 6.94011 130.391 6.60524C130.373 6.27038 130.229 5.95513 129.987 5.72436C129.746 5.49359 129.426 5.36485 129.093 5.36462Z"
          fill="#606479"
        />
        <path
          d="M131.819 5.07853C131.98 4.20271 131.055 3.29657 129.754 3.0546C128.453 2.81263 127.268 3.32647 127.107 4.20229C126.947 5.0781 127.872 5.98424 129.173 6.22621C130.474 6.46817 131.659 5.95434 131.819 5.07853Z"
          fill="#6C65D9"
        />
        <path
          d="M129.814 6.54304C129.583 6.54266 129.353 6.5212 129.127 6.47893C128.487 6.37195 127.892 6.07912 127.415 5.63636C127.206 5.45678 127.045 5.22702 126.948 4.96834C126.85 4.70965 126.819 4.43039 126.857 4.15636C126.919 3.88672 127.047 3.63707 127.229 3.43048C127.412 3.22388 127.643 3.06699 127.902 2.9743C128.507 2.74394 129.164 2.68471 129.8 2.80303C130.436 2.92134 131.028 3.2127 131.512 3.64559C131.721 3.82517 131.881 4.05493 131.979 4.31361C132.076 4.5723 132.107 4.85156 132.069 5.12559C132.008 5.39523 131.88 5.64488 131.697 5.85148C131.514 6.05808 131.283 6.21496 131.024 6.30765C130.641 6.46765 130.229 6.54772 129.814 6.54304ZM129.111 3.25123C128.765 3.2468 128.423 3.31282 128.103 3.44533C127.922 3.50632 127.759 3.61179 127.629 3.75219C127.499 3.8926 127.406 4.0635 127.358 4.24944C127.337 4.44043 127.363 4.63365 127.435 4.81162C127.507 4.98959 127.622 5.14668 127.769 5.26867C128.181 5.63067 128.682 5.87441 129.219 5.97434C129.756 6.07427 130.31 6.0267 130.823 5.83662C131.003 5.77563 131.166 5.67016 131.296 5.52976C131.427 5.38935 131.52 5.21845 131.568 5.03252C131.589 4.84152 131.562 4.64825 131.491 4.47025C131.419 4.29226 131.304 4.13518 131.156 4.01328C130.751 3.64091 130.247 3.39561 129.706 3.30738C129.51 3.2706 129.311 3.25181 129.112 3.25123H129.111Z"
          fill="#606479"
        />
        <path
          d="M129.17 2.99561L128.658 6.08946L129.413 6.2628L130.046 3.12202L129.17 2.99561Z"
          fill="#606479"
        />
        <path
          d="M132.237 6.32415C132.373 6.32415 132.484 6.21256 132.484 6.07492C132.484 5.93727 132.373 5.82568 132.237 5.82568C132.1 5.82568 131.989 5.93727 131.989 6.07492C131.989 6.21256 132.1 6.32415 132.237 6.32415Z"
          fill="#606479"
        />
        <path
          d="M132.668 5.04827C132.804 5.04827 132.915 4.93668 132.915 4.79904C132.915 4.66139 132.804 4.5498 132.668 4.5498C132.531 4.5498 132.42 4.66139 132.42 4.79904C132.42 4.93668 132.531 5.04827 132.668 5.04827Z"
          fill="#606479"
        />
      </g>
      <defs>
        <clipPath id="clip0_7025_12175">
          <rect width="142" height="122" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
