import React, { useState } from "react";
//UI
import { ChevronDown } from "react-feather";
import { MenuItem, FormControl, Select, Divider } from "@material-ui/core";
import useStyles from "./NotificationProjectFilter.styles";

function NotificationDurationFilter({ period, handlePeriod }) {
  const classes = useStyles();
  const filter = [
    { name: "Today", value: "today" },
    { name: "Yesterday", value: "yesterday" },
    { name: "This Week", value: "weekly" },
    { name: "This Month", value: "monthly" },
  ];
  const [periodName, setPeriodName] = useState("None");

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <div className={classes.dropdownDiv}>
        <text className={classes.selectText}>{periodName}</text>
        <ChevronDown className={classes.iconStyle} />
      </div>
      <Select
        style={{ height: "0%", opacity: "0" }}
        className={classes.formLabelText}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Select Status"
        defaultValue="none"
        MenuProps={{
          classes: {
            paper: classes.durationDropDown,
          },
        }}
        value={period}
        onChange={(event) => {
          setPeriodName(event.target.value[1]);
          handlePeriod(event.target.value[0]);
        }}
      >
        <MenuItem value={["", "None"]} className={classes.menuItem}>
          <div>None</div>
          <Divider className={classes.divider} />
        </MenuItem>
        {filter.map((eachFilter, i) => (
          <MenuItem
            value={[eachFilter.value, eachFilter.name]}
            key={i}
            className={classes.menuItem}
          >
            <div>{eachFilter.name}</div>
            {i !== 3 && <Divider className={classes.divider} />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default NotificationDurationFilter;
