import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "220px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    cursor: "pointer",
    /* "&:hover": {
      backgroundColor: "gray",
    }, */
  },
  cardContent: {
    padding: "9px",
  },
  dots: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
    textAlign: "center",
  },
  flexCenterFile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    textAlign: "center",
  },
  btnStyle: {
    minWidth: "0px",
    minHeight: "0px",
    padding: "0px 2px",
  },
  cardText: {
    fontSize: "12px",
    color: theme.palette.text.tertiary,
  },
  flexCenterModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  name: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
}));

export default useStyles;
