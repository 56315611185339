import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Popover,
  Radio,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DotsThreeOutline, File } from "phosphor-react";
import ProjectMediaFileTools from "../ProjectMediaModals/ProjectMediaToolsModal/ProjectMediaFileTools";
import useStyles from "./ProjectMediaCard.styles";

const ProjectMediaFile = ({
  eachFile,
  handleOpenEditMediaModal,
  handleOpenDeleteMediaModal,
  selectedMedias,
  handleSelectUnselectMedia,
  getFileUrl,
}) => {
  const classes = useStyles();
  const [fileToolsModal, setFileTollsModal] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleOpenFileToolsModal = (event) => {
    setFileTollsModal(event.currentTarget);
  };

  const handleCloseFileToolsModal = () => {
    setFileTollsModal(null);
  };

  useEffect(() => {
    setChecked(selectedMedias.some((single) => single === eachFile.id));
  }, [eachFile, selectedMedias]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
      <Card
        className={classes.card}
        style={{ border: checked && "1px solid #6C65D9" }}
      >
        <CardContent className={classes.cardContent}>
          <Box className={classes.dots}>
            <Radio
              color={checked ? `secondary` : "default"}
              style={{
                color: !checked && "gray",
                height: "20px",
                width: "20px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectUnselectMedia(eachFile.id);
              }}
              checked={checked}
            />
            <Button
              className={classes.btnStyle}
              onClick={handleOpenFileToolsModal}
            >
              <DotsThreeOutline weight="fill" width={20} height={20} />
            </Button>
          </Box>
          <Box className={classes.flexCenterFile}>
            <File weight="fill" size={60} color="#6C65D9" />
            {/* <Cube weight="fill" size={60} color="#6C65D9" /> */}
            {/* <VideoCamera weight="fill" size={60} color="#6C65D9"/> */}
            {/* <ImageIcon /> */}
            <Tooltip title={eachFile.file_name}>
              <Typography variant="h5" className={classes.name}>
                {eachFile.file_name}
              </Typography>
            </Tooltip>
            <Typography className={classes.cardText}>
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(eachFile?.updated_at))}
            </Typography>
            {/* <Typography className={classes.cardText}>2MB</Typography> */}
            <Typography className={classes.cardText}>
              {eachFile.created_by?.first_name} {eachFile.created_by?.last_name}
            </Typography>
          </Box>
        </CardContent>
        <Popover
          open={fileToolsModal !== null}
          anchorEl={fileToolsModal}
          onClose={handleCloseFileToolsModal}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ProjectMediaFileTools
            handleCloseFileToolsModal={handleCloseFileToolsModal}
            handleOpenEditMediaModal={() => handleOpenEditMediaModal(eachFile)}
            handleOpenDeleteMediaModal={() =>
              handleOpenDeleteMediaModal(eachFile)
            }
            handleDownload={() => getFileUrl(eachFile.id, "download")}
            handleCopy={() => getFileUrl(eachFile.id, "copy")}
          />
        </Popover>
      </Card>
    </Grid>
  );
};

export default ProjectMediaFile;
