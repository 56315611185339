import {
  Button,
  Card,
  Checkbox,
  Divider,
  Modal,
  Tooltip,
} from "@material-ui/core";
import {
  ArrowBendDownRight,
  Clock,
  PaintBrush,
  PaperPlaneTilt,
  X,
} from "phosphor-react";
import React, { useState } from "react";
import { CornerDownLeft, Trash2 } from "react-feather";
import { fdelete, fpost } from "../../../../API/callsAPI";
import PostEditor from "../../../PostEditor/postEditor";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import SkeletonDiv from "../../VersionViewer/Comments/SkeletonDiv";
import useStyles from "./ReviewVersionCommentOverview.styles";
import DateAndTimeViewer from "../../DateAndTimeViewer/DateAndTimeViewer.component";

export default function ReviewVersionCommentOverview({
  loggedInUser,
  selectedVersion,
  fetchComments,
  commentList,
  handleOpenCanvas,
  changeSeekTime,
  allUsersName,
  currentTime,
  showTimeStamp,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [repliedTo, setRepliedTo] = useState({});
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [isTimeStampChecked, setIsTimeStampChecked] = useState(true);

  let mentionsRef = React.createRef();

  const deleteComment = (comment) => {
    setSelectedCommentId(comment.id);
    setDeleteCommentModalOpen(true);
  };

  const handleCommentSubmit = async (comment, mentions, replied_to) => {
    const dataObj = {
      url: `review-version/${selectedVersion.id}/comments/`,
      data: {
        text:
          isTimeStampChecked &&
          currentTime !== "00:00" &&
          currentTime > 0 &&
          showTimeStamp
            ? currentTime + comment
            : comment,
      },
    };
    if (mentions) dataObj.data.mentions_to = mentions;
    if (replied_to) dataObj.data.replied_to = replied_to?.id;
    try {
      const commentRes = await fpost(dataObj);
      if (commentRes.status === 200 || 201) {
        fetchComments();
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject();
    }
  };

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  let changeColor;
  const scrollTo = (comment) => {
    if (changeColor !== null) clearTimeout(changeColor);
    const scrollItem = document.getElementById(comment.replied_to?.id);
    if (scrollItem) {
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
      if (scrollItem?.style) {
        scrollItem.style.backgroundColor = "#252A38";
      }
    }
    changeColor = setTimeout(() => {
      if (scrollItem?.style) {
        scrollItem.style.backgroundColor = "#1B1D28";
      }
    }, 5000);
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `review/comments/${selectedCommentId}/`,
      });
      fetchComments();
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  function formatTime(seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div
        style={{
          height: "calc(100% - 93px)",
          overflowY: "scroll",
          marginBottom: "12px",
        }}
      >
        {/* single comment */}
        {commentList.loading ? (
          <div style={{ margin: "15px 5px" }}>
            {/* calling this component 3 times to fill up comments area */}
            <SkeletonDiv />
            <SkeletonDiv />
            <SkeletonDiv />
          </div>
        ) : (
          commentList?.data?.map((eachComment) => (
            <SingleComment
              eachComment={eachComment}
              changeSeekTime={changeSeekTime}
              loggedInUser={loggedInUser}
              setRepliedTo={setRepliedTo}
              setShowReplyBox={setShowReplyBox}
              deleteComment={deleteComment}
              setOpen={setOpen}
              setDisplayImage={setDisplayImage}
            />
          ))
        )}
      </div>
      {showReplyBox ? (
        <div style={{ position: "relative" }} onKeyDown={handleKeyDown}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.replyCommentField}>
              <Card
                className={classes.replyCard}
                onClick={() => {
                  scrollTo({ replied_to: repliedTo });
                }}
              >
                <div></div>
                {repliedTo.attachment ? (
                  <img
                    // className={classes.AssetImage}
                    onClick={() => {
                      setOpen(true);
                      setDisplayImage(repliedTo.attachment);
                    }}
                    alt="projectImage"
                    src={repliedTo?.attachment}
                    height="50px"
                    width="50px"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : null}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "92%",
                    height: "44px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: repliedTo?.text
                      ?.replace(/<p>/g, "<div>")
                      ?.replace(/<\/p>/g, "</div>")
                      ?.replace("0:0", ""),
                  }}
                ></span>
                <div
                  onClick={() => {
                    setShowReplyBox(false);
                  }}
                >
                  <X size={20} />
                </div>
              </Card>
              <div style={{ display: "flex", alignItems: "center" }}>
                <UserAvatar
                  avatar={loggedInUser?.avatar}
                  firstName={loggedInUser?.first_name}
                  lastName={loggedInUser?.last_name}
                />
                <PostEditor
                  placeholder={"Type a Comment..."}
                  ref={mentionsRef}
                  mentions={allUsersName}
                  handleSubmit={() => {
                    handleCommentSubmit(
                      mentionsRef.current.toHtml(),
                      mentionsRef.current.onExtractMentions(),
                      repliedTo
                    ).then(() => {
                      mentionsRef.current.reset();
                      setShowReplyBox(false);
                    });
                  }}
                />
                <div>
                  <Button
                    style={{ minWidth: "0px", padding: "5px" }}
                    onClick={handleOpenCanvas}
                    id="annotation-button"
                  >
                    <PaintBrush size={23} />
                  </Button>
                </div>
              </div>
            </div>
            <Button
              className={classes.submitButton}
              onClick={() => {
                handleCommentSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions(),
                  repliedTo
                ).then(() => {
                  mentionsRef.current.reset();
                  setShowReplyBox(false);
                });
              }}
            >
              <PaperPlaneTilt size={22} />
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onKeyDown={handleKeyDown}
        >
          <div className={classes.submitCommentField}>
            <UserAvatar
              avatar={loggedInUser?.avatar}
              firstName={loggedInUser?.first_name}
              lastName={loggedInUser?.last_name}
            />
            <div style={{ width: "100%" }}>
              {showTimeStamp ? (
                <div className={classes.timeStampDiv}>
                  <Clock />
                  <span>
                    {formatTime(currentTime !== "00:00" ? currentTime : 0)}
                  </span>
                  <Checkbox
                    checked={isTimeStampChecked}
                    size="small"
                    onClick={() => setIsTimeStampChecked(!isTimeStampChecked)}
                    style={{
                      padding: "0px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div>
                <PostEditor
                  placeholder={"Type a Comment..."}
                  ref={mentionsRef}
                  mentions={allUsersName}
                  handleSubmit={() => {
                    handleCommentSubmit(
                      mentionsRef.current.toHtml(),
                      mentionsRef.current.onExtractMentions()
                    )
                      .then(() => {
                        mentionsRef.current.reset();
                      })
                      .catch(() => {
                        mentionsRef.current.reset();
                      });
                  }}
                />
              </div>
            </div>
            <div>
              <Button
                style={{ minWidth: "0px", padding: "10px" }}
                onClick={handleOpenCanvas}
              >
                <PaintBrush size={23} />
              </Button>
            </div>
          </div>
          <Button
            className={classes.submitButton}
            onClick={() => {
              handleCommentSubmit(
                mentionsRef.current.toHtml(),
                mentionsRef.current.onExtractMentions()
              )
                .then(() => {
                  mentionsRef.current.reset();
                })
                .catch(() => {
                  mentionsRef.current.reset();
                });
            }}
          >
            <PaperPlaneTilt size={22} />
          </Button>
        </div>
      )}
      <div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={classes.flexCenter}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              outline: "none",
              height: "80vh",
            }}
          >
            <X
              size={30}
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
            <img
              src={displayImage}
              height="90%"
              onContextMenu={(e) => e.preventDefault()}
              alt="from annotate"
            ></img>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}

const SingleComment = ({
  eachComment,
  changeSeekTime,
  loggedInUser,
  setRepliedTo,
  setShowReplyBox,
  deleteComment,
  setOpen,
  setDisplayImage,
}) => {
  const classes = useStyles();
  const [showReplies, setShowReplies] = useState(false);

  const getDisplayTime = (time) => {
    if (time.includes(":")) {
      return time;
    }
    let seconds = 0;
    let minutes = 0;
    if (time % 60) {
      minutes = parseInt(time / 60);
      seconds = parseInt(time % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };
  return (
    <div>
      <div
        id={eachComment.id}
        key={eachComment.id}
        style={{
          marginTop: "8px",
        }}
      >
        <div className={classes.commentHead}>
          {/* user not an object yet! */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserAvatar
              avatar={eachComment?.user?.avatar}
              firstName={eachComment?.user?.first_name}
              lastName={eachComment?.user?.last_name}
            />
            <span style={{ margin: "0px 8px" }}>
              {eachComment?.user?.first_name} {eachComment?.user?.last_name}
            </span>
          </div>
          <span
            className={classes.time}
            style={{
              justifySelf: "flex-end",
            }}
          >
            <DateAndTimeViewer dateAndTime={eachComment?.date_added} />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "40px",
            justifyContent: "space-between",
          }}
        >
          {/* add show more on big comments */}
          <div style={{ display: "flow", alignItems: "start", gap: "4px" }}>
            {eachComment?.text?.split("<p>")[0] && (
              <span>
                <span
                  style={{
                    // marginLeft: "40px",
                    cursor: "pointer",
                    color: "#008cdb",
                  }}
                  onClick={(e) =>
                    changeSeekTime(eachComment?.text?.split("<p>")[0])
                  }
                >
                  {getDisplayTime(eachComment?.text?.split("<p>")[0])}
                  {"   "}
                </span>
              </span>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: eachComment?.text?.split("<p>")[1]?.split("</p>")[0],
              }}
            />
          </div>
        </div>

        {eachComment?.attachment ? (
          <img
            // className={classes.AssetImage}
            style={{
              marginLeft: "40px",
              borderRadius: "10px",
              cursor: "pointer",
              marginTop: "6px",
            }}
            onClick={() => {
              setOpen(true);
              setDisplayImage(eachComment.attachment);
            }}
            alt="projectImage"
            src={eachComment.attachment}
            height="190px"
            width="358px"
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : null}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "40px",
          }}
        >
          <div>
            <Button
              onClick={() => setShowReplies(!showReplies)}
              className={classes.showHideButton}
              disabled={eachComment?.replies?.length ? false : true}
            >
              {eachComment?.replies?.length ? (
                <>
                  {showReplies
                    ? "Hide Replies"
                    : `Show ${eachComment?.replies?.length} Replies`}
                </>
              ) : (
                "No Replies"
              )}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginRight: "10px",
            }}
          >
            <Tooltip title="Reply" placement="top-start">
              <Button
                style={{
                  padding: "0",
                  margin: "0",
                  minWidth: "25px",
                }}
                onClick={() => {
                  setRepliedTo(eachComment);
                  setShowReplyBox(true);
                }}
              >
                <CornerDownLeft style={{ hight: "16px", width: "16px" }} />
              </Button>
            </Tooltip>
            {/* change to user's id when updated from backend */}
            {eachComment.user?.id == loggedInUser?.id ? (
              <Tooltip title="Delete Comment" placement="top-start">
                <Button
                  style={{
                    padding: "0",
                    margin: "0",
                    minWidth: "25px",
                  }}
                  onClick={() => deleteComment(eachComment)}
                >
                  <Trash2 style={{ hight: "16px", width: "16px" }} />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Divider />
      <div
        style={{
          marginLeft: "20px",
          maxHeight: showReplies ? "300px" : "0px",
          overflowY: "auto",
          marginRight: "6px",
          transition: "max-height 0.5s ease-in-out",
        }}
      >
        {eachComment?.replies?.map((single) => (
          <div
            id={single.id}
            key={single.id}
            style={{
              marginTop: "8px",
            }}
          >
            <div className={classes.commentHead}>
              {/* user not an object yet! */}
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <ArrowBendDownRight size={20} className={classes.colorText} />
                <UserAvatar
                  avatar={single?.user?.avatar}
                  firstName={single?.user?.first_name}
                  lastName={single?.user?.last_name}
                />
                <span style={{ margin: "0px 2px" }}>
                  {single?.user?.first_name} {single?.user?.last_name}
                </span>
              </div>
              <span
                className={classes.time}
                style={{
                  justifySelf: "flex-end",
                }}
              >
                <DateAndTimeViewer dateAndTime={single?.date_added} />
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "40px",
                justifyContent: "space-between",
              }}
            >
              {/* add show more on big comments */}
              <div style={{ display: "flow", alignItems: "start", gap: "4px" }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: single?.text?.split("<p>")[1]?.split("</p>")[0],
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginRight: "10px",
                }}
              >
                {/* change to user's id when updated from backend */}
                {single.user?.id == loggedInUser?.id ? (
                  <Tooltip title="Delete Comment" placement="top-start">
                    <Button
                      style={{
                        padding: "0",
                        margin: "0",
                        minWidth: "25px",
                      }}
                      onClick={() => deleteComment(single)}
                    >
                      <Trash2 style={{ hight: "16px", width: "16px" }} />
                    </Button>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};
