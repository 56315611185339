import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rowCell: {
    padding: "5px",
  },
  flexGap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarWithName: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    padding: "1px 7px 1px 0px",
  },
  episodesPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
