import MomentUtils from "@date-io/moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { X } from "phosphor-react";
import React, { useState } from "react";
import { fpost } from "../../../../API/callsAPI";
import useStyles from "./UpdateDeadlineModal.style";

const UpdateDeadlineModal = ({
  handleClose,
  timeline,
  updateRefreshCounter,
  setSnackValue,
}) => {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [note, setNote] = useState("");

  const handleUpdateDeadline = async () => {
    try {
      const data = {
        url: `trackables/task-update-date/`,
        data: {
          id: timeline?.id,
          end_date: selectedStartDate,
          note: note,
        },
      };
      const res = await fpost(data);
      if (res.status) {
        setSnackValue({
          isOpen: true,
          message: `SuccessFully updated deadline`,
          isError: false,
        });
        updateRefreshCounter();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: "Error On update Deadline",
        isError: true,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardTool}>
            <Typography className={classes.title}>
              Update deadline modal
            </Typography>
            <Button onClick={handleClose}>
              <X size={20} />
            </Button>
          </Box>
          <Typography className={classes.name}>{timeline?.name}</Typography>
          <Typography className={classes.textPurple}>
            Current Deadline : {new Date().toDateString(timeline?.end_time)}
          </Typography>
          <div>
            <span className={classes.inputLabel}>New Deadline</span>
            <div className={classes.datePickerDiv}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  invalidDateMessage={"inValid"}
                  variant="inline"
                  format="yyyy/MM/DD"
                  margin="normal"
                  name="startDate"
                  value={selectedStartDate}
                  onChange={(date) => setSelectedStartDate(new Date(date))}
                  style={{ marginTop: "5px", height: "30px" }}
                  disablePast
                  InputProps={{
                    disableUnderline: true,
                  }}
                  id="Startdate-picker"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    style: {
                      padding: "0px",
                    },
                  }}
                  className={classes.cardContentInput1}
                  size="small"
                  fullWidth={true}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.cardContentDiv4}>
            <span className={classes.inputLabel}>Reason</span>
            <div>
              <TextField
                type="text"
                id="desc"
                placeholder="Enter a reason for change"
                className={classes.descriptionInput}
                style={{ height: "70px", overflow: "auto" }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontFamily: "Poppins",
                  },
                }}
                name="description"
                onChange={(e) => setNote(e.target.value)}
                value={note}
                multiline
              />
              {/*  {formik.errors.description && formik.touched.description ? (
                  <HelperText text={formik.errors.description} />
                ) : null} */}
            </div>
          </div>
          <div className={classes.doneButtonContainer}>
            <Button
              className={classes.doneButton}
              onClick={() => handleUpdateDeadline()}
              disabled={selectedStartDate && note ? false : true}
            >
              Extend Deadline
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UpdateDeadlineModal;
