import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  nextPageLoader: {
    width: "43%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    width: "100%",
  },
  notSelectedTab: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    borderRadius: "10px",
    borderBottom: "none",
  },
  selectedTab: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    borderBottom: "none",
  },
}));

export default useStyles;
