import React, { useState, useEffect } from "react";
import { fget, fpost, fdelete } from "../../../API/callsAPI";
import StatusList from "./StatusList/StatusList";
import StatusSettingTools from "./StatusSettingTools/StatusSettingTools";
import useStyles from "./StatusSettingOverview.style";
import { Modal } from "@material-ui/core";
import ImportStatusModal from "./StatusSettingsModals/ImportStatusFromProject/ImportStatusFromProject";
import ImportStatusConfirmModal from "./StatusSettingsModals/ImportStatusConfrimModal/ImportStatusConfirmModal";
import CreateNewStatusModal from "../../Utilities/NewStatus/CreateNewStatusModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import EditStatusModal from "../../Utilities/EditStatus/EditStatusModal";
import ViewImportedStatus from "./StatusSettingsModals/ViewImportedStatus/ViewImportedStatus.jsx";
export default function StatusSettingOverview({ projectId, setSnackValue }) {
  const [selectedProject, setSelectedProject] = useState("");
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedStatus, setselectedStatus] = useState({});
  const [selectedTrackable, setSelectedTrackable] = useState({});
  const [allProjects, setAllProjects] = useState([]);
  const [trackableClass, setTrackableClass] = useState([]);
  const [importedStatus, setImportedStatus] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);
  const [isOpenImportStatusModal, setOpenImportStatusModal] = useState(false);
  const [isOpenImportConfirmModal, setOpenImportConfirmModal] = useState(false);
  const [isOpenimportedStatusModal, setImportedStatusModal] = useState(false);
  const [isDeleteStatusModalOpen, setDeleteStatusModalOpen] = useState(false);
  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [isAddStatusModalOpen, setAddStatusModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    fetchStatus();
  }, [refreshCounter]);

  useEffect(() => {
    fetchTrackableClass();
    fetchProjects();
  }, []);

  useEffect(() => {
    const data_filter = allStatus.filter((el) => {
      return el.status_class.some((val) => {
        return val === selectedTrackable?.id;
      });
    });
    setProjectStatus(data_filter);
  }, [selectedTrackable]);

  const filterByTrackable = (value) => {
    setSelectedTrackable(value);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  function handleImportedStatusModal() {
    importStatusFromProject();
    setImportedStatusModal(!isOpenimportedStatusModal);
    setOpenImportStatusModal(!setOpenImportStatusModal);
    setOpenImportConfirmModal(!isOpenImportConfirmModal);
  }

  function handleImportStatusModal() {
    setOpenImportStatusModal(!isOpenImportStatusModal);
  }

  function handleImportConfrimModal(e, el) {
    setSelectedProject(el);
    setOpenImportConfirmModal(!isOpenImportConfirmModal);
  }

  function handleAddStatusModal() {
    setAddStatusModalOpen(!isAddStatusModalOpen);
  }

  function handleDeleteStatusModal(e, status) {
    setselectedStatus(status);
    setDeleteStatusModalOpen(!isDeleteStatusModalOpen);
  }

  function handleEditStatusModal(e, status) {
    setselectedStatus(status);
    setEditStatus(true);
    setEditStatusModalOpen(!isEditStatusModalOpen);
  }

  //fetch all status of specific project
  const fetchStatus = async () => {
    try {
      const res = await fget({
        url: `trackables/status/?project=${projectId}`,
      });
      setAllStatus(res.data.results);
      setProjectStatus(res.data.results);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  //to import status from selected project
  const importStatusFromProject = async () => {
    try {
      const res = await fpost({
        url: `import/project/`,
        data: {
          project_id: projectId,
          import_project_id: selectedProject.id,
          import_components: ["Status"],
        },
      });

      if (res.status === 200 || 201) {
        setImportedStatus(res.data);
        setSnackValue({
          isOpen: true,
          message: `Status Imported SuccessFully.`,
          isError: false,
        });
        updateRefreshCounter();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  //delete selected status
  const deleteStatus = async () => {
    try {
      await fdelete({
        url: `trackables/status/${selectedStatus.id}`,
      });
      setSnackValue({
        isOpen: true,
        message: `Status Deleted SuccessFully.`,
        isError: false,
      });
      updateRefreshCounter();
      setDeleteStatusModalOpen(false);
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const fetchTrackableClass = async () => {
    try {
      const res = await fget({ url: "trackables/trackable-classes/" });
      setTrackableClass(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  //fetch project list
  const fetchProjects = async () => {
    try {
      const res = await fget({
        url: `project/`,
      });
      setAllProjects(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <StatusSettingTools
          filterByTrackable={filterByTrackable}
          selectedTrackable={selectedTrackable}
          handleAddStatusModal={handleAddStatusModal}
          handleStatusModal={handleImportStatusModal}
        />
      </div>
      <div>
        <StatusList
          selectedTrackable={selectedTrackable}
          projectStatus={projectStatus}
          trackableClass={trackableClass}
          handleDeleteStatusModal={handleDeleteStatusModal}
          handleEditStatusModal={handleEditStatusModal}
        />
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isOpenImportStatusModal}
          onClose={() => setOpenImportStatusModal(false)}
        >
          <ImportStatusModal
            allProjects={allProjects}
            handleImportStatusModal={handleImportStatusModal}
            handleConfrimModal={handleImportConfrimModal}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isOpenImportConfirmModal}
          onClose={() => setOpenImportConfirmModal(false)}
        >
          <ImportStatusConfirmModal
            selectedProject={selectedProject}
            handleImportedModal={handleImportedStatusModal}
            handleConfrimModal={handleImportConfrimModal}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isOpenimportedStatusModal}
          onClose={() => setImportedStatusModal(false)}
        >
          <ViewImportedStatus
            importedStatus={importedStatus}
            closeImportedStatusModal={() => setImportedStatusModal(false)}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isAddStatusModalOpen}
          onClose={() => setAddStatusModalOpen(false)}
        >
          <CreateNewStatusModal
            projectId={projectId}
            updateRefreshCounter={updateRefreshCounter}
            closeCreateNewStatusModal={() => setAddStatusModalOpen(false)}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isDeleteStatusModalOpen}
          onClose={() => setDeleteStatusModalOpen(false)}
        >
          <DeleteModal
            handleCloseDeleteModal={handleDeleteStatusModal}
            handleDelete={deleteStatus}
            type={"Status"}
            name={selectedStatus?.name}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.settingsPageModals}
          open={isEditStatusModalOpen}
          onClose={() => setEditStatusModalOpen(false)}
        >
          <EditStatusModal
            closeCreateNewStatusModal={() => setEditStatusModalOpen(false)}
            projectId={projectId}
            edit={editStatus}
            updateRefreshCounter={updateRefreshCounter}
            editStatus={selectedStatus}
          />
        </Modal>
      </div>
    </>
  );
}
