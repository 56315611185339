import { Button, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { MoreHorizontal, Trash2, X } from "react-feather";
import useStyles from "./ModalTitleAndButtons.styles.js";

const ModalTitleAndButtons = ({ name, type, closeModal, openDeleteModal }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <div className={classes.displayFlex}>
        <div>
          <span className={classes.title}>{type + "  " + name}</span>
        </div>
        <div>
          {type?.toLowerCase() === "edit" &&
          name !== "Project" &&
          name !== "Asset" ? (
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreHorizontal className={classes.closeIcon} />
            </Button>
          ) : name === "Asset" && type !== "New" ? (
            <Button onClick={() => openDeleteModal()}>
              <Trash2 size={20} className={classes.deleteIcon} />
            </Button>
          ) : (
            ""
          )}
          <Button onClick={closeModal}>
            <X size={25} className={classes.closeIcon} />
          </Button>
        </div>
      </div>
      <div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
          MenuListProps={{ className: `${classes.menuPop}` }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setAnchorEl(null);
              openDeleteModal();
            }}
          >
            <Trash2 size={25} className={classes.deleteIcon} />
            <span>Delete {name}</span>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default ModalTitleAndButtons;
