import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRight: "0",
    borderRadius: "10px",
    height: "89vh",
    margin: "15px",
    marginTop: "90px",
    position: "fixed",
    backgroundColor: "#252A38",
    padding: "12px",
    width: "200px",
    [theme.breakpoints.down("lg")]: {
      height: "85vh",
    },
  },
  heading: {
    fontSize: "20px !important",
    textAlign: "center",
  },
  subHeading: {
    fontSize: "16px !important",
  },
  subHeadingInnerContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  notificationType: {
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    height: "51px",
    width: "100%",
  },
  notificationTypeCount: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    height: "100%",
    width: "25%",
    backgroundColor: theme.palette.secondary.main,
    fontSize: "20px",
    padding: "15px",
    paddingTop: "12px",
  },
  notificationTypeText: {
    height: "100%",
    width: "75%",
    backgroundColor: "#1B1D28",
    fontSize: "13px",
    padding: "4px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  notificationTypeText2: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    height: "100%",
    width: "55%",
    backgroundColor: "#1B1D28",
    fontSize: "13px",
    padding: "4px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    wordWrap: "break-word",
  },
  notificationTypeIcon: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    height: "100%",
    width: "20%",
    backgroundColor: "#6C65D9",
    padding: "15px 7px",
    "&:hover": {},
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  settingsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  flexBox1: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  pendingNotificationType: {
    backgroundColor: "#31BAF4",
  },
  priorityNotificationType: {
    backgroundColor: "#FF0000",
  },
  approvedNotificationType: {
    backgroundColor: "#44B927",
  },
  simple: {
    backgroundColor: "#6C65D9",
  },
  approvedNotificationTypeHover: {
    backgroundColor: "#44B927",
    "&:hover": {
      backgroundColor: "rgba(68, 185, 39, 0.5)",
    },
  },
  simpleHover: {
    backgroundColor: "#6C65D9",
    "&:hover": {
      backgroundColor: "rgba(108, 101, 217, 0.5)",
    },
  },
}));

export default useStyles;
