import React, { useEffect, useState } from "react";
//@MUI
import { Modal, Snackbar } from "@material-ui/core";
//@API
import { fdelete, fget, fpatch, fpost, fpostBFF } from "../../../API/callsAPI";

//@css
import useStyles from "./MyTasksOverview.styles";

//@Components
import { Alert } from "@material-ui/lab";
import { ViewMode } from "movie-collab-gantt";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import Loader from "../../Utilities/Loader/Loader";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";
import { StatusRearrange } from "../../Utilities/StatusRearrange/StatusRearrange";
import CreateTaskOptionModal from "../MyTaskPageModals/CreateTaskOptionModal";
import MyTaskPageFiltersModal from "../MyTaskPageModals/MyTaskPageFiltersModal";
import TaskBulkUpdateModal from "../MyTaskPageModals/TaskBulkUpdateModal";
import CreateTaskFromTemplate from "../TaskPageModals/CreateTaskFromTemplate";
import NewTask from "../TaskPageModals/NewTaskModalOverview";
import TaskUpdateModal from "../TaskPageModals/TaskUpdateModal/TaskUpdateModal";
import TaskPageTools from "../TaskPageTools/TaskPageTools.component";
import TaskPageGanttViewOverview from "../TaskPageViews/TaskPageGanttView/TaskPageGanttViewOverview";
import TaskPageKanbanViewOverview from "../TaskPageViews/TaskPageKanbanView/TaskPageKanbanViewOverview/TaskPageKanbanViewOverview.component";
import TaskPageListViewOverview from "../TaskPageViews/TaskPageListView/TaskPageListViewOverview";
import TaskPageTableViewOverview from "../TaskPageViews/TaskPageTableView/TaskPageTableViewOverview";
import { clearTrackable, reOrderTask } from "./TaskPageUtilities";

function TaskComponentOverview(props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [showMyTask, setShowMyTask] = useState(false);
  //modal state
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isCreateTaskOptionModalOpen, setCreateTaskOptionModalOpen] =
    useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [
    isCreateTaskFromTemplateModalOpen,
    setIsCreateTaskFromTemplateModalOpen,
  ] = useState(false);
  //gantt states
  const [taskViewMode, setTaskViewMode] = useState("List");
  const [ganttViewMode, setGanttViewMode] = useState(ViewMode.Day);
  const [ganttSaveEvent, setGanttSaveEvent] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [assetList, setAssetList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);

  //this state retaains value of filters in parent so even modal is closed the value will persist
  const [filtersForTask, setFiltersForTask] = useState({
    department: "",
    assigned_user: "",
    status: "",
    taskSearchQuery: "",
    linked_class: "",
  });
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [updating, setUpdating] = useState(false);
  const [ganttFilterLoad, setGanttFilterLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTaskCount, setTotalTaskCount] = useState(0);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [filteredTaskCount, setFilteredTaskCount] = useState(0);
  const [selectTasksActive, setSelectTasksActive] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [bulkTaskUpdateModal, setBulkTaskUpdateModal] = useState(false);
  const [isLoadingBulkTask, setIsLoadedBulkTask] = useState(false);
  const [deleteBulkTasksModalOpen, setDeleteBulkTasksModalOpen] =
    useState(false);
  const [selectedTrackable, setSelectedTrackable] = useState([]);
  const [isTaskBulkUpdateModalOpen, setIsTaskBulkUpdateModalOpen] =
    useState(false);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };
  useEffect(() => {
    // if (firstRender && isFilterActive === false) {
    //   if (props.trackableType === "asset" || "shot") {
    //     fetchFilteredTasks({
    //       trackableType: props.trackableType,
    //       id: props.match.params.Id,
    //     });
    //   } else {
    //     fetchFilteredTasks();
    //   }
    // }
    handleFilterBy(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter, showMyTask, currentPage]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //change it later
    return () => {
      clearTrackable();
    };
  }, []);

  useEffect(() => {
    getAllTrackables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasksDepartmentAndStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasksDepartmentAndStatusList = async () => {
    setIsLoaded(false);
    const data = {
      url: "web/tasks/",
      data: {
        project_id: props.match.params.projectId,
      },
    };
    try {
      const res = await fpostBFF(data);
      if (props.trackableType === "task") {
        setTaskList(res?.data?.tasks?.results);
      }
      setTaskStatus(StatusRearrange(res?.data?.status_list?.results));
      setDepartments(res?.data?.department_list?.results);
      setTotalTaskCount(res?.data?.tasks?.count);
      if (props.trackableType !== "asset" && props.trackableType !== "shot") {
        setIsLoaded(true);
      }
      setFirstRender(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTrackables = async () => {
    try {
      const trackableRes = await fget({
        url: `trackables/asset/?project=${props.match.params.projectId}`,
      });
      setAssetList(trackableRes.data.results);
      const sequenceListRes = await fget({
        url: `trackables/shot-sequence/?project=${props.match.params.projectId}`,
      });
      setSequenceList(sequenceListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchFilteredTasks = async ({ trackableType, id }) => {
  //   let url = `trackables/task/?project=${
  //     props.match.params.projectId
  //   }&assigned=${showMyTask ? 1 : 0}&page=${currentPage}`;

  //   if (trackableType && id) {
  //     url = `trackables/task/?project=${
  //       props.match.params.projectId
  //     }&linked=${id}&assigned=${showMyTask ? 1 : 0}`;
  //   }
  //   if (firstRender || (trackableType && id)) {
  //     try {
  //       const taskListRes = await fget({
  //         url: url,
  //       });
  //       setTaskList(taskListRes.data.results);
  //       setIsLoaded(true);
  //       setIsNewPageLoading(false);
  //       setTotalTaskCount(taskListRes?.data?.count);
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoaded(true);
  //       setIsNewPageLoading(false);
  //     }
  //   }
  // };

  const rearrangeTaskOrder = async (orderArray) => {
    try {
      await fpatch({
        url: "trackables/task-order/",
        data: { order: orderArray },
      });
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully updated`,
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.match.params.projectId}/users/`,
      });
      if (usersRes.status === 200) {
        setProjectUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleNewTaskModalState() {
    setIsNewTaskModalOpen(!isNewTaskModalOpen);
  }

  function handleCreateTaskFromTemplateModalState() {
    setIsCreateTaskFromTemplateModalOpen(!isCreateTaskFromTemplateModalOpen);
  }

  function handleCreateTaskOptionModalState() {
    setCreateTaskOptionModalOpen(!isCreateTaskOptionModalOpen);
  }

  function handleFilterModalState() {
    setFilterModalOpen(!isFilterModalOpen);
  }

  //change on tab toggle
  function handleTabChange(mode) {
    setShowMyTask(false);
    setTaskViewMode(mode);
  }
  function handleGanttViewModeChange(viewMode) {
    setGanttViewMode(viewMode);
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  async function handleFilterBy(loader) {
    // const startDate =
    //   formatDate(new Date(start)) === "NaN-NaN-NaN"
    //     ? ""
    //     : formatDate(new Date(start));
    // // const endDate = formatDate(new Date(end));
    // const currentDate =
    //   formatDate(Date.now()) === "NaN-NaN-NaN" ? "" : formatDate(Date.now());
    //"NaN-NaN-NaN";
    //add start date and end date later, date is causeing a rerender loop!
    let linkedIdsString = "";

    selectedTrackable.forEach((single) => {
      linkedIdsString += `&linked_ids=${single.id}`;
    });
    loader && setGanttFilterLoad(true);
    loader && setIsLoaded(false);
    loader && setIsNewPageLoading(false);
    //url dosent works with assigned_users="" so add assigned_users only when necessary

    //keywords to search
    const taskSearchQuerryArray = filtersForTask.taskSearchQuery
      .split(",")
      .map((item) => item.trim());
    var searchQueries = ``;
    taskSearchQuerryArray.map((word) => {
      if (word !== "") {
        searchQueries += `&name=${word}`;
      }
    });
    let filterUrl = `trackables/task/?project=${
      props.match.params.projectId
    }&page=${currentPage}&assigned=${showMyTask ? 1 : 0}`;

    if (
      props.trackableType &&
      props.trackableType !== "task" &&
      props?.match?.params?.Id
    ) {
      filterUrl += `&linked=${props?.match?.params?.Id}`;
    }
    if (filtersForTask.department) {
      filterUrl += `&department=${filtersForTask.department}`;
    }
    if (filtersForTask.assigned_user) {
      filterUrl += `&assigned_users=${filtersForTask.assigned_user}`;
    }
    if (filtersForTask.status) {
      filterUrl += `&status=${filtersForTask.status}`;
    }
    if (filtersForTask.linked_class) {
      filterUrl += `&linked_class=${filtersForTask.linked_class}`;
    }
    if (filtersForTask.taskSearchQuery) {
      filterUrl += `${searchQueries}`;
    }
    if (linkedIdsString?.length) {
      filterUrl += `${linkedIdsString}`;
    }

    try {
      const taskListRes = await fget({
        url: filterUrl,
      });

      setTaskList(taskListRes.data.results);
      setFilteredTaskCount(taskListRes.data.count);
      setGanttFilterLoad(false);
      setIsLoaded(true);
    } catch (error) {
      setGanttFilterLoad(false);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    }
  }

  const handleTaskDelete = async (id) => {
    try {
      await fdelete({
        url: `trackables/task/${id}/`,
      });
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  useEffect(() => {
    getTasksTemplates();
  }, []);

  const getTasksTemplates = async () => {
    try {
      const url = `task-template/?project=${props.match.params.projectId}`;
      const res = await fget({ url });
      setTaskTemplateList(res?.data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTaskFilter = () => {
    setIsFilterActive(true);
    if (currentPage === 1) {
      handleFilterBy(true);
    }
    setCurrentPage(1);
  };

  const clearFilter = () => {
    setIsFilterActive(false);
    setFilteredTaskCount(0);
    setFiltersForTask({
      department: "",
      assigned_user: "",
      status: "",
      taskSearchQuery: "",
      linked_class: "",
    });
    setSelectedTrackable([]);
    updateRefreshCounter();
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const handleBulkTasksUpdate = async (
    selectedStatus,
    selectedPriority,
    selectedEndDate,
    selectedStartDate
  ) => {
    setIsLoadedBulkTask(true);
    const taskArray = taskList.map((task) =>
      selectedTasks.some((selectedTask) => selectedTask.id === task.id)
        ? {
            id: task.id,
            status:
              selectedStatus === ""
                ? task.status?.id
                  ? task.status?.id
                  : null
                : selectedStatus === "none"
                ? null
                : selectedStatus,
            priority:
              selectedPriority !== 0
                ? (selectedPriority === 1 && "Low") ||
                  (selectedPriority === 2 && "Medium") ||
                  (selectedPriority === 3 && "High")
                : task.priority,
            color: task.color,
            linked: task?.linked?.id,
            end_time: selectedEndDate ? selectedEndDate : task.end_time,
            start_time: selectedStartDate ? selectedStartDate : task.start_time,
          }
        : {
            id: task.id,
            status: task.status?.id ? task.status?.id : null,
            priority: task.priority,
            color: task.color,
            linked: task.linked?.id,
            end_time: task.end_time,
            start_time: task.start_time,
          }
    );
    let updatedTasks = {
      url: `trackables/task/`,
      data: taskArray,
    };
    try {
      const updateTaskRes = await fpatch(updatedTasks);
      if (updateTaskRes?.status) {
        updateRefreshCounter();
        setIsLoadedBulkTask(false);
        setBulkTaskUpdateModal(false);
        setSelectedTasks([]);
      }
    } catch (error) {
      setIsLoadedBulkTask(false);
      console.log(error);
    }
  };
  const handleBulkTasksDelete = async () => {
    const ids = selectedTasks.map((item) => item.id);
    let updatedTasks = {
      url: `trackables/bulk-delete-task/`,
      data: {
        ids: ids,
      },
    };
    try {
      const updateTaskRes = await fpost(updatedTasks);
      if (updateTaskRes?.status) {
        updateRefreshCounter();
        setIsLoadedBulkTask(false);
        setBulkTaskUpdateModal(false);
        setSelectedTasks([]);
        setDeleteBulkTasksModalOpen(false);
      }
    } catch (error) {
      setIsLoadedBulkTask(false);
      console.log(error);
    }
  };

  return (
    <div>
      {updating ? (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="info">Updating Please wait</Alert>
        </Snackbar>
      ) : (
        <ErrorOrSuccessMessage snackValue={snackValue} />
      )}
      <TaskPageTools
        openFilterModal={handleFilterModalState}
        openNewTaskModal={handleNewTaskModalState}
        onTabChange={handleTabChange}
        onGanttViewModeChange={(view) => handleGanttViewModeChange(view)}
        externalSaveEvent={ganttSaveEvent}
        trackableType={props.trackableType}
        params={props.match.params}
        showMyTaskCheckBox={true}
        myTaskCheckBoxState={showMyTask}
        onCheckBoxClick={() => {
          setCurrentPage(1);
          setIsLoaded(false);
          setShowMyTask(!showMyTask);
        }}
        handleCreateTaskOptionModalState={handleCreateTaskOptionModalState}
        updateRefreshCounter={updateRefreshCounter}
        filtersForTask={filtersForTask}
        selectTasksActive={selectTasksActive}
        clearSelectedTask={() => setSelectedTasks([])}
        setSelectTasksActive={setSelectTasksActive}
        openTaskUpdateModal={() => setBulkTaskUpdateModal(true)}
        selectedTaskCount={selectedTasks.length}
        handleOpenTaskBulkUpdateModal={() => setIsTaskBulkUpdateModalOpen(true)}
      />
      {isLoaded ? (
        <>
          {taskList && taskList.length !== 0 ? (
            <>
              {taskViewMode === "List" ? (
                <TaskPageListViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  setUpdating={setUpdating}
                  trackableType={props.trackableType}
                  selectTasksActive={selectTasksActive}
                  setSelectedTasks={setSelectedTasks}
                  selectedTasks={selectedTasks}
                  handleFilterBy={handleFilterBy}
                />
              ) : taskViewMode === "Gantt" ? (
                <TaskPageGanttViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  task={taskList}
                  statusList={taskStatus}
                  projectId={props.match.params.projectId}
                  viewModeUser={ganttViewMode}
                  projectUsers={projectUsers}
                  externalSaveEvent={(event) => setGanttSaveEvent(event)}
                  onTaskRefresh={updateRefreshCounter}
                  absoluteLinked={
                    props.trackableType === "asset" || "shot"
                      ? props.match.params.Id
                      : null
                  }
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  ganttFilterLoad={ganttFilterLoad}
                  setGanttFilterLoad={setGanttFilterLoad}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Table" ? (
                <TaskPageTableViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Kanban" ? (
                <TaskPageKanbanViewOverview
                  taskList={taskList}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  setSnackValue={setSnackValue}
                  handleNewTaskModalState={handleNewTaskModalState}
                  setUpdating={setUpdating}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
          <>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={
                    filteredTaskCount > 0 ? filteredTaskCount : totalTaskCount
                  }
                  totalPage={
                    filteredTaskCount > 0
                      ? Math.ceil(filteredTaskCount / 50)
                      : Math.ceil(totalTaskCount / 50)
                  }
                  setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Tasks"
                />
              </div>
            </footer>
          </>
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          open={isFilterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          className={classes.TaskPageModals}
        >
          <MyTaskPageFiltersModal
            handleTaskFilter={handleTaskFilter}
            closeModal={handleFilterModalState}
            departments={departments}
            projectUsers={projectUsers}
            taskStatus={taskStatus} //list of all status in rearranged format
            trackableList={taskList}
            filtersForTask={filtersForTask} // state persisted in overview
            clearFilter={clearFilter}
            setFiltersForTask={setFiltersForTask}
            setCurrentPage={setCurrentPage}
            sequenceList={sequenceList}
            assetList={assetList}
            trackableType={props.trackableType}
            selectedTrackable={selectedTrackable}
            setSelectedTrackable={setSelectedTrackable}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isNewTaskModalOpen}
          onClose={() => setIsNewTaskModalOpen(false)}
        >
          <NewTask
            projectId={props.match.params.projectId}
            taskCount={taskList.length}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : ""
            }
            allUsers={projectUsers}
            // allTrackables={getTrackableOfTask()}
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            taskStatus={taskStatus}
            handleNewTaskModal={handleNewTaskModalState}
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskOptionModalOpen}
          onClose={() => setCreateTaskOptionModalOpen(false)}
        >
          <CreateTaskOptionModal
            closeModal={handleCreateTaskOptionModalState}
            handleNewTaskModal={handleNewTaskModalState}
            handleCreateTaskFromTemplateModal={
              handleCreateTaskFromTemplateModalState
            }
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskFromTemplateModalOpen}
          onClose={() => setIsCreateTaskFromTemplateModalOpen(false)}
        >
          <CreateTaskFromTemplate
            projectId={props.match.params.projectId}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : null
            }
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            handleCreateTaskFromTemplateModalState={
              handleCreateTaskFromTemplateModalState
            }
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
            taskStatus={taskStatus}
            allUsers={projectUsers}
            taskTemplateList={taskTemplateList}
          />
        </Modal>
        <Modal className={classes.TaskPageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Task" />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={bulkTaskUpdateModal}
          onClose={() => setBulkTaskUpdateModal(false)}
        >
          <TaskBulkUpdateModal
            statusList={taskStatus}
            handleClose={() => setBulkTaskUpdateModal(false)}
            taskCount={selectedTasks.length}
            handleBulkTasksUpdate={handleBulkTasksUpdate}
            handleBulkTasksDelete={() => setDeleteBulkTasksModalOpen(true)}
            isLoading={isLoadingBulkTask}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={deleteBulkTasksModalOpen}
          onClose={() => setDeleteBulkTasksModalOpen(false)}
        >
          <DeleteModal
            type="Bulk Tasks"
            name="Tasks"
            handleCloseDeleteModal={() => {
              setDeleteBulkTasksModalOpen(false);
            }}
            handleDelete={handleBulkTasksDelete}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isTaskBulkUpdateModalOpen}
          onClose={() => setIsTaskBulkUpdateModalOpen(false)}
        >
          <TaskUpdateModal
            selectedTasks={selectedTasks}
            statusList={taskStatus}
            setSelectedTasks={setSelectedTasks}
            handleClose={() => setIsTaskBulkUpdateModalOpen(false)}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
    </div>
  );
}

export default TaskComponentOverview;
