import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  comment: {
    display: "flex",
    justifyContent: "space-between",
    height: "20px",
    color: theme.palette.text.tertiary,
    fontFamily: "poppins",
  },
  commentHead: {
    display: "flex",
    fontFamily: "poppins",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "13px",
  },
  time: {
    color: theme.palette.text.tertiary,
    fontFamily: "poppins",
    fontSize: "14px",
  },
  replyBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    gap: "15px",
  },
  submitCommentField: {
    width: "87%",
    height: "60px",
    padding: "4px 12px 12px 12px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    // display: "flex",
    alignItems: "center",
    overflowY: "hidden",
    marginLeft: "4px",
    marginTop: "-10px",
  },
  replyCommentField: {
    width: "87%",
    height: "120px",
    padding: "4px 12px 12px 12px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    // display: "flex",
    alignItems: "center",
    overflowY: "hidden",
    marginLeft: "4px",
    marginTop: "-70px",
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    marginLeft: "8px",
    minWidth: "44px",
    height: "44px",
  },
  replyCard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    gap: "15px",
    width: "99%",
    margin: "4px 0px 10px 4px",
    backgroundColor: theme.palette.primary.main,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  showMoreButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    width: "150px",
    marginTop: "10px",
  },
  showHideButton: {
    color: theme.palette.secondary.main,
    paddingLeft: "0px !important",
  },
  colorText: {
    color: theme.palette.secondary.main,
  },
  annotationText: {
    cursor: "pointer",
    color: "#008cdb",
    marginRight: "8px",
  },
  image: {
    marginLeft: "40px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  buttons: {
    display: "flex",
    gap: "10px",
    marginRight: "10px",
  },
  commonIconButton: {
    padding: "0",
    margin: "0",
    minWidth: "25px",
  },
  repliesHead: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  replyDescription: {
    margin: "8px 0px 4px 66px",
    paddingRight: "4px",
  },
  replyIcons: {
    display: "flex",
    gap: "10px",
    marginRight: "10px",
    justifyContent: "flex-end",
  },
  timeStampDiv: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    backgroundColor: theme.palette.primary.main,
    padding: "2px 4px",
    color: "#A8A8A8",
    borderRadius: "4px",
    marginBottom: "4px",
  },
}));

export default useStyles;
