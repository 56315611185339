import { useEffect, useState } from "react";
import { fget } from "../API/callsAPI";

export const ProjectsPageDataHook = () => {
  const [testData, setTestData] = useState("test data passed successfully");
  const [projectItems, setProjectItems] = useState([]);
  const fetchActiveProjects = async () => {
    try {
      const projectsRes = await fget({
        url: `project/?page=1`,
      });
      setProjectItems(projectsRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchActiveProjects();
  }, []);
  return {
    testData,
    projectItems,
  };
};
