import React, { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Gear, Warning } from "phosphor-react";
import { Box } from "@material-ui/core";

export default function CustomLoader({ type }) {
  const loaderData = [
    {
      firstMessage: (
        <span>
          Feeling too <span style={{ color: "#6C65D9" }}>overwhelmed</span> by
          in-app and email notifications?
        </span>
      ),
      secondMessage: (
        <span>
          You can choose which notifications to receive in{" "}
          <Gear color="#6C65D9" />
          <span style={{ color: "#6C65D9" }}>Project Settings</span>.
        </span>
      ),
    },
    {
      firstMessage: (
        <span>
          You’ve been working for quite some time now.{" "}
          <span style={{ color: "#6C65D9" }}>Take a small break!</span>
        </span>
      ),
      secondMessage: (
        <span>
          Taking small breaks helps{" "}
          <span style={{ color: "#6C65D9" }}>revitalize</span> the mind and can
          boost <span style={{ color: "#6C65D9" }}>productivity</span>.
        </span>
      ),
    },
    {
      firstMessage: (
        <span>
          Found an Issue? Go ahead and report the issue at our{" "}
          <Warning color="#6C65D9" />{" "}
          <span style={{ color: "#6C65D9" }}>Report Center</span>
        </span>
      ),
      secondMessage: (
        <span>
          We take <span style={{ color: "#6C65D9" }}>feedback</span> very
          seriously and it would mean a lot!
        </span>
      ),
    },
    {
      firstMessage:
        "“No matter what anybody tells you, words and ideas can change the world.”",
      secondMessage: (
        <span style={{ color: "#6C65D9" }}>~ Dead Poets Society</span>
      ),
    },
    {
      firstMessage:
        "“There should be no boundaries to human endeavor. We are all different. However bad life may seem, there is always something you can do, and succeed at.”",
      secondMessage: (
        <span style={{ color: "#6C65D9" }}>~ The Theory of Everything</span>
      ),
    },
    {
      firstMessage:
        "“You miss 100% of the shots you don't take. -  Wayne Gretzky”",
      secondMessage: <span style={{ color: "#6C65D9" }}>~ Michael Scott</span>,
    },
    {
      firstMessage:
        "“Oh yes, the past can hurt. But, you can either run from it or learn from it.”",
      secondMessage: <span style={{ color: "#6C65D9" }}> ~ The Lion King</span>,
    },
    {
      firstMessage:
        "“If you focus on what you left behind, you will never be able to see what lies ahead.”",
      secondMessage: <span style={{ color: "#6C65D9" }}>~ Ratatouille</span>,
    },
    {
      firstMessage:
        "“No matter what anybody tells you, words and ideas can change the world.”",
      secondMessage: (
        <span style={{ color: "#6C65D9" }}>~ Dead Poets Society</span>
      ),
    },
    {
      firstMessage:
        "“I don't know all the answers, but I know where to find them.”",
      secondMessage: <span style={{ color: "#6C65D9" }}>~ Primer</span>,
    },
    {
      firstMessage:
        "“It's not about how many times you get knocked down, but how many times you get up.”",
      secondMessage: <span style={{ color: "#6C65D9" }}>~ Wreck-It Ralph</span>,
    },
    {
      firstMessage:
        "“In the scrolls of wisdom, it is written: We must strive to surpass the selves of yesterday.”",
      secondMessage: (
        <span style={{ color: "#6C65D9" }}>~ Siddesh “Oogway” Sharma</span>
      ),
    },
  ];
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * loaderData.length));
  }, []);

  return (
    <Box
      style={{
        height: type === "appRegister" ? "100vh" : `calc(100vh - 50%)`,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: type === "appRegister" && "100%",
        position: type === "appRegister" && "absolute",
        zIndex: type === "appRegister" && "1300",
        left: type === "appRegister" && "0",
        top: type === "appRegister" && "0",
        backgroundColor: type === "appRegister" && "#1B1D28",
      }}
    >
      <div
        style={{
          width: "550px",
          height: "300px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "320px",
            height: "110px",
            left: "180px",
            top: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            paddingBottom: "5px",
          }}
        >
          <div style={{ color: "black" }}>
            {loaderData[randomNumber].firstMessage}
          </div>
          <div style={{ color: "black" }}>
            {loaderData[randomNumber].secondMessage}
          </div>
        </div>
        <div>
          <LoaderSvg />
        </div>

        <div
          style={{
            height: "400px",
            display: "flex",
            width: "100%",
            position: "absolute",
            left: "310px",
            top: "160px",
          }}
        >
          <ScaleLoader
            color="#6C65D9"
            height={65}
            style={{
              display: "flex",
              verticalAlign: "middle",
            }}
          />
        </div>
      </div>
    </Box>
  );
}

export const LoaderSvg = () => {
  return (
    <svg
      width="504"
      height="279"
      viewBox="0 0 504 279"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="18.9801"
        y="120.003"
        width="7.34715"
        height="120.616"
        rx="3.67358"
        fill="#FFBB56"
      />
      <rect
        x="140.82"
        y="94.9008"
        width="7.34715"
        height="142.657"
        rx="3.67358"
        fill="#FFBB56"
      />
      <path
        d="M15.9189 133.177C15.9189 129.858 18.2491 126.996 21.4986 126.323L144.033 100.931C148.378 100.031 152.454 103.348 152.454 107.786V165.607C152.454 168.925 150.123 171.788 146.874 172.461L24.3393 197.853C19.9945 198.753 15.9189 195.435 15.9189 190.998V133.177Z"
        fill="black"
      />
      <rect
        x="196.536"
        y="241.231"
        width="9.18395"
        height="142.657"
        rx="4.59197"
        transform="rotate(90 196.536 241.231)"
        fill="#FFBB56"
      />
      <rect
        x="213.68"
        y="181.842"
        width="9.18395"
        height="142.657"
        rx="4.59197"
        transform="rotate(90 213.68 181.842)"
        fill="#FFBB56"
      />
      <path
        d="M1.09017 201.114C7.76834 143.883 69.6093 124.831 98.8472 124.097C190.414 116.942 190.191 188.909 190.414 241.231L0.0652417 241.231C0.0662276 226.562 -0.38867 213.788 1.09017 201.114Z"
        fill="#6C65D9"
      />
      <path
        d="M56.9406 145.72C37.5921 118.349 63.2942 104.315 80.1652 100.223C81.1857 99.9757 82.1792 100.67 82.3413 101.707L83.4774 108.978C83.54 109.379 83.4682 109.79 83.2731 110.145L73.9686 127.112C73.5132 127.943 73.7721 128.986 74.5447 129.534C113.472 157.114 119.548 210.943 119.409 239.135C119.404 240.139 118.585 240.927 117.581 240.927H80.33C79.2686 240.927 78.4288 240.035 78.4824 238.975C79.6241 216.416 76.4442 173.311 56.9406 145.72Z"
        fill="#A09AFB"
      />
      <path
        d="M144.8 133.781C150.07 116.266 135.718 108.984 126.126 106.842L51.4302 129.189C44.3279 130.903 42.5524 138.271 42.5524 141.741C49.8995 138.802 60.5121 142.965 64.9 145.414C100.717 165.405 134.392 168.374 144.8 133.781Z"
        fill="#A09AFB"
      />
      <path
        d="M139.855 184.167C140.812 173.758 130.095 146.749 123.653 132.394C123.279 131.56 123.916 130.628 124.83 130.667L144.759 131.513C145.21 131.532 145.614 131.797 145.81 132.204L171.53 185.487C171.774 185.992 171.646 186.604 171.19 186.932C163.456 192.485 153.512 186.282 141.188 185.763C140.383 185.729 139.782 184.97 139.855 184.167Z"
        fill="#A09AFB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.388 144.931C137.993 145.234 138.238 145.969 137.936 146.574C135.029 152.387 130.509 156.225 124.225 159.367C123.62 159.669 122.884 159.424 122.582 158.819C122.279 158.214 122.525 157.479 123.129 157.176C129.09 154.196 133.142 150.687 135.745 145.479C136.048 144.874 136.783 144.629 137.388 144.931Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.327 142.261C85.7158 141.708 86.4796 141.574 87.0329 141.963C98.6746 150.144 104.905 162.994 109.205 174.053C109.451 174.683 109.138 175.393 108.508 175.638C107.878 175.883 107.168 175.571 106.923 174.94C102.652 163.958 96.6369 151.705 85.6248 143.967C85.0715 143.578 84.9382 142.814 85.327 142.261Z"
        fill="#252A38"
      />
      <path
        d="M66.4686 112.069C78.1874 105.038 95.2772 105.233 102.357 106.21C117.982 108.163 146.01 115.585 133.119 129.648C120.229 143.71 95.0331 138.437 84.0467 134.042C73.3046 129.648 54.7499 119.101 66.4686 112.069Z"
        fill="#252A38"
      />
      <path
        d="M80.3849 132.578L79.6525 93.7594L120.668 106.943L122.865 136.24C111.147 142.099 89.1739 136.972 80.3849 132.578Z"
        fill="#E5986D"
      />
      <path
        d="M122.133 123.789C108.07 126.132 93.8126 114.511 88.4415 108.408L121.4 112.802L122.133 123.789Z"
        fill="#252A38"
      />
      <path
        d="M134.825 67.8674C138.513 91.4465 135.098 112.399 118.471 114.999C89.9063 119.467 78.2998 100.865 74.6116 77.2856C70.9235 53.7065 81.4129 32.4836 98.0402 29.8828C114.668 27.2821 131.137 44.2884 134.825 67.8674Z"
        fill="#E6986D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M122.769 56.5618C121.37 56.7694 120.116 57.4994 119.424 58.0926C118.809 58.6191 117.885 58.548 117.358 57.9338C116.832 57.3195 116.903 56.3948 117.517 55.8683C118.534 54.9967 120.283 53.9689 122.339 53.6638C124.468 53.3478 126.903 53.8204 129.028 55.9447C129.6 56.5167 129.6 57.4442 129.028 58.0163C128.456 58.5883 127.528 58.5883 126.956 58.0163C125.565 56.6249 124.094 56.3651 122.769 56.5618Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.183 64.6019C104.991 64.5346 105.711 65.0255 105.792 65.6985L106.526 71.8209C106.607 72.4939 106.018 73.0939 105.21 73.1612C104.403 73.2285 103.683 72.7375 103.602 72.0646L102.868 65.9421C102.787 65.2692 103.376 64.6692 104.183 64.6019Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125 62.7651C125.808 62.6978 126.528 63.1887 126.609 63.8617L127.343 69.9842C127.424 70.6571 126.835 71.2571 126.027 71.3244C125.22 71.3917 124.5 70.9008 124.419 70.2278L123.684 64.1053C123.604 63.4324 124.193 62.8324 125 62.7651Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.397 59.8666C102.46 58.8825 99.9097 61.096 99.1819 62.1878C98.7331 62.8609 97.8236 63.0428 97.1505 62.594C96.4774 62.1453 96.2955 61.2358 96.7442 60.5627C97.9695 58.7247 101.669 55.6648 107.107 57.0244C107.892 57.2206 108.369 58.016 108.173 58.8008C107.977 59.5857 107.182 60.0628 106.397 59.8666Z"
        fill="#1B1D28"
      />
      <path
        d="M88.9422 72.6518C90.2659 81.1148 89.0403 88.635 83.0724 89.5685C72.82 91.1721 68.6542 84.4952 67.3304 76.0322C66.0067 67.5691 69.7715 59.9518 75.7395 59.0183C81.7074 58.0848 87.6184 64.1888 88.9422 72.6518Z"
        fill="#E6986D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.9905 73.4414C73.7995 73.4414 74.4553 74.0972 74.4553 74.9062C74.4553 75.8894 74.625 77.3373 75.083 78.4821C75.5587 79.6714 76.1059 80.0332 76.6526 80.0332C77.4616 80.0332 78.1174 80.689 78.1174 81.498C78.1174 82.307 77.4616 82.9629 76.6526 82.9629C74.2696 82.9629 72.9857 81.1274 72.3628 79.5701C71.7221 77.9684 71.5256 76.1204 71.5256 74.9062C71.5256 74.0972 72.1815 73.4414 72.9905 73.4414Z"
        fill="#1B1D28"
      />
      <path
        d="M83.2678 64.9016C86.9414 83.8818 90.9822 93.5555 107.146 93.0657C111.492 92.934 115.243 92.6181 118.473 92.106L122.146 89.3921L127.045 89.812C131.555 87.8916 134.022 85.2887 135.367 81.6004C135.6 80.9611 136.551 81.0065 136.59 81.6859C137.186 92.2478 134.124 113.184 119.697 116.026C104.841 118.952 86.5939 115.117 79.2879 96.127C73.1655 85.7185 84.1862 87.8615 76.533 70.7181L83.2678 64.9016Z"
        fill="#C9C9C9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M114.049 98.0851C112.46 96.426 113.739 94.0277 115.687 93.8216L125.014 92.8351C126.521 92.6758 127.855 93.8062 127.946 95.3183L128.012 96.4101C128.048 97.0198 127.878 97.681 127.424 98.2147C126.278 99.5641 124.381 101.025 121.978 101.38C119.489 101.747 116.732 100.887 114.049 98.0851ZM116.16 96.4436C118.282 98.5715 120.148 98.9643 121.59 98.7514C123.138 98.523 124.482 97.5551 125.358 96.543L125.294 95.4775L116.16 96.4436Z"
        fill="#1B1D28"
      />
      <path
        d="M137.239 93.9611C128.371 97.3343 123.733 93.152 122.165 89.8853C121.932 89.4007 121.987 88.8264 122.306 88.3935C126.032 83.3349 133.209 87.3853 137.706 91.1963C138.667 92.0102 138.416 93.5135 137.239 93.9611Z"
        fill="#ECECEC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.933 69.552C118.667 69.3331 119.439 69.7506 119.658 70.4846C120.605 73.6593 121.84 76.1258 122.923 78.2889C123.394 79.2282 123.836 80.1103 124.213 80.9684C124.797 82.299 125.275 83.696 125.141 85.0575C124.994 86.554 123.23 88.3444 121.804 89.2866C121.165 89.7089 120.305 89.5332 119.882 88.8943C119.46 88.2553 119.636 87.395 120.275 86.9727C121.194 86.365 122.335 85.2474 122.381 84.7861C122.439 84.1899 122.236 83.364 121.674 82.0842C121.38 81.4165 120.996 80.6479 120.564 79.7833C119.451 77.5577 118.02 74.6954 117 71.2772C116.781 70.5433 117.199 69.7709 117.933 69.552Z"
        fill="#1B1D28"
      />
      <path
        d="M101.683 94.4481C113.867 98.0811 120.299 93.7409 122.542 90.2617C122.906 89.6971 122.824 88.9612 122.354 88.4804C117.214 83.2125 107.427 87.4636 101.247 91.498C100.045 92.2825 100.307 94.0381 101.683 94.4481Z"
        fill="#ECECEC"
      />
      <path
        d="M75.6145 71.0244C63.081 69.7516 60.2482 56.5895 60.2019 52.2187C49.1701 44.3263 57.6782 37.6058 63.3112 35.2321C63.3112 30.9218 66.7367 23.5741 87.2474 15.615C112.879 5.66865 132.143 9.7917 140.514 22.3496C146.025 30.6156 144.527 33.3934 142.075 36.8814C127.951 50.6388 108.713 46.0962 100.86 42.1052C93.6363 53.0399 82.6762 55.5876 78.0993 55.4946C82.6243 59.8341 88.1481 72.2971 75.6145 71.0244Z"
        fill="#ECECEC"
      />
      <path
        d="M123.065 241.542C116.736 245.761 108.531 247.636 107.594 250.683L85.0903 237.323C114.626 224.664 118.845 238.025 123.065 241.542Z"
        fill="#E5986D"
      />
      <path
        d="M25.3162 278.107C4.21929 273.886 -0.000218975 251.384 8.52291e-09 238.726L31.6454 240.132L79.4649 233.1C99.7178 231.975 109.704 243.648 110.407 251.384C111.11 259.12 46.4131 282.328 25.3162 278.107Z"
        fill="#6C65D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.54 238.648C101.973 238.023 102.83 237.867 103.455 238.3C105.081 239.426 107.475 241.49 109.477 244.096C111.464 246.683 113.19 249.966 113.19 253.496C113.19 254.256 112.574 254.872 111.814 254.872C111.054 254.872 110.438 254.256 110.438 253.496C110.438 250.838 109.116 248.143 107.294 245.773C105.487 243.42 103.31 241.547 101.889 240.563C101.264 240.13 101.108 239.273 101.54 238.648Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M76.382 234.565C77.1273 234.416 77.8523 234.899 78.0014 235.645C78.1504 236.39 77.6671 237.115 76.9218 237.264C67.9965 239.049 59.9694 239.227 54.173 238.959C51.2732 238.826 48.927 238.58 47.3 238.367C46.4864 238.26 45.8523 238.162 45.4183 238.089C45.2012 238.053 45.0342 238.023 44.9198 238.002C44.8625 237.991 44.8184 237.983 44.7878 237.977L44.752 237.97L44.7418 237.968L44.7386 237.968C44.7381 237.968 44.7367 237.967 45.0065 236.618"
        fill="#252A38"
      />
      <path
        d="M205.877 140.07L199.084 149.023C198.059 150.373 198.48 152.323 199.969 153.126L213.616 160.484C214.292 160.848 215.088 160.912 215.813 160.66L262.729 144.35C265.657 143.332 264.929 138.984 261.83 138.984H208.061C207.204 138.984 206.396 139.385 205.877 140.07Z"
        fill="#E5986D"
      />
      <path
        d="M227.776 166.974C213.498 183.357 202.351 217.021 196.255 237.246C195.304 240.404 191.849 242.06 188.792 240.817L157.713 228.188C155.213 227.172 153.787 224.509 154.454 221.896C162.731 189.484 185.037 159.322 197.854 145.295C199.553 143.437 202.3 143.063 204.496 144.296L226.533 156.679C230.103 158.685 230.465 163.888 227.776 166.974Z"
        fill="#6C64D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M184.157 159.907C184.769 160.333 184.918 161.174 184.49 161.783C179.756 168.514 171.765 180.467 168.446 193.682C168.265 194.404 167.53 194.843 166.804 194.662C166.079 194.482 165.638 193.75 165.819 193.028C169.27 179.289 177.527 166.984 182.271 160.238C182.7 159.628 183.544 159.48 184.157 159.907Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M218.247 151.824C218.647 151.077 219.526 150.824 220.211 151.26L228.827 156.743C229.512 157.179 229.744 158.138 229.344 158.886C228.944 159.633 228.065 159.885 227.38 159.449L218.764 153.967C218.079 153.531 217.848 152.571 218.247 151.824Z"
        fill="#252A38"
      />
      <path
        d="M159.842 89.0022C163.112 87.1194 166.446 84.7074 168.72 82.9252C169.504 82.3102 170.645 82.4147 171.211 83.2356C173.701 86.848 175.37 91.4668 176.596 94.6192C176.835 95.2336 167.584 94.4009 160.299 93.2627C158.443 92.9726 158.214 89.9396 159.842 89.0022Z"
        fill="#F9F9F9"
      />
      <rect x="169" y="6" width="340" height="130" rx="10" fill="#F9F9F9" />
    </svg>
  );
};
