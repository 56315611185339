import React, { useEffect, useState } from "react";
import { fget } from "../../../API/callsAPI";

//@MUI
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "./ReviewsOverview.styles";

//@Components
import { useHistory } from "react-router-dom";
import {
  FileDirectionAvatar,
  SidebarDirectionAvatar,
} from "../../Utilities/AppTour/AppTourCustomAvatars/AppTourCustomAvatars.jsx";
import CustomAppTour from "../../Utilities/AppTour/CustomAppTour/CustomAppTour.jsx";
import AppTourStartModal from "../../Utilities/AppTour/Modals/AppTourStartModal.jsx";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import Loader from "../../Utilities/Loader/Loader";
import ReviewPageManageReviewModal from "../ReviewPageModals/ReviewPageManageReviewModal";
import ReviewPageNewReviewModal from "../ReviewPageModals/ReviewPageNewReviewModal.jsx";
import ReviewPageNoData from "../ReviewPageNoData/ReviewPageNoData";
import { reviewFileFakeData } from "../ReviewerPageFakeData.js";
import ReviewsList from "../ReviewsList/ReviewsList.component";
import ReviewTools from "../ReviewsPageTools/ReviewsPageTool.component";

function ReviewsOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [isNewReviewModalOpen, setNewReviewModalOpen] = useState(false);
  const [isManageReviewerModalOpen, setManageReviewerModalOpen] =
    useState(false);
  const [reviewFiles, setReviewFiles] = useState([]); //all the review files
  const [filterCompleted, setFilterCompleted] = useState("False"); //for filtering according to pending and completed in the tools
  const [orgUsers, setOrgUsers] = useState([]);
  const [projectReviewer, setProjectReviewer] = useState([]); //reviewer added to a project
  const [isReviewTogglerOn, setIsReviewTogglerOn] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [appTourInitialModal, setAppTourInitialModal] = useState(false);
  const [appTourRun, setAppTourRun] = useState(false);

  const handleReviewToggler = () => {
    setIsReviewTogglerOn(!isReviewTogglerOn);
  };

  const handleNewReviewModal = () => {
    setNewReviewModalOpen(!isNewReviewModalOpen);
  };

  const handleManageReviewModal = () => {
    if (!isManageReviewerModalOpen) {
      getReviewer();
    }
    setManageReviewerModalOpen(!isManageReviewerModalOpen);
  };

  const getReviewer = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.params.projectId}/reviewers`,
      });
      if (usersRes.status === 200) {
        setProjectReviewer(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReviewFiles = async () => {
    setIsLoaded(false);
    try {
      const reviewFilesRes = await fget({
        url: `project/${props.params.projectId}/reviews/?page=${currentPage}&completed=${filterCompleted}`,
      });
      if (reviewFilesRes.status === 200) {
        setReviewFiles(reviewFilesRes.data);
        setIsLoaded(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!props.externalReviewer) {
      fetchOrghUsers();
    }
    getReviewer();
  }, []);

  useEffect(() => {
    getReviewFiles();
  }, [updateOnPageChange, refreshCounter, filterCompleted]);

  const fetchOrghUsers = async () => {
    try {
      const usersRes = await fget({
        url: `organization/users/`,
      });
      if (usersRes.status === 200) {
        setOrgUsers(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    const open = localStorage.getItem("externalAppTour");
    if (open === null) {
      setAppTourInitialModal(true);
    } else {
      setAppTourInitialModal(false);
    }
  }, []);

  // setUserRoleForProject('reviewer'); put it in App.js
  const steps = [
    {
      content: (
        <div>
          We shall now load{" "}
          <strong style={{ color: "#6C65D9" }}>demonstrational content</strong>{" "}
          to help simulate an External Reviewers journey
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      target: "#external-sidebar",
      placement: "right",
      content: <SidebarDirectionAvatar />,
    },
    {
      target: "#file-track",
      content: <FileDirectionAvatar />,
    },
  ];

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <CustomAppTour
        run={appTourRun}
        steps={steps}
        handleFinish={() => {
          setAppTourRun(false);
          localStorage.setItem("externalAppTour", false);
          history.push(
            `/${props.params.projectId}/review/${reviewFileFakeData.id}/version`
          );
        }}
        /* handleOthers={(e) => {
          console.log(e.action);
          if (e.action === "reset") {
            localStorage.setItem("externalAppTour", false);
            history.push(
              `/${props.params.projectId}/review/${reviewFileFakeData.id}/version`
            );
          }
        }} */
      />
      <ReviewTools
        handleNewReviewModal={handleNewReviewModal}
        handleManageReviewModal={handleManageReviewModal}
        setFilterCompleted={setFilterCompleted}
        filterCompleted={filterCompleted}
        handleReviewToggler={handleReviewToggler}
        isReviewVersion={"no"}
        isExternalReviewer={props.externalReviewer}
      />
      {isLoaded ? (
        reviewFiles.count !== 0 ? (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headTableCell} align="left">
                      File Name
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Modified on
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Added by
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Status
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Reviewer
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="right">
                      {props.externalReviewer ? "Versions" : "Actions"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="list">
                  {appTourRun ? (
                    <ReviewsList
                      isReviewTogglerOn={isReviewTogglerOn}
                      isExternalReviewer={props.externalReviewer}
                      projectId={props.params.projectId}
                      review={reviewFileFakeData}
                      updateRefreshCounter={updateRefreshCounter}
                      setSnackValue={setSnackValue}
                      isCompleted={filterCompleted}
                    />
                  ) : (
                    <>
                      {reviewFiles?.results?.map((review) => {
                        return (
                          <ReviewsList
                            isReviewTogglerOn={isReviewTogglerOn}
                            isExternalReviewer={props.externalReviewer}
                            projectId={props.params.projectId}
                            review={review}
                            updateRefreshCounter={updateRefreshCounter}
                            key={review.id}
                            setSnackValue={setSnackValue}
                            isCompleted={filterCompleted}
                          />
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={reviewFiles?.count ? reviewFiles.count : 0}
                  totalPage={
                    reviewFiles?.count ? Math.ceil(reviewFiles?.count / 50) : 0
                  }
                  // setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Review Files"
                  isExternalReviewer={props.externalReviewer}
                />
              </div>
            </footer>
          </>
        ) : (
          <ReviewPageNoData
            filterCompleted={filterCompleted}
            handleNewReviewModal={handleNewReviewModal}
            isExternalReviewer={props.externalReviewer}
            isReviewVersion={false}
          />
        )
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isNewReviewModalOpen}
          onClose={handleNewReviewModal}
        >
          <ReviewPageNewReviewModal
            handleNewReviewModal={handleNewReviewModal}
            reviewerUserList={projectReviewer}
            projectId={props.params.projectId}
            setSnackValue={setSnackValue}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isManageReviewerModalOpen}
          onClose={handleManageReviewModal}
        >
          <ReviewPageManageReviewModal
            handleManageReviewModal={handleManageReviewModal}
            orgUsers={orgUsers}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            projectReviewer={projectReviewer}
            getReviewer={getReviewer}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={appTourInitialModal}
          onClose={() => setAppTourInitialModal(false)}
        >
          <AppTourStartModal
            name="External Reviewer"
            handleClose={() => {
              localStorage.setItem("externalAppTour", false);
              localStorage.setItem("externalVersionAppTour", false);
              setAppTourInitialModal(false);
              setAppTourRun(false);
            }}
            handleStartTour={() => {
              setAppTourInitialModal(false);
              setAppTourRun(true);
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewsOverview;
