import React from "react";

function FolderIcon({ size }) {
  return (
    <svg
      width={size === "small" ? "48" : "60"}
      height={size === "small" ? "48" : "60"}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 50C8.625 50 7.44833 49.5108 6.47 48.5325C5.49 47.5525 5 46.375 5 45V15C5 13.625 5.49 12.4483 6.47 11.47C7.44833 10.49 8.625 10 10 10H22.9375C23.6042 10 24.24 10.125 24.845 10.375C25.4483 10.625 25.9792 10.9792 26.4375 11.4375L30 15H50C51.375 15 52.5525 15.49 53.5325 16.47C54.5108 17.4483 55 18.625 55 20H15C13.625 20 12.4483 20.4892 11.47 21.4675C10.49 22.4475 10 23.625 10 25V45L14.9375 28.5625C15.2708 27.4792 15.8858 26.6142 16.7825 25.9675C17.6775 25.3225 18.6667 25 19.75 25H52C53.7083 25 55.0525 25.6767 56.0325 27.03C57.0108 28.385 57.2708 29.8542 56.8125 31.4375L52.3125 46.4375C51.9792 47.5208 51.365 48.3858 50.47 49.0325C49.5733 49.6775 48.5833 50 47.5 50H10Z"
        fill="#606479"
      />
    </svg>
  );
}

export default FolderIcon;
