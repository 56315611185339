export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter(
    (t) => t.project === projectId && !t.isNull
  );
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

function getStartEndDate(tasks) {
  let start = tasks[0].start_time;
  let end = tasks[0].end_time;
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    if (new Date(start) > new Date(task.start_time)) {
      start = task.start_time;
    }
    if (new Date(end) < new Date(task.end_time)) {
      end = task.end_time;
    }
  }
  return [start, end];
}

/**
 * Returns new task array after date change
 * @param {Object} task task that was changed
 * @param {Array} tasks all task array
 * @returns {Array} updated task array
 */
export const handleTaskChange = (task, tasks) => {
  let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  if (task.project) {
    const [start, end] = getStartEndDateForProject(newTasks, task.project);
    const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
    if (
      project.start.getTime() !== start.getTime() ||
      project.end.getTime() !== end.getTime()
    ) {
      const changedProject = { ...project, start, end };
      newTasks = newTasks.map((t) =>
        t.id === task.project ? changedProject : t
      );
    }
  }

  return newTasks;
};

function getNextField(taskId, taskList) {
  let dependencies = [];
  taskList.map((eachItem) => {
    if (eachItem.next === taskId) {
      dependencies.push(eachItem.id);
    }
  });
  return dependencies;
}

export function TaskToGanttTasks(tasksList) {
  const noNullDateTasks = tasksList.filter(
    (task) => task.start_time !== null && task.end_time !== null
  );
  //noNullDateTasks->will have all the task with start and end date not as null
  //1. all tasks are null(if)
  //2. atleast one task is null(else if)
  //3. no task is null(else)
  let localTasks = [];
  let trackableArray = [];
  if (noNullDateTasks.length === 0) {
    tasksList.forEach((eachTask, index) => {
      if (
        trackableArray.findIndex((each) => each === eachTask.linked.id) === -1
      ) {
        trackableArray.push(eachTask.linked.id);
        let projectObj = {
          start: new Date(),
          end: new Date(),
          name: eachTask.linked.name || eachTask.linked.code,
          id: eachTask.linked.id,
          progress: 25,
          type: "project",
          parentSequence: eachTask.linked.parent_sequence || "Asset",
          hideChildren: false,
          displayOrder: index + 1,
          isNull: true,
        };

        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(),
          end: new Date(),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 2,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
          isNull: true,
        };
        localTasks.push(projectObj);
        localTasks.push(taskObj);
      } else {
        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(),
          end: new Date(),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 1,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
          isNull: true,
        };
        localTasks.push(taskObj);
      }
    });
  } else if (
    noNullDateTasks.length > 0 &&
    noNullDateTasks.length < tasksList.length
  ) {
    let indexOfCurrentTask = 0;
    let currentNoNullDateTask = [noNullDateTasks[0]];
    tasksList.forEach((eachTask, index) => {
      if (
        trackableArray.findIndex((each) => each === eachTask.linked.id) === -1
      ) {
        //filtered has task with no null date for a particular trackable used for setting start and end date of project
        const filtered = noNullDateTasks.filter(
          (task) => task.linked.id == eachTask.linked.id
        );
        let startTimeProject = new Date(
          currentNoNullDateTask[indexOfCurrentTask].start_time
        );
        let endTimeProject = new Date(
          currentNoNullDateTask[currentNoNullDateTask.length - 1].end_time
        );
        if (filtered.length > 0) {
          currentNoNullDateTask = filtered;
          indexOfCurrentTask = 0;
          const [start, end] = getStartEndDate(filtered);
          startTimeProject = new Date(start);
          endTimeProject = new Date(end);
        }
        trackableArray.push(eachTask.linked.id);
        let projectObj = {
          start: startTimeProject,
          end: endTimeProject,
          name: eachTask.linked.name || eachTask.linked.code,
          id: eachTask.linked.id,
          progress: 25,
          type: "project",
          parentSequence: eachTask.linked.parent_sequence || "Asset",
          hideChildren: false,
          displayOrder: index + 1,
          isNull:
            currentNoNullDateTask[indexOfCurrentTask].linked.id ===
            eachTask.linked.id
              ? false
              : true,
        };
        //for a task with null start and end date takes the date of currentNoNullDateTask in the iteration
        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(currentNoNullDateTask[indexOfCurrentTask].start_time),
          end:
            currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id
              ? new Date(currentNoNullDateTask[indexOfCurrentTask].end_time)
              : new Date(currentNoNullDateTask[indexOfCurrentTask].start_time),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 2,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
          isNull:
            currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id
              ? false
              : true,
        };
        localTasks.push(projectObj);
        localTasks.push(taskObj);
        if (
          currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id &&
          indexOfCurrentTask !== currentNoNullDateTask.length - 1
        ) {
          indexOfCurrentTask = indexOfCurrentTask + 1;
        }
      } else {
        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(currentNoNullDateTask[indexOfCurrentTask].start_time),
          end:
            currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id
              ? new Date(currentNoNullDateTask[indexOfCurrentTask].end_time)
              : new Date(currentNoNullDateTask[indexOfCurrentTask].start_time),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 1,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
          isNull:
            currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id
              ? false
              : true,
        };
        if (
          currentNoNullDateTask[indexOfCurrentTask].id === eachTask.id &&
          indexOfCurrentTask !== currentNoNullDateTask.length - 1
        ) {
          indexOfCurrentTask = indexOfCurrentTask + 1;
        }
        localTasks.push(taskObj);
      }
    });
  } else {
    tasksList.forEach((eachTask, index) => {
      if (
        trackableArray.findIndex((each) => each === eachTask.linked.id) === -1
      ) {
        trackableArray.push(eachTask.linked.id);
        let projectObj = {
          start: new Date(eachTask.start_time),
          end: new Date(eachTask.end_time),
          name: eachTask.linked.name || eachTask.linked.code,
          id: eachTask.linked.id,
          progress: 25,
          type: "project",
          parentSequence: eachTask.linked.parent_sequence || "Asset",
          hideChildren: false,
          displayOrder: index + 1,
        };

        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(eachTask.start_time),
          end: new Date(eachTask.end_time),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 2,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
        };
        localTasks.push(projectObj);
        localTasks.push(taskObj);
      } else {
        localTasks.map((eachItem) => {
          if (
            eachItem.type === "project" &&
            eachItem.id === eachTask.linked.id
          ) {
            if (
              new Date(eachItem.end).getTime() <
              new Date(eachTask.end_time).getTime()
            ) {
              eachItem.end = new Date(eachTask.end_time);
            }
            return null;
          } else return null;
        });
        let taskObj = {
          id: eachTask.id,
          type: "task",
          name: eachTask.name,
          start: new Date(eachTask.start_time),
          end: new Date(eachTask.end_time),
          /**
           * From 0 to 100
           */
          progress: 0,
          isDisabled: false,
          // project: string;
          // dependencies: getNextField(eachTask.id, tasksList),
          hideChildren: false,
          displayOrder: index + 1,
          isChildTask: true,
          taskStatus: eachTask.status || {},
          linked: eachTask.linked.id,
          project: eachTask.linked.id,
        };
        localTasks.push(taskObj);
      }
    });
  }
  return localTasks;
}
