import React from "react";
import EpisodesOverview from "../../Components/Episodes/EpisodesOverview/EpisodesOverview.component";

function EpisodesPage(props) {
  return (
    <div>
      <EpisodesOverview params={props.match.params} />
    </div>
  );
}

export default EpisodesPage;
