import MomentUtils from "@date-io/moment";
import { Button, Grid, Modal, Radio } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import { X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { fget, fpost } from "../../API/callsAPI";
import DateAndTimeViewer from "../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import Loader from "../Utilities/Loader/Loader";
import PdfReport from "./PdfReport";
import useStyles from "./PdfReportsStyles";

const PdfReportsOverview = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [projectItems, setProjectItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectProjectModal, setSelectProjectModal] = useState(false);
  const containerRef = useRef(null);

  const fetchActiveProjects = async () => {
    try {
      const projectsRes = await fget({
        url: `project/`,
      });
      setProjectItems(projectsRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
    }
  };

  const handleGetReportData = async () => {
    setIsReportLoading(true);
    const data = {
      url: "trackables/generate-task-report/",
      data: {
        start_date: startDate,
        end_date: endDate,
        ids: selectedProjects.map((item) => item.id),
      },
    };
    try {
      const res = await fpost(data);

      setIsReportLoading(false);
      setReports(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectOrUnselectProject = (project) => {
    if (selectedProjects.length) {
      const isExist = selectedProjects?.some((item) => item.id == project.id);
      if (isExist) {
        const others = selectedProjects?.filter(
          (item) => item.id != project.id
        );
        setSelectedProjects(others);
      } else {
        setSelectedProjects([...selectedProjects, project]);
      }
    } else {
      setSelectedProjects([project]);
    }
  };

  const generatePdf = async () => {
    try {
      if (reports?.length) {
        const blob = await pdf(
          <PdfReport
            reports={reports}
            startDate={startDate}
            endDate={endDate}
          />
        ).toBlob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `REPORT_${new Date().toISOString()}.pdf`;

        containerRef.current?.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        containerRef.current?.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchActiveProjects();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
        <div className={classes.cardContentDiv2}>
          <div>
            <label className={classes.label}>Start Date</label>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                invalidDateMessage={"inValid"}
                variant="inline"
                format="yyyy/MM/DD"
                style={{ marginTop: "2.7%" }}
                value={startDate}
                onChange={(date) => setStartDate(new Date(date))}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "150px",
                    fontSize: "14px",
                    padding: "2%",
                  },
                }}
                className={classes.input}
                id="Startdate-picker"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: {
                    padding: "2%",
                  },
                }}
                size="small"
                fullWidth={false}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <label className={classes.label}>End Date</label>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                invalidDateMessage={"inValid"}
                variant="inline"
                style={{ marginTop: "2.7%" }}
                format="yyyy/MM/DD"
                value={endDate}
                onChange={(date) => setEndDate(new Date(date))}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "150px",
                    fontSize: "14px",
                    padding: "2%",
                  },
                }}
                className={classes.input}
                id="Startdate-picker"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: {
                    padding: "2%",
                  },
                }}
                size="small"
                fullWidth={false}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <Button
          fullWidth
          className={classes.doneButton}
          onClick={() => setSelectProjectModal(true)}
        >
          Select Project
        </Button>
        <Button
          fullWidth
          className={classes.doneButton}
          onClick={handleGetReportData}
          disabled={
            startDate && endDate && selectedProjects?.length ? false : true
          }
        >
          Generate Report
        </Button>
        <Button
          fullWidth
          className={classes.doneButton}
          onClick={generatePdf}
          disabled={reports?.length ? false : true}
        >
          Download PDF
        </Button>
      </div>
      <div>
        <p>Selected Projects</p>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          {selectedProjects?.map((project) => (
            <div className={classes.selectedProjectsContainer}>
              <div className={classes.selectedProject}>
                <img
                  src={
                    project.thumbnail ||
                    "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  }
                  alt=""
                  height="50px"
                  width="50px"
                />
                <p>{project.name}</p>
              </div>
              <Button onClick={() => handleSelectOrUnselectProject(project)}>
                <X />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.reportsContainer}>
        {!isReportLoading ? (
          <div className={classes.reports}>
            {reports?.map((single) => (
              <div className={classes.report}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <span className={classes.projectName}>
                      {single?.project?.name}
                    </span>
                    <p>{single?.project?.abbreviation}</p>
                  </div>
                  <div>
                    <div className={classes.flexAndGap}>
                      <p>Start Date :</p>
                      <DateAndTimeViewer dateAndTime={startDate} />
                    </div>
                    <div className={classes.flexAndGap}>
                      <p>End Date :</p>
                      <DateAndTimeViewer dateAndTime={endDate} />
                    </div>
                  </div>
                </div>
                <div>
                  <Grid container className={classes.gridUnderline}>
                    <Grid item md={6}>
                      <p className={classes.artist}>Artist</p>
                    </Grid>
                    <Grid item md={2}>
                      <p className={classes.text}>Blocked</p>
                    </Grid>
                    <Grid item md={2}>
                      <p className={classes.text}>Delayed</p>
                    </Grid>
                    <Grid item md={2}>
                      <p className={classes.text}>In Progress</p>
                    </Grid>
                  </Grid>
                  {single?.users?.map((user, index) => (
                    <Grid
                      container
                      style={{
                        borderBottom:
                          index !== single.users?.length - 1 &&
                          "1px solid gray",
                      }}
                    >
                      <Grid item md={6}>
                        <p>
                          {user?.user?.first_name + "  " + user.user?.last_name}
                        </p>
                      </Grid>
                      <Grid item md={2}>
                        <p className={classes.text}>{user.blocked_tasks}</p>
                      </Grid>
                      <Grid item md={2}>
                        <p className={classes.text}>{user?.delayed_tasks}</p>
                      </Grid>
                      <Grid item md={2}>
                        <p className={classes.text}>
                          {user?.in_progress_tasks}
                        </p>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        open={selectProjectModal}
        onClose={() => setSelectProjectModal(false)}
        className={classes.flexCenter}
      >
        <div className={classes.modalCard}>
          <div className={classes.flexBetween}>
            <p>Select Project</p>
            <Button onClick={() => setSelectProjectModal(false)}>
              <X />
            </Button>
          </div>
          {isLoaded ? (
            <div className={classes.modalProjects}>
              {projectItems.map((project) => {
                const isExist = selectedProjects?.some(
                  (item) => item.id == project.id
                );
                return (
                  <div className={classes.singleProjectOfModal}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Radio
                        onClick={() => handleSelectOrUnselectProject(project)}
                        checked={isExist}
                      />
                      <p>{project.name}</p>
                    </div>
                    <img
                      src={
                        project.thumbnail ||
                        "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      }
                      alt=""
                      height="50px"
                      width="50px"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <Loader />
          )}
          <div
            className={classes.flexCenter}
            style={{
              marginTop: "6px",
            }}
          >
            <Button
              className={classes.doneButton}
              onClick={() => setSelectProjectModal(false)}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PdfReportsOverview;
