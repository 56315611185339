import Joyride from "react-joyride";
import useStyles from "./CustomAppTour.style";
import { Button } from "@material-ui/core";

const CustomAppTour = ({ run, steps, handleFinish, handleOthers }) => {
  const classes = useStyles();
  return (
    <Joyride
      continuous
      callback={(e) => {
        if (e.action === "reset") {
          handleFinish();
        } else {
          handleOthers && handleOthers(e);
        }
      }}
      hideCloseButton
      scrollToFirstStep
      showSkipButton
      showProgress
      run={run}
      locale={{
        skip: <Button className={classes.skipBtn}>SKIP TOUR</Button>,
        back: "BACK",
        next: "NEXT",
      }}
      steps={steps}
      styles={{
        options: {
          primaryColor: "#6C65D9",
          backgroundColor: "#1B1D28",
          textColor: "white",
          arrowColor: "#1B1D28",
          width: 520,
          zIndex: 9999,
        },
        buttonSkip: {
          background: "#6C65D9",
          color: "white",
          border: "none",
          borderRadius: 5,
          padding: "4px 16px",
          margin: 10,
        },
      }}
    />
  );
};

export default CustomAppTour;
