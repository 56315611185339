import React from "react";
import useStyles from "./iconSize.styles";

export function ProjectMediaIconSmall() {
  const classes = useStyles();
  return (
    <svg
      className={classes.iconsize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3333 9.66683H8.33329C6.67644 9.66683 5.33329 11.01 5.33329 12.6668V23.0002H14.6667C15.403 23.0002 16 23.5971 16 24.3335C16 25.0699 15.403 25.6668 14.6667 25.6668H5.33329C4.62605 25.6668 3.94777 25.3859 3.44767 24.8858C2.94758 24.3857 2.66663 23.7074 2.66663 23.0002V7.00016C2.66663 5.52016 3.85329 4.3335 5.33329 4.3335H13.3333L16 7.00016H26.6666C27.3739 7.00016 28.0522 7.28111 28.5522 7.78121C29.0523 8.28131 29.3333 8.95959 29.3333 9.66683Z"
        fill="#F9F9F9"
      />
      <path
        d="M28.6786 20.4539C29.1071 20.6966 29.1071 21.3034 28.6786 21.5461L20.9643 25.9146C20.5357 26.1573 20 25.8539 20 25.3685L20 16.6315C20 16.1461 20.5357 15.8427 20.9643 16.0854L28.6786 20.4539Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export function ProjectMediaIconBig() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3333 9.66683H8.33329C6.67644 9.66683 5.33329 11.01 5.33329 12.6668V23.0002H14.6667C15.403 23.0002 16 23.5971 16 24.3335C16 25.0699 15.403 25.6668 14.6667 25.6668H5.33329C4.62605 25.6668 3.94777 25.3859 3.44767 24.8858C2.94758 24.3857 2.66663 23.7074 2.66663 23.0002V7.00016C2.66663 5.52016 3.85329 4.3335 5.33329 4.3335H13.3333L16 7.00016H26.6666C27.3739 7.00016 28.0522 7.28111 28.5522 7.78121C29.0523 8.28131 29.3333 8.95959 29.3333 9.66683Z"
        fill="#F9F9F9"
      />
      <path
        d="M28.6786 20.4539C29.1071 20.6966 29.1071 21.3034 28.6786 21.5461L20.9643 25.9146C20.5357 26.1573 20 25.8539 20 25.3685L20 16.6315C20 16.1461 20.5357 15.8427 20.9643 16.0854L28.6786 20.4539Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}
