import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listText: {
    color: theme.palette.text.primary,
    padding: "2px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "16.6%",
  },
  actionBtn: {
    minWidth: "0px",
    padding: "5px",
  },
  reviewListModals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionIcons: {
    color: theme.palette.text.tertiary,
  },
  avatarWithName: {
    border: `2px solid ${theme.palette.text.tertiary}`,
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "25px",
    padding: "1px 7px 1px 0px",
  },
  reviewPageModals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
