import { Box, Button, Chip, Grid, Typography } from "@material-ui/core";
import { CaretDown, CaretRight, DotsThree } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Circle, X } from "react-feather";
import { fget } from "../../../API/callsAPI";
import DateAndTimeViewer from "../DateAndTimeViewer/DateAndTimeViewer.component";
import Loader from "../Loader/Loader";
import StatusViewer from "../StatusViewer/StatusViewer";
import useStyles from "./AssetAndShotVersionTimeline.styles";
import NoResultFound from "../NoResultFound/NoResultFound";

const AssetAndShotVersionTimeline = ({ id, type, parent, handleClose }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [timelines, setTimelines] = useState([]);

  const handleGetTimeline = async () => {
    setIsLoaded(false);
    try {
      const url = `trackables/version-timeline?type=${type}&id=${id}`;
      const res = await fget({ url: url });
      setIsLoaded(true);
      setTimelines(res?.data);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    handleGetTimeline();
  }, [id, type]);
  return (
    <Box className={classes.card}>
      <Box className={classes.flexBetween}>
        <Typography className={classes.title}>
          {parent?.name ? parent?.name : parent?.code}
        </Typography>
        <Box>
          <Button>
            <DotsThree size={24} />
          </Button>
          <Button onClick={handleClose}>
            <X />
          </Button>
        </Box>
      </Box>
      <Typography className={classes.listText}>
        {parent?.description ? parent?.description : ""}
      </Typography>
      <Box className={classes.flexBetween}>
        <Typography>Date</Typography>
        <Box>
          <StatusViewer status={parent?.status} />
        </Box>
      </Box>
      <Box className={classes.flexBetween}>
        <Typography className={classes.textGray}>Department Name</Typography>
        {parent?.allowed_dept?.length ? (
          <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {parent?.allowed_dept?.map((sin) => (
              <Chip
                className={classes.listText}
                style={{ fontSize: "smaller", minWidth: "20px" }}
                size="small"
                label={sin?.name}
                variant="outlined"
              />
            ))}
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Box className={classes.versionsContainer}>
        <Box
          className={
            timelines?.length ? classes.versions : classes.versionsWithNoBorder
          }
        >
          <Box className={classes.singleVersion}>
            <Box style={{ marginLeft: "-10px" }}>
              <Circle fill="#6C65D9" size={20} stroke="#6C65D9" weight="fill" />
            </Box>
            <Typography>Total Versions : {timelines?.length}</Typography>
          </Box>
          {isLoaded ? (
            <>
              {timelines?.length ? (
                <>
                  {timelines?.map((timeline) => (
                    <SingleVersionDetails timeline={timeline} />
                  ))}
                </>
              ) : (
                <NoResultFound />
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssetAndShotVersionTimeline;

const SingleVersionDetails = ({ timeline }) => {
  const classes = useStyles();
  const [isStatusCollapsed, setIsStatusCollapsed] = useState(true);

  return (
    <Box>
      <Box className={classes.singleVersion}>
        <Box style={{ marginLeft: "-10px" }}>
          <Circle
            fill={
              timeline?.status?.id ? `rgb(${timeline?.status?.color})` : "black"
            }
            size={20}
            weight="fill"
            stroke="#6C65D9"
          />
        </Box>
        <Box className={classes.singleVersionDetails}>
          <Typography>{timeline.name}</Typography>
          <Box>
            <Typography className={classes.flexAndGap}>
              <span className={classes.textPurple}>Date Created : </span>{" "}
              <DateAndTimeViewer dateAndTime={timeline?.created_at} />
            </Typography>
          </Box>
          <Box className={classes.flexBetween}>
            <Typography className={classes.flexAndGap}>
              <span className={classes.textPurple}>Date Modified : </span>
              <DateAndTimeViewer dateAndTime={timeline?.updated_at} />
            </Typography>
            <Typography>
              {timeline?.status?.id ? (
                <StatusViewer status={timeline?.status} />
              ) : (
                "No Status"
              )}
            </Typography>
          </Box>
          <Box className={classes.singleVersionStatusLogs}>
            <Button
              onClick={() => setIsStatusCollapsed(!isStatusCollapsed)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "0px",
              }}
            >
              <Typography>Status Logs</Typography>
              {isStatusCollapsed ? (
                <CaretRight size={20} color="#6C65D9" weight="fill" />
              ) : (
                <CaretDown size={20} color="#6C65D9" weight="fill" />
              )}
            </Button>
            {!isStatusCollapsed ? (
              <Box style={{ marginTop: "6px" }}>
                {timeline?.status_list?.map((singleStatus) => (
                  <Box className={classes.singleStatusDetails}>
                    <Grid container>
                      <Grid item md={6}>
                        <Typography className={classes.textGray}>
                          <DateAndTimeViewer
                            dateAndTime={singleStatus?.created_at}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          className={classes.textGray}
                          style={{ textAlign: "left" }}
                        >
                          {singleStatus?.created_by?.first_name ?? " "}
                          {"  "}
                          {singleStatus?.created_by?.last_name ?? " "}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          className={classes.textGray}
                          style={{ textAlign: "right" }}
                        >
                          {singleStatus?.status?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
