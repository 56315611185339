import ConfigureNotifications from "./ConfigureNotifications/ConfigureNotifications";
import ChannelConnectorConfiguration from "./ChannelConnectorConfiguration/ChannelConnectorConfiguration";
import { Divider } from "@material-ui/core";
export default function GeneralSettingOverview({ projectId, setSnackValue }) {
  return (
    <>
      <div style={{ marginTop: "0.75%" }}>
        <ConfigureNotifications
          setSnackValue={setSnackValue}
          projectId={projectId}
        />
      </div>
      <Divider />
      {/* <div style={{ marginTop: "0.75%" }}>
        <ConfigureEmail
          setSnackValue={setSnackValue}
          emailNotifications={emailEvents}
          projectId={projectId}
        />
      </div>
      <Divider /> */}
      <div style={{ marginTop: "0.75%" }}>
        <ChannelConnectorConfiguration
          projectId={projectId}
          setSnackValue={setSnackValue}
        />
      </div>
    </>
  );
}
