import React from "react";
import PropTypes from "prop-types";
import ReviewVersionOverview from "../../Components/ReviewVersion/ReviewVersionOverview/ReviewVersionTools/ReviewVersionOverview.component";

export default function ReviewVersion(props) {
  return (
    <div>
      <ReviewVersionOverview
        params={props.params}
        externalReviewer={props.externalReviewer}
      />
    </div>
  );
}
