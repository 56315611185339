import React from "react";
import * as Icon from "react-feather";

//@MUI
import { Button, Tooltip } from "@material-ui/core";

import useStyles from "./AssetVersionsListStatusButtons.styles";

export default function AssetVersionsListStatusButtons(props) {
  const classes = useStyles();
  return (
    <>
      <Tooltip title="Edit Asset" placement="top">
        <Button
          id="editAssetVersion"
          className={classes.inlineButtons}
          onClick={(e) => props.openEditAssetVerisonModal(e)}
        >
          <Icon.Edit3 style={{ padding: "2px" }} />
        </Button>
      </Tooltip>
      {/* <Button
        variant="outlined"
        color="secondary"
        id="deleteAssetVersion"
        className={classes.inlineButtons}
        onClick={props.openDeleteAssetVersionModal}
      >
        <Icon.Trash2 style={{ padding: "2px" }} />
      </Button> */}
    </>
  );
}
