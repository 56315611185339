import {
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Modal,
  Tooltip,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {
  ArrowBendDownRight,
  Clock,
  PaperPlaneTilt,
  ThumbsUp,
} from "phosphor-react";
import React, { useState } from "react";
import * as Icon from "react-feather";
import { fdelete } from "../../../../API/callsAPI";
import PostEditor from "../../../PostEditor/postEditor";
import DateAndTimeViewer from "../../DateAndTimeViewer/DateAndTimeViewer.component";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import UserAvatar from "../../UserAvatar/UserAvatar.component";
import useStyles from "./CommentOverview.styles";
import SkeletonDiv from "./SkeletonDiv";

// make dragable comment here
// add skeleton loader as well
export default function CommentsOverview({
  commentList,
  setCommentList,
  loggedInUser,
  setTaskNameFromComment,
  fetchComments,
  allUsersName,
  handleSubmit,
  changeSeekTime,
  matchHeight,
  commentsPageNo,
  setCommentsPageNo,
  currentTime,
  showTimeStamp,
}) {
  const [open, setOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [repliedTo, setRepliedTo] = useState({});
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [addingComment, setAddingComment] = useState(false);
  const [resetCommentText, setResetCommentText] = React.useState(0);
  const [isTimeStampChecked, setIsTimeStampChecked] = useState(true);

  let mentionsRef = React.createRef();
  let replyRef = React.createRef();
  const classes = useStyles();

  const deleteComment = (comment) => {
    setSelectedCommentId(comment.id);
    setDeleteCommentModalOpen(true);
  };

  let changeColor;
  const scrollTo = (comment) => {
    if (changeColor !== null) clearTimeout(changeColor);
    const scrollItem = document.getElementById(comment?.replied_to?.id);
    if (scrollItem) {
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
      if (scrollItem?.style) {
        scrollItem.style.backgroundColor = "#252A38";
      }
    }
    changeColor = setTimeout(() => {
      if (scrollItem?.style) {
        scrollItem.style.backgroundColor = "#1B1D28";
      }
    }, 5000);
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `trackables/trackable-comment/${selectedCommentId}/`,
      });
      const res = await fetchComments();
      setCommentList({
        data: res?.comments,
        loading: false,
        isNextAvailable: res.isNextAvailable,
      });
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
      return "deleted";
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  const getTimeStamp = () => {
    return isTimeStampChecked && currentTime !== "00:00" && currentTime > 0
      ? currentTime
      : "";
  };
  const addNewComment = async () => {
    if (!mentionsRef.current.toHtml()) {
      return;
    }
    setAddingComment(true);
    try {
      const newCommentRes = await handleSubmit({
        description: getTimeStamp() + mentionsRef.current.toHtml(),
        mentions: mentionsRef.current.onExtractMentions(),
        attachmentImage: attachmentImage,
      });
      setAttachmentImage(null);
      if (newCommentRes) {
        mentionsRef.current?.reset();
        const commentsRes = await fetchComments();
        setAddingComment(false);
        setCommentList({
          data: commentsRes?.comments,
          loading: false,
          isNextAvailable: commentsRes?.isNextAvailable,
        });
        setResetCommentText(resetCommentText + 1);
        scrollTo(newCommentRes);
      }
    } catch (error) {
      console.log(error);
      setAddingComment(false);
    }
  };

  const replyComment = async () => {
    if (!replyRef.current.toHtml()) {
      return;
    }
    setAddingComment(true);
    try {
      const replyCommentRes = await handleSubmit({
        description: replyRef?.current?.toHtml(),
        mentions: replyRef?.current?.onExtractMentions(),
        repliedTo,
      });
      setAttachmentImage(null);
      if (replyCommentRes) {
        replyRef?.current?.reset();
        setShowReplyBox(false);
        const commentsRes = await fetchComments();
        setAddingComment(false);
        setCommentList({
          data: commentsRes?.comments,
          loading: false,
          isNextAvailable: commentsRes?.isNextAvailable,
        });
        setResetCommentText(resetCommentText + 1);
        scrollTo(replyCommentRes);
      }
    } catch (error) {
      console.log(error);
      setAddingComment(false);
    }
  };

  function formatTime(seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div className={classes.comment}>
        <span>Comments</span>
        <span style={{ marginRight: "18px" }}>
          {commentList.data.length} comments
        </span>
      </div>
      <div
        style={{
          height: matchHeight ? "calc(100% - 107px)" : "calc(100% - 97px)",
          overflowY: "scroll",
          marginBottom: "12px",
        }}
      >
        {/* single comment */}
        {commentList.loading ? (
          <div style={{ margin: "15px 5px" }}>
            {/* calling this component 3 times to fill up comments area */}
            <SkeletonDiv />
            <SkeletonDiv />
            <SkeletonDiv />
          </div>
        ) : (
          commentList.data.map((eachComment) => (
            <SingleComment
              eachComment={eachComment}
              key={eachComment?.id}
              loggedInUser={loggedInUser}
              setRepliedTo={setRepliedTo}
              setShowReplyBox={setShowReplyBox}
              deleteComment={deleteComment}
              setOpen={setOpen}
              setDisplayImage={setDisplayImage}
              changeSeekTime={changeSeekTime}
            />
          ))
        )}
        <div
          className={classes.flexCenter}
          style={{ display: commentList?.isNextAvailable ? "flex" : "none" }}
        >
          <Button
            className={classes.showMoreButton}
            onClick={() => setCommentsPageNo(commentsPageNo + 1)}
          >
            See More
          </Button>
        </div>
      </div>
      {showReplyBox ? (
        <div style={{ position: "relative" }} onKeyDown={handleKeyDown}>
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ height: "40px" }}>
              {attachmentImage ? (
                <Chip
                  avatar={
                    <img
                      src={
                        attachmentImage ||
                        "https://fastly.picsum.photos/id/175/200/200.jpg?hmac=5rzD884Hqi9oWr_n5vg0XSc9f6yMlPotvKa8Y0cVzd4"
                      }
                      style={{
                        height: "40px",
                        width: "40px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  }
                  style={{ borderRadius: "10px", height: "45px" }}
                  variant="outlined"
                  onDelete={() => {
                    setAttachmentImage(null);
                  }}
                ></Chip>
              ) : (
                ""
              )}
            </div>
            <div className={classes.replyCommentField}>
              <div>
                <Card
                  className={classes.replyCard}
                  onClick={() => {
                    scrollTo(repliedTo);
                  }}
                >
                  {repliedTo.attachment ? (
                    <img
                      // className={classes.AssetImage}
                      onClick={() => {
                        setOpen(true);
                        setDisplayImage(repliedTo.attachment);
                      }}
                      alt="projectImage"
                      src={repliedTo.attachment}
                      height="50px"
                      width="50px"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  ) : null}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "92%",
                      height: "30px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: repliedTo.description
                        .replace(/<p>/g, "<div>")
                        .replace(/<\/p>/g, "</div>")
                        .replace("0:0", ""),
                    }}
                  ></span>
                  <div
                    onClick={() => {
                      setShowReplyBox(false);
                    }}
                  >
                    <Icon.X size={20} />
                  </div>
                </Card>
              </div>
              <PostEditor
                placeholder={"Type a Comment..."}
                mentions={allUsersName}
                ref={replyRef}
                handleAddImageAttachment={(image) => setAttachmentImage(image)}
                handleFilePaste={(file) => {
                  setAttachmentImage(URL.createObjectURL(file[0]));
                }}
                handleSubmit={() => replyComment()}
                resetPrevious={resetCommentText}
              />
            </div>
            <Button
              className={classes.submitButton}
              onClick={() => replyComment()}
              disabled={addingComment ? true : false}
            >
              {addingComment ? (
                <CircularProgress size={20} />
              ) : (
                <PaperPlaneTilt size={22} />
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }} onKeyDown={handleKeyDown}>
          <div style={{ height: "40px" }}>
            {attachmentImage ? (
              <Chip
                avatar={
                  <img
                    src={
                      attachmentImage ||
                      "https://fastly.picsum.photos/id/175/200/200.jpg?hmac=5rzD884Hqi9oWr_n5vg0XSc9f6yMlPotvKa8Y0cVzd4"
                    }
                    style={{
                      height: "40px",
                      width: "40px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                }
                style={{ borderRadius: "10px", height: "45px" }}
                variant="outlined"
                onDelete={() => {
                  setAttachmentImage(null);
                }}
              ></Chip>
            ) : (
              ""
            )}
          </div>
          <div className={classes.submitCommentField}>
            {showTimeStamp ? (
              <div className={classes.timeStampDiv}>
                <Clock />
                <span>
                  {formatTime(currentTime !== "00:00" ? currentTime : 0)}
                </span>
                <Checkbox
                  checked={isTimeStampChecked}
                  size="small"
                  onClick={() => setIsTimeStampChecked(!isTimeStampChecked)}
                  style={{
                    padding: "0px",
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <PostEditor
              placeholder={"Type a Comment..."}
              mentions={allUsersName}
              ref={mentionsRef}
              handleAddImageAttachment={(image) => setAttachmentImage(image)}
              handleFilePaste={(file) => {
                setAttachmentImage(URL.createObjectURL(file[0]));
              }}
              handleSubmit={() => addNewComment()}
              resetPrevious={resetCommentText}
            />
          </div>
          <Button
            className={classes.submitButton}
            onClick={() => addNewComment()}
            disabled={addingComment ? true : false}
          >
            {addingComment ? (
              <CircularProgress size={20} />
            ) : (
              <PaperPlaneTilt size={22} />
            )}
          </Button>
        </div>
      )}

      <div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={classes.flexCenter}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              outline: "none",
              height: "80vh",
              width: "65%",
            }}
          >
            <Icon.X
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
            <img
              src={displayImage}
              height="90%"
              width="100%"
              onContextMenu={(e) => e.preventDefault()}
              alt="from annotate"
            ></img>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.flexCenter}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}

const SingleComment = ({
  eachComment,
  loggedInUser,
  setRepliedTo,
  setShowReplyBox,
  deleteComment,
  setDisplayImage,
  setOpen,
  changeSeekTime,
}) => {
  const classes = useStyles();
  const [showReplies, setShowReplies] = useState(false);

  const getDisplayTime = (time) => {
    if (time.includes(":")) {
      return time;
    }
    let seconds = 0;
    let minutes = 0;
    if (time % 60) {
      minutes = parseInt(time / 60);
      seconds = parseInt(time % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  return (
    <>
      <div
        id={eachComment.id}
        style={{
          marginTop: "8px",
        }}
      >
        <div className={classes.commentHead}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserAvatar
              avatar={eachComment?.author?.avatar}
              firstName={eachComment?.author?.first_name[0]}
              lastName={eachComment?.author?.last_name[0]}
            />
            <span style={{ margin: "0px 8px" }}>
              {eachComment.author.first_name} {eachComment.author.last_name}
            </span>
          </div>
          <span className={classes.time}>
            <DateAndTimeViewer dateAndTime={eachComment.created_at} />
          </span>
        </div>

        <div
          style={{
            marginLeft: "40px",
          }}
        >
          {/* add show more on big comments */}
          <div style={{ display: "flow" }}>
            {eachComment?.description?.split("<p>")[0] ? (
              <span
                className={classes.annotationText}
                onClick={(e) =>
                  changeSeekTime(eachComment?.description?.split("<p>")[0])
                }
              >
                {getDisplayTime(eachComment?.description?.split("<p>")[0])}
              </span>
            ) : null}
            <span
              dangerouslySetInnerHTML={{
                __html: eachComment?.description
                  ?.split("<p>")[1]
                  ?.split("</p>")[0],
              }}
            />
          </div>
        </div>

        {eachComment.attachment ? (
          <img
            className={classes.image}
            onClick={() => {
              setOpen(true);
              setDisplayImage(eachComment.attachment);
            }}
            alt="projectImage"
            src={eachComment.attachment}
            height="190px"
            width="358px"
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : null}
        <div
          className={classes.flexBetween}
          style={{ margin: "4px 0px 4px 40px" }}
        >
          <div>
            <Button
              onClick={() => setShowReplies(!showReplies)}
              className={classes.showHideButton}
              disabled={eachComment?.replies?.length ? false : true}
            >
              {eachComment?.replies?.length ? (
                <>
                  {showReplies
                    ? "Hide Replies"
                    : `Show ${eachComment?.replies?.length} Replies`}
                </>
              ) : (
                "No Replies"
              )}
            </Button>
          </div>
          <div className={classes.buttons}>
            <Tooltip title="Reply" placement="top-start">
              <Button
                className={classes.commonIconButton}
                onClick={() => {
                  setRepliedTo(eachComment);
                  setShowReplyBox(true);
                }}
              >
                <Icon.CornerDownLeft style={{ hight: "16px", width: "16px" }} />
              </Button>
            </Tooltip>
            {eachComment.author.id === loggedInUser?.user_id ? (
              <Tooltip title="Delete Comment" placement="top-start">
                <Button
                  className={classes.commonIconButton}
                  onClick={() => deleteComment(eachComment)}
                >
                  <Icon.Trash2 style={{ hight: "16px", width: "16px" }} />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
        <Divider />
      </div>
      <div
        style={{
          marginLeft: "20px",
          maxHeight: showReplies ? "300px" : "0px",
          overflowY: "auto",
          marginRight: "6px",
          transition: "max-height 0.5s ease-in-out",
        }}
      >
        {eachComment?.replies?.map((single) => (
          <div
            id={single.id}
            style={{
              marginTop: "8px",
            }}
          >
            <div className={classes.commentHead}>
              <div className={classes.repliesHead}>
                <ArrowBendDownRight size={20} className={classes.colorText} />
                <UserAvatar
                  avatar={single?.author?.avatar}
                  firstName={
                    single?.author?.first_name
                      ? single?.author?.first_name[0]
                      : ""
                  }
                  lastName={
                    single?.author?.last_name
                      ? single?.author?.last_name[0]
                      : ""
                  }
                />
                <span style={{ margin: "0px 2px" }}>
                  {single.author.first_name} {single.author.last_name}
                </span>
              </div>
              <span className={classes.time}>
                <DateAndTimeViewer dateAndTime={eachComment.created_at} />
              </span>
            </div>

            <div className={classes.replyDescription}>
              {/* add show more on big comments */}
              <span
                dangerouslySetInnerHTML={{
                  __html: single?.description
                    ?.split("<p>")[1]
                    ?.split("</p>")[0],
                }}
              />
            </div>
            <div className={classes.replyIcons}>
              <Button className={classes.commonIconButton}>
                <ThumbsUp size={20} />
              </Button>
              {single.author.id === loggedInUser?.user_id ? (
                <Tooltip title="Delete Comment" placement="top-start">
                  <Button
                    className={classes.commonIconButton}
                    onClick={() => deleteComment(single)}
                  >
                    <Icon.Trash2 style={{ hight: "16px", width: "16px" }} />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </>
  );
};
