// Library
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./theme";

//Pages
import _, { isNumber } from "lodash";
import { Route, Switch, useHistory } from "react-router-dom";
import { fget } from "./API/callsAPI";
import "./App.css";
import AuthPageOverview from "./Components/AuthPageComponents/AuthPageOverview.jsx";
import PushNotificationTest from "./Components/Fcm/PushNotification.component.jsx";
import SidebarAndNavbar from "./Components/SidebarAndNavbar/SidebarAndNavbar.component";
import { auth, getPayload, readCookie } from "./Components/authorization";
import MovieColabDataProvider from "./Context/MovieColabContext.jsx";
import UnAuthorized from "./Pages/InformativeComponents/UnAuthorized.component";
import ProjectsPage from "./Pages/ProjectsPage/ProjectsPage.component";
import RegisterAppRoutes from "./RegisterAppRoutes.jsx";

const SERVICE_IDENTIFIER = process.env.SERVICE_IDENTIFIER;
//change
function App({ location }) {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") === null
      ? "dark"
      : localStorage.getItem("theme")
  );
  const history = useHistory();

  const [loggedIn, setloggedIn] = useState(false);

  const showSeqExperimental = localStorage.getItem("sequencerExp");
  const [isAuthorized, setIsAuthorized] = useState(false);
  //users info state
  const [loggedInUser, setLoggedInUser] = useState({});
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
  //selected Project
  const [selectedProjectData, setSelectedProjectData] = useState({}); //holds project id.
  const [userRoleForProject, setUserRoleForProject] = useState("reviewer"); //admin || user || reviewer any one of the following.

  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === "light") {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
      // otherwise, it should be light
    } else {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
    window.location.reload();
  };

  let authorized = false;
  useEffect(() => {
    const accesstoken = readCookie("access");
    if (accesstoken) {
      setloggedIn(true);
      const payload = getPayload(accesstoken);

      if (payload?.aud?.length) {
        for (let i = 0; i < payload.aud.length; i++) {
          if (payload.aud[i] === SERVICE_IDENTIFIER) {
            authorized = true;
            setIsAuthorized(true);
          }
        }
      }
    } else {
      setloggedIn(false);
    }

    return () => {};
  }, []);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line no-useless-escape
  }, []);

  useEffect(() => {
    //if it is redirected and there is no selected project
    var routePath = history?.location.pathname.split("/");
    routePath = routePath.filter((eachItem) => eachItem !== "");
    if (routePath.length) {
      setIsUserInfoLoading(true);
      const localProjectId = routePath[0];
      // if project id is not found
      if (!isNumber(Number(localProjectId))) {
        return;
      }
      //this means it is no redirected from home
      //but it is an direct url direction for other internal pages
      //step 1 is to fetch and get project object
      async function getProjectObject() {
        try {
          const projectDetailObject = await fget({
            url: `project/${localProjectId}/`,
          });
          if (projectDetailObject.status === 200) {
            setUserRoleForProject(projectDetailObject.data.user_role);
            setSelectedProjectData(projectDetailObject.data);
            setIsUserInfoLoading(false);
          }
        } catch (error) {
          setIsUserInfoLoading(false);
        }
      }
      getProjectObject();
    }
    if (_.isEmpty(selectedProjectData)) {
    }
    return () => {};
  }, []);

  //fetch user details before registering other routes.
  const getUserDetails = async () => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (accessToken) {
      const userId = JSON.parse(atob(accessToken.split(".")[1])).user_id;
      try {
        const res = await fget({ url: `user/${userId}/` });
        setLoggedInUser(res.data);
        console.log(loggedInUser);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //<ErrorHandler FallbackComponent={ErrorHandler}></ErrorHandler>

  //if deployment is crossdomain show moviecolab's own login page
  //A user with role of reviewer in a project does not have access to other pages.
  //how to fix the flow? Ans: we can show projects page before registering sidebar and other routes.

  const ProjectPageComponent = (props) => {
    return <ProjectsPage {...props} />;
  };

  /**
   * Navigates user to appropriate project.
   *
   * @params ProjectObject
   *
   */
  async function handleProjectChange(projectData) {
    //function takes project id and fetches users role for that project.
    //get external reviewer out of project.
    setUserRoleForProject(projectData.user_role);
    setSelectedProjectData(projectData);
    if (projectData.user_role === "reviewer") {
      history.push(`${projectData.id}/review`);
    } else {
      history.push(`${projectData.id}/updates`);
    }
  }

  if (window?.location?.toString().includes("auth")) {
    return (
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <Switch>
          <Route exact path="/auth" component={() => <AuthPageOverview />} />
        </Switch>
      </ThemeProvider>
    );
  } else {
    if (auth(SERVICE_IDENTIFIER)) {
      // if (1) {
      return (
        <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
          <MovieColabDataProvider>
            <PushNotificationTest />
            <SidebarAndNavbar
              themeToggler={toggleTheme}
              showSeqExperimental={showSeqExperimental}
              loggedInUser={loggedInUser}
              selectedProject={selectedProjectData}
              userRoleForProject={userRoleForProject} //admin || user || reviewer
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => (
                    <ProjectPageComponent
                      loggedInUser={loggedInUser}
                      onProjectSelect={handleProjectChange}
                    />
                  )}
                />
                <RegisterAppRoutes
                  showSeqExperimental={showSeqExperimental}
                  userRoleForProject={userRoleForProject}
                  isTimed={true}
                  isUserInfoLoading={isUserInfoLoading}
                />
              </Switch>
            </SidebarAndNavbar>
          </MovieColabDataProvider>
        </ThemeProvider>
      );
    } else {
      return (
        <>
          {isAuthorized ? (
            <UnAuthorized notLoggedIn={true} />
          ) : (
            <>
              <UnAuthorized />
            </>
          )}
        </>
      );
    }
  }
}

export default App;
