import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  root: {
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
    height: `calc(100vh - 210px)`,
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    background: theme.palette.primary.main,
    width: "100%",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  headTableCell: {
    padding: "12px 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    width: "16.6%",
    background: theme.palette.primary.main,
  },
  reviewPageModals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
