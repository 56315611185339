//Check if the file has an image extension
export const checkImage = function (url) {
  if (url) {
    return url.match(/\.(jpeg|jpg|gif|png|bmp)/) != null;
  } else {
    return true;
  }
};
export const checkAudio = function (url) {
  if (url) {
    return url.match(/\.(mp3|wav|ogg|aac|m4a|flac)/) != null;
  } else {
    return true;
  }
};

export const checkVideo = function (url) {
  if (url) {
    return url.match(/\.(mp4|webm|ogg|avi|mov|wmv|flv)/) !== null;
  } else {
    return false;
  }
};


export const checkGlb = function (url) {
  if (url) {
    return url.match(/\.(glb)/) || url.match(/\.(fbx)/) != null;
  } else {
    return false;
  }
};

export const getModelFileType = function (url) {
  if(url.match(/\.(glb)/)){
    return "GLB"
  }
  if(url.match(/\.(fbx)/)){
    return "FBX"
  }
  
}




