import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import { CaretDown, CaretRight, DotsThree } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Circle, X } from "react-feather";
import { fget } from "../../../../API/callsAPI";
import DateAndTimeViewer from "../../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import Loader from "../../../Utilities/Loader/Loader";
import NoResultFound from "../../../Utilities/NoResultFound/NoResultFound";
import StatusViewer from "../../../Utilities/StatusViewer/StatusViewer";
import UpdateDeadlineModal from "../UpdateDeadlineModal/UpdateDeadlineModal";
import useStyles from "./TaskTimelineSlider.style";

const TaskTimelineSlider = ({ handleClose, type, id, name, setSnackValue }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [timelines, setTimelines] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const handleGetTimeline = async () => {
    setIsLoaded(false);
    try {
      const url = `trackables/task-timeline?type=${type.toLowerCase()}&id=${id}`;
      const res = await fget({ url: url });
      setIsLoaded(true);
      setTimelines(res?.data);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (type && id) {
      handleGetTimeline();
    }
  }, [type, id, refresh]);

  return (
    <Box className={classes.card}>
      <Box className={classes.flexBetween}>
        <Typography className={classes.title}>{name}</Typography>
        <Box>
          <Button>
            <DotsThree size={24} />
          </Button>
          <Button onClick={handleClose}>
            <X />
          </Button>
        </Box>
      </Box>
      {/* <Box className={classes.flexBetween}>
        <Typography>Date</Typography>
        <Box>
          <StatusViewer status={parent?.status} />
        </Box>
      </Box> */}
      {/* <Box className={classes.flexBetween}>
        <Typography className={classes.textGray}>Department Name</Typography>
        {parent?.allowed_dept?.length ? (
          <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {parent?.allowed_dept?.map((sin) => (
              <Chip
                className={classes.listText}
                style={{ fontSize: "smaller", minWidth: "20px" }}
                size="small"
                label={sin?.name}
                variant="outlined"
              />
            ))}
          </Box>
        ) : (
          ""
        )}
      </Box> */}
      <Box className={classes.versionsContainer}>
        <Box
          className={classes.versions}
          /* className={
            timelines?.length ? classes.versions : classes.versionsWithNoBorder
          } */
        >
          <Box className={classes.singleVersion}>
            <Box style={{ marginLeft: "-10px" }}>
              <Circle fill="#6C65D9" size={20} stroke="#6C65D9" weight="fill" />
            </Box>
            <Typography>Total Versions : {timelines?.length}</Typography>
          </Box>
          {isLoaded ? (
            <>
              {timelines?.length ? (
                <>
                  {timelines?.map((timeline) => (
                    <SingleVersionDetails
                      timeline={timeline}
                      updateRefreshCounter={() => setRefresh(refresh + 1)}
                      key={timeline?.id}
                      setSnackValue={setSnackValue}
                    />
                  ))}
                </>
              ) : (
                <NoResultFound />
              )}
            </>
          ) : (
            <Loader />
          )}
          {/* {[1, 2, 3, 4, 5, 6]?.map((timeline) => (
            <SingleVersionDetails timeline={timeline} />
          ))} */}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskTimelineSlider;

const SingleVersionDetails = ({
  timeline,
  updateRefreshCounter,
  setSnackValue,
}) => {
  const classes = useStyles();
  const [isStatusCollapsed, setIsStatusCollapsed] = useState(true);
  const [isProgressChangesCollapsed, setIsProgressChangesCollapsed] =
    useState(true);
  const [isUpdateDeadlineModalOpen, setIsUpdateDeadlineModalOpen] =
    useState(false);

  return (
    <Box>
      <Box className={classes.singleVersion}>
        <Box style={{ marginLeft: "-10px" }}>
          <Circle
            fill={
              timeline?.status?.id ? `rgb(${timeline?.status?.color})` : "black"
            }
            size={20}
            weight="fill"
            stroke="#6C65D9"
          />
        </Box>
        <Box className={classes.singleVersionDetails}>
          <Typography>{timeline.name}</Typography>
          <LinearProgress variant="determinate" value={80} />
          <Box className={classes.flexBetween}>
            <Typography>Due In</Typography>
            <Typography>
              {Math.ceil(
                (new Date(timeline?.end_time) - new Date()) /
                  (1000 * 60 * 60 * 24)
              )}{" "}
              Days
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.flexAndGap}>
              <span className={classes.textPurple}>Start Date : </span>{" "}
              <DateAndTimeViewer dateAndTime={new Date(timeline.start_time)} />
            </Typography>
          </Box>
          <Box className={classes.flexBetween}>
            <Typography className={classes.flexAndGap}>
              <span className={classes.textPurple}>Current Deadline : </span>
              <DateAndTimeViewer dateAndTime={new Date(timeline.end_time)} />
            </Typography>
            <Typography>
              {timeline?.status?.id ? (
                <StatusViewer status={timeline?.status} />
              ) : (
                "No Status"
              )}
            </Typography>
          </Box>
          <Box className={classes.singleVersionStatusLogs}>
            <Box className={classes.flexBetween}>
              <Button
                onClick={() =>
                  setIsProgressChangesCollapsed(!isProgressChangesCollapsed)
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "0px",
                }}
              >
                <Typography>Progress Changes</Typography>
                {isProgressChangesCollapsed ? (
                  <CaretRight size={20} color="#6C65D9" weight="fill" />
                ) : (
                  <CaretDown size={20} color="#6C65D9" weight="fill" />
                )}
              </Button>
              <Button onClick={() => setIsUpdateDeadlineModalOpen(true)}>
                <Typography className={classes.textPurple}>
                  Update Deadline
                </Typography>
              </Button>
            </Box>
            {!isProgressChangesCollapsed ? (
              <Box style={{ marginTop: "6px" }}>
                <Grid container className={classes.gridWithBottomBorder}>
                  <Grid item md={3}>
                    <Typography className={classes.textGray}>
                      Start Date
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      style={{ textAlign: "left" }}
                      className={classes.textGray}
                    >
                      Deadline
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      style={{ textAlign: "left" }}
                      className={classes.textGray}
                    >
                      Changed By
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      style={{ textAlign: "end", paddingRight: "8px" }}
                      className={classes.textGray}
                    >
                      Changed On
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    paddingRight: "8px",
                  }}
                >
                  {timeline?.progress_changes?.length ? (
                    <>
                      {timeline?.progress_changes?.map((singleProgress) => (
                        <Box
                          className={classes.singleStatusDetails}
                          key={singleProgress?.start}
                        >
                          <Grid container>
                            <Grid item md={3}>
                              <Typography className={classes.textGray}>
                                {singleProgress?.start ? (
                                  <div>
                                    {new Intl.DateTimeFormat("en-US", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }).format(new Date(singleProgress?.start))}
                                    <br />
                                    {new Intl.DateTimeFormat("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }).format(new Date(singleProgress?.start))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography className={classes.textGray}>
                                {singleProgress?.end ? (
                                  <div>
                                    {new Intl.DateTimeFormat("en-US", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }).format(new Date(singleProgress?.end))}
                                    <br />
                                    {new Intl.DateTimeFormat("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }).format(new Date(singleProgress?.end))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography
                                className={classes.textGray}
                                style={{ textAlign: "left" }}
                              >
                                {singleProgress?.created_by?.first_name ?? " "}
                                {"  "}
                                {singleProgress?.created_by?.last_name ?? " "}
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography
                                className={classes.textGray}
                                style={{ textAlign: "right" }}
                              >
                                {singleProgress?.created_at ? (
                                  <div>
                                    {new Intl.DateTimeFormat("en-US", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }).format(
                                      new Date(singleProgress?.created_at)
                                    )}
                                    <br />
                                    {new Intl.DateTimeFormat("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }).format(
                                      new Date(singleProgress?.created_at)
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <>No Progress Changes</>
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box className={classes.flexBetween}>
            <Box className={classes.flexAndGap}>
              <Typography className={classes.textPurple}>Delays:</Typography>
              <Typography>0</Typography>
            </Box>
            <Box className={classes.flexAndGap}>
              <Typography className={classes.textPurple}>
                Performance:
              </Typography>
              <Typography>NA</Typography>
            </Box>
            <Box className={classes.flexAndGap}>
              <Typography className={classes.textPurple}>
                Iterations:
              </Typography>
              <Typography>0</Typography>
            </Box>
          </Box>
          <Box className={classes.singleVersionStatusLogs}>
            <Button
              onClick={() => setIsStatusCollapsed(!isStatusCollapsed)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "0px",
              }}
            >
              <Typography>Status Log</Typography>
              {isStatusCollapsed ? (
                <CaretRight size={20} color="#6C65D9" weight="fill" />
              ) : (
                <CaretDown size={20} color="#6C65D9" weight="fill" />
              )}
            </Button>
            {!isStatusCollapsed ? (
              <Box style={{ marginTop: "6px" }}>
                {timeline?.status_logs?.map((singleStatus) => (
                  <Box
                    className={classes.singleStatusDetails}
                    key={singleStatus?.id}
                  >
                    <Grid container>
                      <Grid item md={5}>
                        <Typography className={classes.textGray}>
                          <DateAndTimeViewer
                            dateAndTime={singleStatus?.created_at}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          className={classes.textGray}
                          style={{
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {singleStatus?.created_by?.first_name ?? " "}
                          {"  "}
                          {singleStatus?.created_by?.last_name ?? " "}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          className={classes.textGray}
                          style={{
                            textAlign: "right",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {singleStatus?.status?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        className={classes.flexBetween}
        open={isUpdateDeadlineModalOpen}
        onClose={() => setIsUpdateDeadlineModalOpen(false)}
      >
        <UpdateDeadlineModal
          timeline={timeline}
          handleClose={() => setIsUpdateDeadlineModalOpen(false)}
          updateRefreshCounter={() => updateRefreshCounter()}
          setSnackValue={setSnackValue}
        />
      </Modal>
    </Box>
  );
};
