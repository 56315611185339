import React, { useContext, useEffect, useState } from "react";
import { fget, fpost } from "../../API/callsAPI";
import PushNotificationToast from "./PushNotificationToast.component";
import { getFirebaseToken, onMessageListener } from "./firebaseInit";
import { MovieColabContext } from "../../Context/MovieColabContext";

function PushNotificationTest() {
  const [userToken, setUserToken] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [show, setShow] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState({});
  const [user, setUser] = useState({});
  const [trackableClass, setTrackableClass] = useState([]);
  const { handleUpdateNotificationOnSession, handleNewNotificationStatus } =
    useContext(MovieColabContext);

  useEffect(() => {
    fetchTrackableClass();
  }, []);

  //receive notification here
  onMessageListener()
    .then((payload) => {
      setNotificationInfo(payload);
      handleUpdateNotificationOnSession({
        ...payload?.data,
        createdAt: new Date(),
      });
      handleNewNotificationStatus(true);
      const getUser = {
        url: `user/${payload?.data?.recipient}/`,
      };
      fget(getUser).then((res) => {
        if (res.status === 200 || 201) {
          setUser(res.data);
        }
      });
      setShow(true);
    })
    .catch((err) => console.log("failed: ", err));

  const fetchTrackableClass = () => {
    fget({ url: "trackables/trackable-classes" })
      .then((res) => res.data)
      .then(
        (result) => {
          setTrackableClass(result);
        },
        (error) => {
          // setIsLoaded(true);
          // setError(error);
        }
      );
  };

  const firebaseToken = async () => {
    const token = await getFirebaseToken();
    console.log(token);

    if (token) {
      setUserToken(token);
    } else {
      console.log("token not found");
    }
  };

  const generateUserId = () => {
    const accessToken = document.cookie?.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken && setLoggedInUser(JSON.parse(atob(accessToken.split(".")[1])));
  };

  const createUniqueId = () => {
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    setUniqueId(uid);
    localStorage.setItem("deviceId", uid);
  };

  //register device to movie colab server
  const registrationDevice = async () => {
    const regDevice = {
      url: "device/register/",
      data: {
        name: "name",
        active: true,
        device_id: uniqueId,
        registration_id: userToken,
        type: "web",
        user: loggedInUser.user_id,
      },
    };
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const res = await fpost(regDevice);
        if (res.status === 200 || 201) {
          console.log("successfully registered for FCM notification");
        }
      } else if (permission === "denied") {
        console.log("Notification permission denied.");
      } else {
        console.log("Notification permission dismissed.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    firebaseToken();
    createUniqueId();
    generateUserId();
  }, []);

  useEffect(() => {
    if (loggedInUser && userToken && uniqueId) {
      registrationDevice();
    }
  }, [loggedInUser, userToken, uniqueId]);

  return (
    <div>
      {show ? (
        <PushNotificationToast
          notificationInfo={notificationInfo}
          user={user}
          trackableClass={trackableClass}
          setShow={setShow}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default PushNotificationTest;
