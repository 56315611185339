import React, { useEffect, useState } from "react";
import { Camera, LogOut, Shield } from "react-feather";
import { deleteCookie } from "../../Components/authorization";

//MUI
import { Divider } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { MoonStars, Sun } from "phosphor-react";
//css
import useStyles from "./ProfileDropdown.styles";
import { unregisterDevice } from "../Fcm/firebaseInit";

function ProfileDropdown(props) {
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL_MCF.split("/")[2];
  const DOMAIN_TYPE = process.env.REACT_APP_DOMAIN_TYPE;
  const APP_URL = window.location.href
    .replace("https://", "")
    .replace("http://", "");
  const redirectParams = `?type=${DOMAIN_TYPE}&baseUrl=${APP_BASE_URL}&redirectUrl=${APP_URL}`;
  const SSO_URL_FRONTEND = process.env.REACT_APP_BASE_URL_SSOF;
  const [onState, setOnState] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (
      localStorage.getItem("theme") === null ||
      localStorage.getItem("theme") === "dark"
    )
      setOnState(true);
    else setOnState(false);
  }, []);

  const logOut = () => {
    unregisterDevice();
    localStorage.removeItem("notifications");
    deleteCookie("access");
    deleteCookie("refresh");
    window.location.href = SSO_URL_FRONTEND + "logout/" + redirectParams;
  };

  return (
    <div className={classes.profileDropdown}>
      <div class={classes.popoverArrow}></div>
      <div className={classes.profileDropdownMenu}>
        <div className={classes.profileDropdownItems}>
          <span
            style={{ fontSize: "24px", marginBottom: "5px", fontWeight: 500 }}
          >
            {props?.userInfo?.first_name}
            {"  "}
            {props?.userInfo?.last_name}
          </span>
        </div>
        <Divider />
        <div className={classes.profileDropdownItems}>
          <span>
            <Camera />
          </span>
          <p>Change Profile Picture</p>
        </div>
        <div className={classes.profileDropdownItems}>
          <Shield />
          <p>Privacy Settings</p>
        </div>
        <div
          className={classes.profileDropdownItems}
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <Sun size={26} />
          </div>
          <div style={{ float: "right" }}>
            <Switch
              height={20}
              width={40}
              offColor={"#171B21"}
              onColor={"#4ECCA3"}
              checked={onState}
              onClick={props.themeToggler}
              onChange={() => setOnState(!onState)}
            />
          </div>
          <div>
            <MoonStars size={26} />
          </div>
        </div>
        <Divider style={{ marginTop: "10px" }} />
        <div
          className={classes.profileDropdownItems}
          onClick={(e) => {
            e.preventDefault();
            logOut();
          }}
        >
          <LogOut />
          <p>Log Out</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileDropdown;
