import React, { useState } from "react";
import { useHistory } from "react-router";
import { fdelete, fpatch } from "../../../API/callsAPI";
//icons
import {
  ShareNetwork,
  PencilSimpleLine,
  Trash,
  CheckCircle,
  DotsThreeCircle,
} from "phosphor-react";

//MUI components
import { Button, TableCell, TableRow, Modal, Tooltip } from "@material-ui/core";
import useStyles from "./ReviewsList.styles";
//components
import ReviewPageShareFileModal from "../ReviewPageModals/ReviewPageShareFileModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
import ReviewPageEditModal from "../ReviewPageModals/ReviewPageEditModal";

function ReviewsList({
  isReviewTogglerOn,
  isExternalReviewer,
  projectId,
  review,
  setSnackValue,
  updateRefreshCounter,
  isCompleted,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [isShareFileModalOpen, setShareFileModalOpen] = useState(false);
  const [isDeleteReviewFileModalOpen, setDeleteReviewFileModalOpen] =
    useState(false);
  const [isEditReviewModalOpen, setEditReviewModalOpen] = useState(false);

  const handleShareFileModal = () => {
    setShareFileModalOpen(!isShareFileModalOpen);
  };

  const handleDeleteFileModal = () => {
    setDeleteReviewFileModalOpen(!isDeleteReviewFileModalOpen);
  };

  const handleEditModal = () => {
    setEditReviewModalOpen(!isEditReviewModalOpen);
  };

  const handleDeleteReviewFile = async () => {
    try {
      const res = await fdelete({
        url: `project/${projectId}/reviews/${review.id}/`,
      });

      if (res.status === 204) {
        setSnackValue({
          isOpen: true,
          message: res.data?.detail,
          isError: false,
        });
        updateRefreshCounter();
        handleDeleteFileModal();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const markReview = async (status) => {
    const reviewObj = {
      url: `project/${projectId}/reviews/${review.id}/`,
      data: {
        completed: status,
      },
    };
    try {
      await fpatch(reviewObj);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully changed status`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const getColor = (status) => {
    switch (status) {
      case "Completed":
        return "#00BF07";
      case "Revision":
        return "#CE7606";
      default:
        return "#31BAF4";
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          //logic to redirect ext reviewer is missing
          if (event.ctrlKey) {
            window.open(`/${projectId}/review/${review.id}/version`);
            sessionStorage.setItem("scrollpos", window.scrollY);
          } else {
            history.push(`/${projectId}/review/${review.id}/version`);
            sessionStorage.setItem("scrollpos", window.scrollY);
          }
        }}
        style={{
          cursor: "pointer",
          width: "100%",
        }}
        id="file-track"
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.listText}
          align="left"
        >
          <div style={{ display: "flex" }}>
            {review?.thumbnail ? (
              <ImageViewer
                sliderState={isReviewTogglerOn ? 25 : 0}
                url={review.thumbnail}
              />
            ) : (
              <NoThumbnail sliderState={isReviewTogglerOn ? 25 : 0} />
            )}
            <p style={{ marginLeft: "10px" }}>{review?.name}</p>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimeViewer dateAndTime={review?.updated_date} />
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={classes.avatarWithName}>
              <UserAvatar
                avatar={review?.created_by?.avatar}
                firstName={review?.created_by?.first_name}
                lastName={review?.created_by?.last_name}
              />
              <span>
                {review?.created_by?.first_name} {review?.created_by?.last_name}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                padding: "8px",
                display: "flex",
                alignItems: "center",
                gap: "12px",
                border: "1px solid #606479",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border: "2px solid #f9f9f9",
                  backgroundColor: getColor(review.status),
                }}
              ></div>
              <span>
                {review.status === "Not Started"
                  ? "In Progress"
                  : review.status}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell className={classes.listText} align="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UsersViewer editable="no" assignedUser={review?.reviewers} />
          </div>
        </TableCell>
        <TableCell align="right" className={classes.listText}>
          {isExternalReviewer ? (
            <span style={{ marginRight: "20px" }}>{review?.versions}</span>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip title="Share File" placement="top">
                <Button
                  className={classes.actionBtn}
                  onClick={handleShareFileModal}
                >
                  <ShareNetwork size={23} className={classes.actionIcons} />
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <Button className={classes.actionBtn}>
                  <PencilSimpleLine
                    size={23}
                    onClick={handleEditModal}
                    className={classes.actionIcons}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Delete" placement="top">
                <Button
                  className={classes.actionBtn}
                  onClick={handleDeleteFileModal}
                >
                  <Trash size={23} className={classes.actionIcons} />
                </Button>
              </Tooltip>
              {isCompleted === "False" ? (
                <Tooltip title="Mark as Completed" placement="top">
                  <Button
                    className={classes.actionBtn}
                    onClick={() => markReview(true)}
                  >
                    <CheckCircle size={23} className={classes.actionIcons} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Mark as Pending" placement="top">
                  <Button
                    className={classes.actionBtn}
                    onClick={() => markReview(false)}
                  >
                    <DotsThreeCircle
                      size={23}
                      className={classes.actionIcons}
                    />
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.reviewListModals}
          open={isShareFileModalOpen}
          onClose={handleShareFileModal}
        >
          <ReviewPageShareFileModal
            handleShareFileModal={handleShareFileModal}
            versionId={review.id}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewListModals}
          open={isDeleteReviewFileModalOpen}
          onClose={handleDeleteFileModal}
        >
          <DeleteModal
            type="review file"
            name={review?.name}
            handleCloseDeleteModal={handleDeleteFileModal}
            handleDelete={handleDeleteReviewFile}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isEditReviewModalOpen}
          onClose={handleEditModal}
        >
          <ReviewPageEditModal
            handleEditModal={handleEditModal}
            projectId={projectId}
            setSnackValue={setSnackValue}
            updateRefreshCounter={updateRefreshCounter}
            review={review}
          />
        </Modal>
      </div>
    </>
  );
}

export default ReviewsList;
