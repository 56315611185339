import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Menu,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { ArchiveBox, PushPin, PushPinSimple } from "phosphor-react";
import React, { useEffect } from "react";
import { Edit3, MoreHorizontal, Trash2, Users } from "react-feather";
import { useHistory } from "react-router";
import StringTruncate from "../../Utilities/StringTruncate/StringTruncate";
import {
  AdminIcon,
  ReviewerIcon,
  UserIcon,
} from "../../Utilities/Svg/UserRoleIcons";
import useStyles from "./ProjectCards.styles";
function CircularProgressWithLabel({ value, size }) {
  const getColor = (value) => {
    if (value <= 10) {
      return "#DD0C08";
    } else if (value <= 20) {
      return "#D92A08";
    } else if (value <= 30) {
      return "#D64007";
    } else if (value <= 40) {
      return "#D25807";
    } else if (value <= 50) {
      return "#CE7606";
    } else if (value <= 60) {
      return "#CD8D09";
    } else if (value <= 70) {
      return "#CDA90E";
    } else if (value <= 80) {
      return "#CEC213";
    } else if (value <= 90) {
      return "#8BBE13";
    } else {
      return "#0BAD11";
    }
  };

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={5}
        style={{
          color: getColor(value),
          position: "absolute",
          top: -25,
          left: -25,
          zIndex: 2,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={5}
        style={{
          color: "#1B1D28",
          position: "absolute",
          top: -25,
          left: -25,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          {`${Math.floor(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ProjectCards(props) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isArchived, setIsArchived] = React.useState(false);
  const [showUserRoleInfo, setShowUserRoleInfo] = React.useState({
    isOpen: false,
    role: "",
  });

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.project.archived_by.length > 0) {
      const exist = props.project.archived_by.some(
        (eachId) => eachId === props?.loggedInUser?.id
      );
      if (exist) {
        setIsArchived(true);
      }
    }
  }, [props.project]);

  return (
    <>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardContentDiv}>
              <div
                className={classes.cardContentDiv1}
                style={{ position: "relative", display: "inline-block" }}
              >
                <img
                  alt="projectImage"
                  src={
                    props.project.thumbnail
                      ? props.project.thumbnail
                      : "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  }
                  className={classes.imageStyle}
                />
                <div
                  className={classes.userRoleIcon}
                  onMouseEnter={() =>
                    setShowUserRoleInfo({
                      isOpen: true,
                      role: props?.project?.user_role,
                    })
                  }
                  onMouseLeave={() =>
                    setShowUserRoleInfo({
                      isOpen: false,
                      role: "",
                    })
                  }
                >
                  {props?.project?.user_role === "admin" ? (
                    <AdminIcon />
                  ) : props?.project?.user_role === "user" ? (
                    <UserIcon />
                  ) : (
                    <ReviewerIcon />
                  )}
                </div>
              </div>

              <div
                className={classes.cardContentDiv2}
                onClick={(event) => {
                  //change and move this to parent function.
                  event.preventDefault();
                  event.stopPropagation();
                  props.handleProjectSelect(props.project); //generic func to handle project change!
                  // if (event.ctrlKey) {
                  //   window.open(`/${props.project.id}/updates`);
                  // } else {
                  //   history.push(`/${props.project.id}/updates`);
                  // }
                }}
              >
                <div className={classes.cardContentDiv2Title}>
                  <Tooltip title={props.project.name} placement="top-start">
                    <span className={classes.title}>
                      {StringTruncate(props.project.name, 17)}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.cardContentDiv2Footer}>
                  <span style={{ marginTop: "0px" }}>Created on : </span>
                  <span style={{ marginTop: "0px" }}>
                    {props.project.created_at.slice(0, 10)}
                  </span>
                  {/* p tag is empty purposefully so that sudo seloctor dosent applys propety to tags above it */}
                  <p></p>
                </div>
                <div className={classes.cardContentDiv2Desc}>
                  {props.project.description ? (
                    <Tooltip
                      title={props.project?.description}
                      placement="bottom-start"
                    >
                      <div className={classes.descText}>
                        {props.project.description
                          ? `${props.project.description}`
                          : "Project has no description !"}
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  {isArchived ? (
                    ""
                  ) : (
                    <>
                      {props.pinnedProjects.includes(props.project.id) ? (
                        <Button
                          style={{ minWidth: "0px", padding: "4px" }}
                          onClick={() =>
                            props.handleunPinnedPost(props.project.id)
                          }
                        >
                          <PushPinSimple
                            size={20}
                            color="#F9F9F9"
                            weight="fill"
                          />
                        </Button>
                      ) : (
                        <Button
                          style={{ minWidth: "0px", padding: "4px" }}
                          onClick={() =>
                            props.handlePinnedPost(props.project.id)
                          }
                        >
                          <PushPin size={20} />
                        </Button>
                      )}
                    </>
                  )}
                  <button
                    id={props.project.id}
                    className={classes.clickableDiv}
                    onClick={(e) => handleOpen(e)}
                  >
                    <center>
                      <MoreHorizontal className={classes.projectDot} />
                    </center>
                  </button>
                </div>

                {/* <div style={{marginTop: "10px", marginLeft: "10px"}}>

                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size="50px"
                    className={classes.circleDefaultColor}
                  />
                </div> */}
                <div
                  style={{
                    cursor: "default",
                  }}
                >
                  <CircularProgressWithLabel
                    value={props.project.completion_percentage}
                    size="50px"
                  />
                </div>
                <Tooltip
                  title={props.project?.abbreviation}
                  placement="bottom-start"
                >
                  <div className={classes.abbreviationText}>
                    {props.project.abbreviation
                      ? `${props.project.abbreviation}`
                      : "Abbreviation !"}
                  </div>
                </Tooltip>
              </div>
            </div>
            {showUserRoleInfo?.isOpen ? (
              <div className={classes.userRoleText}>
                {showUserRoleInfo?.role === "admin"
                  ? `Admin : as you are an admin in this project, you have
                permissions to all of the content in this project.`
                  : showUserRoleInfo?.role === "user"
                  ? `User : as you are a user in this project, some of the content might be hidden, please contact project admin for content permissions.`
                  : `Reviewer : as you are a reviewer in this project, some of the content might be hidden, please contact project admin for content permissions.`}
              </div>
            ) : (
              ""
            )}
          </CardContent>
        </Card>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ className: `${classes.menuPop}` }}
        >
          <MenuItem
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleManageUserModal({
                modalOpen: "true",
                projectId: props.project.id,
              });
            }}
          >
            <Users className={classes.trashIcon} />
            Manage Users
          </MenuItem>
          <MenuItem
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleEditProjectModal(props.project);
            }}
          >
            <Edit3 className={classes.trashIcon} />
            Edit Project
          </MenuItem>
          <MenuItem
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              if (isArchived) {
                props.handleUnArchiveProject(props.project);
              } else {
                props.handleArchiveProject(props.project);
              }
            }}
          >
            <ArchiveBox className={classes.trashIcon} />
            {isArchived ? "Unarchive Project" : "Archive Project"}
          </MenuItem>
          <MenuItem
            id={`del${props.project.id}`}
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleDeleteProjectModal(props.project);
            }}
          >
            <Trash2 className={classes.trashIcon1} />
            Delete Project
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

export default ProjectCards;
