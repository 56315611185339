import React, { useState } from "react";
import useStyles from "./SequencePageNewSequenceModal.styles";
import { Typography, Button, MenuItem, Select } from "@material-ui/core";
import { X } from "phosphor-react";

function SequencePageFilter({
  episodeList,
  handleFilterModal,
  episodeId,
  handleEpisodeChange,
}) {
  const classes = useStyles();
  const [selectedEpi, setSelectedEpi] = useState(episodeId || "none");

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        overflowY: "auto",
        overflowX: "none",
      },
    },
  };
  return (
    <div className={classes.filterRoot}>
      <div className={classes.flexBox}>
        <Typography className={classes.title2}>Filter</Typography>
        <Button onClick={handleFilterModal}>
          <X size={25} />
        </Button>
      </div>
      <div>
        <Typography className={classes.text}>Episode Name</Typography>
        <Select
          fullWidth
          disableUnderline
          value={selectedEpi}
          onChange={(event) => setSelectedEpi(event.target.value)}
          className={classes.input}
          MenuProps={MenuProps}
        >
          <MenuItem className={classes.menuItem} value={"none"}>
            None
          </MenuItem>
          {episodeList.map((eachEpisode) => {
            return (
              <MenuItem className={classes.menuItem} value={eachEpisode.id}>
                <div className={classes.flexBox}>
                  <Typography>{eachEpisode.name}</Typography>
                  <Typography className={classes.text}>
                    Sequence Count - {eachEpisode.sequence_list.length}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <Button
        className={classes.done2}
        onClick={() =>
          selectedEpi === "none"
            ? handleEpisodeChange("")
            : handleEpisodeChange(selectedEpi)
        }
      >
        Filter
      </Button>
    </div>
  );
}

export default SequencePageFilter;
