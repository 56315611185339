import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fget, fpatch } from "../../../../API/callsAPI";

//@MUI
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "./ReviewVersionOverview.styles";

//@Components
import ReviewPageManageReviewModal from "../../../Reviews/ReviewPageModals/ReviewPageManageReviewModal";
import ReviewPageNoData from "../../../Reviews/ReviewPageNoData/ReviewPageNoData";
import {
  fakeVersions,
  reviewVersionFakeData,
} from "../../../Reviews/ReviewerPageFakeData.js";
import ReviewTools from "../../../Reviews/ReviewsPageTools/ReviewsPageTool.component";
import {
  StatusDirectionAvatar,
  VersionViewerAvatar,
} from "../../../Utilities/AppTour/AppTourCustomAvatars/AppTourCustomAvatars.jsx";
import CustomAppTour from "../../../Utilities/AppTour/CustomAppTour/CustomAppTour";
import CustomPagination from "../../../Utilities/CustomPagination/CustomPagination.component";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import Loader from "../../../Utilities/Loader/Loader";
import ReviewPageVersionViewerOverview from "../../../Utilities/ReviewPageVersionViewer/ReviewPageVersionViewerOverview/ReviewPageVersionViewerOverview";
import { checkImage } from "../../../utils";
import ReviewVersionList from "../../ReviewVersionList/ReviewVersionList.component";
import ReviewVersionNewReviewModal from "../../ReviewVersionModals/ReviewVersionNewReviewModal";

function ReviewVersionOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [signedUrl, setSignedUrl] = useState("");
  const [isNewReviewVersionModalOpen, setNewReviewVersionModalOpen] =
    useState(false);
  const [isManageReviewerModalOpen, setManageReviewerModalOpen] =
    useState(false);
  const [isReviewVersionTogglerOn, setIsReviewVersionTogglerOn] =
    useState(false);
  const [isVersionViewerOpen, setVersionViewerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [orgUsers, setOrgUsers] = useState([]);
  const [projectReviewer, setProjectReviewer] = useState([]);
  const [reviewVersions, setReviewVersions] = useState([]); //review versions
  const [selectedVersion, setSelectedVersion] = useState(); //selected version for version viewer
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); //for view modal for corners out button
  const [loggedInUser, setLoggedInUser] = useState();
  const [allUsersName, setAllUsersName] = useState([]);
  const [parentReview, setParentReview] = useState({});
  const [commentId, setCommentId] = useState("");
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [appTourRun, setAppTourRun] = useState(false);
  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);

  const handleNewReviewVersionModal = () => {
    setNewReviewVersionModalOpen(!isNewReviewVersionModalOpen);
  };

  const handleManageReviewModal = () => {
    setManageReviewerModalOpen(!isManageReviewerModalOpen);
  };

  const handleReviewVersionToggler = () => {
    setIsReviewVersionTogglerOn(!isReviewVersionTogglerOn);
  };

  const handleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  useEffect(() => {
    if (selectedVersion && selectedVersion.id) {
      searchParams.set("versionId", `${selectedVersion.id}`);
      const updatedUrl = `${
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      }`;
      window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
      setVersionViewerOpen(true);
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (
      reviewVersions.results &&
      reviewVersions.results.length > 0 &&
      searchParams.get("versionId")
    ) {
      const version = reviewVersions.results.filter(
        (eachVersion) => eachVersion.id == searchParams.get("versionId")
      );
      if (version) {
        setSelectedVersion(version[0]);
      }
    }
  }, [reviewVersions]);

  const handleVersionViewer = (version) => {
    if (version) {
      setSelectedVersion(version);
    }
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const getReviewVersions = async () => {
    setIsLoaded(false);
    try {
      const versionRes = await fget({
        url: `review/${props.params.id}/versions/?page=${currentPage}`,
      });
      if (versionRes.status === 200) {
        setReviewVersions(versionRes.data);
        setIsLoaded(true);
      }
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const fetchOrghUsers = async () => {
    try {
      const usersRes = await fget({
        url: `organization/users/`,
      });
      if (usersRes.status === 200) {
        setOrgUsers(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReviewer = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.params.projectId}/reviewers`,
      });
      if (usersRes.status === 200) {
        setProjectReviewer(
          usersRes.data.results.map((user) => {
            const data = {
              label: user.first_name + " " + user.last_name,
              value: user.id,
              avatar: user?.avatar,
              email: user.email,
            };
            return data;
          })
        );
        setAllUsersName(
          usersRes.data.results.map((user) => {
            const data = {
              name: user.first_name + " " + user.last_name,
              id: user.id,
            };
            return data;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateVersionStatus = async (versionStatus) => {
    let statusObj = {
      url: `review-version/${selectedVersion.id}/`,
      data: { status: versionStatus },
    };
    try {
      const res = await fpatch(statusObj);
      if (res.status === 200 || 201) {
        updateRefreshCounter();
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchFile = async (id) => {
    const fetchFileRes = await fget({
      url: `reviewversion/${id}/file`,
    });
    setSignedUrl(fetchFileRes.data.signed_url);
  };

  const getLoggedInUser = async () => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (accessToken) {
      const userId = JSON.parse(atob(accessToken.split(".")[1])).user_id;
      try {
        const res = await fget({ url: `user/${userId}/` });
        setLoggedInUser(res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getParentReview = async () => {
    try {
      const res = await fget({
        url: `project/${props.params.projectId}/reviews/${props.params.id}/`,
      });
      if (res.status === 200) {
        setParentReview(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviewVersions();
  }, [updateOnPageChange, refreshCounter]);

  useEffect(() => {
    getParentReview();
    if (!props.externalReviewer) {
      fetchOrghUsers();
    }
    getReviewer();
    getLoggedInUser();
    setCommentId(searchParams.get("commentId" || ""));
  }, []);

  useEffect(() => {
    const open = localStorage.getItem("externalVersionAppTour");
    if (open === null) {
      setAppTourRun(true);
    } else {
      setAppTourRun(false);
    }
  }, []);

  const steps = [
    {
      content: (
        <div>
          We shall now load{" "}
          <strong style={{ color: "#6C65D9" }}>demonstrational content</strong>{" "}
          to help simulate an External Reviewers journey
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      target: "#version-file-track",
      content: <StatusDirectionAvatar />,
    },
    {
      placement: "center",
      target: "body",
      content: <VersionViewerAvatar />,
    },
    /* {
      placement: "left",
      target: "#annotation-button",
      content: <VersionViewerAvatar />,
    }, */
  ];

  return (
    <div className={classes.root} id="version">
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <CustomAppTour
        run={appTourRun}
        steps={steps}
        handleFinish={() => {
          localStorage.setItem("externalVersionAppTour", false);
          setAppTourRun(false);
          history.push(`/${props.params.projectId}/review`);
        }}
        handleOthers={(e) => {
          if (e.index === 2) {
            setSelectedVersion(reviewVersionFakeData);
            setVersionViewerOpen(true);
          } else {
            setVersionViewerOpen(false);
          }
        }}
      />
      <ReviewTools
        handleNewReviewModal={handleNewReviewVersionModal}
        handleManageReviewModal={handleManageReviewModal}
        setFilterStatus={() => console.log("inside version")}
        handleReviewToggler={handleReviewVersionToggler}
        isReviewVersion={"yes"}
        isExternalReviewer={props.externalReviewer}
        parentReview={parentReview}
      />
      {isLoaded ? (
        reviewVersions.count !== 0 ? (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headTableCell} align="left">
                      Version Name
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Last Modified
                    </TableCell>
                    {!props.externalReviewer && (
                      <TableCell
                        className={classes.headTableCell}
                        align="center"
                      >
                        Added by
                      </TableCell>
                    )}
                    <TableCell className={classes.headTableCell} align="center">
                      Reviewer
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="center">
                      Comments
                    </TableCell>
                    <TableCell className={classes.headTableCell} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appTourRun ? (
                    <ReviewVersionList
                      isReviewVersionTogglerOn={isReviewVersionTogglerOn}
                      isExternalReviewer={props.externalReviewer}
                      handleVersionViewer={handleVersionViewer}
                      review={reviewVersionFakeData}
                      key={reviewVersionFakeData.id}
                      updateRefreshCounter={updateRefreshCounter}
                      setSnackValue={setSnackValue}
                      fetchFile={fetchFile}
                      handleViewModal={handleViewModal}
                      loggedInUser={loggedInUser}
                    />
                  ) : (
                    <>
                      {reviewVersions?.results?.map((review) => {
                        return (
                          <ReviewVersionList
                            isReviewVersionTogglerOn={isReviewVersionTogglerOn}
                            isExternalReviewer={props.externalReviewer}
                            handleVersionViewer={handleVersionViewer}
                            review={review}
                            key={review.id}
                            updateRefreshCounter={updateRefreshCounter}
                            setSnackValue={setSnackValue}
                            fetchFile={fetchFile}
                            handleViewModal={handleViewModal}
                            loggedInUser={loggedInUser}
                          />
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <footer style={{ bottom: "0", height: "100%" }}>
              <div>
                <CustomPagination
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalItem={reviewVersions?.count}
                  totalPage={Math.ceil(reviewVersions?.count / 50)}
                  // setIsNewPageLoading={setIsNewPageLoading}
                  trackable="Review Versions"
                  isExternalReviewer={props.externalReviewer}
                />
              </div>
            </footer>
          </>
        ) : (
          <ReviewPageNoData
            filterCompleted={"False"}
            handleNewReviewModal={handleNewReviewVersionModal}
            isReviewVersion={true}
            isExternalReviewer={props.externalReviewer}
          />
        )
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isNewReviewVersionModalOpen}
          onClose={handleNewReviewVersionModal}
        >
          <ReviewVersionNewReviewModal
            handleNewReviewVersionModal={handleNewReviewVersionModal}
            setSnackValue={setSnackValue}
            reviewId={props.params.id}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isManageReviewerModalOpen}
          onClose={handleManageReviewModal}
        >
          <ReviewPageManageReviewModal
            handleManageReviewModal={handleManageReviewModal}
            orgUsers={orgUsers}
            setSnackValue={setSnackValue}
            projectId={props.params.projectId}
            projectReviewer={projectReviewer}
            getReviewer={getReviewer}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isVersionViewerOpen}
          onClose={() => {
            setSelectedVersion("");
            searchParams.set("versionId", "");
            const updatedUrl = `${
              "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
            }`;
            window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
            setVersionViewerOpen(false);
          }}
        >
          <ReviewPageVersionViewerOverview
            onClose={() => {
              setSelectedVersion("");
              searchParams.set("versionId", "");
              const updatedUrl = `${
                "?" +
                btoa(unescape(encodeURIComponent(searchParams.toString())))
              }`;
              window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
              setVersionViewerOpen(false);
            }}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            versionList={appTourRun ? fakeVersions : reviewVersions.results}
            updateVersionStatus={updateVersionStatus}
            projectId={props.params.projectId}
            isExternalReviewer={props.externalReviewer}
            loggedInUser={loggedInUser}
            allUsersName={allUsersName}
            parentReview={parentReview}
            isAppTourRunning={appTourRun}
            commentId={commentId}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.reviewPageModals}
          open={isViewModalOpen}
          onClose={handleViewModal}
        >
          {signedUrl ? (
            checkImage(signedUrl) ? (
              <img
                src={signedUrl}
                className="card-img-top"
                style={{ width: "500px", height: "auto" }}
                alt="asset"
              />
            ) : (
              <video
                src={signedUrl}
                controls
                autoPlay
                style={{ width: "auto", height: "500px" }}
              />
            )
          ) : (
            <span>No file found</span>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default ReviewVersionOverview;
