import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationsDropdown: {
    height: "80%",
    width: "83%",
    background: theme.palette.primary.main,
    borderRadius: "20px",
    fontFamily: "Nunito sans",
    color: theme.palette.text.primary,
    padding: "22px",
    position: "relative",
  },
  notificationHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "8%",
    marginBottom: "5px",
    marginRight: "10px",
  },
  searchDiv: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "30%",
    height: "46px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  searchInput: {
    padding: "0.2px 6px",
    width: "60%",
    background: theme.palette.secondary.light,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontSize: "14px",
  },
  headerCorner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
  },
  headerDivider: {
    margin: "0 15px 0 15px",
    backgroundColor: theme.palette.text.tertiary,
  },
  crossBtn: {
    minWidth: "0px",
    padding: "5px",
  },
  divider: {
    marginTop: "5px",
    marginRight: "20px",
    backgroundColor: theme.palette.text.tertiary,
  },
  notificationsDropdownMenu: {
    marginTop: "5px",
    height: "75%",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  bulkEditContainer: {
    position: "absolute",
    bottom: 0,
    left: "40%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px 10px 0 0",
    border: `2px solid ${theme.palette.secondary.main}`,
    width: "423px",
    height: "43px",
    borderBottom: 0,
    zIndex: "1",
  },
}));

export default useStyles;
