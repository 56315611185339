import {
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import { fpatch } from "../../../../API/callsAPI";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import TaskPageEditModal from "../../TaskPageModals/TaskPageEditModal";
import TaskTimelineSlider from "../../TaskPageModals/TaskTimelineSlider/TaskTimelineSlider";
import TaskViewModal from "../../TaskPageModals/TaskViewModal/TaskViewModal";
import useStyles from "./TaskPageListViewOverview.styles";
import TaskPageListGrouped from "./TaskPageListViewTools/TaskPageListGrouped";

export default function TaskPageListViewOverview(props) {
  const {
    groupedTask,
    taskStatus,
    updateRefreshCounter,
    projectUsers,
    departments,
    setUpdating,
    rearrangeTaskOrder,
    selectTasksActive,
    setSelectedTasks,
    selectedTasks,
    handleFilterBy,
  } = props;
  const [isTaskPageEditModalOpen, setIsTaskPageEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [mutualTaskList, setMutualTaskList] = useState([]); //task sharing same trackable
  const [scrollValue, setScrollValue] = useState(0);
  const [openTaskViewModal, setTaskViewModal] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [viewModal, setViewModal] = useState(true);
  const [selectedTrackable, setSelectedTrackable] = useState("none");
  const [openTaskTimelineSlider, setOpenTaskTimelineSlider] = useState({
    isOpen: false,
    type: "",
    id: "",
    name: "",
  });
  //get the filtered object from params
  const classes = useStyles();

  function handleViewTaskModal(task, mutualTasks) {
    setTaskViewModal(true);
    setSelectedTask(task);
    if (mutualTasks) {
      setMutualTaskList(mutualTasks);
    }
  }

  const handleOpenTimelineSlider = (type, id, name) => {
    setOpenTaskTimelineSlider({
      isOpen: true,
      type: type,
      id: id,
      name: name,
    });
  };

  const handleEditTaskModal = () => {
    setIsTaskPageEditModalOpen(!isTaskPageEditModalOpen);
  };

  const handleTaskUpdate = async (taskId, updatedTaskForm) => {
    setUpdating(true);
    let updatedTask = {
      url: `trackables/task/${taskId}/`,
      data: updatedTaskForm,
    };
    try {
      const updateTaskRes = await fpatch(updatedTask);
      setUpdating(false);
      setIsTaskPageEditModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully edited task : ${updateTaskRes.data.name}`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <TableContainer
        style={{
          height:
            props.trackableType === "task"
              ? `calc(100vh - 230px)`
              : `calc(100vh - 260px)`,
          marginTop: "5px",
          paddingRight: "10px",
          width: "100%",
        }}
        /* onScroll={(e) => {
          setScrollValue(e.target.scrollTop);
        }} */
      >
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.headTable} align="left">
                Name
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Duration
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Status
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                priority
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Trackable
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Department
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Users
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Reviewer
              </TableCell>
              <TableCell className={classes.headTable} align="center">
                Comments
              </TableCell>
              <TableCell className={classes.headTableTwo} align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedTask &&
              groupedTask.groupedTask.map((task, index) => (
                <TaskPageListGrouped
                  key={index}
                  handleViewTaskModal={handleViewTaskModal}
                  handleOpenTimelineSlider={handleOpenTimelineSlider}
                  groupedTask={task}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  handleEditTaskModal={handleEditTaskModal}
                  handleTaskUpdate={handleTaskUpdate}
                  projectUsers={projectUsers}
                  departments={departments}
                  scrollValue={scrollValue}
                  setSelectedTrackable={setSelectedTrackable}
                  selectedTrackable={selectedTrackable}
                  selectTasksActive={selectTasksActive}
                  setSelectedTasks={setSelectedTasks}
                  selectedTasks={selectedTasks}
                  handleFilterBy={handleFilterBy}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Slide direction="left" in={openTaskViewModal}>
        <div
          style={{
            display: openTaskViewModal ? "block" : "none",
            top: props.trackableType === "task" ? "155px" : "185px",
            height:
              props.trackableType === "task"
                ? `calc(100vh - 155px)`
                : `calc(100vh - 185px)`,
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            {viewModal ? (
              <TaskViewModal
                closeModal={() => setTaskViewModal(false)}
                task={selectedTask}
                projectId={props.projectId}
                taskStatus={taskStatus}
                handleEditTaskModal={handleEditTaskModal}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                switchToEditModal={() => setViewModal(false)}
                handleTaskDelete={props.handleTaskDelete}
                viewType="List"
              />
            ) : (
              <TaskPageEditModal
                rearrangeTaskOrder={rearrangeTaskOrder}
                SwitchToViewModal={() => setViewModal(true)}
                selectedTask={selectedTask}
                mutualTaskList={mutualTaskList}
                statusList={taskStatus}
                handleEditTaskModal={() => setTaskViewModal(false)}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                departments={departments}
              />
            )}
          </div>
        </div>
      </Slide>
      <Slide direction="left" in={openTaskTimelineSlider.isOpen}>
        <div
          style={{
            display: openTaskTimelineSlider ? "block" : "none",
            top: "155px",
            height: `calc(100vh - 155px)`,
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            <TaskTimelineSlider
              handleClose={() =>
                setOpenTaskTimelineSlider({
                  isOpen: false,
                  type: "",
                  id: "",
                  name: "",
                })
              }
              type={openTaskTimelineSlider.type}
              id={openTaskTimelineSlider.id}
              name={openTaskTimelineSlider.name}
              setSnackValue={setSnackValue}
            />
          </div>
        </div>
      </Slide>
    </>
  );
}
