import { makeStyles } from "@material-ui/core/styles";
import { renderToStaticMarkup } from "react-dom/server";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "30%",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
    fontFamily: "Poppins",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
  },
  closeIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: "600",
  },

  inputLabel: {
    color: theme.palette.text.tertiary,
    display: "inline-block",
    marginBottom: "10px",
  },

  imageInput: {
    cursor: "pointer",
    opacity: 0,
    width: "100%",
    height: "100%",
    fontSize: "0",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconStyle: {
    width: "100%",
    marginTop: "30%",
    color: theme.palette.border.main,
  },

  imageInputAndNameAndAbbreviation: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "15px",
    alignItems: "start",
  },

  imageViewerDiv: {
    width: "30%",
    height: "150px",
    position: "relative",
  },
  cardContentDiv2: {
    width: "30%",
    height: "150px",
    display: "grid",
    // marginTop: "2%",
    placeItems: "center",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
      renderToStaticMarkup(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="192"
          height="192"
          fill={theme.palette.text.primary}
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <path
            d="M208,208H48a16,16,0,0,1-16-16V80A16,16,0,0,1,48,64H80L96,40h64l16,24h32a16,16,0,0,1,16,16V192A16,16,0,0,1,208,208Z"
            fill="none"
            stroke={theme.palette.text.primary}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></path>
          <circle
            cx="128"
            cy="132"
            r="36"
            fill="none"
            stroke={theme.palette.text.primary}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></circle>
        </svg>
      )
    )}")`,
    backgroundPosition: "center",
    backgroundSize: "25px",
    backgroundRepeat: "no-repeat",
  },
  imageContentDiv: {
    width: "100%",
    height: "100%",
    display: "grid",
    borderRadius: "10px",
    overflow: "hidden",
  },
  projectThumbnail: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    borderRadius: "10px",
    objectFit: "cover",
  },
  cardContentDiv3: {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    maxHeight: "30px",
    //padding: "-4px",
    borderRadius: "10px",
  },
  cardContentInput1: {
    padding: "3.5px 18px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
  },
  helperText: {
    marginTop: "-10px",
  },
  cardContentDiv4: {
    // margin: "0% 5% 0% 5%",
    marginTop: "8px",
  },
  cardContentDiv5: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "4px",
    height: "20vh",
    width: "100%",
  },
  cardContentInput2: {
    width: "100%",
    padding: "8px",
  },
  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    textTransform: "none",
    padding: "6px 10px",
    borderRadius: "10px",
    minWidth: "120px",
    color: theme.palette.text.secondary,
  },
  cardAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2%",
    "& :hover": {
      color: theme.palette.text.primary,
    },
    paddingBottom: "7px ",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  label: {
    marginTop: "10px",
    marginBottom: "2px",
    fontSize: "16px",
    color: theme.palette.text.tertiary,
    display: "flex",
  },
  divider: {
    width: "80%",
    height: "1px",
    alignSelf: "flex-end",
    marginBottom: "7px",
    backgroundColor: theme.palette.text.tertiary,
  },
  removeThumbnailIcon: {
    color: "#fff",
    backgroundColor: "red",
    position: "absolute",
    height: "24px",
    width: "24px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
    borderColor: "transparent",
  },
}));

export default useStyles;
