import React from "react";
import useStyles from "./NotificationDropdownDetail.styles";
import { Divider } from "@material-ui/core";

//components
import AssestVersion from "../../Utilities/Svg/AssestVersion.svg";
import ShotVersion from "../../Utilities/Svg/ShotVersion.svg";
import {
  Cube,
  FilmStrip,
  FilmSlate,
  Bell,
  CheckSquareOffset,
} from "phosphor-react";

//display for notification dropdown
const NotificationDropdownDetail = ({ notificationDetails }) => {
  const classes = useStyles();

  //function taskes a trackable type and returns the correct matched component
  const trackableIcon = (trackable) => {
    switch (trackable) {
      case "task":
        return <CheckSquareOffset className={classes.notificationIcon} />;
      case "asset":
        return <Cube className={classes.notificationIcon} />;
      case "assetversion":
        return <img src={AssestVersion} className={classes.notificationIcon} />;
      case "sequence":
        return <FilmStrip className={classes.notificationIcon} />;
      case "shot":
        return <FilmSlate className={classes.notificationIcon} />;
      case "shotversion":
        return <img src={ShotVersion} className={classes.notificationIcon} />;
      default:
        return <Bell className={classes.notificationIcon} />;
    }
  };

  return (
    <div>
      <div
        className={
          notificationDetails.isRead
            ? classes.notificationsItems
            : classes.notificationsItemsUnread
        }
      >
        <span>
          {trackableIcon(
            notificationDetails?.notification_object?.content_type
          )}
        </span>
        <p className={classes.descriptionDropDown}>
          {notificationDetails?.notification_object?.content_object?.name
            ? `${notificationDetails?.notification_object?.content_object?.name} :`
            : ""}
          {notificationDetails.message}
        </p>
        <p className={classes.dropdownDate}>
          {new Intl.DateTimeFormat("en-IN", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }).format(new Date(notificationDetails?.createdAt))}
        </p>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};

export default NotificationDropdownDetail;
