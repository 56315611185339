import React, { useEffect, useState } from "react";
import useStyles from "./EpisodesOverview.styles";
import { useHistory } from "react-router";
import EpisodesPageTools from "../EpisodesPageTools/EpisodesPageTools.component";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@material-ui/core";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import EpisodesList from "../EpisodesList/EpisodesList.component";
import NewEpisodeModal from "../EpisodesPageModals/NewEpisodeModal";
import { fget, fpost } from "../../../API/callsAPI";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import Loader from "../../Utilities/Loader/Loader";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

function EpisodesOverview(props) {
  const classes = useStyles();
  const history = useHistory();

  const [newEpisodeModal, setNewEpisodeModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sequenceList, setSequenceList] = useState([]);
  const [episodesList, setEpisodesList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [sortByDefault, setSortByDefault] = useState("");
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  let searchParams = new URLSearchParams(decodedURL);

  const handleNewEpisodeModal = () => {
    setNewEpisodeModal(!newEpisodeModal);
  };

  const getAllSequence = async () => {
    try {
      const sequenceListRes = await fget({
        url: `trackables/shot-sequence/?project=${props.params.projectId}`,
      });
      setSequenceList(sequenceListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllEpisodes = async () => {
    setIsLoaded(false);
    try {
      const episodeListRes = await fget({
        url: `trackables/episode/?page=${currentPage}&project=${props.params.projectId}`,
      });
      setEpisodesList(episodeListRes.data);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    }
  };

  const getStatus = async (id) => {
    try {
      const res = await fget({
        url: `trackables/status-list/${id}/?project=${props.params.projectId}`,
      });
      if (res.status === 200 || res.status === 201) {
        setStatusList(res.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackableClass = async () => {
    try {
      const res = await fget({
        url: `/trackables/trackable-classes/`,
      });
      if (res.status === 200 || res.status === 201) {
        const epiId = res.data.filter(
          (eachStatus) => eachStatus.model === "episode"
        );
        getStatus(epiId[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    getAllEpisodes();
    getAllSequence();
  }, [updateOnPageChange, refreshCounter]);

  useEffect(() => {
    getTrackableClass();
  }, []);

  useEffect(() => {
    episodesList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodesList]);

  //Function to sort Episodes
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );

    switch (sortBy) {
      case "A-Z":
        episodesList?.results?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        episodesList?.results?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        episodesList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        episodesList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        episodesList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return episodesList?.results;
    }
  }

  const handleAddNewEpisode = async (obj) => {
    try {
      const res = await fpost({
        url: "trackables/episode/",
        data: {
          name: obj.name,
          description: obj.description,
          project: props.params.projectId,
          sequences: obj.sequence,
        },
      });
      updateRefreshCounter();
    } catch (error) {
      console.log("error", error);
      setSnackValue({
        isOpen: true,
        message: `Error on adding Episode`,
        isError: true,
      });
    }
  };

  return (
    <div>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <EpisodesPageTools
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        openNewEpisodeModal={handleNewEpisodeModal}
      />
      {isLoaded ? (
        episodesList && episodesList.count > 0 ? (
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.headCellsBig}>
                    Episodes
                  </TableCell>
                  <TableCell align="center" className={classes.headCellsBig}>
                    Last Modified
                  </TableCell>
                  <TableCell align="center" className={classes.headCellsBig}>
                    Description
                  </TableCell>
                  <TableCell align="center" className={classes.headCellsSmall}>
                    Total Sequence
                  </TableCell>
                  <TableCell align="center" className={classes.headCellsBig}>
                    Created By
                  </TableCell>
                  <TableCell align="center" className={classes.headCellsBig}>
                    Status
                  </TableCell>
                  <TableCell align="right" className={classes.headCellsSmall}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {episodesList.results.map((eachEpisode) => {
                  return (
                    <EpisodesList
                      key={eachEpisode.id}
                      eachEpisode={eachEpisode}
                      updateRefreshCounter={updateRefreshCounter}
                      sequenceList={sequenceList}
                      statusList={statusList}
                      projectId={props.params.projectId}
                      setSnackValue={setSnackValue}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData />
        )
      ) : (
        <Loader />
      )}
      {episodesList && episodesList.count > 50 && (
        <footer style={{ bottom: "0", height: "100%" }}>
          <CustomPagination
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalItem={episodesList?.count}
            totalPage={episodesList ? Math.ceil(episodesList?.count / 50) : 0}
            setIsNewPageLoading={setIsNewPageLoading}
            trackable="Episodes"
          />
        </footer>
      )}
      <Modal
        className={classes.episodesPageModals}
        open={newEpisodeModal}
        onClose={handleNewEpisodeModal}
      >
        <NewEpisodeModal
          handleClose={handleNewEpisodeModal}
          sequenceList={sequenceList}
          addNewEpisode={handleAddNewEpisode}
        />
      </Modal>
    </div>
  );
}

export default EpisodesOverview;
