import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30%",
    borderRadius: "20px",
    fontFamily: "poppins",
    [theme.breakpoints.down("lg")]: {
      width: "35%",
    },
  },

  card: {
    background: theme.palette.primary.main,
    borderRadius: "20px",
    padding: "10px 15px",
  },

  cardContent: {
    backgroundColor: "#393E46",
  },

  trashContainer: {
    width: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    transform: "rotate(90deg)",
  },
  trashIcon: {
    color: "#FF0000",
  },

  headText: {
    textAlign: "left",
    fontWeight: "600",
    fontSize: "30px",
    margin: "3px 0px",
  },

  text: {
    fontSize: "16px",
    textAlign: "left",
  },

  boldText: {
    // fontSize: "24px",
    wordBreak: "break-all",
  },

  buttonDiv: {
    display: "flex",
    gap: "5%",
    padding: "20px 30px 0px 30px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },

  yesButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "45px",
    width: "50%",
    borderRadius: "10px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
    },
    display: "flex",
    gap: "5%",
    alignItems: "center",
    fontSize: "14px",
  },
  noButton: {
    height: "45px",
    width: "50%",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    gap: "5%",
    alignItems: "center",
    fontSize: "14px",
  },
}));

export default useStyles;
