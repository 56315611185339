import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    display: "flex",
  },
  appBar: {
    background: theme.palette.primary.main,
    height: "80px",
    justifyContent: "space-between",
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    paddingLeft: "0%",
    paddingRight: "0%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  projectNameAndImage: {
    width: "100%",
    marginLeft: "5.5%",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  projectThumbnail: {
    height: "40px",
    width: "40px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  searchInput: {
    padding: "0.2px 6px",
    width: "60%",
    background: theme.palette.secondary.light,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
  },
  searchDiv: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "30%",
    height: "46px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.text.primary,
  },
  hide: {
    display: "none",
  },
  drawer: {
    background: theme.palette.secondary.light,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: "1202",
    borderRadius: "10px",
    position: "fixed",
    margin: "20px",
  },
  paperAnchorDockedLeft: {
    borderRightWidth: "0px",
  },
  drawerOpen: {
    background: theme.palette.secondary.light,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: theme.palette.secondary.light,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
  },
  toolbar: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  topToolbar: {
    paddingTop: "10px",
    justifyContent: "space-between",
  },
  extrProjectName: {
    display: "flex",
    alignItems: "center",
    gap: "50px",
  },
  simpleProjectName: {
    marginLeft: "5.5%",
  },
  contentOpen: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: "8rem",
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentClose: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: "8rem",
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  reviewerContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: "15rem",
  },
  topBarIconDiv: {},
  topBarIcon: {
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px !important",
    lineHeight: "58px",
    marginLeft: "10px",
  },
  sideBarIconsColor: {
    color: theme.palette.text.primary,
  },
  closeSidebar: {
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },

  sideBarList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "0",
  },

  sideBarListOpen: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0",
  },

  rightArrowIcon: {
    marginLeft: "2px",
    height: "30px",
    borderRadius: "50%",
    width: "30px",
    borderRadius: "50%",
    background: theme.palette.secondary.light,
  },

  searchBar: {
    width: "30%",
    borderRadius: "10px",
    background: theme.palette.secondary.light,
  },
  sideBarDiv: {
    paddingLeft: "10px",
  },
  paperAnchorDockedLeft: {
    borderRight: "0px",
  },
  iconDiv: {
    padding: "10px",
    width: "100%",
    alignItems: "center",
    color: theme.palette.text.primary,
    justifyContent: "center",
    display: "flex",

    border: `3px solid ${theme.palette.secondary.light}`,
    "&:hover": {
      color: theme.palette.text.primary,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px",
      border: `3px solid ${theme.palette.secondary.dark}`,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "6px",
    },
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    border: `3px solid ${theme.palette.secondary.dark}`,
    borderRadius: "10px",
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: "14px",
  },
  popoverArrow: {
    position: "absolute",
    borderRadius: "5px",
    left: "71%",
    top: "-23px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    marginLeft: "71%",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.secondary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  iconsize: {
    height: "30px !important",
    width: "30px !important",
    [theme.breakpoints.down("lg")]: {
      height: "20px !important",
      width: "20px !important",
    },
  },
}));

export default useStyles;
