import MomentUtils from "@date-io/moment";
import { Button, Typography } from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import useStyles from "./TaskUpdateModal.style";

const DateAndTimePicker = ({
  task,
  handleUpdateSingleTaskDate,
  handleClose,
}) => {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  useEffect(() => {
    setSelectedStartDate(task?.start_time);
    setSelectedEndDate(task?.end_time);
  }, [task]);

  return (
    <div className={classes.dateModalRoot}>
      <div className={classes.flexBetween}>
        <span style={{ fontSize: "20px", fontWeight: 500 }}>
          Select Date & Time
        </span>
        <Button onClick={handleClose}>
          <X />
        </Button>
      </div>
      <div>
        <Typography className={classes.textTwo}>
          Select Start Date & Time
        </Typography>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            autoOk
            invalidDateMessage={"Invalid date format"}
            variant="inline"
            format="YYYY/MM/DD hh:mm A"
            margin="normal"
            value={selectedStartDate}
            onChange={(date) => setSelectedStartDate(date)}
            id="date-time-picker"
            KeyboardButtonProps={{
              "aria-label": "change date",
              style: {
                padding: "0px",
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.dateInput}
            size="small"
            fullWidth
            ampm={true}
            minDate={new Date()}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <Typography className={classes.textTwo}>
          Select End Date & Time
        </Typography>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            autoOk
            invalidDateMessage={"Invalid date format"}
            variant="inline"
            format="YYYY/MM/DD hh:mm A"
            margin="normal"
            value={selectedEndDate}
            onChange={(date) => setSelectedEndDate(date)}
            id="date-time-picker"
            KeyboardButtonProps={{
              "aria-label": "change date",
              style: {
                padding: "0px",
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.dateInput}
            size="small"
            fullWidth
            ampm={true}
            minDate={new Date(selectedStartDate)}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.newTaskButton}
          onClick={() => {
            handleUpdateSingleTaskDate(
              task?.id,
              selectedStartDate,
              selectedEndDate
            );
            handleClose();
          }}
        >
          Change Date & Time
        </Button>
      </div>
    </div>
  );
};

export default DateAndTimePicker;
