import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  sequencePageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sequenceTableContainer: {
    width: "100%",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(823)]: {
      height: `calc(100vh - 305px)`,
    },
  },
  sequencePageTableCell: {
    backgroundColor: theme.palette.primary.main,
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    zIndex: "2",
    borderCollapse: "collapse",
    width: "15%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  sequencePageTableCellTwo: {
    backgroundColor: theme.palette.primary.main,
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    zIndex: "2",
    borderCollapse: "collapse",
    width: "15%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  sequencePageTableCellThree: {
    backgroundColor: theme.palette.primary.main,
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    zIndex: "2",
    borderCollapse: "collapse",
    width: "10%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
}));

export default useStyles;
