import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./PushNotificationToast.style";

const PushNotificationToast = (props) => {
  const history = useHistory();
  const classes = useStyles();
  let toastId = null;

  const handleOnClick = () => {
    const resourceClass = props.trackableClass.find(
      (el) => el.id == props.notificationInfo.data.resource_class
    );
    if (props.notificationInfo.data.asset) {
      history.push(
        `/${props.notificationInfo.data.project}/assets/${props.notificationInfo.data.asset}/${resourceClass.model}s`
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      props.setShow(false);
    }, 4000);
  }, []);

  if (!toast.isActive(toastId)) {
    toast(<Display />, {
      toastId: "my_toast",
      className: `${classes.toastTheme}`,
    });
  }

  function Display() {
    return (
      <div
        className={classes.notificationToast}
        // onClick={() => handleOnClick()}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {props?.user && (
            <p>
              {props?.user?.first_name} {props?.user?.last_name}
            </p>
          )}
          <h4 className={classes.notificationTitle}>
            {props.notificationInfo?.notification?.title}
          </h4>
        </div>
        <p>{props.notificationInfo?.notification?.body}</p>
      </div>
    );
  }

  return (
    <ToastContainer
      position="top-right"
      autoClose={4000}
      newestOnTop={true}
      rtl={false}
      pauseOnFocusLoss={false}
      hideProgressBar={true}
      draggable
      pauseOnHover={true}
      closeOnClick={true}
      closeButton={false}
    />
  );
};

PushNotificationToast.defaultProps = {
  title: "This is title",
  body: "Some body",
};

PushNotificationToast.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default PushNotificationToast;
