import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Popover,
  Radio,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DotsThreeOutline } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import FolderIcon from "../../Utilities/Svg/FolderIcon";
import ProjectMediaFolderTools from "../ProjectMediaModals/ProjectMediaToolsModal/ProjectMediaFolderTools";
import useStyles from "./ProjectMediaCard.styles";

const ProjectMediaFolder = ({
  eachFolder,
  handleSelectUnselectMedia,
  selectedMedias,
  projectId,
  openEditFolderModal,
  setDeleteFolderModal,
  handleCopyFolderLink,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [folderToolsModal, setFolderTollsModal] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleOpenFolderToolsModal = (event) => {
    setFolderTollsModal(event.currentTarget);
  };

  const handleCloseFolderToolsModal = () => {
    setFolderTollsModal(null);
  };

  useEffect(() => {
    setChecked(selectedMedias.some((single) => single === eachFolder.id));
  }, [eachFolder, selectedMedias]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
      <Card
        className={classes.card}
        style={{ border: checked && "1px solid #6C65D9" }}
      >
        <CardContent className={classes.cardContent}>
          <Box className={classes.dots}>
            <Radio
              size="small"
              color={checked ? `secondary` : "default"}
              style={{
                color: !checked && "gray",
                height: "16px",
                width: "16px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectUnselectMedia(eachFolder.id);
              }}
              checked={checked}
            />
            <Button
              className={classes.btnStyle}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleOpenFolderToolsModal(event);
              }}
            >
              <DotsThreeOutline weight="fill" width={20} height={20} />
            </Button>
          </Box>
          <Box
            className={classes.flexCenter}
            onClick={() => {
              history.push(`/${projectId}/project-media/${eachFolder.id}`, {
                shallow: true,
              });
            }}
          >
            <FolderIcon />
            <Tooltip title={eachFolder.name}>
              <Typography variant="h5" className={classes.name}>
                {eachFolder.name}
              </Typography>
            </Tooltip>
            <Typography className={classes.cardText}>
              {new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(eachFolder.updated_at))}
            </Typography>
            <Typography className={classes.cardText}>
              {eachFolder?.files?.length + eachFolder?.folders?.length} Items
            </Typography>
            <Typography className={classes.cardText}>
              {eachFolder?.created_by?.first_name}{" "}
              {eachFolder?.created_by?.last_name}
            </Typography>
          </Box>
        </CardContent>
        <Popover
          open={folderToolsModal !== null}
          anchorEl={folderToolsModal}
          onClose={handleCloseFolderToolsModal}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ProjectMediaFolderTools
            openEditFolderModal={() =>
              openEditFolderModal({
                isOpen: true,
                data: eachFolder,
                type: "edit",
              })
            }
            handleCloseFolderToolsModal={handleCloseFolderToolsModal}
            openDeleteModal={() =>
              setDeleteFolderModal({
                isOpen: true,
                data: eachFolder,
                type: "Folder",
              })
            }
            handleCopy={() => handleCopyFolderLink(eachFolder.id)}
          />
        </Popover>
      </Card>
    </Grid>
  );
};

export default ProjectMediaFolder;
