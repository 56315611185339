import React, { useState, useEffect } from "react";
import useStyles from "./SettingsModal.styles";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { X } from "phosphor-react";
import { fget, fpatch, fpost } from "../../../API/callsAPI";

export default function SettingsModal({ onClose, projectId }) {
  const classes = useStyles();
  const [notificationType, setNotificationType] = useState("1");
  const [check, setCheck] = useState([]);
  const [allNotification, setAllNotification] = useState([]);
  const [externalNotificationConfig, setExternalNotificationConfig] = useState(
    []
  );

  function transformNotification(notificationArr) {
    const result = [];
    const modal_type = [];

    for (const eachNotification of notificationArr) {
      if (
        eachNotification.content_type === "reviewversion" ||
        eachNotification.content_type === "reviewcomment" ||
        eachNotification.content_type === "review"
      ) {
        if (!modal_type.includes(eachNotification.content_type)) {
          const values = notificationArr.filter(
            (obj) => obj.content_type === eachNotification.content_type
          );
          result.push({ name: eachNotification.content_type, values });
          modal_type.push(eachNotification.content_type);
        }
      }
    }
    return result;
  }

  const getReviewerNotificationConfig = async () => {
    try {
      const res = await fget({
        url: `notification-types/${projectId}/`,
      });
      const data = transformNotification(res.data.results);
      const eventsObj = {};
      res.data.results.forEach((eachNotification) => {
        if (
          eachNotification.content_type === "reviewversion" ||
          eachNotification.content_type === "reviewcomment" ||
          eachNotification.content_type === "review"
        ) {
          eventsObj[eachNotification.event_type] = eachNotification.is_active;
        }
      });
      setCheck(eventsObj);
      setExternalNotificationConfig(data);
      const data1 = res.data.results.filter(
        (eachNotification) =>
          eachNotification.content_type === "reviewversion" ||
          eachNotification.content_type === "reviewcomment" ||
          eachNotification.content_type === "review"
      );
      setAllNotification(data1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviewerNotificationConfig();
  }, []);

  const handleOnChange = (e) => {
    setCheck({
      ...check,
      [e.target.name]: e.target.checked,
    });
  };

  const changeEmailConfiguration = async () => {
    const eventObj = {};
    if (notificationType === "1") {
      allNotification.map((eachNotification) => {
        eventObj[eachNotification.event_type] = true;
      });
    } else if (notificationType === "2") {
      allNotification.map((eachNotification) => {
        if (check[eachNotification.event_type] !== eachNotification.is_active) {
          eventObj[eachNotification.event_type] =
            check[eachNotification.event_type];
        }
      });
    }
    try {
      const res = await fpatch({
        url: `configure-notifications/`,
        data: {
          project: projectId,
          events: eventObj,
        },
      });
      if (res.status === 200 || 201) {
        getReviewerNotificationConfig();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const discardChanges = () => {
    const eventsObj = {};
    allNotification.forEach((eachNotification) => {
      eventsObj[eachNotification.event_type] = eachNotification.is_active;
    });
    setCheck(eventsObj);
  };

  return (
    <Box className={classes.root}>
      <div className={classes.flexBox}>
        <Typography className={classes.heading}>Settings</Typography>
        <Button onClick={onClose}>
          <X size="24px" />
        </Button>
      </div>
      <Typography className={classes.heading2}>
        Configure Email Notifications
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={notificationType}
        onChange={(e) => setNotificationType(e.target.value)}
      >
        <FormControlLabel
          value="1"
          control={<Radio />}
          label="Send me everything"
        />
        <FormControlLabel
          value="2"
          control={<Radio />}
          label="Let me choose my notifications"
        />
      </RadioGroup>
      {notificationType === "2" && (
        <div className={classes.flexBox3}>
          {externalNotificationConfig.map((eachNotif, index) => {
            return (
              <div key={index}>
                <Typography className={classes.heading3}>
                  {eachNotif.name === "reviewversion" && "Review Version"}
                  {eachNotif.name === "reviewcomment" && "Review Comment"}
                  {eachNotif.name === "review" && "Review"}
                </Typography>
                <FormGroup>
                  {eachNotif.values.map((eachValue, ind) => {
                    return (
                      <div
                        key={ind}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox
                          checked={check[eachValue.event_type]}
                          onClick={(e) => handleOnChange(e)}
                          name={eachValue.event_type}
                        />
                        <div>
                          <span className={classes.labelText}>
                            {eachValue.plain_text}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </FormGroup>
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.flexBox2}>
        <Button className={classes.colorBtn} onClick={changeEmailConfiguration}>
          Save Changes
        </Button>
        <Button className={classes.borderBtn} onClick={discardChanges}>
          Reset Changes
        </Button>
      </div>
    </Box>
  );
}
