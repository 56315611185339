import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: "40px",
    borderRadius: "20px",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  heading: {
    fontSize: "24px !important",
  },
  flexOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexTwo: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  episodesPageModals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sequenceContainer: {
    height: "180px",
    overflow: "auto",
    width: "100%",
    borderRadius: "10px 0px 0px 10px",
    padding: "8px 12px",
    backgroundColor: theme.palette.secondary.light,
  },
  textInput: {
    padding: "10px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "100%",
  },
  formText: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
  },
  outlineButtonOne: {
    borderRadius: "10px",
    padding: "8px !important",
    paddingLeft: "10px !important",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  coloredBtn: {
    borderRadius: "10px",
    padding: "10px 80px !important",
    backgroundColor: theme.palette.secondary.main,
  },
  outlinedBtn: {
    borderRadius: "10px",
    padding: "10px 80px !important",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  colour: {
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
