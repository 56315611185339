import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    // height: "150px",
  },
  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
  },
  referenceDate: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    marginTop: "20px",
  },

  newTaskButton: {
    color: theme.palette.text.secondary,
    background: theme.palette.secondary.main,
    fontSize: "14px",
    height: "40px",
    width: "200px",
    whiteSpace: "nowrap",
    borderRadius: "10px",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  resetButton: {
    color: theme.palette.text.secondary,
    border: `1px solid${theme.palette.secondary.main}`,
    fontSize: "14px",
    height: "40px",
    width: "200px",
    whiteSpace: "nowrap",
    borderRadius: "10px",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },

  //table styles
  tableContainer: {
    width: "100%",
  },
  table: {
    width: "100%",
  },

  tableRow: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },

  headTable: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    background: theme.palette.primary.main,
  },
  headTableTwo: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    background: theme.palette.primary.main,
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    maxHeight: "400px",
    paddingRight: "8px",
    [theme.breakpoints.down(823)]: {
      height: `calc(100vh - 305px)`,
    },
  },
  tableBodyRow: {
    cursor: "pointer",
    display: "table",
    width: "100%",
    tableLayout: "fixed",
    backgroundColor: "#252A38",
    padding: "0px 8px",
  },

  listText: {
    color: theme.palette.text.primary,
    padding: "8px 0px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  calenderAndDelete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
    paddingRight: "6px",
  },

  //common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    justifyContent: "center",
  },

  iconColor: {
    color: theme.palette.text.tertiary,
  },

  //date time modal style

  dateModalRoot: {
    width: "450px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },

  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 14px",
    height: "15px",
    width: "15px",
    border: "2px solid",
    borderColor: theme.palette.borderColor,
  },

  dateInput: {
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    borderRadius: "10px",
    marginTop: "0px",
  },

  textTwo: {
    fontSize: "14px",
    color: theme.palette.text.tertiary,
    marginBottom: "5px",
  },

  input: {
    backgroundColor: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.text.tertiary}`,
    padding: "4px",
    borderRadius: "10px",
  },
}));

export default useStyles;
