import React, { useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { PencilSimpleLine, DownloadSimple, Trash, Link } from "phosphor-react";
import useStyles from "./ProjectMediaTools.styles";

function ProjectMediaFolderTools({
  openEditFolderModal,
  handleCloseFolderToolsModal,
  openDeleteModal,
  handleCopy,
}) {
  const classes = useStyles();

  const handleDeleteModal = () => {
    openDeleteModal();
    handleCloseFolderToolsModal();
  };

  return (
    <List className={classes.root}>
      <ListItem button onClick={handleCopy}>
        <ListItemIcon>
          <Link size={23} className={classes.listText} />
        </ListItemIcon>
        <ListItemText className={classes.listText} primary="Copy Link" />
      </ListItem>
      <Divider className={classes.divider} />
      <ListItem
        button
        onClick={() => {
          openEditFolderModal();
          handleCloseFolderToolsModal();
        }}
      >
        <ListItemIcon>
          <PencilSimpleLine size={23} />
        </ListItemIcon>
        <ListItemText primary="Edit Folder" />
      </ListItem>
      <Divider className={classes.divider} />
      <ListItem button onClick={handleDeleteModal}>
        <ListItemIcon>
          <Trash size={23} className={classes.redText} />
        </ListItemIcon>
        <ListItemText className={classes.redText} primary="Delete Folder" />
      </ListItem>
    </List>
  );
}

export default ProjectMediaFolderTools;
