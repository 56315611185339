import React, { useEffect, useState } from "react";
import useStyles from "./NewEpisodeModal.styles";
import { Button, TextField, Typography, Modal } from "@material-ui/core";
import { Asterisk, FilmStrip, X } from "phosphor-react";
import AddSequenceEpisodeModal from "./AddSequenceEpisodeModal";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import HelperText from "../../Utilities/HelperText/HelperText";
import { useFormik } from "formik";
import * as yup from "yup";

function NewEpisodeModal({ handleClose, sequenceList, addNewEpisode }) {
  const classes = useStyles();
  const [addSequenceModal, setAddSequenceModal] = useState(false);
  const [selectedSequenceList, setSelectedSequenceList] = useState([]);

  //Validators
  const EpisodeScheme = yup.object({
    episodeName: yup
      .string("String required")
      .required("This is required field"),
  });

  const handleSequeneModal = () => {
    setAddSequenceModal(!addSequenceModal);
  };

  const handleSequenceListSelect = (id) => {
    const filteredSeq = selectedSequenceList.filter(
      (eachSeq) => eachSeq.id !== id
    );
    setSelectedSequenceList(filteredSeq);
  };

  const handleCancel = () => {
    formik.setValues({
      ...formik.values,
      episodeName: "",
      episodeDesc: "",
    });
    setSelectedSequenceList([]);
  };

  const formik = useFormik({
    initialValues: {
      episodeName: "",
      episodeDesc: "",
    },
    validationSchema: EpisodeScheme,
    onSubmit: async (values) => {
      const newList = selectedSequenceList.map((eachSeq) => {
        return eachSeq.id;
      });
      addNewEpisode({
        name: values.episodeName,
        description: values.episodeDesc,
        sequence: newList,
      });
      handleClose();
    },
  });

  return (
    <div>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <div className={classes.flexOne}>
          <Typography className={classes.heading}>New Episode</Typography>
          <Button onClick={handleClose}>
            <X size={32} />
          </Button>
        </div>
        <div className={classes.flexTwo}>
          <Typography className={classes.formText}>
            Episode Name
            <Asterisk color="red" />
          </Typography>
          <TextField
            id="name"
            name="episodeName"
            placeholder="Enter episode name"
            InputProps={{
              disableUnderline: true,
              style: {
                fontFamily: "Poppins",
              },
            }}
            className={classes.textInput}
            value={formik.values.episodeName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.episodeName)}
          />
          {formik.errors.episodeName && formik.touched.episodeName ? (
            <HelperText text={formik.errors.episodeName} />
          ) : null}
        </div>
        <div className={classes.flexTwo}>
          <Typography className={classes.formText}>Description</Typography>
          <TextField
            id="description"
            name="episodeDesc"
            placeholder="Enter description"
            className={classes.textInput}
            InputProps={{
              disableUnderline: true,
              style: {
                fontFamily: "Poppins",
              },
            }}
            multiline
            maxRows={4}
            value={formik.values.episodeDesc}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classes.flexTwo}>
          <div className={classes.flexOne}>
            <Typography className={classes.formText}>
              Sequence Selected - {selectedSequenceList.length}
            </Typography>
            <Button
              className={classes.outlineButtonOne}
              startIcon={<FilmStrip />}
              onClick={handleSequeneModal}
            >
              Add Sequence
            </Button>
          </div>
          <div className={classes.sequenceContainer}>
            {selectedSequenceList.map((eachSeqence) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={eachSeqence.id}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {eachSeqence.thumbnail ? (
                      <ImageViewer
                        sliderState={0}
                        url={eachSeqence.thumbnail}
                      />
                    ) : (
                      <NoThumbnail sliderState={0} />
                    )}
                    <Typography>{eachSeqence.code}</Typography>
                  </div>
                  <Button
                    onClick={() => handleSequenceListSelect(eachSeqence.id)}
                  >
                    <X size={20} className={classes.colour} />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.flexOne}>
          <Button className={classes.outlinedBtn} onClick={handleCancel}>
            Cancel
          </Button>
          <Button className={classes.coloredBtn} type="submit" value="Submit">
            Save
          </Button>
        </div>
      </form>
      <Modal
        className={classes.episodesPageModals}
        open={addSequenceModal}
        onClose={handleSequeneModal}
      >
        <AddSequenceEpisodeModal
          handleSequenceModalClose={handleSequeneModal}
          sequenceList={sequenceList}
          onSubmit={(value) => setSelectedSequenceList(value)}
          selectedSequenceList={selectedSequenceList}
        />
      </Modal>
    </div>
  );
}

export default NewEpisodeModal;
