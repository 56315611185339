import React from "react";
import AssetsOverview from "../../Components/Assets/AssetsOverview/AssetsOverview.component";

function AssetsPage(props) {
  return (
    <div>
      <AssetsOverview params={props.match.params} />
    </div>
  );
}

export default AssetsPage;
