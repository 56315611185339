import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { DownloadSimple, Link, PencilSimpleLine, Trash } from "phosphor-react";
import React from "react";
import useStyles from "./ProjectMediaTools.styles";

function ProjectMediaFileTools({
  handleCloseFileToolsModal,
  handleOpenEditMediaModal,
  handleOpenDeleteMediaModal,
  handleDownload,
  handleCopy,
}) {
  const classes = useStyles();

  const openEditModal = () => {
    handleOpenEditMediaModal();
    handleCloseFileToolsModal();
  };

  const openDeleteModal = () => {
    handleOpenDeleteMediaModal();
    handleCloseFileToolsModal();
  };

  return (
    <List className={classes.root}>
      <ListItem button onClick={handleCopy}>
        <ListItemIcon>
          <Link size={23} className={classes.listText} />
        </ListItemIcon>
        <ListItemText className={classes.listText} primary="Copy Link" />
      </ListItem>
      <Divider className={classes.divider} />
      <ListItem button onClick={openEditModal}>
        <ListItemIcon>
          <PencilSimpleLine size={23} />
        </ListItemIcon>
        <ListItemText primary="Edit" />
      </ListItem>
      <Divider className={classes.divider} />
      <ListItem button onClick={handleDownload}>
        <ListItemIcon>
          <DownloadSimple size={23} />
        </ListItemIcon>
        <ListItemText primary="Download" />
      </ListItem>
      <Divider className={classes.divider} />
      <ListItem button onClick={openDeleteModal}>
        <ListItemIcon>
          <Trash size={23} className={classes.redText} />
        </ListItemIcon>
        <ListItemText className={classes.redText} primary="Delete File" />
      </ListItem>
    </List>
  );
}

export default ProjectMediaFileTools;
