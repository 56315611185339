import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import ProjectMediaList from "./ProjectMediaList";
import useStyles from "./ProjectMediaListView.style";

const ProjectMediaListView = ({
  type,
  handleSelectUnselectMedia,
  selectedItems,
  allProjectMediaData,
  projectId,
  openEditModal,
  openDeleteModal,
  handleCopyFolderLink,
  handleOpenEditMediaModal,
  handleOpenDeleteMediaModal,
  getFileUrl,
  searchQuery,
}) => {
  const classes = useStyles();
  return (
    <div>
      <>
        <TableContainer>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.headTableTwo} align="left">
                  {type === "folder" ? "Folder Name" : "Media Name"}
                </TableCell>
                <TableCell className={classes.headTable} align="center">
                  {type === "folder" ? "Last Modified" : "Updated On"}
                </TableCell>
                <TableCell className={classes.headTable} align="center">
                  {type === "folder" ? " Total Files" : "File Type"}
                </TableCell>
                {type === "file" ? (
                  <TableCell className={classes.headTable} align="center">
                    File Size{" "}
                  </TableCell>
                ) : (
                  ""
                )}
                <TableCell className={classes.headTable} align="center">
                  Created By
                </TableCell>
                <TableCell className={classes.headTableTwo} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              className={classes.tableBody}
              // onScroll={handleTableScroll}
              // ref={tableRef}
              // onLoad={() => (tableRef.current.scrollTop = scrollValue)}
              /* style={{
                      height:
                        assetList?.count > 50
                          ? `calc(100vh - 290px)`
                          : `calc(100vh - 250px)`,
                    }} */
            >
              {allProjectMediaData
                .filter((search) => {
                  if (type === "folder") {
                    if (
                      search.name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ) {
                      return search;
                    }
                  } else {
                    if (
                      search.file_name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ) {
                      return search;
                    }
                  }
                })
                .map((singleItem) => {
                  return (
                    <ProjectMediaList
                      key={singleItem.id}
                      singleItem={singleItem}
                      type={type}
                      handleSelectUnselectMedia={handleSelectUnselectMedia}
                      selectedItems={selectedItems}
                      projectId={projectId}
                      openEditModal={openEditModal}
                      openDeleteModal={openDeleteModal}
                      handleCopyFolderLink={handleCopyFolderLink}
                      handleOpenEditMediaModal={handleOpenEditMediaModal}
                      handleOpenDeleteMediaModal={handleOpenDeleteMediaModal}
                      getFileUrl={getFileUrl}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
};

export default ProjectMediaListView;
