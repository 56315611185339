import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
//@Components
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import { checkImage } from "../../utils";
import ShotsList from "../ShotsList/ShotsList.component";
import ShotsPageNewShotModal from "../ShotsPageModals/ShotsPageNewShotModal";
import ShotsPageTools from "../ShotsPageTools/ShotsPageTools.component";
import useStyles from "./ShotsPageOverview.styles";

//@MUI
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
//@API
import { fget, fpostBFF } from "../../../API/callsAPI";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import Loader from "../../Utilities/Loader/Loader";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";
import ShotPageMultiCreate from "../ShotsPageModals/ShotPageMultiCreate";
import ShotPageOptionModal from "../ShotsPageModals/ShotPageOptionModal";

export default function ShotsPageOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef();

  const [sliderState, setSliderState] = React.useState("0");
  const [isNewShotModalOpen, setNewShotModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [shotsList, setShotsList] = useState();
  const [sortByDefault, setSortByDefault] = useState("");
  const [departmentsList, setDepartmentsList] = useState();
  const [departments, setDepartments] = useState();
  const [shotsFilteredStatus, setShotsFilteredStatus] = useState("");
  const [shotsFilteredDepartment, setShotsFilteredDepartment] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shotPageToggler, setShotPageToggler] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [file, setFile] = useState("");
  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  const [scrollValue, setScrollValue] = useState("");
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [isCreateShotOptionModalOpen, setIsCreateShotOptionModalOpen] =
    useState(false);

  const [isMultiCreateModalOpen, setIsMultiCreateModalOpen] = useState(false);
  const [shotsStats, setShotsStats] = useState({ total: 0, visibleCount: 0 });
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  let searchParams = new URLSearchParams(decodedURL);
  //initial bff call
  useEffect(() => {
    fetchShots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShotsFilteredStatus(searchParams.get("status") || "");
    setSortByDefault(searchParams.get("sortBy") || "A-Z");
    var scrollStored = JSON.parse(localStorage.getItem("scroll"));
    if (scrollStored) {
      setScrollValue(scrollStored.shots);
    } else {
      const scrollData = {
        asset: "0",
        assetVersion: "0",
        sequence: "0",
        shots: "0",
        shotVersion: "0",
      };
      localStorage.setItem("scroll", JSON.stringify(scrollData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  useEffect(() => {
    if (
      parseInt(shotsFilteredStatus) > 0 ||
      updateOnPageChange ||
      refreshCounter
    ) {
      fetchFilteredShots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shotsFilteredStatus,
    shotsFilteredDepartment,
    currentPage,
    updateOnPageChange,
    refreshCounter,
  ]);

  function handleNewShotModalState() {
    setNewShotModalOpen(!isNewShotModalOpen);
  }

  const handleShotPageToggler = () => {
    setShotPageToggler(!shotPageToggler);
  };

  async function fetchFilteredShots() {
    const url = `trackables/shot/?page=${currentPage}&project=${props.params.projectId}&parent_sequence=${props.params.sid}&status=${shotsFilteredStatus}&sortBy=${sortByDefault}&?allowed_dept=${shotsFilteredDepartment}`;
    try {
      const res = await fget({
        url: `trackables/shot/?page=${currentPage}&project=${props.params.projectId}&parent_sequence=${props.params.sid}&status=${shotsFilteredStatus}&?allowed_dept=${shotsFilteredDepartment}`,
      });
      setShotsList(res?.data);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsNewPageLoading(false);
    }

    history.push("?" + btoa(unescape(encodeURIComponent(url.split("?")[1]))));
  }

  //post req bff to get projectDetails,status,shot
  const fetchShots = async () => {
    const shots = {
      url: "web/shot/list/",
      data: {
        project_id: props.params.projectId,
        sequence_id: props.params.sid,
      },
    };
    try {
      const res = await fpostBFF(shots);
      setStatusList(res?.data.status_list.results);
      setDepartmentsList(res?.data?.department_list?.results);
      if (
        searchParams.get("status") === null ||
        searchParams.get("status") === ""
      ) {
        setShotsList(res.data.shot_list);
        setIsLoaded(true);
      }
      setShotsStats((prevData) => ({
        ...prevData,
        visibleCount: res.data.shot_list.count,
      }));
      setDepartments(
        res?.data?.department_list?.results.map((el) => {
          const data = {
            title: el.name,
            id: el.id,
          };
          return data;
        })
      );
      window.scrollTo(0, sessionStorage.getItem("scrollpos"));
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `Error on loading shots`,
        isError: true,
      });
      setIsLoaded(true);
    }
  };

  async function fetchFile(id) {
    if (id) {
      // fetching the signed url only if file is there

      try {
        const res = await fget({
          url: `trackables/shot/${id}/file`,
        });
        setFile(res?.data?.signed_url);
      } catch (error) {
        setFile("");
        console.log(error);
      }
    } else setFile("");
  }

  useEffect(() => {
    shotsList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shotsList]);

  //Function to sort Shots
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );
    switch (sortBy) {
      case "A-Z":
        shotsList?.results?.sort((a, b) => a.code.localeCompare(b.code));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        shotsList?.results?.sort((a, b) => b.code.localeCompare(a.code));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        shotsList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        shotsList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        shotsList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return shotsList?.results;
    }
  }

  //event to save scrollBar position
  window.onbeforeunload = function (e) {
    sessionStorage.setItem("scrollpos", window.scrollY);
  };

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type === "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type === "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  let scrollTimer = null;
  const handleTableScroll = (e) => {
    if (scrollTimer !== null) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      searchParams.set("scroll", `${e.target.scrollTop}`);
      history.push(
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      );
      let scrollStored = JSON.parse(localStorage.getItem("scroll"));
      scrollStored.shots = e.target.scrollTop;
      localStorage.setItem("scroll", JSON.stringify(scrollStored));
    }, 700);
  };

  const getShotsStats = async () => {
    try {
      const shotsRes = await fget({
        url: `trackables/trackable-count/shot/${props.params.sid}/`,
      });
      setShotsStats((prevData) => ({
        ...prevData,
        total: shotsRes?.data?.instances,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShotsStats();
  }, [refreshCounter]);

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <ShotsPageTools
        handleSliderChange={(value) => {
          setSliderState(value);
          sessionStorage.setItem("sliderState", `${sliderState}`);
        }}
        sliderState={sliderState}
        openNewShotModal={handleNewShotModalState}
        handleBackButton={() =>
          history.replace(
            history.location.pathname.split("/").slice(0, -2).join("/")
          )
        }
        status={statusList}
        departmentsList={departmentsList}
        handleStatusChange={(value) => {
          setShotsFilteredStatus(value);
          if (value === "") {
            history.replace(history.location.pathname);
            window.location.reload();
          }
        }}
        handleDepartmentChange={(value) => setShotsFilteredDepartment(value)}
        filterValues={{
          status: shotsFilteredStatus,
          department: shotsFilteredDepartment,
        }}
        handleShotPageToggler={handleShotPageToggler}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        isCreateShotOptionModalOpen={isCreateShotOptionModalOpen}
        setIsCreateShotOptionModalOpen={setIsCreateShotOptionModalOpen}
        shotsStats={shotsStats}
      />
      {isLoaded ? (
        <>
          {shotsList && shotsList.count !== 0 ? (
            <>
              <TableContainer className={classes.shotsTableContainer}>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        align="left"
                        className={classes.shotPageTableCellTwo}
                      >
                        Shots Name
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotPageTableCell}
                      >
                        Last Modified
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotPageTableCell}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotPageTableCell}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.shotPageTableCellTwo}
                      >
                        Action
                      </TableCell>
                      {/* <TableCell align="left">Department</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    className={classes.tableBody}
                    onScroll={handleTableScroll}
                    ref={tableRef}
                    onLoad={() => {
                      if (tableRef?.current && tableRef?.current?.scrollTop) {
                        tableRef.current.scrollTop = scrollValue;
                      }
                    }}
                    style={{
                      height:
                        shotsList.count > 50
                          ? `calc(100vh - 295px)`
                          : `calc(100vh - 250px)`,
                    }}
                  >
                    {shotsList && shotsList.count !== 0
                      ? shotsList.results?.map((currShot) => (
                          <ShotsList
                            key={currShot.id}
                            data={currShot}
                            projectId={props.params.projectId}
                            sequenceId={props.params.sid}
                            status={statusList}
                            sliderState={sliderState}
                            shotPageToggler={shotPageToggler}
                            updateRefreshCounter={updateRefreshCounter}
                            setIsViewModalOpen={setIsViewModalOpen}
                            fetchFile={fetchFile}
                            dragScreen={dragScreen}
                            handleDragScreen={handleDragScreen}
                            setDragFileEnterModal={setDragFileEnterModal}
                            departmentsList={departmentsList}
                          />
                        ))
                      : "No Shots"}
                  </TableBody>
                </Table>
              </TableContainer>
              {shotsList && shotsList.count > 50 ? (
                <footer style={{ bottom: "0", height: "100%" }}>
                  <div>
                    <CustomPagination
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      totalItem={shotsList.count}
                      totalPage={Math.ceil(shotsList.count / 50)}
                      setIsNewPageLoading={setIsNewPageLoading}
                    />
                  </div>
                </footer>
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal className={classes.shotsPageModals} open={isNewPageLoading}>
          <NewDataLoadingLoader trackable="Shots" />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotsPageModals}
          open={isNewShotModalOpen}
          onClose={() => setNewShotModalOpen(false)}
        >
          <ShotsPageNewShotModal
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            departments={departments}
            projectId={props.params.projectId}
            sequenceId={props.params.sid}
            status={statusList}
            handleNewShotModal={handleNewShotModalState}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
      {/* <div>
        <Modal
          className={classes.shotsPageModals}
          open={isEditSequenceModalOpen}
          onClose={() => setEditSequenceModalOpen(false)}
        >
          <SequencePageEditSequenceModal
            sequenceId={props.params.sid}
            projectId={props.params.projectId}
            sequence={parentSequence}
            status={sequenceStatus}
            handleEditSequenceModal={handleEditSequenceModalState}
          />
        </Modal>
      </div> */}
      <div>
        <Modal
          className={classes.shotsPageModals}
          open={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
          }}
        >
          {file ? (
            checkImage(file) ? (
              <img
                src={file}
                alt="shot-img"
                className="card-img-top"
                style={{ width: "500px", height: "auto" }}
              />
            ) : (
              <video
                src={file}
                controls
                autoPlay
                style={{ width: "auto", height: "500px" }}
              />
            )
          ) : (
            <span>No file found</span>
          )}
        </Modal>

        <Modal
          open={isCreateShotOptionModalOpen}
          onClose={() => setIsCreateShotOptionModalOpen(false)}
        >
          <ShotPageOptionModal
            setIsCreateShotOptionModalOpen={setIsCreateShotOptionModalOpen}
            setIsMultiCreateModalOpen={setIsMultiCreateModalOpen}
            setIsNewCreateModalOpen={setNewShotModalOpen} //illegal name change, fix later
          />
        </Modal>
        <Modal
          className={classes.shotsPageModals}
          open={isMultiCreateModalOpen}
          onClose={() => setIsMultiCreateModalOpen(false)}
        >
          <ShotPageMultiCreate
            projectId={props.params.projectId}
            sequenceId={props.params.sid}
            setIsMultiCreateModalOpen={setIsMultiCreateModalOpen}
            updateRefreshCounter={updateRefreshCounter}
          />
        </Modal>
      </div>
    </div>
  );
}
