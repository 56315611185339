import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "470px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },

  cardContentDiv2: {
    display: "flex",
    // marginTop: "2%",
  },

  input: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    padding: "2%",
  },

  label: {
    color: "grey",
  },

  cardContentInput1: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    width: "200px",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },

  doneButton: {
    width: "300px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
  },

  selectedProjectsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    justifyContent: "space-between",
    padding: "4px",
  },
  selectedProject: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  reportsContainer: {
    marginTop: "20px",
    height: "60vh",
    overflowY: "auto",
    paddingRight: "20px",
  },

  reports: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  report: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: "10px 20px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalCard: {
    height: "650px",
    width: "500px",
    overflowY: "auto",
    backgroundColor: "black",
    padding: "20px",
    borderRadius: "10px",
  },
  modalProjects: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    height: "500px",
    overflowY: "auto",
    paddingRight: "8px",
  },
  singleProjectOfModal: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    justifyContent: "space-between",
    padding: "20px",
  },

  projectName: {
    fontSize: "30px",
    fontWeight: 600,
  },
  artist: {
    fontSize: "20px",
    fontWeight: 600,
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  text: {
    textAlign: "center",
  },
  gridUnderline: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
