import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "33%",
    borderRadius: "20px",
    padding: "30px",
    backgroundColor: theme.palette.primary.main,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "24px !important",
    fontWeight: "500 !important",
  },
  heading2: {
    fontSize: "14px !important",
    color: theme.palette.text.tertiary,
    marginTop: "24px",
    marginBottom: "10px",
  },
  heading3: {
    fontSize: "14px !important",
    color: theme.palette.text.tertiary,
  },
  flexBox3: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "10px 0px 26px 30px",
  },
  flexBox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  colorBtn: {
    borderRadius: "10px",
    padding: "12px 30px",
    backgroundColor: theme.palette.secondary.main,
  },
  borderBtn: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    padding: "12px 30px",
  },
}));

export default useStyles;
