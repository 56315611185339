export const reviewFileFakeData = {
  id: 54,
  comment_count: 2,
  created_by: {
    id: "id-1",
    email: "user@deltaorg.com",
    first_name: "User",
    last_name: "Name",
    avatar:
      "https://png.pngtree.com/png-vector/20190629/ourmid/pngtree-office-work-user-icon-avatar-png-image_1527655.jpg",
  },
  reviewers: [
    {
      id: "ceacf603-233d-4012-bfd7-992225a74434",
      email: "siddeshsharma1999@gmail.com",
      first_name: "Siddesh",
      last_name: "Sharma",
      avatar:
        "https://static.vecteezy.com/system/resources/thumbnails/019/896/012/small_2x/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png",
    },
    {
      id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
      email: "user@deltaorg.com",
      first_name: "User",
      last_name: "Name",
      avatar:
        "https://png.pngtree.com/png-vector/20190629/ourmid/pngtree-office-work-user-icon-avatar-png-image_1527655.jpg",
    },
  ],
  versions: 2,
  name: "TrailerReview",
  file: "https://storage.googleapis.com/movie-colab-stage-media-bucket/AnimeFlix_6/review-files/Soul%20Stone%20Montage%20Teaser%20v1.3.mp4",
  thumbnail:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvrRAwkRxwsHSJMc8qE1ZhP_OmEtoxp4Bs-pOyoPAzyg&s",
  status: "Completed",
  updated_date: "2023-07-18T11:20:05.685323+01:00",
  completed: false,
  review_stage: 2,
  flow: 63,
};

export const reviewVersionFakeData = {
  id: 45,
  comment_count: 1,
  created_by: {
    id: "id-1",
    email: "user@deltaorg.com",
    first_name: "User",
    last_name: "Name",
    avatar:
      "https://png.pngtree.com/png-vector/20190629/ourmid/pngtree-office-work-user-icon-avatar-png-image_1527655.jpg",
  },
  reviewers: [
    {
      id: "ceacf603-233d-4012-bfd7-992225a74434",
      email: "siddeshsharma1999@gmail.com",
      first_name: "Siddesh",
      last_name: "Sharma",
      avatar:
        "https://static.vecteezy.com/system/resources/thumbnails/019/896/012/small_2x/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png",
    },
    {
      id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
      email: "admin@deltaorg.com",
      first_name: "admin",
      last_name: "delta",
      avatar:
        "https://storage.googleapis.com/movie-colab-stage-media-bucket/users/avatars/1b2bf489-8bb6-4d6c-a983-0cdaef4dad29/wide-putin-awoken.gif",
    },
    {
      id: null,
      email: "",
      first_name: "",
      last_name: "",
      avatar: null,
    },
  ],
  status_by_users: [
    {
      id: 56,
      user: {
        id: "ceacf603-233d-4012-bfd7-992225a74434",
        email: "siddeshsharma1999@gmail.com",
        first_name: "Siddesh",
        last_name: "Sharma",
        avatar:
          "https://static.vecteezy.com/system/resources/thumbnails/019/896/012/small_2x/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png",
      },
      status: "In Progress",
      user_index: 0,
      version: 45,
    },
    {
      id: 58,
      user: {
        id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
        email: "admin@deltaorg.com",
        first_name: "admin",
        last_name: "delta",
        avatar:
          "https://png.pngtree.com/png-vector/20190629/ourmid/pngtree-office-work-user-icon-avatar-png-image_1527655.jpg",
      },
      status: "In Progress",
      user_index: 1,
      version: 45,
    },
    {
      id: 57,
      user: null,
      status: "Completed",
      user_index: 2,
      version: 45,
    },
  ],
  file: "https://storage.googleapis.com/movie-colab-stage-media-bucket/AnimeFlix_6/review-files/Soul%20Stone%20Montage%20Teaser%20v1.3_6CmR7tg.mp4",
  thumbnail:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvrRAwkRxwsHSJMc8qE1ZhP_OmEtoxp4Bs-pOyoPAzyg&s",
  name: "TrailerReview.v01",
  created_date: "2023-07-18T11:22:05.297321+01:00",
  review_stage: 2,
  review: 54,
};

export const fakeVersions = [
  {
    id: 59,
    comment_count: 1,
    created_by: {
      id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
      email: "admin@deltaorg.com",
      first_name: "admin",
      last_name: "delta",
      avatar:
        "https://storage.googleapis.com/movie-colab-stage-media-bucket/users/avatars/1b2bf489-8bb6-4d6c-a983-0cdaef4dad29/wide-putin-awoken.gif",
    },
    reviewers: [
      {
        id: "ceacf603-233d-4012-bfd7-992225a74434",
        email: "siddeshsharma1999@gmail.com",
        first_name: "Siddesh",
        last_name: "Sharma",
        avatar:
          "https://storage.googleapis.com/movie-colab-stage-media-bucket/4/ceacf603-233d-4012-bfd7-992225a74434/avatars/SWORDS.png",
      },
      {
        id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
        email: "admin@deltaorg.com",
        first_name: "admin",
        last_name: "delta",
        avatar:
          "https://storage.googleapis.com/movie-colab-stage-media-bucket/users/avatars/1b2bf489-8bb6-4d6c-a983-0cdaef4dad29/wide-putin-awoken.gif",
      },
      {
        id: null,
        email: "",
        first_name: "",
        last_name: "",
        avatar: null,
      },
    ],
    status_by_users: [
      {
        id: 103,
        user: {
          id: "ceacf603-233d-4012-bfd7-992225a74434",
          email: "siddeshsharma1999@gmail.com",
          first_name: "Siddesh",
          last_name: "Sharma",
          avatar:
            "https://storage.googleapis.com/movie-colab-stage-media-bucket/4/ceacf603-233d-4012-bfd7-992225a74434/avatars/SWORDS.png",
        },
        status: "In Progress",
        user_index: 0,
        version: 59,
      },
      {
        id: 105,
        user: {
          id: "1b2bf489-8bb6-4d6c-a983-0cdaef4dad29",
          email: "admin@deltaorg.com",
          first_name: "admin",
          last_name: "delta",
          avatar:
            "https://storage.googleapis.com/movie-colab-stage-media-bucket/users/avatars/1b2bf489-8bb6-4d6c-a983-0cdaef4dad29/wide-putin-awoken.gif",
        },
        status: "Revision",
        user_index: 1,
        version: 59,
      },
      {
        id: 104,
        user: null,
        status: "In Progress",
        user_index: 2,
        version: 59,
      },
    ],
    file: "https://storage.googleapis.com/movie-colab-stage-media-bucket/AnimeFlix_6/review-files/ocean.jpg_JzcuWW5.jpg",
    thumbnail: null,
    name: "adsasd",
    created_date: "2023-07-26T07:01:35.538107+01:00",
    review_stage: 2,
    review: 54,
  },
];

export const fakeComments = {
  data: [
    {
      id: 48,
      replied_to: null,
      mentions_to: [],
      user: {
        first_name: "Admin",
        last_name: "delta",
      },
      attachment: "https://i.ibb.co/Tc5gqr3/img-9r2ao-FN.png",
      text: "9.150719<p>font change</p>",
      date_added: new Date(),
      version: 45,
    },
  ],
  isLoading: false,
};
