import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { ArrowUpRight, Eye, EyeSlash } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { useLocation } from "react-router-dom";
import { fget } from "../../../API/callsAPI";
import CanvasFrame from "../../CanvasFrame/CanvasFrame.component";
import Player from "../../Player/player.component";
import StatusViewer from "../../Utilities/StatusViewer/StatusViewer";
import ThreeDModelSvg from "../../Utilities/Svg/ThreeDModelSvg";
import { checkGlb, checkImage } from "../../utils";
import UpdatesPageCardComment from "../UpdatesPageCardsTools/UpdatesPageCardComment.component";
import UpdatesPageCardCommentDiv from "../UpdatesPageCardsTools/UpdatesPageCardCommentDiv.component";
import useStyles from "./UpdatesPageUpdateCards.styles";

function UpdatesPageUpdateCards(props) {
  const classes = useStyles();
  //States

  const [updateDetail] = useState(props.update);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(
    props.update.comments ? props.update.comments.count : 0
  );
  const [commentList, setCommentList] = useState(
    props.update.comments ? props.update.comments.results : null
  );
  const [seekTime, setSeekTime] = useState(0);
  const [screenshot, setScreenshot] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [canvasModal, setCanvasModal] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [playerProgress, setPlayerProgress] = useState("00:00");
  const [repliedTo, setRepliedTo] = useState({});
  const player = useRef(null);
  const location = useLocation();

  const canvasModalToggle = () => {
    setCanvasModal(!canvasModal);
  };
  const displayImageToggle = () => setImageModal(!imageModal);

  useEffect(() => {
    const accessToken = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken && setLoggedInUser(JSON.parse(atob(accessToken.split(".")[1])));
  }, []);

  const values = [
    { id: 1, name: "Newest" },
    { id: 2, name: "Oldest" },
    { id: 3, name: "Incomplete " },
    { id: 4, name: "Time code" },
  ];

  const handleOpenVersionInNewTab = () => {
    //logic is totally flawed when it comes to other trackables

    //TODO: flawed logic, improve this and handle all edge cases

    if (props?.update?.resource_class === "assetversion") {
      location.pathname = `/${props.projectId}/assets/${props?.update?.resource_object.asset?.id}/assetversions`;
      const encode = btoa(
        unescape(encodeURIComponent(`versionId=${props?.update?.resource_id}`))
      );

      window.open(`${location.pathname}?${encode}`);
    } else if (props?.update?.resource_class === "shotversion") {
      location.pathname = `/${props.projectId}/sequence/${props?.update?.resource_object?.shot?.parent_sequence}/shots/${props?.update?.resource_object?.shot?.id}/shotversions/`;
      const encode = btoa(
        unescape(
          encodeURIComponent(`versionId=${props?.update?.resource_object?.id}`)
        )
      );

      window.open(`${location.pathname}?${encode}`);
    }
  };

  const fetchComments = async () => {
    try {
      const commentsRes = await fget({
        url: `trackables/trackable-comment/?version_id=${updateDetail.resource_object.id}&&version_type=${updateDetail.resource_class}`,
      });
      if (commentsRes.status === 200 || 201) {
        setCommentList(commentsRes.data.results);
        setCommentCount(commentsRes.data.count);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeSeekTime = (time) => {
    time = time.trim();
    if (time.includes(":")) {
      var timeInNos = 0; //setting time to 0
      if (time.length === 4) {
        timeInNos +=
          parseInt(time.substr(0, 1)) * 60 + parseInt(time.substr(2, 2));
      } else {
        timeInNos +=
          parseInt(time.substr(0, 2)) * 60 + parseInt(time.substr(3, 2));
      }
      setSeekTime(timeInNos);
    } else {
      setSeekTime(time);
    }
  };

  return (
    <>
      <Modal
        open={imageModal}
        onClose={displayImageToggle}
        className={classes.modal1}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            outline: "none",
            height: "80vh",
          }}
        >
          <Icon.X
            style={{ alignSelf: "flex-end" }}
            onClick={displayImageToggle}
          />
          <img
            src={displayImage}
            height="90%"
            alt="attachment"
            onContextMenu={(e) => e.preventDefault()}
          ></img>
        </div>
      </Modal>
      <div>
        <Modal open={canvasModal} className={classes.modal1}>
          <CanvasFrame
            users={props.users}
            imageRef={imageRef}
            image={screenshot}
            progress={playerProgress}
            //flawed logic needs to be fixed!
            assetID={
              updateDetail.resource_object
                ? updateDetail.resource_object.asset
                  ? updateDetail.resource_object.asset?.id
                  : updateDetail.resource_object.shot?.id
                : null
            }
            closeModal={canvasModalToggle}
            toggle={canvasModalToggle}
            version_id={
              updateDetail.resource_object
                ? updateDetail.resource_object.id
                : null
            }
            fetchComments={fetchComments}
          />
        </Modal>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <Card className={classes.cardDiv}>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid
                item
                md={8}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div className={classes.avatar}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="28" height="28" rx="14" fill="#1B1D28" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 9C10 7.93913 10.4214 6.92172 11.1716 6.17157C11.9217 5.42143 12.9391 5 14 5C15.0609 5 16.0783 5.42143 16.8284 6.17157C17.5786 6.92172 18 7.93913 18 9C18 10.0609 17.5786 11.0783 16.8284 11.8284C16.0783 12.5786 15.0609 13 14 13C12.9391 13 11.9217 12.5786 11.1716 11.8284C10.4214 11.0783 10 10.0609 10 9ZM10 15C8.67392 15 7.40215 15.5268 6.46447 16.4645C5.52678 17.4021 5 18.6739 5 20C5 20.7956 5.31607 21.5587 5.87868 22.1213C6.44129 22.6839 7.20435 23 8 23H20C20.7956 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7956 23 20C23 18.6739 22.4732 17.4021 21.5355 16.4645C20.5979 15.5268 19.3261 15 18 15H10Z"
                      fill="#CD7E06"
                    />
                  </svg>
                </div>
                <div
                  className={classes.title}
                  onClick={() => handleOpenVersionInNewTab()}
                >
                  {updateDetail.message}
                  {/* {updateDetail.resource_object
                    ? updateDetail.resource_object.name
                    : null} */}
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={classes.date}>
                  {new Intl.DateTimeFormat("en-IN", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  }).format(new Date(updateDetail.created_at))}
                </div>
                <div className={classes.status}>
                  <StatusViewer
                    status={updateDetail?.resource_object?.status}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={classes.cardMediaContainer}>
              <div>
                {updateDetail.signed_url ? (
                  checkImage(updateDetail.resource_object.file) ? (
                    <>
                      <img
                        ref={(image) => {
                          setImageRef(image);
                        }}
                        crossOrigin="Anonymous"
                        onClick={() => {
                          setDisplayImage(updateDetail.signed_url.signed_url);
                          displayImageToggle();
                        }}
                        // className="card-img-top"
                        src={updateDetail.signed_url.signed_url}
                        alt="Project new"
                        className={classes.image}
                        onContextMenu={(e) => e.preventDefault()}
                      ></img>
                    </>
                  ) : checkGlb(updateDetail.signed_url?.signed_url) ? (
                    updateDetail.signed_url?.signed_url ? (
                      <>
                        <div className={classes.flexCenter}>
                          <ThreeDModelSvg />
                        </div>
                        {/* <div style={{ height: "250", position: "relative" }}>
                          <img
                            src={threeDImage}
                            alt=""
                            className={classes.threeDImage}
                          />
                          <div className={classes.threeIconContainer}>
                            <div
                              style={{
                                display: isHovered ? "flex" : "none",
                              }}
                              className={classes.noteModal}
                            >
                              <div>
                                <ThreeDIcon />
                              </div>
                              <div>
                                This is a 3D asset, to “view” please click on
                                “open in version viewer”
                              </div>
                            </div>
                            <Button
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <ThreeDIcon />
                            </Button>
                          </div>
                        </div> */}
                      </>
                    ) : (
                      "File is Loading"
                    )
                  ) : (
                    <>
                      <Player
                        users={props.users}
                        className="card-img-top"
                        controls
                        alt="Project new"
                        autoPlay
                        muted
                        width="100%"
                        height="100%"
                        data={updateDetail.signed_url.signed_url}
                        seekTime={seekTime}
                        setSeekTime={setSeekTime}
                        screenshot={screenshot}
                        setScreenshot={setScreenshot}
                        assetId={
                          updateDetail.resource_object.asset
                            ? updateDetail.resource_object.asset?.id
                            : updateDetail.resource_object.shot?.id
                        }
                        version_id={updateDetail.resource_object.id}
                        setList={setCommentList}
                        updatesPage={true}
                        setPlayerProgress={setPlayerProgress}
                        canvasModal={canvasModal}
                        player={player}
                      />
                    </>
                  )
                ) : null}
              </div>
            </div>
            <div className={classes.commentAndAnnotation}>
              <div>
                {updateDetail.comments ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // marginTop: "13px",
                    }}
                  >
                    <UpdatesPageCardComment
                      users={props.users}
                      //flawed logic as it misses out other trackables
                      trackableId={
                        updateDetail.resource_object.shot
                          ? updateDetail.resource_object?.shot?.id
                          : updateDetail.resource_object?.asset?.id
                      }
                      versionId={updateDetail.resource_object.id}
                      setCommentList={setCommentList}
                      updateCommentCount={() =>
                        setCommentCount(commentCount + 1)
                      }
                      repliedTo={repliedTo}
                      setRepliedTo={setRepliedTo}
                      fetchComments={fetchComments}
                    />
                    <Button
                      className={classes.editButton}
                      onClick={canvasModalToggle}
                    >
                      <Icon.Edit className={classes.editLogo} />
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ marginTop: "10px" }}>
                {updateDetail?.comments ? (
                  <div className={classes.flexBetween}>
                    <div
                      onClick={(e) => {
                        setShowComments(!showComments);
                      }}
                      className={classes.commentIconDiv}
                    >
                      <span className={classes.showHide}>
                        {showComments ? (
                          <span className={classes.showHide}>
                            <EyeSlash size={20} /> <span>Hide all </span>
                          </span>
                        ) : (
                          <span className={classes.showHide}>
                            <Eye size={20} /> <span>Show all</span>
                          </span>
                        )}
                        {commentCount} Comments
                      </span>
                    </div>

                    <div
                      onClick={() => handleOpenVersionInNewTab()}
                      className={classes.commentIconDiv}
                    >
                      <span className={classes.showHide}>
                        <span className={classes.showHide}>
                          <span>Open in version viewer </span>
                          <ArrowUpRight size={20} />
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showComments ? (
                <div className={classes.commentsTools}>
                  <div className={classes.statusDropDown}>
                    <FormControl fullWidth>
                      <Select
                        disableUnderline
                        className={classes.input}
                        value={values[0].id || "none"}
                        // onClick={(e) => e.stopPropagation()}
                        // onChange={handleStatusChange}
                      >
                        {values.map((status) => (
                          <MenuItem
                            className={classes.menuItem}
                            value={status.id}
                            key={status.id}
                          >
                            <div className={classes.filterText}>
                              <span
                                style={{ width: "100%", overflow: "hidden" }}
                              >
                                {status.name}
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grayText}>
                    {commentList.length} comments
                  </div>
                </div>
              ) : (
                ""
              )}
              <div>
                {showComments ? (
                  updateDetail.comments ? (
                    <UpdatesPageCardCommentDiv
                      commentResult={commentList}
                      setCommentList={setCommentList}
                      commentList={commentList}
                      updateDetail={updateDetail}
                      loggedInUser={loggedInUser}
                      updateCommentCount={(e) => setCommentCount(e)}
                      changeSeekTime={changeSeekTime}
                      setRepliedTo={setRepliedTo}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default UpdatesPageUpdateCards;
