//!URL constants
//Needs to be modifed

import axios from 'axios';

const SSO_URL_FRONTEND = process.env.REACT_APP_BASE_URL_SSOF;
const SSO_URL_BACKEND = process.env.REACT_APP_BASE_URL_SSO;
const DOMAIN = process.env.REACT_APP_BASE_URL_DOMAIN;
const APP_BASE_URL = process.env.REACT_APP_BASE_URL_MCF.split("/")[2];
const DOMAIN_TYPE = process.env.REACT_APP_DOMAIN_TYPE;
const APP_URL = window.location.href
  .replace("https://", "")
  .replace("http://", "");
const redirectParams = `?type=${DOMAIN_TYPE}&baseUrl=${APP_BASE_URL}&redirectUrl=${APP_URL}`;

export function auth(ServiceIdentifierName) {
  if (
    readCookie("refresh") &&
    Date.now() <= getPayload(readCookie("refresh")).exp * 1000
    ) {
      const access = readCookie("access");
      let found = false;
      if (readCookie("access")) {
        const payload = getPayload(access);
        if (payload.aud.length) {
          for (let i = 0; i < payload.aud.length; i++) {
          if (payload.aud[i] === ServiceIdentifierName) {
            found = true;
          }
        }
      }
    }

    return true;
  } else {
    deleteCookie("access");
    deleteCookie("refresh");
    window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
  }
}

export const getPayload = (token) => {
  let base64Url = token?.split(".")[1];
  if (base64Url) {
    let base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } else {
    deleteCookie("access");
    deleteCookie("refresh");
    window.location = SSO_URL_FRONTEND + "login/" + redirectParams;
  }
};

//!function to get Cookie value
export function readCookie(name) {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return null;
}

//!funciton to delete a cookie
export function deleteCookie(name) {
  //("I am in delete Cookie Function");
  var now = new Date();
  now.setMonth(now.getMonth() - 10);
  let expiration = now.toUTCString();
  //console.log("Next calling create cookie with data ", name, expiration);
  createCookie(name, "", expiration);
}

//function to update access token using refresh token
// function updateAccess(refresh) {
//   console.log("Function called");
//   let stopKey = setInterval(async () => {
//     await axios
//       .post(`${SSO_URL_BACKEND}api/token/refresh/`, {
//         refresh: refresh,
//       })
//       .then((res) => {
//         const { access } = res.data;
//         console.log("Token Updated");
//         createCookie("access", access);

//       createCookie("refresh", refresh);
//     })
//     .catch((err) => {
//       console.error(err);
//       console.log("Cannot Update Access Token");
//       deleteCookie("access");
//       deleteCookie("refresh");
//     });
// }, 230000);
// //!using this stop key we can stop the refresh thing anytime
// window.localStorage.setItem("stopkey", stopKey);
// window.sessionStorage.setItem("stopKey", stopKey);
// }
//function to create a cookie

export function createCookie(key, value, time) {
  let expiration;
  if (!time) {
    var now = new Date();
    // now.setMinutes(now.getMinutes() + 5);
    key.includes("access")
      ? now.setMinutes(now.getMinutes() + 5)
      : now.setHours(now.getHours() + 168);
    expiration = now.toUTCString();
  } else {
    //console.log("time was passed so setting expiraiton time");
    expiration = time;
  }

  //in Production Keep It back To DOMAIN
  const domain = DOMAIN;
  // const domain = "localhost";
  let cookie =
    escape(key) +
    "=" +
    escape(value) +
    ";expires=" +
    expiration +
    ";" +
    "path=/;" +
    "domain=" +
    domain;
  //console.log(cookie);
  //console.log("now setting up the cookie");
  document.cookie = cookie;
}

//For client having different domain

const SSO_URL = SSO_URL_BACKEND;

export class Authorization {
  getAccessToken = (email, password) => {
    const url = `${SSO_URL}/api/token/`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          email,
          password,
        })
        .then(
          (res) => {
            createCookie("refresh", res.data.refresh);
            createCookie("access", res.data.access);
            resolve(true);
          },
          (err) => {
            console.log(err);
            reject(false);
          }
        );
    });
  };
}
