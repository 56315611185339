import React from "react";
import SnackBar from "../../Components/ErrorAndSuccessSnackBar/SnackBar.component";
import ShotsPageOverview from "../../Components/Shots/ShotsPageOverview/ShotsPageOverview.component";

export default function ShotsPage(props) {
  return (
    <div>
      <ShotsPageOverview params={props.match.params} />
    </div>
  );
}
