import { Button, CircularProgress, Modal, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { exportFileWithXhr, fget, fpost } from "../../../API/callsAPI";
import useStyles from "./ReviewVersionNewReviewModal.styles";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//icons
import { Asterisk } from "phosphor-react";
import { X } from "react-feather";
//components
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import HelperText from "../../Utilities/HelperText/HelperText";
import UploadFile from "../../Utilities/UploadFile/UploadFile";

function ReviewVersionNewReviewModal({
  handleNewReviewVersionModal,
  setSnackValue,
  reviewId,
  updateRefreshCounter,
}) {
  const classes = useStyles();
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, uploadFileSet] = useState();
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [fileTouched, setFileTouched] = useState(false);

  //Validators
  const ReviewValidationScheme = yup.object({
    reviewFileName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is required field"),
  });

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadFileSet(null);
    setFileTouched(false);
    setDeleteFileModal(false);
  };

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadFileSet(file);
    setFileTouched(true);
  };

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete() {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled() {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `reviewversion/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await exportFileWithXhr({
          url: uploadUrl.data?.signed_url,
          data: file,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });
        // after upload is complete make thumbnail api call
        await fget({ url: `reviewversion/${newId}/thumbnail` });
        setLoader(false);
        setFileUploading(false);
        handleNewReviewVersionModal();
        updateRefreshCounter();
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
    }
  };

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      reviewFileName: "",
    },
    validationSchema: ReviewValidationScheme,
    onSubmit: async (values) => {
      setLoader(true);
      if (fileTouched) {
        setFileUploading(true);
      }
      let formdata = new FormData();
      formdata.append("name", values.reviewFileName);
      if (file) {
        formdata.append("file_name", file.name);
      }
      let newReviewFile = {
        url: `review/${reviewId}/versions/`,
        data: formdata,
      };

      try {
        const newFileRes = await fpost(newReviewFile);
        if (newFileRes.status === 200 || 201) {
          if (file) {
            getSignedUrl(newFileRes.data.id);
          } else {
            setLoader(false);
            setFileUploading(false);
            handleNewReviewVersionModal();
            updateRefreshCounter();
          }
        }
      } catch (error) {
        setLoader(false);
        setFileUploading(false);
      }
      setFileTouched(false);
    },
  });

  const clearAll = () => {
    formik.setValues({
      ...formik.values,
      reviewFileName: "",
    });
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      if (e?.dataTransfer?.files[0]?.name?.length < 100) {
        uploadFileSet(e.dataTransfer.files[0]);
        setFileTouched(true);
      } else {
        setSnackValue({
          isOpen: true,
          message: "File Name Is too big to upload",
          isError: true,
        });
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={classes.container}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <DragScreen />
      <div className={classes.containerHeader}>
        <p style={{ margin: "0px" }}>New Version</p>
        <Button onClick={handleNewReviewVersionModal}>
          <X size={28} />
        </Button>
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "18px" }}
        onSubmit={formik.handleSubmit}
      >
        <div className={classes.innerContainer1}>
          <span>File Name</span>
          <Asterisk size={12} color="#FF0000" weight="fill" />
          <TextField
            fullWidth
            placeholder="Enter file name"
            name="reviewFileName"
            id="TextField"
            InputProps={{ disableUnderline: true }}
            className={classes.newReviewTextField}
            value={formik.values.reviewFileName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.reviewFileName)}
          />
          {formik.errors.reviewFileName && formik.touched.reviewFileName ? (
            <HelperText text={formik.errors.reviewFileName} />
          ) : null}
        </div>

        <div className={classes.innerContainer1}>
          Attachment
          <UploadFile
            fileUploading={fileUploading}
            uploadProgress={uploadProgress}
            thumbnail={file}
            handleClearUpload={handleClearUpload}
            handleThumbnailUpload={displaySelectedFile}
            setDeleteFileModal={setDeleteFileModal}
          />
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.newFileBtn}
            style={{ backgroundColor: loader && "#eeeeee" }}
            type="submit"
            value="Submit"
            disabled={loader}
          >
            {loader ? (
              <CircularProgress
                color="secondary"
                style={{ width: "25px", height: "25px" }}
              />
            ) : (
              "Create New Version"
            )}
          </Button>

          <Button className={classes.clearBtn} onClick={clearAll}>
            Clear All
          </Button>
        </div>
      </form>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof file === "object" ? file?.name : file}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleClearUpload();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ReviewVersionNewReviewModal;
