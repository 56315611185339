import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  skipBtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",
    padding: "4px",
  },
  cancelBtn: {
    height: "45px",
    width: "183px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

export default useStyles;
