import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fget, fpost } from "../../../../API/callsAPI";

const UploadFileComponent = ({
  file,
  projectId,
  repositoryId,
  isLastFile,
  handleUpdateData,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [xhr, setXhr] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [signedUrl, setSignedUrl] = useState("");

  const handleGenerateFilePath = async () => {
    const mediaData = {
      url: "media/",
      data: {
        project: projectId,
        file_name: file.name,
        repository: repositoryId,
      },
    };
    try {
      if (file?.name?.length <= 100) {
        setIsFailed(false);
        setErrorMessage("");
        const res = await fpost(mediaData);
        if (res.status === 200 || 201) {
          handleGetSignedUrl(res.data.id);
        }
      } else {
        setErrorMessage("File Name Is Long");
        setIsFailed(true);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data[0] ?? "Error");
      setIsFailed(true);
    }
  };

  const handleGetSignedUrl = async (mediaId) => {
    try {
      const res = await fget({
        url: `media/${mediaId}/upload/`,
      });
      if (res.status === 200 || 201) {
        setSignedUrl(res.data.signed_url);
        handlePutFileOnSignedUrl(res.data.signed_url);
      }
    } catch (error) {
      console.log(error);
      setIsFailed(true);
    }
  };

  const handlePutFileOnSignedUrl = async (signedUrl) => {
    try {
      const xhrInstance = new XMLHttpRequest();

      xhrInstance.upload.onprogress = updateProgressBar;
      xhrInstance.addEventListener("load", transferComplete);
      xhrInstance.addEventListener("error", transferFailed);
      xhrInstance.addEventListener("abort", transferCanceled);

      xhrInstance.open("PUT", signedUrl);
      xhrInstance.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      xhrInstance.send(file);

      setXhr(xhrInstance);
    } catch (error) {
      console.log(error);
      setIsFailed(true);
    }
  };

  // Function to cancel the ongoing request
  const cancel = () => {
    if (xhr) {
      xhr.abort();
      setIsCancelled(true);
    }
  };

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete(evt) {
    setSnackValue({
      isOpen: true,
      message: `File transferred SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled(evt) {
    setSnackValue({
      isOpen: true,
      message: `File Transfer Cancelled`,
      isError: true,
    });
  }

  useEffect(() => {
    handleGenerateFilePath();

    // Cleanup function to cancel the ongoing request when the component unmounts
    return () => {
      cancel();
    };
  }, [file]);

  useEffect(() => {
    if (isLastFile && uploadProgress === 100) {
      handleUpdateData();
    }
  }, [isLastFile, uploadProgress]);
  

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{file?.name}</p>
        {!isFailed ? (
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              uploadProgress
            )}%`}</Typography>
          </Box>
        ) : (
          <Typography style={{ color: "red", fontSize: "12px" }}>
            Failed
          </Typography>
        )}
      </Box>
      <Box>
        <Typography style={{ color: "red", fontSize: "12px" }}>
          {errorMessage ? errorMessage : ""}
        </Typography>
      </Box>
      {!isFailed ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <LinearWithValueLabel uploadProgress={uploadProgress} />
          {uploadProgress !== 100 && !isCancelled ? (
            <Button variant="outlined" color="secondary" onClick={cancel}>
              Cancel
            </Button>
          ) : isCancelled ? (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography>cancelled</Typography>
              <Button
                onClick={() => {
                  handlePutFileOnSignedUrl(signedUrl);
                  setIsCancelled(false);
                }}
              >
                Retry
              </Button>
            </div>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default UploadFileComponent;

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          color="primary"
          {...props}
          style={{ backgroundColor: "#252A38" }}
        />
      </Box>
    </Box>
  );
}

function LinearWithValueLabel({ uploadProgress }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={uploadProgress} />
    </Box>
  );
}
