import React from "react";
import ReviewsOverview from "../../Components/Reviews/ReviewsOverview/ReviewsOverview.component";

export default function ReviewersPage(props) {
  return (
    <div>
      <ReviewsOverview
        params={props.params}
        externalReviewer={props.externalReviewer}
      />
    </div>
  );
}
