import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//TAsk Filtering is yet to be Added
//@components
import Shotversionslist from "../ShotVersionsList/Shotversionslist.component";
import ShotVersionsTools from "../ShotVersionsTools/ShotVersionsTools.component.jsx";

//@MUI
import {
  Box,
  Divider,
  Modal,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import {
  fget,
  fpatch,
  fpost,
  fpostBFF,
  uploadFile,
} from "../../../API/callsAPI";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import CanvasFrame from "../../CanvasFrame/CanvasFrame.component";
import AssetAndShotVersionTimeline from "../../Utilities/AssetAndShotVersionTimeline/AssetAndShotVersionTimeline.jsx";
import CustomPagination from "../../Utilities/CustomPagination/CustomPagination.component";
import Loader from "../../Utilities/Loader/Loader";
import NewDataLoadingLoader from "../../Utilities/NewDataLoadingLoader/NewDataLoadingLoader.component";
import { StatusRearrange } from "../../Utilities/StatusRearrange/StatusRearrange";
import UpdateParentAssetOrShootThumbnailModal from "../../Utilities/UpdateParentAssetOrShootThumbnail/UpdateParentAssetOrShootThumbnailModal";
import VersionFilterModal from "../../Utilities/VersionFilterModal/VersionFilterModal";
import VersionViewerOverview from "../../Utilities/VersionViewer/VersionViewerOverview/VersionViewerOverview";
import ShotVersionNewVersionModal from "./../ShotVersionPageModals/ShotVersionNewVersionModal";
import useStyles from "./ShotversionsOverview.styles";

function ShotVersionsOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef();

  const [shotVersionList, setShotVersionList] = useState();
  const [parentShot, setParentShot] = useState();
  const [shotVersionFilteredStatus, setShotVersionFilteredStatus] =
    useState("");
  const [versionFromURL, setVersionFromURL] = useState(); //open versionDetailModal if id present in url

  const [trackableClass, setTrackableClass] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [allUsersName, setAllUsersName] = useState();
  const [isNewShotVersionModalOpen, setNewShotVersionModalOpen] =
    useState(false);
  const [sortByDefault, setSortByDefault] = useState("");
  const [isShotDetailModalOpen, setShotDetailModalOpen] = useState(false);
  const [selectedShotVersion, setSelectedShotVersion] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shotVersionToggle, setShotVersionToggle] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [commentsRefreshCounter, setCommentsRefreshCounter] = useState(0);
  const [isUpdateParentShotModalOpen, setUpdateParentShotModalOpen] =
    useState(false);
  const [updatedFileForParent, setUpdatedFileForParent] = useState();
  const [dragScreen, setDragScreen] = useState(false);
  //state will be true when we start drag file inside modals
  const [dragFileEnterModal, setDragFileEnterModal] = useState(false);
  // to store tasks of the current trackable
  const [taskList, setTaskList] = useState([]);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const [taskNameFromComment, setTaskNameFromComment] = useState("");
  const [canvasModal, setCanvasModal] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [playerProgress, setPlayerProgress] = useState("00:00");
  const [scrollValue, setScrollValue] = useState("");
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  //statuslist of status linked with task
  const [taskStatusList, setTaskStatusList] = useState([]);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    status: "",
    task: "",
  });
  const [filterButtonActive, setFilterButtonActive] = useState(false);
  const [commentsPageNo, setCommentsPageNo] = useState(1);
  const [shotVersionStats, setShotVersionStats] = useState({
    total: 0,
    visibleCount: 0,
  });
  const [isTimeLineModalOpen, setIsTimeLineModalOpen] = useState(false);

  function handleFilterModalState() {
    setIsFilterModalOpen(!isFilterModalOpen);
  }
  const [commentId, setCommentId] = useState(""); //to highlight comment if commentid present in url

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  var searchParams = new URLSearchParams(decodedURL);

  useEffect(() => {
    fetchTrackableClass();
    setShotVersionFilteredStatus(searchParams.get("status") || "");
    setVersionFromURL(searchParams.get("versionId") || "");
    setSortByDefault(searchParams.get("sortBy") || "A-Z");
    setCommentId(searchParams.get("commentId" || ""));

    var scrollStored = JSON.parse(localStorage.getItem("scroll"));
    if (scrollStored) {
      setScrollValue(scrollStored.shotVersion);
    } else {
      const scrollData = {
        asset: "0",
        assetVersion: "0",
        sequence: "0",
        shots: "0",
        shotVersion: "0",
      };
      localStorage.setItem("scroll", JSON.stringify(scrollData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchShotVersion();
  }, []);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const updateCommentsRefreshCounter = () => {
    setCommentsRefreshCounter(commentsRefreshCounter + 1);
  };

  useEffect(() => {
    if (
      filterValues?.status ||
      filterValues?.task ||
      refreshCounter ||
      updateOnPageChange
    )
      fetchFilteredShotversion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shotVersionFilteredStatus,
    currentPage,
    updateOnPageChange,
    refreshCounter,
  ]);

  function handleBackButton() {
    history.replace(
      history.location.pathname.split("/").slice(0, -2).join("/")
    );
  }

  function handleNewshotVersionModalOpenState() {
    setNewShotVersionModalOpen(!isNewShotVersionModalOpen);
  }

  const handleShotVersionToggle = () => {
    setShotVersionToggle(!shotVersionToggle);
  };

  useEffect(() => {
    shotVersionList &&
      versionFromURL &&
      openVersionModalFromURL(versionFromURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionFromURL, shotVersionList]);

  //to open version modal by fetching id from the url
  const openVersionModalFromURL = (verId) => {
    handleShotDetailModalState(
      shotVersionList?.results?.filter((x) => x?.id === parseInt(verId))[0]
    );
  };

  function handleShotDetailModalState(shotVer) {
    searchParams.set("versionId", `${shotVer?.id}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );

    setSelectedShotVersion(shotVer);
    setShotDetailModalOpen(true);
  }

  async function fetchTrackableClass() {
    try {
      const trackableClasses = await fget({
        url: "trackables/trackable-classes",
      });
      setTrackableClass(trackableClasses.data);
      if (trackableClasses.data) {
        const taskClassId = trackableClasses.data.find(
          (eachvalue) => eachvalue.model === "task"
        );
        if (taskClassId.id) {
          await fetchTaskStatusList(taskClassId.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTaskStatusList(taskClassId) {
    try {
      const taskStatusList = await fget({
        url: `trackables/status-list/${taskClassId}/?project=${props.params.projectId}`,
      });
      if (taskStatusList.data) {
        setTaskStatusList(StatusRearrange(taskStatusList.data.results));
      }
    } catch (error) {}
  }

  async function fetchFilteredShotversion() {
    const url = `trackables/shot-version/?page=${currentPage}&shot=${props.params.shotID}&status=${filterValues.status}&sortBy=${sortByDefault}&task=${filterValues.task}`;
    try {
      const res = await fget({
        url: `trackables/shot-version/?page=${currentPage}&shot=${props.params.shotID}&status=${filterValues.status}&task=${filterValues.task}`,
      });
      setShotVersionList(res.data);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log(error);
      setIsNewPageLoading(false);
    }
    history.push("?" + btoa(unescape(encodeURIComponent(url.split("?")[1]))));
  }

  async function fetchShotVersion() {
    const assetversion = {
      url: "web/shotversion/list/",
      data: {
        project_id: props.params.projectId,
        shot_id: props.params.shotID,
      },
    };
    try {
      const res = await fpostBFF(assetversion);

      if (
        searchParams.get("status") === null ||
        searchParams.get("status") === ""
      ) {
        setShotVersionList(res.data.shotversion_list);
        setStatusList(res.data?.status_list?.results);
        setIsLoaded(true);
      }
      setShotVersionStats((prevData) => ({
        ...prevData,
        visibleCount: res.data.shotversion_list.count,
      }));
      setParentShot(res.data.shot_details);
      // setAllUsers(res.data.project_users.results);
      setTaskList(res?.data?.tasks_list?.results);
      setAllUsersName(
        res.data.project_users.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };
          return data;
        })
      );
      // setTask(res.data.tasks_list);
    } catch (error) {
      console.log(error);
      setSnackValue({
        isOpen: true,
        message: `Error on loading shot versions`,
        isError: true,
      });
      setIsLoaded(true);
    }
  }

  useEffect(() => {
    shotVersionList?.results &&
      searchParams.get("sortBy") &&
      handleSubmitSort(searchParams.get("sortBy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shotVersionList]);

  //Function to sort AssetVersion
  function handleSubmitSort(sortBy) {
    searchParams.set("sortBy", `${sortBy}`);
    history.push(
      "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
    );

    switch (sortBy) {
      case "A-Z":
        shotVersionList?.results?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        shotVersionList?.results?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LatestModified":
        shotVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
        setSortByDefault("LatestModified");
        break;
      case "LastModified":
        shotVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        shotVersionList?.results?.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return shotVersionList?.results;
    }
  }

  const handleUpdatedFileForParent = (file) => {
    setUpdatedFileForParent(file);
    setUpdateParentShotModalOpen(true);
  };

  const updateParentShotThumbnail = async () => {
    let formdata = new FormData();
    formdata.append("code", parentShot ? parentShot.code : "");
    updatedFileForParent &&
      formdata.append("file_name", updatedFileForParent.name);
    formdata.append("status", parentShot?.status ? parentShot.status.id : "");
    formdata.append("project", props.params.projectId);
    formdata.append("description", parentShot ? parentShot.description : "");
    formdata.append("parent_sequence", props.params.sid);
    formdata.append("vp_linked_level_sequence", "");

    let updatedShot = {
      url: `trackables/shot/${props.params.shotID}/`,
      data: formdata,
    };

    try {
      const res = await fpatch(updatedShot);

      if (res.status === 200 || 201) {
        getSignedUrl(res.data.id);
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setUpdateParentShotModalOpen(false);
    }
  };
  const getSignedUrl = async (newId) => {
    try {
      const res = await fget({
        url: `trackables/shot/${newId}/upload`,
      });

      if (res.status === 200) {
        await uploadFile({
          url: res.data?.signed_url,
          data: updatedFileForParent,
        });

        await fget({ url: `trackables/shot/${newId}/thumbnail` });
        setSnackValue({
          isOpen: true,
          message: `SuccessFully updated thumbnail for parent shot ${parentShot.code}`,
          isError: false,
        });
        setUpdateParentShotModalOpen(false);
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
      setUpdateParentShotModalOpen(false);
    }
  };

  const handleDragFile = (e) => {
    setDragScreen(true);
    if (e.type === "drop") {
      //if we drop file , removing drag zone screen
      setDragScreen(false);
    }
    if (e.type === "dragleave") {
      //if we leave the drag file , removing drag zone screen
      if (dragFileEnterModal) {
        setDragScreen(true);
      } else {
        setDragScreen(false);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragScreen = (el) => {
    setDragScreen(el);
  };

  const fetchFile = async () => {
    const fetchFileRes = await fget({
      url: `trackables/shotversion/${selectedShotVersion?.id}/file`,
    });
    return fetchFileRes.data.signed_url;
  };

  const fetchComments = async () => {
    const commentsRes = await fget({
      url: `trackables/trackable-comment/?version_id=${
        selectedShotVersion?.id
      }&&version_type=${"shotversion"}&&page=${commentsPageNo}`,
    });

    return {
      comments: commentsRes.data.results
        .filter((el) => el.trackable.id === parseInt(props.params.shotID))
        .reverse(),
      isNextAvailable: commentsRes?.data?.next ? true : false,
    };
  };

  //function to change status by dropdown
  const handleStatusChange = async (e) => {
    let formdata = new FormData();

    formdata.append("status", e.target.value);
    let editedVersion = {
      url: `trackables/shot-version/${selectedShotVersion?.id}/`,
      data: formdata,
    };

    try {
      await fpatch(editedVersion);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully changed status`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  // fetch tasks of the current trackable
  const fetchTasks = async () => {
    let url = `trackables/task/?project=${props.params.projectId}&linked=${props.params.shotID}`;

    try {
      const taskListRes = await fget({
        url: url,
      });
      setTaskList(taskListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  let shotVersionClass = trackableClass?.find(
    (el) => el.model === "shotversion"
  );

  const handleSubmit = async ({
    description,
    mentions,
    repliedTo,
    attachmentImage,
  }) => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("trackable", props.params.shotID);
    formData.append("version_id", selectedShotVersion?.id);
    formData.append("version_type", shotVersionClass ? shotVersionClass.id : 0);
    formData.append("mentions[]", mentions);

    if (repliedTo) formData.append("replied_to", repliedTo.id);

    if (attachmentImage) {
      const blob = await fetch(attachmentImage).then((res) => res.blob());
      const file = new File([blob], "img.jpg", { type: "image/jpg" });
      formData.append("attachment", file);
    }
    // mentionsRef.current.reset();
    let newComment = {
      url: "trackables/trackable-comment/",
      data: formData,
    };
    try {
      await fpost(newComment);
      return newComment;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  let scrollTimer = null;
  const handleTableScroll = (e) => {
    if (scrollTimer !== null) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      searchParams.set("scroll", `${e.target.scrollTop}`);
      history.push(
        "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
      );
      let scrollStored = JSON.parse(localStorage.getItem("scroll"));
      scrollStored.shotVersion = e.target.scrollTop;
      localStorage.setItem("scroll", JSON.stringify(scrollStored));
    }, 700);
  };

  const getShotVersionStats = async () => {
    try {
      const shotVersionRes = await fget({
        url: `trackables/trackable-count/shotversion/${props.params.shotID}/`,
      });
      setShotVersionStats((prevData) => ({
        ...prevData,
        total: shotVersionRes.data.instances,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShotVersionStats();
  }, [refreshCounter]);

  return (
    <div
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <ShotVersionsTools
        handleFilterModalState={handleFilterModalState}
        status={statusList}
        handleBackButton={handleBackButton}
        handleStatusChange={(value) => {
          setShotVersionFilteredStatus(value);
          if (value === "") {
            history.replace(history.location.pathname);
            window.location.reload();
          }
        }}
        filterValues={filterValues}
        openNewShotVersionModal={handleNewshotVersionModalOpenState}
        parentShot={parentShot}
        tabOption={0}
        handleShotVersionToggle={handleShotVersionToggle}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
        filterButtonActive={filterButtonActive}
        projectId={props.params.projectId}
        shotVersionStats={shotVersionStats}
        handleOpenTimelineModal={() => setIsTimeLineModalOpen(true)}
      />
      <Divider style={{ height: "4px", marginTop: "4px" }} />
      {isLoaded ? (
        <>
          {shotVersionList && shotVersionList.count !== 0 ? (
            <>
              <TableContainer
                className={classes.tableContainer}
                style={{
                  height:
                    shotVersionList.count > 50
                      ? `calc(100vh - 225px)`
                      : `calc(100vh - 185px)`,
                }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  className={classes.table}
                >
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        align="left"
                        className={classes.shotVersionTableCellTwo}
                      >
                        Shot Version
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotVersionTableCell}
                      >
                        Last Modified
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotVersionTableCell}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotVersionTableCell}
                      >
                        Updated By
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotVersionTableCell}
                      >
                        Relate Task
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.shotVersionTableCell}
                      >
                        Comments
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.shotVersionTableCellThree}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    onScroll={handleTableScroll}
                    onLoad={() => {
                      if (tableRef?.current && tableRef?.current?.scrollTop) {
                        tableRef.current.scrollTop = scrollValue;
                      }
                    }}
                  >
                    {shotVersionList && shotVersionList.count !== 0
                      ? shotVersionList.results?.map((shotVer) => {
                          return (
                            <Shotversionslist
                              key={shotVer?.id}
                              data={shotVer}
                              taskList={taskList}
                              fetchTasks={fetchTasks}
                              sequenceId={props.params.sid}
                              shotID={props.params.shotID}
                              status={statusList}
                              projectId={props.params.projectId}
                              openShotDetailModal={handleShotDetailModalState}
                              shotVersionToggle={shotVersionToggle}
                              updateRefreshCounter={updateRefreshCounter}
                              dragScreen={dragScreen}
                              handleDragScreen={handleDragScreen}
                              setDragFileEnterModal={setDragFileEnterModal}
                            />
                          );
                        })
                      : "No ShotVersion"}
                  </TableBody>
                </Table>
              </TableContainer>
              {shotVersionList && shotVersionList.count > 50 ? (
                <footer style={{ bottom: "0", height: "100%" }}>
                  <div>
                    <CustomPagination
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      totalItem={shotVersionList?.count}
                      totalPage={Math.ceil(shotVersionList?.count / 50)}
                      setIsNewPageLoading={setIsNewPageLoading}
                    />
                  </div>
                </footer>
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isNewPageLoading}
        >
          <NewDataLoadingLoader trackable="Shot Versions" />
        </Modal>
      </div>
      <div>
        <Modal
          open={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          className={classes.shotVersionPageModals}
        >
          <VersionFilterModal
            updateList={() => fetchFilteredShotversion()}
            closeModal={handleFilterModalState}
            taskList={taskList}
            status={statusList}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setFilterButtonActive={setFilterButtonActive}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isNewShotVersionModalOpen}
          onClose={() => setNewShotVersionModalOpen(false)}
        >
          <ShotVersionNewVersionModal
            dragScreen={dragScreen}
            handleDragScreen={handleDragScreen}
            setDragFileEnterModal={setDragFileEnterModal}
            handleUpdatedFileForParent={handleUpdatedFileForParent}
            projectId={props.params.projectId}
            shotID={props.params.shotID}
            status={statusList}
            handleShotVersionModal={handleNewshotVersionModalOpenState}
            updateRefreshCounter={updateRefreshCounter}
            taskList={taskList}
            fetchTasks={fetchTasks}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isUpdateParentShotModalOpen}
          onClose={() => setUpdateParentShotModalOpen(false)}
        >
          <UpdateParentAssetOrShootThumbnailModal
            versionType="Shot"
            parent={parentShot}
            handleSubmit={() => {
              updateParentShotThumbnail();
            }}
            closeUpdateParentModal={() => setUpdateParentShotModalOpen(false)}
          />
        </Modal>
      </div>
      <div>
        <Modal
          // className={classes.shotVersionPageModals}
          open={isShotDetailModalOpen}
          onClose={() => {
            setShotDetailModalOpen(false);
            searchParams.set("versionId", "");
            setVersionFromURL("");
            history.push(
              "?" + btoa(unescape(encodeURIComponent(searchParams.toString())))
            );
          }}
        >
          <VersionViewerOverview
            commentsPageNo={commentsPageNo}
            setCommentsPageNo={setCommentsPageNo}
            versionList={shotVersionList}
            selectedVersion={selectedShotVersion}
            setSelectedVersion={setSelectedShotVersion}
            projectId={props.params.projectId}
            fetchFile={fetchFile}
            fetchComments={fetchComments}
            parentTrackable={parentShot}
            closeModal={() => {
              setShotDetailModalOpen(false);
              setVersionFromURL("");
              searchParams.set("versionId", "");
              history.push(
                "?" +
                  btoa(unescape(encodeURIComponent(searchParams.toString())))
              );
            }}
            handleStatusChange={handleStatusChange}
            status={statusList}
            updateRefreshCounter={updateRefreshCounter}
            setTaskNameFromComment={setTaskNameFromComment}
            canvasModal={canvasModal}
            setCanvasModal={setCanvasModal}
            setImageRef={setImageRef}
            setScreenshot={setScreenshot}
            screenshot={screenshot}
            allUsersName={allUsersName}
            handleSubmit={handleSubmit}
            version_type={shotVersionClass ? shotVersionClass.id : 0}
            assetId={props.params.shotID}
            setPlayerProgress={setPlayerProgress}
            commentsRefreshCounter={commentsRefreshCounter}
            taskStatusList={taskStatusList}
            commentId={commentId}
          />
        </Modal>
      </div>
      <div>
        <Modal className={classes.shotVersionPageModals} open={canvasModal}>
          <CanvasFrame
            imageRef={imageRef}
            image={screenshot}
            assetID={props.params.shotID}
            toggle={() => setCanvasModal(!canvasModal)}
            fetchComments={fetchComments}
            version_id={selectedShotVersion?.id}
            version_type={shotVersionClass ? shotVersionClass.id : 0}
            users={allUsersName}
            progress={playerProgress}
            closeModal={() => setCanvasModal(false)}
            updateCommentsRefreshCounter={updateCommentsRefreshCounter}
          />
        </Modal>
      </div>
      {isTimeLineModalOpen ? (
        <Slide direction="left" in={isTimeLineModalOpen}>
          <Box
            style={{
              display: isTimeLineModalOpen ? "block" : "none",
              top: "157px",
              height: `calc(100vh - 157px)`,
            }}
            className={classes.floatingDiv}
          >
            <AssetAndShotVersionTimeline
              type="shot"
              id={props.params.shotID}
              parent={parentShot}
              handleClose={() => setIsTimeLineModalOpen(false)}
            />
          </Box>
        </Slide>
      ) : (
        ""
      )}
    </div>
  );
}

export default ShotVersionsOverview;
