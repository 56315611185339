import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },

  card: {
    height: "100%",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  title: {
    fontSize: "22px !important",
  },

  versionsContainer: {
    maxHeight: "90%",
    overflowY: "auto",
    padding: "0px 10px",
  },

  versionsWithNoBorder: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  versions: {
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  singleVersion: {
    display: "flex",
    alignItems: "start",
    gap: "10px",
    marginTop: "-1px",
  },

  singleVersionDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  singleVersionStatusLogs: {
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    padding: "10px 8px",
    borderRadius: "10px",
  },
  singleStatusDetails: {
    paddingBottom: "4px",
  },

  listText: {
    color: theme.palette.text.tertiary,
  },

  //common
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  textPurple: {
    color: theme.palette.secondary.main,
  },
  textGray: {
    color: theme.palette.text.tertiary,
  },
}));

export default useStyles;
