import { Button, Card, CardContent } from "@material-ui/core";
import React from "react";
import useStyles from "./NoFileFoundModal.style";

const NoFileFoundModal = ({ handleClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h2 className={classes.title}>No File Found</h2>
          <Button className={classes.goBackButton} onClick={handleClose}>
            Go Back
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoFileFoundModal;
