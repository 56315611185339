//Library
import { Button, Grid, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";

//Components

import { fget } from "../../../API/callsAPI";

//CSS Styles
import ProjectHealth from "../UpdatePageWidgets/ProjectHealth/ProjectHealth";
import ProjectUpdates from "../UpdatePageWidgets/ProjectUpdates/ProjectUpdates";
import TaskOverview from "../UpdatePageWidgets/TaskOverview/TaskOverview";
import useStyles from "./UpdatesOverview.styles";

function UpdatesOverview(props) {
  const classes = useStyles();
  //States
  const [allUsers, setAllUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState();
  const [projectDetails, setProjectDetails] = useState({});
  const [currentTabView, setCurrentTabView] = useState("health");

  //UseEffect
  useEffect(() => {
    fetchUsers();
    getProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken &&
      setLoggedInUser(
        allUsers.find(
          (user) =>
            user.id === JSON.parse(atob(accessToken.split(".")[1])).user_id
        )
      );
    // eslint-disable-next-line no-useless-escape
  }, [allUsers]);

  const fetchUsers = async () => {
    try {
      const res = await fget({
        url: `project/${props.project}/users/`,
      });

      setAllUsers(
        res.data.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectDetails = async () => {
    try {
      const res = await fget({
        url: `project/${props.project}/`,
      });
      setProjectDetails(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: `calc(100vh - 130px)` }}>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Hidden lgDown>
          <Grid item xl={4} style={{ height: "100%" }}>
            <ProjectHealth
              hideTitle={false}
              projectDetails={projectDetails}
              projectId={props.project}
              loggedInUser={loggedInUser}
            />
          </Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item xl={4} style={{ height: "100%" }}>
            <TaskOverview
              hideTitle={false}
              projectId={props.project}
              users={allUsers}
              loggedInUser={loggedInUser}
              projectDetails={projectDetails}
            />
          </Grid>
        </Hidden>
        <Hidden xlUp>
          <Grid item md={6} lg={6} style={{ height: "100%" }}>
            <div className={classes.tabs}>
              <Button
                onClick={() => setCurrentTabView("health")}
                fullWidth
                className={
                  currentTabView === "health"
                    ? classes.selectedTab
                    : classes.notSelectedTab
                }
              >
                Project Health
              </Button>
              <Button
                onClick={() => setCurrentTabView("task")}
                fullWidth
                className={
                  currentTabView === "task"
                    ? classes.selectedTab
                    : classes.notSelectedTab
                }
              >
                Task Overview
              </Button>
            </div>
            {currentTabView === "health" ? (
              <ProjectHealth
                hideTitle={true}
                projectDetails={projectDetails}
                projectId={props.project}
                loggedInUser={loggedInUser}
              />
            ) : (
              <TaskOverview
                hideTitle={true}
                projectId={props.project}
                users={allUsers}
                loggedInUser={loggedInUser}
                projectDetails={projectDetails}
              />
            )}
          </Grid>
        </Hidden>
        <Grid item md={6} lg={6} xl={4} style={{ height: "100%" }}>
          <ProjectUpdates users={allUsers} projectId={props.project} />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdatesOverview;
