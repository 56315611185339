import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
    "& .MuiFormHelperText-root": {
      color: "#ffcc00",
    },
  },

  card: {
    borderRadius: "10px",
    color: theme.palette.text.tertiary,
  },

  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "24px",
    position: "relative",
  },

  flexDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.text.tertiary,
    fontSize: "14px",
  },
  containedButton: {
    minWidth: "0px",
    padding: "12px 24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    fontFamily: "Poppins",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    backgroundColor: theme.palette.secondary.light,
    border: "none",
    padding: "10px",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  text: {
    fontFamily: "Poppins",
    color: "white",
  },
  label: {
    fontFamily: "Poppins",
    color: theme.palette.text.tertiary,
    textAlign: "start",
  },

  fileTypeAndSize: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid gray`,
    padding: "6px 8px",
    borderRadius: "10px",
  },
}));

export default useStyles;
