import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 20,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  section: {
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "6px",
  },
  sectionRight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4px",
  },
  text: {
    fontSize: "12px",
  },

  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    padding: 4,
    borderBottom: "1px solid gray",
    // backgroundColor: "#dedcdc",
    flexDirection: "row",
  },
  nameTitle: {
    width: "400px",
    fontSize: "16px",
    color: "#6C65D9",
    fontWeight: 600,
  },
  othersTitle: {
    width: "100px",
    fontSize: "12px",
    textAlign: "center",
  },
  userRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // margin: 10,
    padding: 4,
    borderBottom: "1px solid gray",
    flexDirection: "row",
  },
  userName: {
    width: "400px",
    fontSize: "12px",
  },
  othersItemOfUser: {
    width: "100px",
    fontSize: "12px",
    textAlign: "center",
  },

  flexAndGap: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "2px",
  },
});

const PdfReport = ({ reports, startDate, endDate }) => {
  return (
    <Document>
      {reports?.map((report, index) => (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View>
            <View style={styles.container}>
              <View>
                <View style={styles.section}>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#6C65D9",
                    }}
                  >
                    Project {index + 1} -
                  </Text>
                  <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {report?.project?.name}
                  </Text>
                </View>
                <Text style={{ fontSize: "12px" }}>
                  {report?.project?.abbreviation}
                </Text>
              </View>
              <View style={styles.sectionRight}>
                <View style={styles.flexAndGap}>
                  <Text style={styles.text}>Start Date :</Text>
                  <Text style={styles.text}>
                    {new Date(startDate).toLocaleDateString()}
                  </Text>
                </View>
                <View style={styles.flexAndGap}>
                  <Text style={styles.text}>End Date :</Text>
                  <Text style={styles.text}>
                    {new Date(endDate).toLocaleDateString()}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View style={styles.tableHeader}>
                <Text style={styles.nameTitle}>Artist</Text>
                <Text style={styles.othersTitle}>Blocked</Text>
                <Text style={styles.othersTitle}>Delayed</Text>
                <Text style={styles.othersTitle}>In Progress</Text>
              </View>
              <View>
                {report?.users?.map((user, index) => (
                  <View key={user?.id} style={styles.userRow}>
                    <Text style={styles.userName}>
                      {user?.user?.first_name + "  " + user.user?.last_name}
                    </Text>
                    <Text style={styles.othersItemOfUser}>
                      {user.blocked_tasks}
                    </Text>
                    <Text style={styles.othersItemOfUser}>
                      {user?.delayed_tasks}
                    </Text>
                    <Text style={styles.othersItemOfUser}>
                      {user?.in_progress_tasks}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PdfReport;
