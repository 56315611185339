import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { fget, fpostBFF } from "../../../API/callsAPI";
import SelectVersionModal from "../ComparePageModal/ComparePageSelectVersionModal/SelectVersionModal.component";
import ComparePageCard from "../ComparePageCard/ComparePageCard";
import VideoController from "../ComparePageVideoController/VideoController";
import CombineVideoPlayer from "../CompareVersionVideoPlayer/CombineVideoPlayer.component";
import VideoPlayer from "../CompareVersionVideoPlayer/VideoPlayer.component";
import ComparePageTool from "../ComparePageTool/ComparePageTool.component";
import CanvasModal from "../ComparePageModal/ComparePageCanvasModal.component";
import { Modal } from "@material-ui/core";
import useStyles from "./ComparePageOverview.styles";
import { MouseSimple } from "phosphor-react";

//handle api calls.
//keep state of which version is being played
//check weather both the version are video and have same duration
//create custom player with react player
//
export default function ComparePageOverview(props) {
  const classes = useStyles();
  const history = useHistory();
  //modal to select version to compare from
  const [isModalOpen, setModalOpen] = useState(false);
  //ref for the two players
  const refPlayerOne = useRef(null);
  const refPlayerTwo = useRef(null);
  //verions holds the list of asset/shot
  const [versions, setVersions] = useState([{}]);
  //Canvas modal state
  const [canvasModal, setCanvasModal] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [allUsersName, setAllUsersName] = useState();
  //view state
  const [viewType, setViewType] = useState(true);
  //player states
  const [playerOne, setPlayerOne] = useState({
    isPlaying: false,
    version: {},
    totalDuration: 0,
    fileURL:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
  }); //primary version
  const [playerTwo, setPlayerTwo] = useState({
    isPlaying: false,
    version: {},
    totalDuration: 0,
    fileURL: "",
  }); // secondary version
  // other states
  //holds the cureentTime of the custom seekbar
  const [currentTimeSeekOne, setCurrentTimeSeekOne] = useState(0);
  const [currentTimeSeekTwo, setCurrentTimeSeekTwo] = useState(0);

  //holds the current duration of player
  const [currentDurationOne, setCurrentDurationOne] = useState(0);
  const [currentDurationTwo, setCurrentDurationTwo] = useState(0);
  //volume states
  const [volumePlayerOne, setVolumePlayerOne] = useState({
    volume: 20,
    muted: false,
  }); //primary version
  const [volumePlayerTwo, setVolumePlayerTwo] = useState({
    volume: 20,
    muted: false,
  }); //secondary version
  //selected version to highlight in select version modal
  const [selectedVersion, setSelectedVersion] = useState();
  //to set opacity value of video for onTop view
  const [opacityValueOne, setOpacityValueOne] = useState(1);
  const [opacityValueTwo, setOpacityValueTwo] = useState(0.5);
  const [player, setPlayer] = useState("");
  const frameRate = 25;
  const comparePageRef = useRef(null);

  const canvasModalToggle = () => {
    setPlayerOne({ ...playerOne, isPlaying: false });
    setPlayerTwo({ ...playerTwo, isPlaying: false });
    setCanvasModal(!canvasModal);
  };

  const goTo = () => {
    if (props.trackableType === "shot") {
      history.push(
        `/${props.params.projectId}/sequence/${props.params.sid}/shots/${props.params.shotID}/shotversions`
      );
    } else {
      history.push(
        `/${props.params.projectId}/assets/${props.params.assetID}/assetversions`
      );
    }
  };

  const fetchVersions = async () => {
    let fetchObj;
    let versionData;
    if (props.trackableType === "shot") {
      fetchObj = {
        url: "web/shotversion/list/",
        data: {
          project_id: props.params.projectId,
          shot_id: props.params.shotID,
        },
      };
    } else {
      fetchObj = {
        url: "web/assetversion/list/",
        data: {
          project_id: props.params.projectId,
          asset_id: props.params.assetID,
        },
      };
    }

    try {
      const res = await fpostBFF(fetchObj);
      if (props.params.sid) {
        versionData = res.data.shotversion_list.results.filter(
          (v) => v.file?.slice(-4) === ".mp4"
        );
      } else {
        versionData = res.data.assetversion_list.results.filter(
          (v) => v.file?.slice(-4) === ".mp4"
        );
      }
      setVersions(versionData);
      setAllUsersName(
        res.data.project_users.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFile1 = async () => {
    let res;
    try {
      if (props.trackableType === "shot") {
        res = await fget({
          url: `trackables/shotversion/${
            playerOne.version.id || props.params.shotVID
          }/file`,
        });
      } else {
        res = await fget({
          url: `trackables/assetversion/${
            playerOne.version.id || props.params.avID
          }/file`,
        });
      }
      setPlayerOne({ ...playerOne, fileURL: res.data.signed_url });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFile2 = async () => {
    let res2;
    try {
      if (props.trackableType === "shot") {
        res2 = await fget({
          url: `trackables/shotversion/${playerTwo.version.id || ""}/file`,
        });
      } else {
        res2 = await fget({
          url: `trackables/assetversion/${playerTwo.version.id || ""}/file`,
        });
      }
      setPlayerTwo({ ...playerTwo, fileURL: res2.data.signed_url });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //fetches the list of asset/shot
    fetchVersions();
  }, []);

  useEffect(() => {
    const id =
      props.trackableType === "shot" ? props.params.shotVID : props.params.avID;
    versions?.map((eachVersion) => {
      if (eachVersion.id == id) {
        setPlayerOne({ ...playerOne, version: eachVersion });
      }
    });
  }, [versions]);

  useEffect(() => {
    //get file signed url.
    if (playerOne.version) {
      fetchFile1();
    }
    return () => {};
  }, [playerOne.version]);

  useEffect(() => {
    //get file signed url.
    fetchFile2();
    return () => {};
  }, [playerTwo.version]);

  useEffect(() => {
    if (currentTimeSeekOne) {
      refPlayerOne.current.currentTime = currentTimeSeekOne;
    }
  }, [currentTimeSeekOne]);

  useEffect(() => {
    if (currentTimeSeekTwo && refPlayerTwo.current) {
      refPlayerTwo.current.currentTime = currentTimeSeekTwo;
    }
  }, [currentTimeSeekTwo]);

  useEffect(() => {
    if (playerOne.isPlaying) {
      refPlayerOne.current.play();
    } else {
      refPlayerOne.current.pause();
    }
  }, [playerOne.isPlaying]);

  useEffect(() => {
    if (playerTwo.isPlaying) {
      refPlayerTwo?.current?.play();
    } else {
      refPlayerTwo?.current?.pause();
    }
  }, [playerTwo.isPlaying]);

  useEffect(() => {
    if (refPlayerOne.current) {
      refPlayerOne.current.volume = volumePlayerOne.volume / 100;
      refPlayerOne.current.muted = volumePlayerOne.muted;
    }
  }, [volumePlayerOne]);

  useEffect(() => {
    if (refPlayerTwo.current) {
      refPlayerTwo.current.volume = volumePlayerTwo.volume / 100;
      refPlayerTwo.current.muted = volumePlayerTwo.muted;
    }
  }, [volumePlayerTwo]);

  const handleOnPrevious = (player) => {
    if (player.current) {
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime - 1 / frameRate
      );
    }
  };

  const handleOnNext = (player) => {
    if (player.current) {
      player.current.currentTime = Math.min(
        player.current.duration,
        player.current.currentTime + 1 / frameRate
      );
    }
  };

  const onRefactor = () => {
    setPlayerOne({ ...playerOne, isPlaying: false });
    setPlayerTwo({ ...playerTwo, isPlaying: false });
    setCurrentTimeSeekTwo(currentDurationOne);
    setCurrentTimeSeekOne(currentDurationOne);
  };

  const openModal = (p) => {
    if (p === "1") {
      setPlayer("1");
      setSelectedVersion(playerOne.version);
    } else if (p === "2") {
      setPlayer("2");
      setSelectedVersion(playerTwo.version);
    }
    setModalOpen(true);
  };

  const loopVersions = () => {
    if (refPlayerOne.current.ended && refPlayerTwo.current.ended) {
      refPlayerOne.current?.play();
      refPlayerTwo.current?.play();
    }
  };

  const handlePlayPauseMain = () => {
    setCurrentTimeSeekTwo(currentDurationOne);
    setCurrentTimeSeekOne(currentDurationOne);
    setPlayerOne({ ...playerOne, isPlaying: !playerOne.isPlaying });
    setPlayerTwo({ ...playerTwo, isPlaying: !playerOne.isPlaying });
  };

  const handlePreviousMain = () => {
    setPlayerOne({ ...playerOne, isPlaying: false });
    setPlayerTwo({ ...playerTwo, isPlaying: false });
    handleOnPrevious(refPlayerOne);
    handleOnPrevious(refPlayerTwo);
  };

  const handleNextMain = () => {
    setPlayerOne({ ...playerOne, isPlaying: false });
    setPlayerTwo({ ...playerTwo, isPlaying: false });
    handleOnNext(refPlayerOne);
    handleOnNext(refPlayerTwo);
  };

  const handleSeekKeyboardEvent = (event) => {
    switch (true) {
      case event.key === " ":
        handlePlayPauseMain();
        break;
      case event.keyCode === 188 || event.key === ",":
        handlePreviousMain();
        break;
      case event.keyCode === 190 || event.key === ".":
        handleNextMain();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    comparePageRef.current.focus();
  }, []);

  return (
    <>
      <Modal
        open={isModalOpen}
        className={classes.comparePageSelectVersionModal}
      >
        <SelectVersionModal
          versions={versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          setModalOpen={setModalOpen}
          setPlayerTwo={setPlayerTwo}
          setPlayerOne={setPlayerOne}
          player={player}
          closeModal={() => setModalOpen(false)}
        />
      </Modal>
      <div>
        <Modal
          className={classes.comparePageSelectVersionModal}
          open={canvasModal}
        >
          <CanvasModal
            screenshot={screenshot}
            allUsersName={allUsersName}
            canvasModalToggle={canvasModalToggle}
            currentDuration={
              player == "1" ? currentDurationOne : currentDurationTwo
            }
            version_id={
              player == "1" ? playerOne.version?.id : playerTwo.version?.id
            }
            assetId={
              props.trackableType === "shot"
                ? player == "1"
                  ? playerOne.version?.shot?.id
                  : playerTwo.version?.shot?.id
                : player == "1"
                ? playerOne.version?.asset?.id
                : playerTwo.version?.asset?.id
            }
          />
        </Modal>
      </div>
      <div
        ref={comparePageRef}
        tabIndex={0}
        onKeyDown={handleSeekKeyboardEvent}
        style={{
          height: "calc(100vh - 140px)",
          maxWidth: "100%",
          outline: "none",
        }}
      >
        <ComparePageTool
          changeView={() => setViewType(!viewType)}
          goTo={goTo}
        />
        {/* S-B-S view */}
        {viewType ? (
          <>
            <div
              style={{
                height: "55%", //triggerer
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  height: "100%",
                  margin: "10px 20px",
                  width: "50%",
                }}
              >
                <VideoPlayer
                  id="version1"
                  refPlayer={refPlayerOne}
                  isPlaying={playerOne.isPlaying}
                  currentDuration={currentDurationOne}
                  totalDuration={playerOne.totalDuration}
                  fileURL={playerOne.fileURL}
                  volume={volumePlayerOne.volume}
                  muted={volumePlayerOne.muted}
                  currentTimeSeek={currentTimeSeekOne}
                  onVolumeChange={(volume) =>
                    setVolumePlayerOne({ ...volumePlayerOne, volume: volume })
                  }
                  onMute={(value) => {
                    setVolumePlayerOne({ ...volumePlayerOne, muted: value });
                  }}
                  //sets the custom seekbar current time according to players current time
                  onProgress={(duration) => setCurrentDurationOne(duration)}
                  setCurrentTimeSeek={setCurrentTimeSeekOne}
                  setTotalDuration={(duration) =>
                    setPlayerOne({ ...playerOne, totalDuration: duration })
                  }
                  //sets the players current time according to the seek bar
                  handlePlayPause={(play) =>
                    setPlayerOne({ ...playerOne, isPlaying: play })
                  }
                  handlePrevious={() => {
                    setPlayerOne({ ...playerOne, isPlaying: false });
                    handleOnPrevious(refPlayerOne);
                  }}
                  handleNext={() => {
                    setPlayerOne({ ...playerOne, isPlaying: false });
                    handleOnNext(refPlayerOne);
                  }}
                  loopVersions={loopVersions}
                />
              </div>
              <div
                style={{ height: "100%", margin: "10px 20px", width: "50%" }}
              >
                {playerTwo.fileURL ? (
                  <VideoPlayer
                    id="version2"
                    refPlayer={refPlayerTwo}
                    isPlaying={playerTwo.isPlaying}
                    currentDuration={currentDurationTwo}
                    totalDuration={playerTwo.totalDuration}
                    fileURL={playerTwo.fileURL}
                    volume={volumePlayerTwo.volume}
                    muted={volumePlayerTwo.muted}
                    onVolumeChange={(volume) =>
                      setVolumePlayerTwo({ ...volumePlayerTwo, volume: volume })
                    }
                    onMute={(muted) => {
                      setVolumePlayerTwo({ ...volumePlayerTwo, muted: muted });
                    }}
                    //sets the custom seekbar current time according to players current time
                    onProgress={(duration) => setCurrentDurationTwo(duration)}
                    setTotalDuration={(duration) =>
                      setPlayerTwo({ ...playerTwo, totalDuration: duration })
                    }
                    //sets the players current time according to seek bar
                    setCurrentTimeSeek={setCurrentTimeSeekTwo}
                    handlePlayPause={(play) =>
                      setPlayerTwo({ ...playerTwo, isPlaying: play })
                    }
                    handlePrevious={() => {
                      setPlayerTwo({ ...playerTwo, isPlaying: false });
                      handleOnPrevious(refPlayerTwo);
                    }}
                    handleNext={() => {
                      setPlayerTwo({ ...playerTwo, isPlaying: false });
                      handleOnNext(refPlayerTwo);
                    }}
                    loopVersions={loopVersions}
                  />
                ) : (
                  <div
                    className={classes.selectVersion}
                    onClick={() => openModal("2")}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ transform: "rotate(-34.38deg)" }}>
                        <MouseSimple size={40} weight="duotone" />
                      </div>
                      <div>Click to Compare with a version</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                height: "37%",
                marginTop: "40px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={classes.videoPlayerCard}>
                <div
                  style={{
                    width: "100%",
                    height: "30%",
                    pointerEvents: playerTwo.fileURL ? "" : "none",
                    opacity: playerTwo.fileURL ? "1" : "0.4",
                  }}
                >
                  <VideoController
                    type="main"
                    isPlaying={playerOne.isPlaying}
                    totalDuration={playerOne.totalDuration}
                    currentDuration={currentDurationOne}
                    volume={volumePlayerOne.volume}
                    muted={volumePlayerOne.muted}
                    //sets the players current time according to seek bar
                    onTimeSeek={(time) => {
                      setCurrentTimeSeekOne(time);
                      setCurrentTimeSeekTwo(time);
                    }}
                    handlePlayPause={handlePlayPauseMain}
                    handlePrevious={handlePreviousMain}
                    handleNext={handleNextMain}
                    onVolumeChange={(volume) => {
                      setVolumePlayerOne({
                        ...volumePlayerOne,
                        volume: volume,
                      });
                      setVolumePlayerTwo({
                        ...volumePlayerTwo,
                        volume: volume,
                      });
                    }}
                    onMute={(v) => {
                      setVolumePlayerOne({ ...volumePlayerOne, muted: v });
                      setVolumePlayerTwo({ ...volumePlayerTwo, muted: v });
                    }}
                    onRefactor={onRefactor}
                  />
                </div>
                <div style={{ width: "100%", height: "75%" }}>
                  <ComparePageCard
                    isSideBySide={viewType}
                    playerOneVersion={playerOne.version}
                    playerTwoVersion={playerTwo.version}
                    versions={versions}
                    refPlayerOne={refPlayerOne}
                    refPlayerTwo={refPlayerTwo}
                    canvasModalToggle={canvasModalToggle}
                    setScreenshot={setScreenshot}
                    setPlayerTwo={setPlayerTwo}
                    url={playerTwo.fileURL}
                    playBoth={() => {
                      setCurrentTimeSeekTwo(currentDurationOne);
                      setCurrentTimeSeekOne(currentDurationOne);
                      setPlayerOne({ ...playerOne, isPlaying: true });
                      setPlayerTwo({ ...playerTwo, isPlaying: true });
                    }}
                    setPlayer={setPlayer}
                    openModal={openModal}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "95%", //triggerer
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1%",
              }}
            >
              <div
                style={{
                  width: "65%",
                  height: "100%",
                  marginLeft: "1%",
                }}
              >
                {/* playes two video on top of each other */}
                <CombineVideoPlayer
                  playerOne={playerOne}
                  playerTwo={playerTwo}
                  setPlayerOne={setPlayerOne}
                  setPlayerTwo={setPlayerTwo}
                  volumePlayerOne={volumePlayerOne}
                  volumePlayerTwo={volumePlayerTwo}
                  refPlayerOne={refPlayerOne}
                  refPlayerTwo={refPlayerTwo}
                  setCurrentDurationOne={setCurrentDurationOne}
                  setCurrentDurationTwo={setCurrentDurationTwo}
                  opacityValueOne={opacityValueOne}
                  opacityValueTwo={opacityValueTwo}
                  currentDuration={currentDurationOne}
                  //sets the players current time according to seek bar
                  onTimeSeek={(time) => {
                    setCurrentTimeSeekOne(time);
                    setCurrentTimeSeekTwo(time);
                  }}
                  handlePlayPause={handlePlayPauseMain}
                  handlePrevious={handlePreviousMain}
                  handleNext={handleNextMain}
                  onVolumeChange={(volume) => {
                    setVolumePlayerOne({ ...volumePlayerOne, volume: volume });
                    setVolumePlayerTwo({ ...volumePlayerTwo, volume: volume });
                  }}
                  onMute={(v) => {
                    setVolumePlayerOne({ ...volumePlayerOne, muted: v });
                    setVolumePlayerTwo({ ...volumePlayerTwo, muted: v });
                  }}
                  onRefactor={onRefactor}
                  setOpacityValueOne={setOpacityValueOne}
                  setOpacityValueTwo={setOpacityValueTwo}
                  onMuteOne={(volume) => {
                    setVolumePlayerOne({ ...volumePlayerOne, volume: volume });
                  }}
                  onMuteTwo={(volume) => {
                    setVolumePlayerTwo({ ...volumePlayerTwo, volume: volume });
                  }}
                  loopVersions={loopVersions}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  width: "30%",
                  margin: "9px",
                }}
              >
                <ComparePageCard
                  isSideBySide={viewType}
                  opacityValueOne={opacityValueOne}
                  changeOpacityValueOne={setOpacityValueOne}
                  opacityValueTwo={opacityValueTwo}
                  changeOpacityValueTwo={setOpacityValueTwo}
                  playerOne={playerOne}
                  playerTwo={playerTwo}
                  setPlayerOne={setPlayerOne}
                  setPlayerTwo={setPlayerTwo}
                  volumePlayerOne={volumePlayerOne.volume}
                  setVolumeOne={(value) =>
                    setVolumePlayerOne({ ...volumePlayerOne, volume: value })
                  }
                  volumePlayerTwo={volumePlayerTwo.volume}
                  setVolumeTwo={(value) =>
                    setVolumePlayerTwo({ ...volumePlayerTwo, volume: value })
                  }
                  refPlayerOne={refPlayerOne}
                  refPlayerTwo={refPlayerTwo}
                  setCurrentTimeSeekOne={setCurrentTimeSeekOne}
                  setCurrentTimeSeekTwo={setCurrentTimeSeekTwo}
                  currentDurationOne={currentDurationOne}
                  currentDurationTwo={currentDurationTwo}
                  handleOnPrevious={handleOnPrevious}
                  handleOnNext={handleOnNext}
                  playBoth={() => {
                    setCurrentTimeSeekTwo(currentDurationOne);
                    setCurrentTimeSeekOne(currentDurationOne);
                    setPlayerOne({ ...playerOne, isPlaying: true });
                    setPlayerTwo({ ...playerTwo, isPlaying: true });
                  }}
                  versions={versions}
                  setPlayer={setPlayer}
                  canvasModalToggle={canvasModalToggle}
                  setScreenshot={setScreenshot}
                  openModal={openModal}
                  mutedOne={volumePlayerOne.muted}
                  mutedTwo={volumePlayerTwo.muted}
                  onMuteOne={(v) => {
                    setVolumePlayerOne({ ...volumePlayerOne, muted: v });
                  }}
                  onMuteTwo={(v) => {
                    setVolumePlayerTwo({ ...volumePlayerTwo, muted: v });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
