import { Grid } from "@material-ui/core";
import React from "react";
import ProjectMediaFile from "./ProjectMediaFile";
import ProjectMediaFolder from "./ProjectMediaFolder";

const ProjectMediaCardView = ({
  allFolders,
  handleSelectUnselectMedia,
  projectId,
  allFiles,
  openEditFolderModal,
  setDeleteFolderModal,
  handleCopyFolderLink,
  handleSelectUnselectFiles,
  selectedMediasFolder,
  selectedMediaFiles,
  handleOpenEditMediaModal,
  getFileUrl,
  handleOpenDeleteMediaModal = { handleOpenDeleteMediaModal },
  searchQuery,
}) => {
  return (
    <Grid container spacing={2}>
      {allFolders
        .filter((search) => {
          if (search.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
            return search;
          }
        })
        .map((eachFolder) => {
          return (
            <ProjectMediaFolder
              key={eachFolder.id}
              eachFolder={eachFolder}
              handleSelectUnselectMedia={handleSelectUnselectMedia}
              selectedMedias={selectedMediasFolder}
              projectId={projectId}
              openEditFolderModal={openEditFolderModal}
              setDeleteFolderModal={setDeleteFolderModal}
              handleCopyFolderLink={handleCopyFolderLink}
            />
          );
        })}
      {allFiles
        .filter((search) => {
          if (
            search.file_name?.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return search;
          }
        })
        .map((eachFile) => {
          return (
            <ProjectMediaFile
              key={eachFile.id}
              eachFile={eachFile}
              handleSelectUnselectMedia={handleSelectUnselectFiles}
              selectedMedias={selectedMediaFiles}
              handleOpenEditMediaModal={handleOpenEditMediaModal}
              handleOpenDeleteMediaModal={handleOpenDeleteMediaModal}
              getFileUrl={getFileUrl}
            />
          );
        })}
    </Grid>
  );
};

export default ProjectMediaCardView;
