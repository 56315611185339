import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  commentsTools: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    color: theme.palette.primary.light,
    height: "8%",
  },
  commentsContainer: {
    height: "88%",
    overflowY: "auto",
    padding: "0px 20px 0px 10px",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authorName: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: "30px",
    width: "30px",
  },
  editComment: {
    marginLeft: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  commentsDetailsAndButtons: {
    marginLeft: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  commentsDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "10px",
  },
  date: {
    color: theme.palette.primary.light,
    display: "flex",
    gap: "5px",
  },
  text: {
    color: theme.palette.primary.light,
  },
  descriptionText: {
    color: theme.palette.text.tertiary,
  },
  versionName: {
    color: theme.palette.secondary.main,
  },
  attachmentIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    "&:hover ": {
      textDecoration: "underline",
    },
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  icon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover ": {
      color: theme.palette.primary.light,
    },
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    backgroundColor: theme.palette.secondary.light,
    padding: "10px 8px",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  modals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editInput: {
    color: theme.palette.text.tertiary,
    padding: "0px",
  },
  commentInput: {
    // height: "30px",
    padding: "5px",
    overflow: "hidden",
    backgroundColor: theme.palette.text.primary,
  },
  saveButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "30px",
  },
  cancelButton: {
    backgroundColor: "transparent",
    color: theme.palette.primary.light,
    height: "30px",
    "&:hover ": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
    },
  },
  statusDropDown: {
    display: "flex",
    alignItems: "center",
    width: "190px",
  },
  input: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "0px",
    width: "160px",
    color: theme.palette.primary.light,
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
    color: "red",
  },
  filterText: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  showReplays: {
    cursor: "pointer",
    color: theme.palette.primary.light,
    marginLeft: "40px",
  },
  menuDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: "0px",
    width: "140px",
    height: "80px",
  },
  menuButton: {
    minWidth: "120px",
    height: "30px",
    padding: "4px",
  },
  editAndCloseButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    fontSize: "14px",
    gap: "5px",
  },
}));

export default useStyles;
