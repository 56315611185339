import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Slider,
  Typography,
} from "@material-ui/core";
import * as Icon from "react-feather";
import mergeImages from "merge-images";

import useStyles from "./CanvasFrame.styles";
import { fget, fpost } from "../../API/callsAPI";
import {
  X,
  Eraser,
  PaintBrush,
  TextT,
  Cursor,
  ArrowCounterClockwise,
} from "phosphor-react";
import PostEditor from "../PostEditor/postEditor";
import { ReactSketchCanvas } from "moviecolab-canvas-sketch";

const CanvasFrame = (props) => {
  const classes = useStyles();
  const [color, setColor] = useState("#000000");
  const [brushRadius, setBrushRadius] = useState(10);
  const [fontSize, setFontSize] = useState(18);
  const [opacity, setOpacity] = useState(10);
  // const [lazyRadius, setLazyRadius] = useState(2);
  // const [zoomCanvas, setzoomCanvas] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 800, height: 500 });
  const [currentMode, setCurrentMode] = useState("draw");

  const canvasRef = useRef(null);
  const reactSketchCanvas = useRef(null);
  //loader state
  const [isSubmitCommentLoading, setisSubmitCommentLoading] = useState(false);

  let mentionsRef = React.createRef();

  function getDataUrl(img) {
    // Create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // Set width and height
    // Set width and height

    // get the top left position of the image

    if (img.height >= img.width) {
      canvas.width = dimensions.width;
      canvas.height = dimensions.height;
      var scale = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );
      // get the top left position of the image
      var x = canvas.width / 2 - (img.width / 2) * scale;
      var y = canvas.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      return canvas.toDataURL("image/jpeg");
    } else {
      canvas.width = dimensions.width;
      canvas.height = (canvas.width / img.width) * img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL("image/jpeg");
    }
  }
  var i = new Image();
  i.src = props.imageRef ? getDataUrl(props.imageRef) : props.image;

  const handleSubmit = async (description, mentions) => {
    setisSubmitCommentLoading(true);

    reactSketchCanvas?.current?.exportImage("png").then(async (b64) => {
      const imageSrc = b64;
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const file = new File([blob], "img.png", { type: "image/png" });
      let formdata = new FormData();
      if (props.isReviewVersionViewer) {
        // props.versionId
        let time = props.imageRef ? "" : props.progress;
        formdata.append("text", time + description);
        formdata.append("attachment", file);
        formdata.append("version", props.versionId);
        const dataObj = {
          url: `review-version/${props.versionId}/comments/`,
          data: formdata,
        };
        try {
          const commentRes = await fpost(dataObj);
          if (commentRes.status === 200 || 201) {
            setisSubmitCommentLoading(false);
            props.fetchComments();
            props.toggle();
          }
        } catch (error) {
          setisSubmitCommentLoading(false);
          console.log(error);
        }
      } else {
        let time = props.imageRef ? "" : props.progress;
        formdata.append("description", time + description);
        formdata.append("trackable", props.assetID);
        formdata.append("attachment", file);
        formdata.append("version_id", props.version_id);
        // formdata.append("version_type", props.version_type);
        formdata.append("mentions[]", mentions);
        let newSequence = {
          url: "trackables/trackable-comment/",
          data: formdata,
        };

        try {
          const newCommentRes = await fpost(newSequence);
          if (newCommentRes.status === 200 || 201) {
            setisSubmitCommentLoading(false);
            if (props?.updateCommentsRefreshCounter) {
              props?.updateCommentsRefreshCounter();
            }
            props?.fetchComments && props.fetchComments();
            if (props.isUpdate) {
              const userRes = await fget({
                url: `user/${newCommentRes.data.author}`,
              });
              newCommentRes.data.author = { ...userRes?.data };
              props.setCommentList((prevComments) => [
                newCommentRes.data,
                ...prevComments,
              ]);
            }
            if (props.toggle2) {
              props.toggle2();
            }
            props.toggle();
          }
        } catch (error) {
          setisSubmitCommentLoading(false);
          console.log(error);
        }
      }
    });
  };

  const colour = [
    "#000000",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#FF8888",
    "#FFE5E5",
    "#FFC1C1",
    "#FFC259",
    "#A09B6C",
    "#B1DD27",
    "#7EFE00",
    "#4DFFDB",
    "#5FD3C0",
    "#AB94DC",
    "#FF4DDB",
    "#F4939D",
  ];

  const handleResize = () => {
    if (canvasRef?.current) {
      setDimensions({
        width: canvasRef.current?.clientWidth,
        height: canvasRef.current?.clientHeight,
      });
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSelectClick = () => {
    if (reactSketchCanvas.current) {
      reactSketchCanvas.current.selectMode();
      setCurrentMode("select");
    }
  };

  const handleEraserClick = () => {
    if (reactSketchCanvas.current) {
      reactSketchCanvas.current.erasingMode();
      setCurrentMode("erase");
    }
  };
  const handlePencilClick = () => {
    if (reactSketchCanvas.current) {
      reactSketchCanvas.current.drawMode();
      setCurrentMode("draw");
    }
  };

  const handleTextClick = () => {
    if (reactSketchCanvas.current) {
      reactSketchCanvas.current.textMode();
      setCurrentMode("text");
    }
  };

  const handleResetAll = () => {
    if (reactSketchCanvas.current) {
      reactSketchCanvas.current.resetCanvas();
    }
  };

  const handleModeChange = () => {
    setCurrentMode("select");
  };

  return (
    <div className={classes.canvasDiv}>
      <div className={classes.modalHead}>
        <span className={classes.heading}>Canvas</span>
        <Button>
          <X
            onClick={() => props.closeModal()}
            className={classes.closeIcon}
            size={30}
          />
        </Button>
      </div>
      <div style={{ display: "flex", gap: "2%", height: "90%" }}>
        <div ref={canvasRef} className={classes.image}>
          <ReactSketchCanvas
            ref={reactSketchCanvas}
            height={dimensions.height}
            backgroundImage={
              props.imageRef ? getDataUrl(props.imageRef) : props.image
            }
            strokeColor={color}
            strokeWidth={brushRadius}
            opacity={opacity / 10}
            textSize={`${fontSize}px`}
            onSelectMode={handleModeChange}
          />
        </div>
        <div className={classes.anotationTools}>
          <div className={classes.annotationContainer}>
            <div className={classes.title}>Tools</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <Button
                size="small"
                className={
                  currentMode === "draw"
                    ? classes.btnActive
                    : classes.btnInactive
                }
              >
                <PaintBrush
                  size={28}
                  className={
                    currentMode === "draw"
                      ? classes.activeIconColor
                      : classes.iconColor
                  }
                  onClick={handlePencilClick}
                />
              </Button>
              <Button
                size="small"
                className={
                  currentMode === "text"
                    ? classes.btnActive
                    : classes.btnInactive
                }
                onClick={handleTextClick}
              >
                <TextT
                  size={28}
                  className={
                    currentMode === "text"
                      ? classes.activeIconColor
                      : classes.iconColor
                  }
                />
              </Button>
              <Button
                size="small"
                className={
                  currentMode === "select"
                    ? classes.btnActive
                    : classes.btnInactive
                }
                onClick={handleSelectClick}
              >
                <Cursor
                  size={28}
                  className={
                    currentMode === "select"
                      ? classes.activeIconColor
                      : classes.iconColor
                  }
                />
              </Button>
            </div>
            <hr style={{ margin: "4% 0px", border: "2px solid #606479" }} />
            {currentMode === "select" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  height: "60%",
                  marginLeft: "-30px",
                }}
              >
                <Cursor size={48} className={classes.iconColor} />
                <Typography align="center" className={classes.selectText}>
                  Move, adjust and resize
                </Typography>
              </div>
            ) : (
              <div>
                <div>
                  <span>Color</span>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {colour.map((currentColor, i) => {
                      return (
                        <div
                          key={i}
                          className={classes.colorStyle}
                          style={{
                            backgroundColor:
                              color !== currentColor && currentColor,
                            border: `5px solid ${currentColor}`,
                          }}
                          onClick={() => setColor(currentColor)}
                        ></div>
                      );
                    })}
                  </div>
                </div>
                <div style={{ marginTop: "5%" }}>
                  <span>
                    {currentMode === "text" ? "Font Size" : "Brush Size"}
                  </span>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "3%" }}
                  >
                    {currentMode === "text" ? (
                      <Slider
                        className={classes.slider}
                        min={10}
                        max={30}
                        defaultValue={18}
                        value={fontSize}
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        onChange={(e, value) => setFontSize(value)}
                      />
                    ) : (
                      <Slider
                        className={classes.slider}
                        defaultValue={2}
                        value={brushRadius * 5}
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        onChange={(e, value) => setBrushRadius(value / 5)}
                      />
                    )}
                    <label>
                      {currentMode === "text"
                        ? `${fontSize}px`
                        : `${brushRadius * 5}px`}
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <span>Opacity</span>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "3%" }}
                  >
                    <Slider
                      className={classes.slider}
                      defaultValue={1}
                      value={opacity}
                      min={0}
                      max={10}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      onChange={(e, value) => setOpacity(value)}
                    />
                    <label>{opacity}px</label>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.buttonContainer}>
              <div className={classes.btncontainer1}>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleEraserClick}
                >
                  <Eraser
                    size={28}
                    className={
                      currentMode === "erase"
                        ? classes.activeIconColor
                        : classes.iconColor
                    }
                  />
                </Button>
                <span className={classes.label}>Earser</span>
              </div>
              <div className={classes.btncontainer2}>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleResetAll}
                >
                  <ArrowCounterClockwise size={28} />
                </Button>
                <span className={classes.label}>Reset</span>
              </div>
              {/* <div className={classes.btncontainer2}>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={(e) => {
                  e.preventDefault();
                  saveableCanvas.undo();
                }}
              >
                <ArrowUUpLeft size={28} />
              </Button>
              <span className={classes.label}>Undo Changes</span>
            </div> */}
            </div>
          </div>
          <div className={classes.commentBoxContainer}>
            <div className={classes.commentInput}>
              <PostEditor
                placeholder={"Type a comment"}
                mentions={props.users}
                ref={mentionsRef}
                handleSubmit={() => {
                  handleSubmit(
                    mentionsRef.current.toHtml(),
                    mentionsRef.current.onExtractMentions()
                  );
                }}
              />
            </div>
            <Button
              className={classes.sendButton}
              size="small"
              onClick={() => {
                handleSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions()
                );
              }}
              disabled={isSubmitCommentLoading}
            >
              {isSubmitCommentLoading ? (
                <CircularProgress />
              ) : (
                <Icon.Send className={classes.sendIcon} size="20px" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasFrame;
