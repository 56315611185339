import React, { useEffect, useState } from "react";
import { Typography, Button, TextField, Box } from "@material-ui/core";
import { X, MagnifyingGlass, Check } from "phosphor-react";
import useStyles from "./NewEpisodeModal.styles";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";

function AddSequenceEpisodeModal({
  selectedSequenceList,
  handleSequenceModalClose,
  sequenceList,
  onSubmit,
}) {
  const classes = useStyles();
  const [selectedSequences, setSelectedSequences] = useState([]);
  const [sequenceName, setSequenceName] = useState("");

  const handleSequenceSelect = (sequence) => {
    const exist = selectedSequences.some(
      (eachSequence) => eachSequence.id === sequence.id
    );
    if (exist) {
      setSelectedSequences(
        selectedSequences.filter((each) => each.id !== sequence.id)
      );
    } else {
      setSelectedSequences([...selectedSequences, sequence]);
    }
  };

  useEffect(() => {
    setSelectedSequences(selectedSequenceList);
  }, [selectedSequenceList]);

  const handleCancel = () => {
    setSequenceName("");
    setSelectedSequences(selectedSequenceList);
  };

  return (
    <div className={classes.root}>
      <div className={classes.flexOne}>
        <Typography className={classes.heading}>Add Sequences</Typography>
        <Button onClick={handleSequenceModalClose}>
          <X size={32} />
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          height: "50px",
          backgroundColor: "#252A38",
          borderRadius: "10px",
          padding: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="name"
          placeholder="Search Sequence"
          InputProps={{
            disableUnderline: true,
            style: {
              fontFamily: "Poppins",
            },
          }}
          value={sequenceName}
          onChange={(event) => setSequenceName(event.target.value)}
          className={classes.textInput}
        />
        <MagnifyingGlass size={22} color="#F9F9F9" />
      </div>
      <div className={classes.sequenceContainer}>
        {sequenceList &&
          sequenceList
            ?.filter((search) => {
              if (sequenceName === undefined) {
                return search;
              } else if (sequenceName === "") {
                return search;
              } else if (
                `${search.code}`
                  ?.toLowerCase()
                  .includes(sequenceName.toLowerCase())
              ) {
                return search;
              }
              return null;
            })
            .map((eachSeqence) => {
              return (
                <Box
                  key={eachSeqence.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: eachSeqence.parent_episode ? "default" : "pointer",
                    color: eachSeqence.parent_episode ? "#606479" : "#F9F9F9",
                  }}
                  onClick={() =>
                    !eachSeqence.parent_episode &&
                    handleSequenceSelect(eachSeqence)
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {eachSeqence.thumbnail ? (
                      <ImageViewer
                        sliderState={0}
                        url={eachSeqence.thumbnail}
                      />
                    ) : (
                      <NoThumbnail sliderState={0} />
                    )}
                    <Typography>{eachSeqence.code}</Typography>
                  </div>
                  {selectedSequences?.map((value) => {
                    return value.id === eachSeqence.id ? (
                      <span key={value.id}>
                        <Check
                          color="#6C65D9"
                          size={25}
                          className={classes.check}
                        />
                      </span>
                    ) : (
                      ""
                    );
                  })}
                </Box>
              );
            })}
      </div>
      <div className={classes.flexOne}>
        <Button className={classes.outlinedBtn} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={classes.coloredBtn}
          onClick={() => {
            onSubmit(selectedSequences);
            handleSequenceModalClose();
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default AddSequenceEpisodeModal;
