import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "33%",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px 24px 24px 24px",
  },
  flexDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.text.tertiary,
    fontSize: "14px",
  },
  btnStyle: {
    minWidth: "0px",
    padding: "12px 24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    fontFamily: "Poppins",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    backgroundColor: theme.palette.secondary.light,
    border: "none",
    padding: "10px",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "24px !important",
  },
  folderName: {
    fontFamily: "Poppins",
    fontSize: "16px !important",
  },
}));

export default useStyles;
