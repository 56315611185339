import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./ConfigureNotifications.style";
import { Grid, Button, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { fpost, fget, fpatch } from "../../../../API/callsAPI";
import * as Icon from "react-feather";

const ConfigureNotifications = ({ projectId, setSnackValue }) => {
  const classes = useStyles();
  const [showButtons, setShowButtons] = useState(false);
  const [notificationEvents, setnotificationEvents] = useState([]);
  const [value, setValue] = useState("");
  const [check, setCheck] = useState([]);
  const [allNotifications, setAllNotification] = useState([]);

  const handleRadioChange = (e) => {
    setValue(e.target.value);
    setShowButtons(true);
  };

  const handleOnChange = (e) => {
    setCheck({
      ...check,
      [e.target.name]: e.target.checked,
    });
    setShowButtons(true);
  };

  function transformNotification(notificationArr) {
    const result = [];
    const modal_type = [];

    for (const eachNotification of notificationArr) {
      if (!modal_type.includes(eachNotification.content_type)) {
        const values = notificationArr.filter(
          (obj) => obj.content_type === eachNotification.content_type
        );
        result.push({ name: eachNotification.content_type, values });
        modal_type.push(eachNotification.content_type);
      }
    }

    return result;
  }

  const fetchNotificationEvents = async () => {
    try {
      const res = await fget({
        url: `notification-types/${projectId}/`,
      });
      const eventsObj = {};
      res.data.results.forEach((eachNotification) => {
        eventsObj[eachNotification.event_type] = eachNotification.is_active;
      });
      setAllNotification(res.data.results);
      const transformedNotification = transformNotification(res.data.results);
      setnotificationEvents(transformedNotification);
      setCheck(eventsObj);

      const filteredEvent = res.data.results.filter(
        (eachEvent) => eachEvent.is_active === false
      );
      filteredEvent.length > 0 ? setValue("2") : setValue("1");
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmailConfiguration = async () => {
    const eventObj = {};
    if (value === "1") {
      allNotifications.map((eachNotification) => {
        eventObj[eachNotification.event_type] = true;
      });
    } else if (value === "2") {
      allNotifications.map((eachNotification) => {
        if (check[eachNotification.event_type] !== eachNotification.is_active) {
          eventObj[eachNotification.event_type] =
            check[eachNotification.event_type];
        }
      });
    }
    try {
      const res = await fpatch({
        url: `configure-notifications/`,
        data: {
          project: projectId,
          events: eventObj,
        },
      });
      if (res.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: "Changes Saved Successfully",
          isError: false,
        });
        fetchNotificationEvents();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const discardChanges = () => {
    const eventsObj = {};
    allNotifications.forEach((eachNotification) => {
      eventsObj[eachNotification.event_type] = eachNotification.is_active;
    });
    setCheck(eventsObj);
    setSnackValue({
      isOpen: true,
      message: "Changes Discarded Successfully",
      isError: false,
    });
  };

  useEffect(() => {
    fetchNotificationEvents();
  }, []);

  return (
    <>
      <div className={classes.divContent}>
        <div className={classes.statusSettingTitle}>
          Configure Notifications
        </div>

        <div className={classes.divContent1}>
          {showButtons ? (
            <>
              <Button
                className={classes.saveChangesButton}
                onClick={() => changeEmailConfiguration()}
              >
                <Icon.Save
                  style={{
                    marginRight: "6%",
                    width: "18px",
                    marginBottom: "1px",
                  }}
                />
                Save Changes
              </Button>

              <Button
                className={classes.discardChangeButton}
                onClick={() => discardChanges()}
              >
                <Icon.X
                  style={{
                    marginRight: "6%",
                    width: "18px",
                    marginBottom: "1px",
                  }}
                />
                Discard Changes
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <FormControl className={classes.root}>
        <RadioGroup
          name="Radio Form"
          value={value}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            classes={{
              label: classes.formControlLabel,
            }}
            value="1"
            control={<Radio />}
            label="Send me everything"
          />
          <FormControlLabel
            classes={{
              label: classes.formControlLabel,
            }}
            value="2"
            control={<Radio />}
            label="Let me choose my notifications"
          />
        </RadioGroup>
      </FormControl>
      <div
        style={{
          maxHeight: "calc(100vh - 545px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {value === "2" ? (
          <Grid container style={{ flexDirection: "column" }}>
            {notificationEvents.map((eachNotification) => {
              return (
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#606479",
                      marginLeft: "10px",
                    }}
                  >
                    {eachNotification.name.charAt(0).toUpperCase() +
                      eachNotification.name.slice(1)}{" "}
                    notification
                  </Typography>
                  <Grid container justify="between">
                    {eachNotification.values.map((notification, index) => {
                      return (
                        <Grid item xs={2} sm={2}>
                          <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              checked={check[notification.event_type]}
                              onClick={(e) => handleOnChange(e)}
                              name={notification.event_type}
                            />
                            <div>
                              <span className={classes.labelText}>
                                {notification.plain_text}
                              </span>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ConfigureNotifications;
