import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  iconsize: {
    height: "30px !important",
    width: "30px !important",
    [theme.breakpoints.down("lg")]: {
      height: "20px !important",
      width: "20px !important",
    },
  },
}));

export default useStyles;
