import {
  UnrealIcon,
  BlenderIcon,
  UnityIcon,
  MayaIcon,
  MaxIcon,
  HoudiniIcon,
} from "../Svg/TaskMetaDataIcons";

export const TaskMetaData = [
  {
    id: 1,
    icon: <UnrealIcon />,
    applicationName: "UE",
  },
  {
    id: 2,
    icon: <BlenderIcon />,
    applicationName: "Blender",
  },
  {
    id: 3,
    icon: <UnityIcon />,
    applicationName: "Unity",
  },
  {
    id: 4,
    icon: <MayaIcon />,
    applicationName: "Maya",
  },
  {
    id: 5,
    icon: <MaxIcon />,
    applicationName: "Max",
  },
  {
    id: 6,
    icon: <HoudiniIcon />,
    applicationName: "Houdini",
  },
];
