import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  //common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexAround: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  icon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  grayText: {
    color: theme.palette.text.tertiary,
  },

  //project health
  projectHealthText: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "5px",
  },
  hiUserName: {
    padding: "3px 0px",
    height: "7%",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  userName: {
    color: theme.palette.secondary.main,
  },
  allDepartmentGraph: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "20px",
    paddingLeft: "5px",
    height: "33%",
  },

  // Project status Styles
  styleRoot: {
    height: "100%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "20px",
    padding: "0px 10px",
  },
  healthText: {
    fontSize: "24px",
    fontWeight: 500,
    padding: "5px 0px",
  },
  taskCOmpletionText: {
    textAlign: "center",
    fontSize: "10px",
    marginTop: "5px",
    color: theme.palette.text.tertiary,
  },
  textFirst: {
    background:
      "conic-gradient(from 18.43deg at 50.33% 50.33%, #C77617 -254.97deg, #DE3F3F 22.49deg, #C77617 105.03deg, #DE3F3F 382.49deg)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  textSecond: {
    background:
      "conic-gradient(from 18.43deg at 50.33% 50.33%, #C7B517 -208.88deg, #DE653F 22.03deg, #C7B517 151.12deg, #DE653F 382.03deg)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  textThird: {
    background:
      "conic-gradient(from 152.31deg at 49.67% 50.33%, #DEC53F -203.31deg, #A4C717 1.67deg, #DEC53F 156.69deg, #A4C717 361.67deg)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  textFourth: {
    background:
      "conic-gradient(from 18.43deg at 50.33% 50.33%, #7CE117 -154.48deg, #B5DE3F 22.49deg, #7CE117 205.52deg, #B5DE3F 382.49deg)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  textFifth: {
    background:
      "conic-gradient(from 70.28deg at 50.33% 50.33%, #36A600 -2.65deg, #72DE3F 79.29deg, #36A600 357.35deg, #72DE3F 439.29deg)",
    color: "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  percentageBox: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  percentage: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "32px",
    fontWeight: 500,
  },

  //Department Wise Performance styles
  deptRoot: {
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(27, 29, 40, 0) 0%, rgba(27, 29, 40, 0.997917) 99.99%, #1B1D28 100%)",
  },
  departmentWiseText: {
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "10px 0px",
    height: "15%",
  },
  departments: {
    height: "85%",
    overflowY: "auto",
    paddingRight: "10px",
  },
  singleDepartmentCard: {
    borderRadius: "20px",
    backgroundColor: theme.palette.secondary.light,
    cursor: "pointer",
    padding: "0px !important",
  },
  singleDepartmentCardComponent: {
    padding: "5px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  departmentName: {
    fontSize: "16px",
  },
  percentagePlaceOne: {
    position: "relative",
    top: "-10px",
    left: "100px",
  },
  percentagePlaceTwo: {
    position: "relative",
    top: "80px",
    left: "190px",
  },
  percentagePlaceThree: {
    position: "relative",
    top: "150px",
    left: "20px",
  },
  percentagePlaceFour: {
    position: "relative",
    top: "30px",
    left: "8px",
  },

  //performance graph
  performanceRoot: {
    height: "100%",
    padding: "10px 10px 0px 10px",
  },
  performanceTools: {
    height: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropDownAndButtons: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  dropdownContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px 10px 0px 0px",
    padding: "0px 5px",
  },

  select: {
    padding: "0.2px 6px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "white",
    cursor: "pointer",
  },
  leftRightButton: {
    borderRadius: "10px",
    border: "2px solid #606479",
    padding: "4px 6px",
  },
}));

export default useStyles;
