import React, { useState } from "react";
import { Box, TextField, Typography, Button } from "@material-ui/core";
import useStyles from "./ProjectMediaNewAndEditFolder.styles";
import { X } from "phosphor-react";
import { fpatch, fpost } from "../../../../API/callsAPI";

function ProjectMediaNewAndEditFolder({
  type,
  handleClose,
  rootFolderId,
  projectId,
  currentFolder,
  getProjectFolderData,
}) {
  const classes = useStyles();
  const [name, setName] = useState(
    Object.keys(currentFolder).length === 0 ? "" : currentFolder.name
  );

  const createOrEditFolder = async () => {
    if (type === "edit") {
      const data = {
        url: `repository/${currentFolder.id}/`,
        data: { name: name },
      };
      try {
        await fpatch(data);
        getProjectFolderData();
        handleClose();
      } catch (error) {
        console.log("error");
      }
    } else {
      const formData = { project: projectId, name: name };
      if (rootFolderId) {
        formData.root = rootFolderId;
      }
      let newFolder = {
        url: "repository/",
        data: formData,
      };
      try {
        const res = await fpost(newFolder);
        getProjectFolderData();
        handleClose();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <div className={classes.flexDiv}>
        <Typography variant="h4" className={classes.text}>
          {type == "edit" ? "Edit Folder" : "Add New Folder"}
        </Typography>
        <Button onClick={handleClose}>
          <X size={22} />
        </Button>
      </div>
      <Box mt={2} mb={4} className={classes.flexColumn}>
        <Typography className={classes.folderName}>Folder Name</Typography>
        <TextField
          className={classes.textField}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          placeholder="Enter folder name"
          InputProps={{ disableUnderline: true }}
        />
      </Box>
      <Box className={classes.flexCenter}>
        <Button className={classes.btnStyle} onClick={createOrEditFolder}>
          {type === "edit" ? "Save" : "Add New Folder"}
        </Button>
      </Box>
    </Box>
  );
}

export default ProjectMediaNewAndEditFolder;
