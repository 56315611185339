import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";

import { X } from "react-feather";
import SelectTrackablesModal from "../TaskPageModals/SelectTrackablesModal";
import useStyles from "./MyTaskPageFiltersModal.styles";

export default function MyTaskPageFiltersModal({
  handleTaskFilter,
  closeModal,
  departments,
  projectUsers,
  taskStatus,
  trackableList,
  filtersForTask,
  clearFilter,
  setFiltersForTask,
  setCurrentPage,
  assetList,
  sequenceList,
  trackableType,
  selectedTrackable,
  setSelectedTrackable,
}) {
  const classes = useStyles();
  const [findAsset, setFindAsset] = useState(false);
  const [findSequence, setFindSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelectTrackableModalOpen, setIsSelectTrackableModalOpen] =
    useState(false);

  const onChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectTrackable = (data) => {
    const exist = selectedTrackable.some((eachUser) => eachUser.id === data.id);
    if (exist) {
      setSelectedTrackable(
        selectedTrackable?.filter((track) => track.id !== data.id)
      );
    } else {
      setSelectedTrackable([...selectedTrackable, data]);
    }
  };
  const handleSelectAllTrackable = (data) => {
    if (selectedTrackable?.length) {
      let tempArr = [];
      setSelectedTrackable([...selectedTrackable, ...data]);
      for (let index = 0; index < data.length; index++) {
        const selected = data[index];
        const exist = selectedTrackable.some(
          (single) => single.id === selected.id
        );
        if (!exist) {
          tempArr.push(selected);
        } else {
          console.log("not");
        }
      }
      setSelectedTrackable([...selectedTrackable, ...tempArr]);
    } else {
      setSelectedTrackable([...data]);
    }
  };

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className={classes.title}>Filters</h2>
            <div className={classes.clear} onClick={(e) => closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <div className={classes.cardContentDiv}>
            {/* <div className={classes.datePickerContainer}>
              <div>
                From
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedStartDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(date) => props.setSelectedStartDate(date)}
                      id="Startdate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div>
                To
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedEndDate}
                      onChange={(date) => props.setSelectedEndDate(date)}
                      id="Enddate-picker"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div> */}
          </div>
          <div>
            <label id="demo-simple-select">Enter search keyword</label>
            <TextField
              placeholder="Enter keyword"
              InputProps={{ disableUnderline: true }}
              value={filtersForTask.taskSearchQuery}
              className={classes.searchQuery}
              onChange={(event) =>
                setFiltersForTask({
                  ...filtersForTask,
                  taskSearchQuery: event.target.value,
                })
              }
              fullWidth
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Department</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.department}
              onChange={(e) =>
                setFiltersForTask({
                  ...filtersForTask,
                  department: e.target.value,
                })
              }
              name="department"
            >
              <MenuItem value="">All Departments</MenuItem>
              {departments &&
                departments.map((eachDepartment) => (
                  <MenuItem value={eachDepartment.id}>
                    {eachDepartment.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Assigned User</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.assigned_user}
              onChange={(e) =>
                setFiltersForTask({
                  ...filtersForTask,
                  assigned_user: e.target.value,
                })
              }
              name="department"
            >
              <MenuItem value="">All Users</MenuItem>
              {projectUsers &&
                projectUsers.map((eachUser) => (
                  <MenuItem value={eachUser.id}>
                    {eachUser.first_name} {eachUser.last_name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Status</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={filtersForTask.status}
              onChange={(e) =>
                setFiltersForTask({ ...filtersForTask, status: e.target.value })
              }
              name="status"
            >
              <MenuItem value="">None</MenuItem>
              {taskStatus &&
                taskStatus.map((taskStatus) => (
                  <MenuItem value={taskStatus.id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: `rgb(${taskStatus?.color})`,
                          borderRadius: "50%",
                          display: "inline-block",
                          marginRight: "10px",
                          height: "15px",
                          width: "15px",
                        }}
                      ></span>
                      <span style={{ width: "80%", overflow: "hidden" }}>
                        {taskStatus.name}
                      </span>
                    </div>
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className={classes.trackableContainer}>
            <span>Trackable</span>
            {filtersForTask.linked_class === "asset" ? (
              <Button
                className={classes.activeFilter}
                onClick={() =>
                  setFiltersForTask({
                    ...filtersForTask,
                    linked_class: "",
                  })
                }
                endIcon={<X />}
              >
                Assets
              </Button>
            ) : (
              <Button
                className={classes.inactiveFilter}
                onClick={() =>
                  setFiltersForTask({
                    ...filtersForTask,
                    linked_class: "asset",
                  })
                }
              >
                Assets
              </Button>
            )}
            {filtersForTask.linked_class === "shot" ? (
              <Button
                className={classes.activeFilter}
                onClick={() =>
                  setFiltersForTask({
                    ...filtersForTask,
                    linked_class: "",
                  })
                }
                endIcon={<X />}
              >
                Shots
              </Button>
            ) : (
              <Button
                className={classes.inactiveFilter}
                onClick={() =>
                  setFiltersForTask({
                    ...filtersForTask,
                    linked_class: "shot",
                  })
                }
              >
                Shots
              </Button>
            )}
          </div>
          <div className={classes.textFieldContainer}>
            <span>Select Trackable</span>
            <div
              className={classes.cardContentDiv2}
              style={{ marginTop: "5px" }}
            >
              <div
                onClick={() => setIsSelectTrackableModalOpen(true)}
                className={classes.cardContentInput1}
              />
              <Modal
                className={classes.flexCenter}
                open={isSelectTrackableModalOpen}
                onClose={() => setIsSelectTrackableModalOpen(false)}
              >
                <div className={classes.selectedTrackablesModal}>
                  <SelectTrackablesModal
                    disablePortal={trackableType ? true : false}
                    assetList={assetList}
                    sequenceList={sequenceList}
                    selectedTrackable={selectedTrackable}
                    setSelectedTrackable={setSelectedTrackable}
                    setAnchorEl={setIsSelectTrackableModalOpen}
                    findAsset={findAsset}
                    setFindAsset={setFindAsset}
                    findSequence={findSequence}
                    setFindSequence={setFindSequence}
                    setSelectedSequence={setSelectedSequence}
                    searchQuery={searchQuery}
                    selectedSequence={selectedSequence}
                    onChangeSearchQuery={onChangeSearchQuery}
                    handleSelectTrackable={handleSelectTrackable}
                    handleSelectAllTrackable={handleSelectAllTrackable}
                    handleUnSelectAllTrackable={() => setSelectedTrackable([])}
                  />
                </div>
              </Modal>
            </div>
          </div>
          <div className={classes.selectedTrackablesContainer}>
            {selectedTrackable?.map((singleTrackable) => {
              return (
                <div className={classes.singleTrack}>
                  <Tooltip title={singleTrackable?.name} placement="top">
                    <span className={classes.text}>
                      {singleTrackable?.name || singleTrackable?.code}
                    </span>
                  </Tooltip>{" "}
                  <X
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSelectTrackable(singleTrackable)}
                  />
                </div>
              );
            })}
          </div>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.doneButton}
            disabled={
              (filtersForTask.department ||
                filtersForTask.assigned_user ||
                filtersForTask.status ||
                filtersForTask.trackable) === ""
            } //enable only if change occured
            onClick={() => {
              setCurrentPage(1);
              handleTaskFilter();
              closeModal();
            }}
          >
            Done
          </Button>
          <Button
            size="small"
            style={{
              height: "40px",
              marginTop: "0.2rem",
            }}
            onClick={() => {
              clearFilter();
            }}
          >
            <X
              size="20"
              style={{ marginTop: "-0.2rem", paddingRight: "3px" }}
            />
            Clear All
          </Button>
        </div>
      </Card>
    </div>
  );
}
