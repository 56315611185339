import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { CloudArrowUp, X } from "phosphor-react";
import React, { useState } from "react";
import DragScreen from "../../../Utilities/DragScreen/DragScreen";
import useStyles from "./ProjectMediaAddMediaModal.style";
import UploadFileComponent from "./UploadFileComponent";

function ProjectMediaAddMediaModal({
  type,
  handleClose,
  handleDragScreen,
  setDragFileEnterModal,
  dragScreen,
  projectId,
  repositoryId,
  getProjectFilesData,
}) {
  const classes = useStyles();
  const [fileList, setFileList] = useState([]);

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      handleDragScreen(true);
      setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      handleDragScreen(false);
      setFileList([...e.dataTransfer.files]);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUpdateData = () => {
    getProjectFilesData();
    handleClose();
  };

  return (
    <Box className={classes.root}>
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={dragScreen} />
          <div className={classes.flexDiv}>
            <Typography variant="h5" className={classes.text}>
              Upload Media
            </Typography>
            <Button onClick={handleClose}>
              <X size={22} />
            </Button>
          </div>
          <Box mt={2} mb={6} className={classes.flexColumn}>
            <span>Add A Media File</span>
            <div className={classes.cardContentDiv2}>
              <label className={classes.label} for="thumbnailUpload">
                <div className={classes.fileIcon}>
                  <CloudArrowUp size={25} />
                </div>
                <div className={classes.labelText}>
                  Click to upload
                  <br />
                  or
                  <br />
                  Drag and Drop on the screen
                </div>
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/* video/*"
                id="thumbnailUpload"
                multiple
                onChange={(e) => setFileList(e.target.files)}
              />
            </div>
            {fileList?.length ? (
              <>
                {Array.from(
                  { length: fileList.length },
                  (_, index) => index + 1
                ).map((number) => (
                  <UploadFileComponent
                    file={fileList[number - 1]}
                    key={number}
                    projectId={projectId}
                    repositoryId={repositoryId}
                    isLastFile={fileList?.length === number}
                    handleUpdateData={handleUpdateData}
                  />
                ))}
              </>
            ) : (
              ""
            )}
          </Box>
          <Box className={classes.buttonsContainer}>
            <Button className={classes.containedButton} onClick={handleClose}>
              Close
            </Button>
            <Button className={classes.outlinedButton} onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProjectMediaAddMediaModal;
